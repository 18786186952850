import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../api/interceptor";
import "./Footer.css";

const { REACT_APP_BASE_URL } = process.env;

type IconsType = {
  link: string;
  logo: {
    url: string;
  };
};

type FooterType = {
  copyright: string;
  iconeSocial: {
    iconEmail: IconsType;
    iconYoutube: IconsType;
    iconLinkedin: IconsType;
  };
  indirizzo: string;
  logoFooter: { url: string };
};

export function Footer() {
  const [footer, setFooter] = React.useState<FooterType>();

  useEffect(() => {
    axiosInstance
      .get("footer")
      .then((res) => setFooter(res.data))
      .catch((err) => console.log(err));
  }, []);

  const openEmailClient = () => {
    const message = {
      emailId: "info@keybiz.it",
      subject: "",
      from: "",
    };

    const email = message.emailId;
    const subject = message.subject;
    const emailBody = message.from;
    document.location =
      "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  };

  return (
    <div className="footer-container">
      <div className="desktop-footer">
        <div className="logo-container">
          <div className="footer-line"></div>
          <img
            src={`${REACT_APP_BASE_URL}${footer?.logoFooter.url}`}
            alt="logo KB"
          />
          <div className="footer-line"></div>
        </div>
        <div className="content-container">
          <div className="info-container">
            <p>{`${footer?.indirizzo}`}</p>
            <p>00142, Roma</p>
            <p>P.IVA: 09304941009</p>
          </div>
          <div className="social-container">
            <a
              href={`${footer?.iconeSocial.iconLinkedin.link}`}
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconLinkedin.logo.url}`}
                alt="logo LinkedIn"
              />
            </a>
            <a
              href={`${footer?.iconeSocial.iconYoutube.link}`}
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconYoutube.logo.url}`}
                alt="logo YouTube"
              />
            </a>
            <a>
              <img
                src={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconEmail.logo.url}`}
                alt="logo Email"
                onClick={() => openEmailClient()}
              />
            </a>
          </div>
          <div className="terms-container">
            <Link to="/terms-of-service">
              <p>Terms of Service</p>
            </Link>
            <Link to="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/cookie-policy">
              <p>Cookies Policy</p>
            </Link>
          </div>
        </div>
        <div className="footer-copyright">
          <p>© 2022 KeyBiz srl</p>
        </div>
      </div>
      <div className="mobile-footer">
        <div className="seguiSocial">
          <div className="line" />
          <div> Seguici sui social!</div>
          <div className="line" />
        </div>
        <div className="iconContainer">
          <a
            href={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconLinkedin.link}`}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconLinkedin.logo.url}`}
              alt="logo LinkedIn"
            />
          </a>
          <a
            href={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconYoutube.link}`}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconYoutube.logo.url}`}
              alt="logo YouTube"
            />
          </a>
          <a>
            <img
              src={`${REACT_APP_BASE_URL}${footer?.iconeSocial.iconEmail.logo.url}`}
              alt="logo Email"
              onClick={() => openEmailClient()}
            />
          </a>
        </div>
        <div className="line" />
        <div className="box-informazioni">
          <div className="left-box">
            <span>Via Mario Bianchini, 47</span>
            <span>00142, Roma</span>
            <span>P.IVA: 09304941009</span>
          </div>
          <div className="right-box">
            <Link to="/terms-of-service">
              <span>Terms of Service</span>
            </Link>
            <Link to="/privacy-policy">
              <span>Privacy Policy</span>
            </Link>
            <Link to="/cookie-policy">
              <span>Cookies Policy</span>
            </Link>
          </div>
        </div>
        <div className="footer-copyright">
          <p>© 2022 KeyBiz srl</p>
        </div>
      </div>
    </div>
  );
}
