import React, { Children, useEffect } from "react";
import axiosInstance from "../../../api/interceptor";
import parse from "html-react-parser";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { AnimatedCircle } from "../../../animatedSVG/AnimatedCircle/AnimatedCircle";
import { ScrollingLine } from "../../../components/ScrollingLine/ScrollingLine";
import { shieldAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { Highlight } from "../../../components/Highlight/Highlight";
import { HighlightType } from "../../../types/types";

import "./SubCyberSecurity.css";

import "swiper/css/pagination";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";

const { REACT_APP_BASE_URL } = process.env;

type SubCyberSecurityType = {
  mainCyberSection: {
    title: string;
    description: string;
  };
  mainImageMobile: { url: string };
  cyberSolutions: {
    title: string;
    description: string;
    cyberSolution: Array<{ id: string; value: number; title: string }>;
  };
  cyberServices: {
    title: string;
    description: string;
    cyberService: Array<HighlightType>;
  };
};

type SwiperType = {
  width?: number;
  height?: number;
  slidesPerView?: number;
  spaceBetween?: number;
  clickable?: boolean;
  content?: Array<{
    title?: string;
    image?: { url: string };
    description?: string;
    link?: string;
  }>;
};

export function SubCyberSecurity() {
  const [ref, inView] = useInView();
  const controls = useAnimation();

  const [subCyberSecurity, setSubCyberSecurity] =
    React.useState<SubCyberSecurityType>();
  const [showAnimatedLine, setShowAnimatedLine] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("cyber-security")
      .then((res) => {
        setSubCyberSecurity(res.data);
        setShowAnimatedLine(true);
        window.scrollTo(0, 0);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    controls.start("visible");
  }, []);

  return (
    <div className="cyber-security-page">
      {isLoading && <CustomSpinner />}
      {showAnimatedLine && <ScrollingLine page="cyber-security" />}
      <div className="content">
        <div className="background">{shieldAnimated(controls)}</div>
        <div className="background-mobile">
          <img
            src={`${REACT_APP_BASE_URL}${subCyberSecurity?.mainImageMobile?.url}`}
            alt=""
          />
        </div>
        <div className="content-description ">
          <div className="title-content fade-once">
            {subCyberSecurity?.mainCyberSection?.title?.toUpperCase()}
          </div>
          <div className="cyber-security-content fade-once">
            {parse(
              (subCyberSecurity?.mainCyberSection.description as string) ?? ""
            )}
          </div>
          <div className="solutions-paragraph">
            <div className="title-solutions">
              {subCyberSecurity?.cyberSolutions.title}
            </div>
            <div className="solutions-content">
              {parse(
                (subCyberSecurity?.cyberSolutions.description as string) ?? ""
              )}
              <div className="mobile-solutions">
                <SwiperImage
                  content={subCyberSecurity?.cyberSolutions.cyberSolution}
                  slidesPerView={1}
                />
              </div>
              <div className="desktop-solutions fade-in">
                {Children.toArray(
                  subCyberSecurity?.cyberSolutions.cyberSolution?.map(
                    (solution: {
                      id: string;
                      title: string;
                      value?: number;
                    }) => {
                      return (
                        <div
                          className="solution"
                          key={solution.title}
                          ref={ref}
                        >
                          <AnimatedCircle
                            id={solution.id}
                            valore={solution.value as number}
                            inView={inView}
                          />
                          <div className="title">{solution.title}</div>
                        </div>
                      );
                    }
                  )
                )}
              </div>
            </div>
          </div>
          <div className="highlights-paragraph">
            <div className="title-highlights">I nostri servizi</div>
            <div className="highlights-content-desktop">
              {subCyberSecurity?.cyberServices?.cyberService?.map(
                (service: HighlightType) => (
                  <React.Fragment key={service.id}>
                    <Highlight {...service} />
                  </React.Fragment>
                )
              )}
            </div>
            <div className="highlights-content-mobile">
              <SwiperImageDescription
                content={subCyberSecurity?.cyberServices.cyberService}
                slidesPerView={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SwiperImage({
  slidesPerView,
  spaceBetween,
  clickable,
  width,
  height,
  content,
}: SwiperType) {
  return (
    <Swiper
      grabCursor={true}
      modules={[Pagination]}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween || 20}
      pagination={{ clickable: true }}
      width={width}
      height={height}
    >
      {content &&
        Children.toArray(
          content.map(({ image, title }) => {
            return (
              <SwiperSlide>
                <div
                  style={{
                    width: "330px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={`${REACT_APP_BASE_URL}${image?.url}`}
                    style={{
                      height: height ?? 100,
                      width: width ?? "auto",
                    }}
                    alt=""
                  />
                  {title ? (
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      {title}
                    </div>
                  ) : null}
                </div>
              </SwiperSlide>
            );
          })
        )}
    </Swiper>
  );
}

export function SwiperImageDescription({ slidesPerView, content }: SwiperType) {
  return (
    <Swiper
      loop={true}
      grabCursor={true}
      modules={[Pagination]}
      slidesPerView={slidesPerView}
      pagination={{ clickable: true }}
    >
      {content &&
        Children.toArray(
          content.map((el) => {
            return (
              <SwiperSlide>
                <div className="swiper">
                  {el.link ? (
                    <a href={el.link} target="_blank">
                      <img
                        className="img"
                        src={`${REACT_APP_BASE_URL}${el.image?.url}`}
                        alt=""
                      />
                    </a>
                  ) : (
                    <img
                      className="img"
                      src={`${REACT_APP_BASE_URL}${el.image?.url}`}
                      alt=""
                    />
                  )}
                  <div className="swiper-content">
                    <div className="title">{el.title}</div>
                    <div className="description">{el.description}</div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        )}
    </Swiper>
  );
}
