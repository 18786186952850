import { useEffect, useState } from 'react';
const Rainbow = require('rainbowvis.js');

const useScrollColor = () => {
  const [scrollColor, setScrollColor] = useState('071C2B');
  const rainbow = new Rainbow();

  const handleResize = () => {
    const limit = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    rainbow.setNumberRange(0, limit - (window.innerHeight / 2));
    rainbow.setSpectrum('#071C2B', '#071e2f', '#143763');
    setScrollColor(rainbow.colourAt(window.scrollY));
  }

  useEffect(() => {
    window.addEventListener('hashchange', handleResize)
    window.addEventListener('resize', handleResize)
    document.addEventListener('scroll', handleResize);
    return () => {
      window.removeEventListener('hashchange', handleResize)
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('scroll', handleResize);
    }
  }, []);

  return { scrollColor: `#${scrollColor}` };
};

export default useScrollColor;
