import { useRef } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled from "styled-components";

import "./input-fix.css"

const buildName = "byzmasBuild"

export function ChristmasGame() {
  const playerRef = useRef<HTMLCanvasElement>(null);

  const { unityProvider, loadingProgression, isLoaded, requestFullscreen } = useUnityContext({
    loaderUrl: "https://storage.googleapis.com/rilodp-files-metamorphosis/sample_building/bizmas/" + buildName + ".loader.js",
    dataUrl: "https://storage.googleapis.com/rilodp-files-metamorphosis/sample_building/bizmas/" + buildName + ".data",
    frameworkUrl: "https://storage.googleapis.com/rilodp-files-metamorphosis/sample_building/bizmas/" + buildName + ".framework.js",
    codeUrl: "https://storage.googleapis.com/rilodp-files-metamorphosis/sample_building/bizmas/" + buildName + ".wasm",
  });

  const handleClick = () => {
    if (isMobile) {
      setTimeout(() => {
        requestFullscreen(true)
      }, 100)
    }
  }

  return (
    <PlayerContainer id="unity-canvas" onClick={handleClick}>
      {!isLoaded && (
        <Loading>
          Loading Application... {Math.round(loadingProgression * 100)}%
        </Loading>
      )}
      
      <Unity
        unityProvider={unityProvider}
        ref={playerRef}
        style={{
          visibility: isLoaded ? "visible" : "hidden",
          aspectRatio: "9/16",
          height: isDesktop ? "100vh" : "auto",
          maxHeight: 900,
          maxWidth: "100vw"
        }}
      />
    </PlayerContainer>
  );
}

const Loading = styled.div`
  color: black;
  text-align: center;
  width: 100vw;
  position: absolute;
`

const PlayerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #dae0ef;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1000;
  position: absolute;
`
