import { useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { researchAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { ParagraphType } from "../../../types/types";
import { PageButton } from "../../PageButton/PageButton";
import parse from "html-react-parser";
import "./ResearchDevelopment.css";

type ResearchDevelopmentType = {
  title: string;
  researchParagraph: Array<ParagraphType>;
};

export function ResearchDevelopment(content: ResearchDevelopmentType) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  return (
    <div className="research-container">
      <div className="research-text__container">
        <div className="research-text fade-in">
          <div className="research-text__title">
            <h1 style={{ textAlign: "right" }}>
              {content?.title?.toUpperCase()}
            </h1>
          </div>
          <div className="research-text__description">
            {content?.researchParagraph?.map((paragraph) => {
              return (
                <React.Fragment key={paragraph.id}>
                  {parse(paragraph.description)}
                </React.Fragment>
              );
            })}
            {/* <p>
              Il continuo scouting tecnologico è la caratteristica chiave del
              Team di <b>Data Scientists</b> di KeyBiz. I solidi legami con il
              mondo accademico e istituti di ricerca nazionali e internazionali,
              mantengono all'interno dell'azienda un elevato livello di
              competenza sia sulle <b>tecnologie innovative</b> che sui nuovi
              trend del mercato IT.
            </p>
            <p>
              KeyBiz gioca un ruolo determinante nella progettazione innovativa,
              facendo leva su tecnologie come Algoritmi, Reti Neuronali, Machine
              Learning, Modelli Predittivi
            </p> */}
          </div>
        </div>
        <div className="research-page-button fade-in">
          <PageButton text="scopri di più" linkToPage={"cosa-facciamo/r&d"} />
        </div>
      </div>
      <div className="research-image">
        {researchAnimated(controls, ref)}
        <hr />
      </div>
    </div>
  );
}
