import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { css } from "styled-components/macro";
import {
  Coin,
  CoinBag,
  DragCoinHint,
  GamingTextMobile,
  InsertCoin,
  Joystick,
  SelectGameText,
  SelectGameTextMobile,
} from "./ArcadeSVGComponents";

import gameCover from "../../assets/images/gaming-page/byzmas-copertina.png";

export function ArcadeMobile() {
  const history = useNavigate();
  const [isDragged, setIsDragged] = useState(false);
  const [coinsArray, setCoinsArray] = useState(Array.from(Array(1)));

  const getDraggableItemStyle = (isDragging: boolean) => {
    setIsDragged(isDragging);
  };

  const body = document.getElementsByTagName("body")[0];
  const scrollOrNot = isDragged ? "hidden" : "auto";
  body.style.overflow = scrollOrNot;

  const coinSound = new Audio("../coin-sound.mp3");

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      setIsDragged(false);
      return;
    } else if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      setIsDragged(false);
      return;
    } else {
      coinSound.play();
      setCoinsArray([]);
      body.style.overflow = "auto";
      //history("/christmas-game");
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <svg
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 750 983"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H29.9511L59.9429 26.5054H29.9918L0 0Z"
          fill="#143763"
        />
        <path
          d="M719.967 26.5053H29.9509V202.46H719.967V26.5053Z"
          fill="url(#paint0_linear_241_1164)"
        />
        <path
          d="M80.1684 239.914H669.729L719.947 202.46H29.9509L80.1684 239.914ZM374.928 239.914L694.868 221.198L374.928 202.481L55.0291 221.198"
          fill="url(#paint1_linear_241_1164)"
        />
        <path
          d="M669.729 239.914H80.1687V690.057H669.729V239.914Z"
          fill="#2D3067"
        />
        <path
          d="M80.1681 690.057H669.729L719.946 758.053L30.0193 758.131L80.1681 690.057Z"
          fill="url(#paint2_linear_241_1164)"
        />
        <path
          d="M30 758H725V923L377.482 922.999L30.0001 923L30 758Z"
          fill="#1B274F"
        />
        <path d="M29.9511 0H0V202.46H29.9511V0Z" fill="#2D3067" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9932 239.914H81.2954L29.9511 202.46H0L49.9932 239.914Z"
          fill="#143763"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0193 758.131H0.220215L50.2376 690.057H80.1682L30.0193 758.131Z"
          fill="#2D3067"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 921L0.000188741 920.883L0 758H30V921Z"
          fill="#143763"
        />
        <path
          d="M58.2082 221.211H24.9192V724.305H58.2082V221.211Z"
          fill="#143763"
        />
        <path
          d="M35.2724 199.769L85.5102 236.224H667.075V243.225H85.5102V690.057L35.2724 758.074L35.2724 917.5V921.5H29.9509L29.9509 758.074L80.1684 690.057V239.915L29.9509 202.46V21.1841L35.2724 25.792V199.769Z"
          fill="#151737"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.1686 238.661V239.914V690.057L55.0497 724.065L54.9478 239.914V219.6L80.1686 238.661Z"
          fill="#10134B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M749.999 0.000152588H720.048L690.016 26.5055H719.967L749.999 0.000152588Z"
          fill="#143763"
        />
        <path
          d="M750 0.000152588H720.049V202.46H750V0.000152588Z"
          fill="#2D3067"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M700.007 239.914H668.686L719.156 202.46H750L700.007 239.914Z"
          fill="#143763"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M719.961 758.188H749.76L699.782 689.845H669.831L719.961 758.188Z"
          fill="#2D3067"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M720 921H750L750 758H720L720 921Z"
          fill="#143763"
        />
        <path
          d="M725.062 221.211H693.383V724.305H725.062V221.211Z"
          fill="#143763"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M664.449 690.057V236.224L714.687 199.769V25.792L720.049 21.1841V202.46L669.831 239.915V690.057L720.049 758.074V922H714.727L714.687 758.074L664.449 690.057Z"
          fill="#151737"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M670.015 239.914H669.831V690.057L694.93 724.065L695.052 239.914V221.177L670.015 239.914Z"
          fill="#10134B"
        />
        <path
          d="M639.839 276.43H110.058V653.52H639.839V276.43Z"
          fill="#2C2C2A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.058 276.43V653.52L134.403 629.176C125.513 521.931 125.737 412.423 134.403 300.795L110.058 276.451V276.43Z"
          fill="#3C3C3C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M615.577 300.795C625.934 412.688 626.24 522.237 615.577 629.176L639.839 653.52V276.43L615.577 300.775V300.795Z"
          fill="#3C3C3C"
        />
        <path d="M640 276H110V653.246H640V276Z" fill="#2C2C2A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110 276V653.246L134.354 628.891C125.461 521.602 125.685 412.049 134.354 300.375L110 276.02V276Z"
          fill="#3C3C3C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M615.727 300.375C626.089 412.314 626.395 521.908 615.727 628.892L640 653.246V276L615.727 300.354V300.375Z"
          fill="#3C3C3C"
        />
        <mask
          id="mask0_241_1164"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="123"
          y="289"
          width="505"
          height="351"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.818 634.48C298.98 641.354 451.143 641.354 603.286 634.48C613.362 634.031 621.5 625.954 622.255 615.735C629.884 514.83 628.415 413.905 617.849 313C616.809 303.026 608.773 295.275 598.921 294.827C449.675 288.075 300.408 288.075 151.162 294.827C141.31 295.275 133.274 303.006 132.234 313C121.647 413.905 120.179 514.83 127.828 615.735C128.603 625.954 136.741 634.031 146.797 634.48H146.818Z"
            fill="url(#paint3_radial_241_1164)"
          />
        </mask>
        <path
          d="M179.385 78.4641L143.027 114.822L179.012 151.031C172.108 151.428 164.26 151.652 155.469 151.701C149.707 151.801 141.711 151.826 131.479 151.776C120.104 151.726 112.108 151.701 107.488 151.701L107.786 78.1661C123.73 78.0668 147.596 78.1661 179.385 78.4641ZM177.075 150.062L141.835 114.375L177.075 79.1347H108.457L107.786 150.062H177.075ZM173.797 149.168H109.426C109.426 147.331 109.401 135.882 109.351 114.822C109.351 101.014 109.599 89.5652 110.096 80.4757H173.797L139.749 114.375L173.797 149.168ZM168.209 82.7854H134.31L133.937 85.0205L161.727 85.691C160.734 86.8334 156.686 90.9559 149.583 98.0587C149.384 98.307 149.285 98.5554 149.285 98.8037C149.285 99.1017 149.509 99.6232 149.956 100.368C152.787 98.3318 155.941 95.5504 159.418 92.0238C162.348 88.9443 165.279 85.8648 168.209 82.7854ZM121.495 147.082C121.595 146.536 121.52 145.816 121.272 144.922C121.172 144.922 119.831 144.922 117.248 144.922C115.56 144.971 114.343 144.599 113.598 143.804V125.849H111.735V147.082H121.495Z"
          fill="#F7EC53"
        />
        <path
          d="M196.605 159.192L100.668 159.647V69.8079H196.605L154.134 114.424L196.605 159.192ZM190.233 156.552L150.017 114.424L190.233 72.5386H103.398L102.943 156.552H190.233Z"
          fill="#F75353"
        />
        <path
          d="M289.935 116.161L249.669 115.355C248.988 115.36 248.953 115.445 248.953 115.445C248.953 117.355 249.191 117.183 249.55 117.186L264.329 117.588V133.186L247.042 133.587V134.802L289.076 134.35L288.277 159.422H203.144V70.213H289.666L289.935 116.161ZM285.859 156.735L286.657 137.306H245.161C244.265 134.082 244.33 133.992 244.803 130.768H261.731V120.364L247.042 119.963C246.355 119.613 246.153 117.79 246.153 117.588C246.153 117.588 245.95 115.563 246.355 113.474H287.158L287.248 73.2583H205.742V156.735H285.859Z"
          fill="#F75353"
        />
        <path
          d="M282.99 111.061H242.311C241.268 112.203 240.746 113.817 240.746 115.903C240.746 117.791 241.119 119.455 241.864 120.895L256.243 121.566V129.016L242.311 129.686C241.963 130.432 241.739 131.326 241.64 132.369C241.541 133.064 241.516 134.082 241.566 135.423C241.615 136.913 241.64 137.932 241.64 138.478C245.564 138.826 250.059 139.024 255.125 139.074C258.304 139.124 262.874 139.124 268.834 139.074C275.142 139.024 279.736 139.024 282.617 139.074V152.038H210.944V77.8317H282.617L282.99 111.061ZM281.947 151.144V140.415L241.193 140.117L240.97 129.016C242.807 128.519 245.216 128.271 248.196 128.271C252.816 128.321 255.274 128.345 255.572 128.345V122.46L240.671 121.789C240.125 119.753 239.852 117.716 239.852 115.68C239.852 113.445 240.224 111.607 240.97 110.166H281.947V79.1728H212.062L211.615 151.144H281.947ZM280.68 149.877H213.254V80.4394H280.308L280.68 108.751H240.001L238.66 110.166C238.71 110.613 238.685 112.824 238.585 116.797C238.536 119.529 238.859 121.64 239.554 123.13L254.231 123.875V126.706L240.001 127.377C239.455 130.158 239.181 132.617 239.181 134.753C239.181 137.683 239.678 140.018 240.671 141.756H280.308L280.68 149.877ZM247.451 84.7606V82.749L215.564 82.451V109.719H217.203L217.873 85.2821L247.451 84.7606Z"
          fill="#F7EC53"
        />
        <path
          d="M389.439 77.4956C384.174 87.2805 377.22 99.5239 368.578 114.226C354.223 138.713 347.046 150.956 347.046 150.956C343.37 151.255 339.173 151.453 334.455 151.553C331.475 151.602 327.153 151.602 321.491 151.553C315.58 151.453 311.259 151.428 308.527 151.478L328.42 116.685C326.533 111.718 323.453 105.137 319.181 96.9411C314.364 87.802 311.135 81.3201 309.496 77.4956H389.439ZM387.576 78.6877H311.135C315.258 87.0321 321.566 99.4743 330.059 116.014L310.166 150.509L346.375 150.286L387.576 78.6877ZM384.968 80.1032C380.598 88.398 374.141 99.7971 365.597 114.301C356.061 130.592 349.455 142.041 345.779 148.647C341.458 149.144 335.97 149.367 329.314 149.317C319.082 149.268 313.569 149.268 312.774 149.317L331.698 116.014C330.208 112.984 327.079 106.974 322.311 97.9842C318.337 90.5338 315.382 84.5735 313.445 80.1032H384.968ZM334.008 84.2755V81.9658H317.691C318.486 84.6976 319.951 88.0007 322.087 91.8749C323.875 95.1034 325.663 98.3319 327.451 101.56L329.09 100.89C328.594 99.1514 327.327 96.3948 325.291 92.6199C323.403 89.0934 322.261 86.3119 321.864 84.2755H334.008ZM320.225 84.5735C319.827 84.0768 319.628 83.8036 319.628 83.7539C319.628 83.6049 319.753 83.4559 320.001 83.3069C320.2 83.4062 320.324 83.5304 320.374 83.6794C320.374 83.7788 320.349 83.9526 320.299 84.201C320.249 84.3996 320.225 84.5238 320.225 84.5735Z"
          fill="#F7EC53"
        />
        <path
          d="M401.211 70.213L400.846 72.1249L351.813 159.182L296.304 159.182C301.888 148.317 309.991 134.054 320.613 116.392C318.367 111.233 315.77 104.02 310.186 93.5198C304.784 83.3231 300.961 75.5541 298.715 70.213H401.211ZM396.476 73.4905H302.903L324.163 115.937L300.947 156.45H350.174L396.476 73.4905Z"
          fill="#F75353"
        />
        <path
          d="M518.673 154.462H489.51L489.954 75.4785H518.673V154.462ZM517.711 153.431V76.5095H490.62L490.176 153.431H517.711ZM516.453 152.162H491.805V77.9369H516.083L516.453 152.162ZM505.72 82.8535C505.72 82.7478 505.745 82.4834 505.794 82.0605C505.843 81.7433 505.843 81.5054 505.794 81.3468C505.745 81.0825 505.621 80.8446 505.424 80.6331L494.099 80.3952V125.438H495.728L496.394 83.4086C497.479 82.9328 498.984 82.7214 500.909 82.7742C504.511 82.7742 506.115 82.8007 505.72 82.8535Z"
          fill="#F7EC53"
        />
        <path
          d="M523.129 159.728H485.459L485.735 70.618H523.129V159.728ZM520.28 157.044V73.302H488.032V157.044H520.28Z"
          fill="#F75353"
        />
        <path
          d="M473.405 91.0868C473.611 92.4511 473.713 93.8155 473.713 95.1799C473.713 102.526 466.246 107.072 460.473 110.11C459.498 110.792 454.39 111.055 453.056 111.528C451.722 112 451.556 112.235 451.233 113.205C450.629 115.019 450.833 115.94 452.044 118.008C453.718 118.987 461.297 120.11 462.39 121.012C465.31 122.253 470.748 125.879 471.877 129.028C472.596 130.97 473.021 132.673 473.021 134.93C473.021 139.075 472.02 142.723 470.018 145.871C467.452 149.859 463.065 152.483 456.855 153.742L413.362 154.057L413.747 76.2887L459.472 76.9971C462.757 78.0991 465.682 79.857 468.248 82.2709C471.122 84.9996 472.841 87.9383 473.405 91.0868ZM472.251 91.4803C471.789 88.7516 470.352 86.1279 467.94 83.609C465.785 81.3526 463.296 79.5947 460.473 78.3353L414.517 77.7843L414.055 153.034L458.241 152.719C461.936 151.092 463.963 150.2 464.322 150.043C466.58 148.731 468.248 147.104 469.326 145.163C471.173 141.647 471.096 138.029 471.096 134.199C471.096 131.155 470.557 129.156 469.172 127.11C466.452 122.229 456.914 120.606 448.601 118.874C448.344 118.559 448.601 112.457 448.601 111.933C448.601 111.355 448.832 110.962 449.293 110.752C454.631 111.119 460.473 108.895 466.246 105.083C470.018 101.934 472.713 97.5412 472.713 95.1799C472.713 93.9204 472.559 92.6873 472.251 91.4803ZM471.019 92.11C471.07 92.9496 471.096 93.7893 471.096 94.6289C471.096 101.766 462.59 103.708 457.766 106.647C455.918 108.221 452.121 109.218 446.373 109.638C445.603 110.53 445.86 118.44 446.373 121.012C450.478 121.746 455.662 121.722 457.766 122.666C460.948 124.188 466.683 126.244 468.325 129.235C469.505 131.439 470.018 133.125 470.018 135.853C470.018 138.897 469.71 140.833 468.325 144.139C465.81 147.97 462.218 150.358 457.548 151.302L415.748 151.775V79.0437L459.934 79.7521C462.449 80.7491 464.732 82.4021 466.785 84.711C468.889 87.0199 470.301 89.4863 471.019 92.11ZM439.997 148.862C440.099 148.337 439.997 147.681 439.689 146.894L421.522 146.579L420.521 113.205H418.827V149.334L439.997 148.862Z"
          fill="#F7EC53"
        />
        <path
          d="M477.571 131.515C478.052 133.381 478.293 135.277 478.293 137.203C478.293 144.968 474.832 151.227 467.91 155.982C463.396 158.209 458.34 159.323 452.743 159.323H407.691V70.213L460.146 70.5741C463.577 71.2362 466.797 72.6807 469.806 74.9077C472.816 77.0745 475.133 79.7529 476.758 82.9429C478.203 85.8922 478.925 89.0822 478.925 92.513C478.925 100.458 475.795 106.718 469.536 111.292C467.068 113.098 464.209 114.482 460.959 115.445C464.69 116.348 468.121 118.334 471.251 121.404C474.441 124.413 476.548 127.784 477.571 131.515ZM475.584 87.9085C474.742 84.5981 472.936 81.6188 470.168 78.9705C467.399 76.3222 464.329 74.5466 460.959 73.6437L410.31 73.2826V156.614H455.993C459.303 156.614 462.463 155.802 465.473 154.177C468.482 152.551 470.83 150.294 472.515 147.405C474.381 144.215 475.314 140.724 475.314 136.932C475.314 133.441 474.501 130.342 472.876 127.633C468.783 120.952 462.524 117.521 454.097 117.341C454.097 117.1 454.037 116.558 453.916 115.716C453.916 115.054 453.916 114.512 453.916 114.091C453.977 113.549 454.127 113.067 454.368 112.646C455.15 112.767 455.933 112.827 456.715 112.827C461.29 112.827 466.285 110.479 471.702 105.785C474.652 101.331 476.126 96.8165 476.126 92.2421C476.126 90.7374 475.946 89.2929 475.584 87.9085Z"
          fill="#F75353"
        />
        <path
          d="M623.218 78.4796C616.57 90.1273 606.417 107.496 592.759 130.585C593.945 130.946 595.336 131.178 596.934 131.281C598.016 131.332 599.511 131.332 601.418 131.281C603.531 131.229 605.025 131.229 605.902 131.281C606.262 133.445 606.494 135.919 606.597 138.702C606.649 140.506 606.675 143.057 606.675 146.356C606.623 149.964 606.597 152.515 606.597 154.009H541.581V152.309C543.798 147.928 547.972 140.661 554.105 130.508C559.723 121.282 563.82 113.964 566.397 108.552L539.571 108.011V77.088H623.218V78.4796ZM621.595 78.4796H540.267V107.315L568.639 107.547C566.578 111.361 562.171 118.963 555.42 130.353C549.802 139.733 545.55 147.387 542.664 153.314H604.665V132.672C604.355 132.672 602.01 132.698 597.63 132.75C594.743 132.75 592.424 132.492 590.672 131.977L621.595 78.4796ZM619.198 80.1803L588.662 132.672C590.053 133.652 592.115 134.141 594.847 134.141C595.413 134.141 596.831 134.09 599.098 133.987C600.851 133.884 602.217 133.884 603.196 133.987V151.999H544.906C548.101 145.763 552.301 138.316 557.507 129.657C561.939 122.287 566.346 114.917 570.726 107.547L568.639 105.847H541.581V79.7938C558.847 79.6907 584.719 79.8196 619.198 80.1803ZM614.328 81.8811H590.363L590.131 84.2776H609.69L610.385 84.8188C606.468 90.9004 603.093 97.1107 600.258 103.45L602.191 103.914L614.328 81.8811ZM581.627 149.603V147.515L554.492 147.206L553.796 146.51C554.621 144.707 556.373 141.717 559.053 137.543C561.475 133.729 563.125 130.662 564.001 128.343C564.258 128.034 564.387 127.776 564.387 127.57C564.387 127.312 564.104 127.132 563.537 127.029C562.815 126.874 562.403 126.745 562.3 126.642L549.003 149.603H581.627Z"
          fill="#F7EC53"
        />
        <path
          d="M633.925 73.4334L602.866 125.46L614.888 125.742V159.349H531.346C530.824 159.349 530.419 159.349 529.609 159.238L530.217 157.843C533.543 151.254 535.854 147.577 542.38 136.595C549.848 123.919 554.9 118.272 557.535 113.377L534.17 113.189V71.8331H633.925V73.4334ZM629.407 74.9395H536.712V110.553L561.018 110.271L562.618 111.4C561.803 112.844 556.531 118.554 546.804 134.807C540.403 145.538 537.935 149.372 534.17 156.526L610.841 156.808L611.405 128.943C610.276 128.88 611.745 128.88 608.984 128.943C606.474 129.005 604.529 129.005 603.148 128.943C601.077 128.817 599.257 128.535 597.689 128.095C605.721 114.039 613.028 102.928 629.407 74.9395Z"
          fill="#F75353"
        />
        <defs>
          <linearGradient
            id="paint0_linear_241_1164"
            x1="374.959"
            y1="26.5053"
            x2="374.959"
            y2="202.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_241_1164"
            x1="374.949"
            y1="202.46"
            x2="374.949"
            y2="239.914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_241_1164"
            x1="374.983"
            y1="690.057"
            x2="374.983"
            y2="758.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <radialGradient
            id="paint3_radial_241_1164"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(-91.1766 562.784) scale(470.257 470.257)"
          >
            <stop stopColor="#8FACBD" />
            <stop offset="0.4" stopColor="#819DAD" />
            <stop offset="0.69" stopColor="#648598" />
            <stop offset="0.95" stopColor="#698595" />
            <stop offset="1" stopColor="#678393" />
          </radialGradient>
        </defs>
        <GamingTextMobile coinsLength={coinsArray.length} />
        <Joystick x="300" y="650" />
        <CoinBag x="80" y="620" />
        <InsertCoin x="630" y="780" width="63" height="68" />
        <DragCoinHint
          coinsLength={coinsArray.length}
          coinDragged={isDragged}
          x="600"
          y="700"
          width="123"
          height="126"
        />
        {coinsArray.length <= 0 && <SelectGameTextMobile />}
      </svg>
      {coinsArray.length <= 0 && (
        <div
          css={css`
            width: 100%;
            position: absolute;
            top: 39%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 80px;
              height: 100px;
              border: 2px solid white;
              border-radius: 5px;
              opacity: 0.9;
            `}
          >
            <img
              src={gameCover}
              alt=""
              css={css`
                border-radius: 2px;
                width: 100%;
                height: 100%;
                z-index: 2;
              `}
              onClick={() => history("/christmas-game")}
            />
          </div>
        </div>
      )}
      <Droppable droppableId="insert-coin">
        {(provided) => {
          return (
            <div
              css={css`
                z-index: 10;
                width: 32px;
                height: 30px;
                position: absolute;
                bottom: 13.8%;
                right: 7.4%;
              `}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      <Droppable droppableId="coin">
        {(provided) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              css={css`
                position: absolute;
                z-index: 10;
                bottom: 25%;
                right: 20%;
              `}
            >
              {provided.placeholder}
              <Draggable draggableId="coin" index={0}>
                {(provided, snapshot) => {
                  getDraggableItemStyle(snapshot.isDragging);
                  return (
                    <div
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      {coinsArray.length >= 1 &&
                        coinsArray.map(() => (
                          <Coin
                            key={0}
                            className={isDragged ? "dragged" : ""}
                            width="22"
                            height="16"
                          />
                        ))}
                    </div>
                  );
                }}
              </Draggable>
            </div>
          );
        }}
      </Droppable>
      {!isDragged && coinsArray.length >= 1 && (
        <div
          css={css`
            position: absolute;
            width: 120px;
            bottom: 12%;
            right: 20%;
            font-size: 7px;
            z-index: 10;
            background: #021e34;
            padding: 8px;
            border: 1px solid white;
            border-radius: 10px;
            opacity: 0.9;
            box-shadow: 5px 5px 15px #000;
          `}
        >
          <span>Tieni premuto sulla moneta e trascinala nello slot</span>
        </div>
      )}
    </DragDropContext>
  );
}
