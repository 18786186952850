import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import parse from "html-react-parser";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";

import axiosInstance from "../../../api/interceptor";
import { ScrollingLine } from "../../../components/ScrollingLine/ScrollingLine";
import { researchAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import whiteArrow from "../../../assets/images/subResearch/white-arrow.svg";
import focusIcon from "../../../assets/images/subEngineering/focus.svg";
import longArrow from "../../../assets/images/subResearch/long-arrow.svg";

import "./SubResearch.css";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";

const { REACT_APP_BASE_URL } = process.env;

type ResearchDevelopmentType = {
  redMain: {
    id?: number;
    title: string;
    description: string;
    mobileIcon: { url: string };
  };
};

export function SubResearch() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const [researchDevelopment, setResearchDevelopment] =
    React.useState<ResearchDevelopmentType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showAnimatedLine, setShowAnimatedLine] =
    React.useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("research-development")
      .then((res) => {
        setResearchDevelopment(res.data);
        setShowAnimatedLine(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  const pathAnimation = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 1.2,
        ease: "easeInOut",
      },
    },
  };

  const titleLine = () => {
    return (
      <svg
        width="10"
        height="203"
        viewBox="0 0 10 203"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M5 5V2 203"
          variants={pathAnimation}
          initial="hidden"
          animate={controls}
          stroke="white"
          strokeWidth="10"
        />
      </svg>
    );
  };

  function SecondSectionMobile() {
    return (
      <div className="red-second-section__mobile fade-once">
        <p>
          <b>Change</b> your way{" "}
        </p>
        <p>
          to see<b>your data</b>
        </p>
        <hr style={{ border: "3px solid white", width: "80%" }} />
        <SecondSectionText
          firstString="Data-driven approcach"
          secondString="Data becomes insight"
        />
        <SecondSectionText
          firstString="Expressive visualizations"
          secondString="Improve processes"
        />
      </div>
    );
  }

  function SecondSectionText({
    firstString,
    secondString,
  }: {
    firstString: string;
    secondString: string;
  }) {
    return (
      <div className="mobile-section-string-wrapper">
        <div className="mobile-section-string__container fade-in">
          <img src={focusIcon} alt="" />
          <span>{firstString}</span>
          <img src={focusIcon} alt="" />
        </div>
        <div className="mobile-arrow-container fade-in">
          <img src={longArrow} alt="" />
        </div>
        <div className="mobile-section-string__container fade-in">
          <span>{secondString}</span>
        </div>
      </div>
    );
  }

  return (
    <div id="research-development">
      {isLoading && <CustomSpinner />}
      {showAnimatedLine && <ScrollingLine page="red" />}
      <div className="content">
        <div className="red-main">
          {researchAnimated("visible")}
          <img
            className="mobile-icon"
            src={`${REACT_APP_BASE_URL}${researchDevelopment?.redMain.mobileIcon.url}`}
            alt="mobile icon"
          />
          <h1 className="fade-once">
            {researchDevelopment?.redMain.title.toUpperCase()}
          </h1>
          <div className="fade-once">
            {parse((researchDevelopment?.redMain.description as string) ?? "")}
          </div>
        </div>
        <div className="red-second-section">
          <div className="subtitle-animation-container" ref={ref}>
            {titleLine()}
            <div className="subtitle-text-content">
              <span className="subtitle-first fade-in">Change</span>
              <span className="subtitle-second fade-in">your way</span>
              <span className="subtitle-third fade-in">to see</span>
              <span className="subtitle-fourth fade-in">your data</span>
            </div>
          </div>
          <div className="description-second-section">
            <div className="description-first-part">
              <span className="first-sentence fade-in">
                Data-driven approach
              </span>
              <img className="fade-in" src={whiteArrow} alt="arrow" />
              <span className="second-sentence fade-in">
                Expressive visualization
              </span>
            </div>
            <div className="description-second-part">
              <span className="first-sentence fade-in">
                Data becomes insight
              </span>
              <img className="fade-in" src={whiteArrow} alt="arrow" />
              <span className="second-sentence fade-in">Improve processes</span>
            </div>
          </div>
        </div>
        <SecondSectionMobile />
      </div>
    </div>
  );
}
