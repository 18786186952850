import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { css } from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Coin,
  CoinBag,
  DragCoinHint,
  GamingText,
  InsertCoin,
  Joystick,
  SelectGameText,
} from "./ArcadeSVGComponents";

import gameCover from "../../assets/images/gaming-page/byzmas-copertina.png";

export function Arcade() {
  const history = useNavigate();
  const [isDragged, setIsDragged] = useState(false);
  const [coinsArray, setCoinsArray] = useState(Array.from(Array(1)));

  const getDraggableItemStyle = (isDragging: boolean) => {
    setIsDragged(isDragging);
  };

  const body = document.getElementsByTagName("body")[0];
  const scrollOrNot = isDragged ? "hidden" : "auto";
  body.style.overflow = scrollOrNot;

  const coinSound = new Audio("../coin-sound.mp3");

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      setIsDragged(false);
      return;
    } else if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      setIsDragged(false);
      return;
    } else {
      coinSound.play();
      setCoinsArray([]);
      body.style.overflow = "auto";
      //history("/christmas-game");
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="insert-coin">
        {(provided) => {
          const insertCoinSVG = document.getElementById("insert-coin");
          const svgInfo = insertCoinSVG?.getBoundingClientRect();

          return (
            <div
              css={css`
                z-index: 10;
                width: ${Math.floor(svgInfo?.width as number)}px;
                height: ${Math.floor(svgInfo?.height as number)}px;
                position: absolute;
                bottom: 2.5%;
                right: 30.8%;
              `}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      <Droppable droppableId="coin">
        {(provided) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              css={css`
                position: absolute;
                z-index: 10;
                bottom: 12%;
                right: 36%;
              `}
            >
              {provided.placeholder}
              <Draggable draggableId="coin" index={0}>
                {(provided, snapshot) => {
                  getDraggableItemStyle(snapshot.isDragging);
                  return (
                    <div
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      {coinsArray.length >= 1 &&
                        coinsArray?.map(() => {
                          return (
                            <Coin
                              key={0}
                              className={isDragged ? "dragged" : ""}
                              width="40"
                              height="28"
                            />
                          );
                        })}
                    </div>
                  );
                }}
              </Draggable>
            </div>
          );
        }}
      </Droppable>
      {coinsArray.length <= 0 && (
        <div
          css={css`
            width: 100%;
            position: absolute;
            top: 46.5%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 120px;
              height: 150px;
              border: 4px solid white;
              border-radius: 10px;
              opacity: 0.5;
              &:hover {
                cursor: pointer;
                opacity: 1;
              }
            `}
          >
            <img
              src={gameCover}
              alt=""
              css={css`
                border-radius: 5px;
                width: 100%;
                height: 100%;
                z-index: 2;
              `}
              onClick={() => history("/christmas-game")}
            />
          </div>
        </div>
      )}
      <svg
        width="75%"
        height="100%"
        z-index="2"
        viewBox="0 0 1088 713"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path d="M51 615H1059L1087.5 716H0.5L51 615Z" fill="#1F2039" />
        <path
          d="M1001 540.571C1001 575.591 980.181 587.818 954.5 587.818C928.819 587.818 908 575.591 908 540.571C908 505.551 954.5 461 954.5 461C954.5 461 1001 505.551 1001 540.571Z"
          fill="#1C9E7F"
        />
        <path
          d="M953.283 553.153L973.101 516.894L953.357 548.555L953.571 535.377L967.23 509.144L953.628 531.889L954.012 508.188L968.64 487.303L954.073 504.461L954.314 461L952.867 516.07L938.055 493.398L952.688 520.716L951.302 547.187L951.261 546.484L934.116 522.531L951.209 548.966L951.035 552.277L951.005 552.327L951.019 552.599L947.503 587.923H952.201L952.764 585.069L969.814 558.697L952.806 582.461L953.283 553.153Z"
          fill="#3F3D56"
        />
        <path
          d="M984.198 663.785H928.761L914.434 582.809H995.41L984.198 663.785Z"
          fill="#7875AD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223 0H248.545L274.125 22.6061H248.58L223 0Z"
          fill="#143763"
        />
        <path
          d="M837.051 22.606H248.545V172.676H837.051V22.606Z"
          fill="url(#paint0_linear_187_2367)"
        />
        <path
          d="M291.375 204.62H794.203L837.033 172.676H248.545L291.375 204.62ZM542.772 204.62L815.644 188.657L542.772 172.693L269.934 188.657"
          fill="url(#paint1_linear_187_2367)"
        />
        <path
          d="M794.204 204.62H291.375V588.541H794.204V204.62Z"
          fill="#2D3067"
        />
        <path
          d="M291.374 588.541H794.203L837.033 646.534L248.603 646.6L291.374 588.541Z"
          fill="url(#paint2_linear_187_2367)"
        />
        <path
          d="M248.832 646.142H840.938V713L544.87 712.998L248.832 713L248.832 646.142Z"
          fill="#1B274F"
        />
        <path d="M248.545 0H223V172.676H248.545V0Z" fill="#2D3067" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265.639 204.62H292.336L248.545 172.676H223L265.639 204.62Z"
          fill="#143763"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.603 646.6H223.188L265.847 588.541H291.375L248.603 646.6Z"
          fill="#2D3067"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.832 713L223.188 712.771L223.188 646.6H248.832V713Z"
          fill="#143763"
        />
        <path
          d="M272.645 188.668H244.253V617.75H272.645V188.668Z"
          fill="#143763"
        />
        <path
          d="M253.083 170.38L295.931 201.472H791.939V207.443H295.931V588.541L253.083 646.551L253.083 713H248.545L248.545 646.551L291.375 588.541V204.62L248.545 172.676V18.0676L253.083 21.9976V170.38Z"
          fill="#151737"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M291.375 203.551V204.62V588.541L269.951 617.546L269.864 204.62V187.294L291.375 203.551Z"
          fill="#10134B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M862.665 6.10352e-05H837.12L811.506 22.6061H837.051L862.665 6.10352e-05Z"
          fill="#143763"
        />
        <path
          d="M862.665 6.10352e-05H837.12V172.676H862.665V6.10352e-05Z"
          fill="#2D3067"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M820.027 204.62H793.313L836.359 172.676H862.665L820.027 204.62Z"
          fill="#143763"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M837.046 646.649H862.461L819.835 588.36H794.291L837.046 646.649Z"
          fill="#2D3067"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M837.275 713H862.461L862.461 646.6H837.275L837.275 713Z"
          fill="#143763"
        />
        <path
          d="M841.396 188.668H814.378V617.75H841.396V188.668Z"
          fill="#143763"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M789.7 588.541V201.472L832.547 170.38V21.9976L837.121 18.0676V172.676L794.291 204.62V588.541L837.121 646.551V713H832.582L832.547 646.551L789.7 588.541Z"
          fill="#151737"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M794.447 204.62H794.291V588.541L815.697 617.546L815.801 204.62V188.639L794.447 204.62Z"
          fill="#10134B"
        />
        <path
          d="M768.711 235.764H316.867V557.379H768.711V235.764Z"
          fill="#2C2C2A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M316.867 235.764V557.379L337.63 536.616C330.049 445.148 330.24 351.75 337.63 256.544L316.867 235.781V235.764Z"
          fill="#3C3C3C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M748.017 256.544C756.851 351.977 757.112 445.409 748.017 536.616L768.71 557.379V235.764L748.017 256.527V256.544Z"
          fill="#3C3C3C"
        />
        <mask
          id="mask0_187_2367"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="327"
          y="247"
          width="431"
          height="299"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M348.255 541.381C477.98 547.241 607.704 547.241 737.41 541.381C746.001 540.998 752.939 534.112 753.582 525.4C760.086 439.375 758.834 353.333 749.826 267.308C748.939 258.805 742.088 252.197 733.689 251.814C606.452 246.058 479.197 246.058 351.959 251.814C343.56 252.197 336.709 258.787 335.822 267.308C326.797 353.333 325.545 439.375 332.066 525.4C332.727 534.112 339.665 540.998 348.238 541.381H348.255Z"
            fill="url(#paint3_radial_187_2367)"
          />
        </mask>
        <path
          d="M375.995 66.7478L344.986 97.757L375.677 128.639C369.789 128.978 363.096 129.169 355.598 129.211C350.684 129.296 343.863 129.317 335.137 129.275C325.436 129.232 318.615 129.211 314.676 129.211L314.93 66.4936C328.528 66.4089 348.883 66.4936 375.995 66.7478ZM374.025 127.813L343.969 97.3757L374.025 67.3197H315.502L314.93 127.813H374.025ZM371.229 127.051H316.328C316.328 125.483 316.307 115.719 316.264 97.757C316.264 85.9803 316.476 76.2158 316.9 68.4635H371.229L342.19 97.3757L371.229 127.051ZM366.464 70.4333H337.551L337.234 72.3396L360.935 72.9115C360.088 73.8858 356.636 77.4019 350.578 83.4597C350.408 83.6715 350.324 83.8833 350.324 84.0952C350.324 84.3493 350.514 84.7941 350.895 85.4296C353.31 83.6927 356 81.3204 358.966 78.3127C361.465 75.6862 363.964 73.0598 366.464 70.4333ZM326.622 125.271C326.707 124.805 326.643 124.191 326.431 123.429C326.347 123.429 325.203 123.429 323 123.429C321.56 123.471 320.522 123.153 319.886 122.475V107.161H318.298V125.271H326.622Z"
          fill="#F7EC53"
        />
        <path
          d="M390.682 135.773L308.858 136.161V59.5383H390.682L354.459 97.591L390.682 135.773ZM385.248 133.521L350.948 97.591L385.248 61.8672H311.187L310.799 133.521H385.248Z"
          fill="#F75353"
        />
        <path
          d="M470.282 99.0723L435.94 98.3848C435.359 98.3891 435.329 98.4612 435.329 98.4612C435.329 100.091 435.532 99.9437 435.838 99.9466L448.443 100.289V113.593L433.699 113.935V114.971L469.549 114.586L468.868 135.969H396.259V59.8837H470.053L470.282 99.0723ZM466.806 133.677L467.486 117.107H432.095C431.331 114.357 431.386 114.28 431.789 111.53H446.227V102.657L433.699 102.315C433.113 102.016 432.941 100.462 432.941 100.289C432.941 100.289 432.768 98.5618 433.113 96.7806H467.914L467.99 62.481H398.474V133.677H466.806Z"
          fill="#F75353"
        />
        <path
          d="M464.358 94.5488H429.664C428.774 95.5231 428.329 96.8999 428.329 98.6791C428.329 100.289 428.647 101.708 429.282 102.937L441.546 103.508V109.863L429.664 110.435C429.367 111.07 429.176 111.833 429.092 112.722C429.007 113.315 428.986 114.184 429.028 115.328C429.07 116.598 429.092 117.467 429.092 117.933C432.438 118.229 436.272 118.399 440.593 118.441C443.304 118.483 447.201 118.483 452.285 118.441C457.665 118.399 461.584 118.399 464.041 118.441V129.498H402.912V66.2084H464.041L464.358 94.5488ZM463.469 128.735V119.585L428.71 119.331L428.52 109.863C430.087 109.439 432.142 109.227 434.683 109.227C438.623 109.27 440.72 109.291 440.974 109.291V104.271L428.266 103.699C427.8 101.962 427.567 100.225 427.567 98.4885C427.567 96.5822 427.884 95.0148 428.52 93.7863H463.469V67.3522H403.865L403.484 128.735H463.469ZM462.388 127.655H404.882V68.4324H462.071L462.388 92.5789H427.694L426.55 93.7863C426.592 94.1675 426.571 96.0527 426.486 99.4416C426.444 101.772 426.719 103.572 427.312 104.843L439.83 105.478V107.893L427.694 108.465C427.228 110.837 426.995 112.934 426.995 114.756C426.995 117.255 427.418 119.246 428.266 120.729H462.071L462.388 127.655ZM434.048 72.1179V70.4023L406.851 70.1481V93.405H408.249L408.821 72.5628L434.048 72.1179Z"
          fill="#F7EC53"
        />
        <path
          d="M555.147 65.9217C550.657 74.2671 544.726 84.7094 537.355 97.2487C525.112 118.133 518.991 128.576 518.991 128.576C515.856 128.83 512.277 128.999 508.252 129.084C505.71 129.126 502.025 129.126 497.196 129.084C492.155 128.999 488.469 128.978 486.139 129.02L503.105 99.3456C501.495 95.1094 498.869 89.4964 495.226 82.5066C491.117 74.7119 488.363 69.1836 486.965 65.9217H555.147ZM553.559 66.9384H488.363C491.879 74.0553 497.259 84.667 504.503 98.7737L487.537 128.194L518.419 128.004L553.559 66.9384ZM551.335 68.1457C547.607 75.2203 542.1 84.9424 534.813 97.3122C526.68 111.207 521.046 120.972 517.911 126.606C514.225 127.029 509.544 127.22 503.868 127.178C495.141 127.135 490.439 127.135 489.761 127.178L505.901 98.7737C504.63 96.1896 501.961 91.0638 497.895 83.3962C494.506 77.0418 491.985 71.9584 490.333 68.1457H551.335ZM507.871 71.7042V69.7343H493.955C494.633 72.0643 495.882 74.8814 497.704 78.1856C499.229 80.9392 500.754 83.6927 502.279 86.4463L503.677 85.8744C503.253 84.3917 502.173 82.0406 500.436 78.8211C498.827 75.8133 497.852 73.441 497.513 71.7042H507.871ZM496.115 71.9584C495.777 71.5347 495.607 71.3017 495.607 71.2594C495.607 71.1323 495.713 71.0052 495.925 70.8781C496.094 70.9628 496.2 71.0688 496.242 71.1958C496.242 71.2806 496.221 71.4288 496.179 71.6406C496.137 71.8101 496.115 71.916 496.115 71.9584Z"
          fill="#F7EC53"
        />
        <path
          d="M565.187 59.8837L564.877 61.5144L523.057 135.764L475.714 135.764C480.476 126.498 487.387 114.333 496.446 99.269C494.531 94.8689 492.316 88.7173 487.554 79.7618C482.947 71.0652 479.685 64.4391 477.77 59.8837H565.187ZM561.15 62.6791H481.342L499.475 98.8808L479.674 133.435H521.659L561.15 62.6791Z"
          fill="#F75353"
        />
        <path
          d="M665.369 131.739H640.497L640.875 64.3747H665.369V131.739ZM664.549 130.86V65.2539H641.444L641.065 130.86H664.549ZM663.476 129.777H642.454V66.4714H663.16L663.476 129.777ZM654.322 70.6647C654.322 70.5745 654.343 70.3491 654.385 69.9884C654.427 69.7178 654.427 69.5149 654.385 69.3797C654.343 69.1542 654.238 68.9513 654.069 68.7709L644.411 68.568V106.985H645.799L646.368 71.1382C647.293 70.7324 648.577 70.552 650.218 70.5971C653.291 70.5971 654.659 70.6196 654.322 70.6647Z"
          fill="#F7EC53"
        />
        <path
          d="M669.169 136.23H637.042L637.277 60.2292H669.169V136.23ZM666.74 133.941V62.5184H639.236V133.941H666.74Z"
          fill="#F75353"
        />
        <path
          d="M626.761 77.6866C626.936 78.8503 627.024 80.0139 627.024 81.1776C627.024 87.4434 620.655 91.3203 615.731 93.9112C614.9 94.4931 610.544 94.7175 609.406 95.1203C608.268 95.5231 608.126 95.724 607.851 96.5511C607.336 98.098 607.509 98.8836 608.542 100.648C609.97 101.482 616.434 102.44 617.366 103.209C619.857 104.268 624.495 107.361 625.458 110.046C626.071 111.702 626.433 113.155 626.433 115.08C626.433 118.616 625.579 121.726 623.872 124.411C621.684 127.813 617.942 130.051 612.646 131.125L575.551 131.393L575.879 65.0656L614.878 65.6698C617.679 66.6096 620.174 68.109 622.362 70.1677C624.813 72.495 626.28 75.0013 626.761 77.6866ZM625.776 78.0223C625.383 75.695 624.157 73.4572 622.1 71.309C620.261 69.3845 618.139 67.8852 615.731 66.811L576.536 66.3411L576.142 130.521L613.827 130.252C616.979 128.865 618.708 128.104 619.014 127.97C620.94 126.851 622.362 125.463 623.282 123.807C624.857 120.809 624.792 117.723 624.792 114.456C624.792 111.86 624.332 110.155 623.15 108.41C620.83 104.248 612.696 102.863 605.606 101.386C605.387 101.118 605.606 95.9133 605.606 95.4658C605.606 94.9735 605.802 94.6378 606.196 94.4588C610.748 94.7721 615.731 92.8749 620.655 89.6237C623.872 86.9378 626.17 83.1916 626.17 81.1776C626.17 80.1034 626.039 79.0517 625.776 78.0223ZM624.726 78.5594C624.77 79.2755 624.792 79.9916 624.792 80.7076C624.792 86.7944 617.537 88.4512 613.422 90.9576C611.847 92.3002 608.608 93.1506 603.706 93.5086C603.049 94.2695 603.268 101.016 603.706 103.209C607.207 103.836 611.628 103.815 613.422 104.62C616.136 105.918 621.027 107.672 622.428 110.223C623.435 112.102 623.872 113.54 623.872 115.867C623.872 118.463 623.61 120.115 622.428 122.935C620.283 126.202 617.219 128.238 613.236 129.044L577.586 129.446V67.4152L615.272 68.0194C617.416 68.8698 619.364 70.2796 621.115 72.2488C622.91 74.2181 624.113 76.3216 624.726 78.5594ZM598.267 126.963C598.355 126.515 598.267 125.956 598.005 125.284L582.51 125.016L581.657 96.5511H580.212V127.365L598.267 126.963Z"
          fill="#F7EC53"
        />
        <path
          d="M630.314 112.168C630.724 113.759 630.93 115.376 630.93 117.019C630.93 123.641 627.978 128.98 622.075 133.035C618.225 134.935 613.912 135.884 609.138 135.884H570.715V59.8837L615.452 60.1917C618.379 60.7564 621.125 61.9884 623.692 63.8878C626.258 65.7358 628.235 68.0202 629.621 70.7409C630.853 73.2563 631.469 75.977 631.469 78.9031C631.469 85.6792 628.799 91.018 623.461 94.9194C621.356 96.4595 618.918 97.6401 616.146 98.4615C619.328 99.2315 622.254 100.926 624.924 103.544C627.644 106.11 629.441 108.985 630.314 112.168ZM628.62 74.976C627.901 72.1526 626.361 69.6116 624 67.3529C621.638 65.0942 619.02 63.5798 616.146 62.8098L572.948 62.5018V133.574H611.91C614.734 133.574 617.429 132.881 619.996 131.495C622.562 130.109 624.564 128.184 626.002 125.72C627.593 122.999 628.389 120.022 628.389 116.788C628.389 113.81 627.696 111.167 626.31 108.857C622.819 103.159 617.48 100.233 610.293 100.079C610.293 99.8732 610.242 99.4112 610.139 98.6925C610.139 98.1278 610.139 97.6658 610.139 97.3065C610.191 96.8445 610.319 96.4338 610.524 96.0745C611.192 96.1771 611.859 96.2285 612.526 96.2285C616.428 96.2285 620.689 94.2264 625.309 90.2223C627.824 86.4236 629.082 82.5735 629.082 78.6721C629.082 77.3887 628.928 76.1567 628.62 74.976Z"
          fill="#F75353"
        />
        <path
          d="M754.536 67.0552C748.866 76.9894 740.206 91.8027 728.558 111.495C729.569 111.803 730.756 112.001 732.118 112.089C733.041 112.133 734.316 112.133 735.943 112.089C737.745 112.045 739.019 112.045 739.767 112.089C740.074 113.935 740.272 116.045 740.36 118.418C740.404 119.957 740.426 122.133 740.426 124.946C740.382 128.023 740.36 130.199 740.36 131.474H684.909V130.023C686.799 126.287 690.36 120.089 695.59 111.429C700.382 103.561 703.876 97.3193 706.074 92.7038L683.195 92.2423V65.8683H754.536V67.0552ZM753.152 67.0552H683.788V91.6489L707.986 91.8467C706.228 95.0995 702.47 101.583 696.711 111.297C691.92 119.298 688.294 125.825 685.832 130.88H738.712V113.276C738.448 113.276 736.448 113.297 732.712 113.341C730.25 113.341 728.272 113.122 726.778 112.682L753.152 67.0552ZM751.108 68.5057L725.063 113.276C726.25 114.111 728.008 114.528 730.338 114.528C730.822 114.528 732.03 114.484 733.964 114.396C735.459 114.308 736.624 114.308 737.459 114.396V129.759H687.744C690.469 124.44 694.052 118.089 698.491 110.704C702.272 104.418 706.03 98.1325 709.766 91.8467L707.986 90.3961H684.909V68.1761C699.634 68.0881 721.701 68.198 751.108 68.5057ZM746.954 69.9563H726.514L726.316 72.0003H742.998L743.591 72.4618C740.25 77.6487 737.371 82.9455 734.953 88.3521L736.602 88.7477L746.954 69.9563ZM719.063 127.715V125.935L695.92 125.671L695.327 125.078C696.03 123.539 697.524 120.99 699.81 117.429C701.876 114.177 703.283 111.561 704.03 109.583C704.25 109.319 704.36 109.1 704.36 108.924C704.36 108.704 704.118 108.55 703.634 108.462C703.019 108.33 702.667 108.221 702.579 108.133L691.239 127.715H719.063Z"
          fill="#F7EC53"
        />
        <path
          d="M763.667 62.6303L737.177 107.003L747.431 107.244V135.907H676.179C675.734 135.907 675.388 135.907 674.697 135.812L675.215 134.623C678.052 129.003 680.023 125.867 685.59 116.501C691.959 105.689 696.267 100.872 698.515 96.6977L678.588 96.5372V61.2655H763.667V62.6303ZM759.814 63.9149H680.755V94.2892L701.486 94.0484L702.85 95.0118C702.155 96.2428 697.659 101.113 689.363 114.975C683.904 124.127 681.799 127.397 678.587 133.499L743.979 133.739L744.46 109.974C743.497 109.92 744.75 109.92 742.395 109.974C740.254 110.027 738.595 110.027 737.418 109.974C735.652 109.866 734.099 109.626 732.761 109.251C739.612 97.262 745.844 87.7858 759.814 63.9149Z"
          fill="#F75353"
        />
        <path d="M192 492H67V638.552H192V492Z" fill="#7875AD" />
        <path
          d="M172.603 514.741H86.3964V615.811H172.603V514.741Z"
          fill="#F2F2F2"
        />
        <mask
          id="mask1_187_2367"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="86"
          y="514"
          width="87"
          height="102"
        >
          <path
            d="M172.603 514.741H86.3964V615.811H172.603V514.741Z"
            fill="#F2F2F2"
          />
        </mask>
        <g mask="url(#mask1_187_2367)">
          <path
            d="M153.612 598.193C149.075 603.465 140.565 601.378 137.547 600.08C140.008 598.043 140.061 593.988 140.409 591.037C141.653 592.78 150.496 593.074 153.612 592.777C153.781 593.259 153.906 595.701 153.612 598.193Z"
            fill="white"
            stroke="#7875AD"
            strokeWidth="0.899943"
          />
          <circle
            cx="136.02"
            cy="594.44"
            r="6.2996"
            transform="rotate(-136.473 136.02 594.44)"
            fill="url(#paint4_radial_187_2367)"
            stroke="#7875AD"
            strokeWidth="0.899943"
          />
          <path
            d="M151.002 589.75C149.966 590.675 149.032 592.946 148.792 593.725C148.631 594.629 148.39 596.672 148.72 597.611C149.132 598.783 149.691 600.966 152.45 602.077C155.209 603.189 159.383 602.298 162.28 601.283C165.176 600.269 165.869 596.354 164.398 593.692C163.221 591.563 159.766 592.106 158.185 592.644C157.408 592.834 156.306 593.18 154.713 592.851C153.852 592.674 154.072 591.595 154.432 590.976C155.067 589.884 155.395 589.276 155.366 588.656C155.477 587.353 154.095 587.224 153.641 587.526C152.671 588.384 152.038 588.825 151.002 589.75Z"
            fill="url(#paint5_linear_187_2367)"
            stroke="#7875AD"
            strokeWidth="0.90085"
            strokeLinecap="round"
          />
          <path
            d="M130.499 591.001C122.068 586.039 112.645 588.934 108.988 591.001C102.07 603.687 97.1908 653.259 96.9305 657.878C101.745 660.654 107.86 658.876 109.53 658.377C109.313 653.086 112.262 643.826 112.999 641.463C114.253 637.444 117.163 634.69 117.727 633.67C119.678 634.487 121.5 634.993 123.734 634.502C123.408 638.34 123.885 654.257 124.579 657.553C127.459 659.045 133.094 658.175 135.552 657.553C136.615 647.065 138.452 624.799 138.154 619.582C137.482 607.822 134.207 597.131 130.499 591.001Z"
            fill="white"
            stroke="#7875AD"
            strokeWidth="0.86741"
          />
          <path
            d="M162.814 534.661C150.827 501.422 121.273 498.982 107.994 501.916C98.227 503.46 89.0772 509.557 85.7232 512.412C76.3769 521.476 61.0414 548.426 81.6681 575.343C110.852 606.824 136.166 593.567 146.872 586.223C154.577 581.228 168.123 562.418 162.814 534.661Z"
            fill="white"
            stroke="#071C36"
            strokeWidth="0.86741"
          />
          <path
            d="M99.5545 507.115C113.954 521.393 126.097 528.403 130.369 530.123C136.766 526.784 148.592 516.086 153.659 509.067C157.375 508.691 166.419 507.484 174 505.142C162.897 534.482 141.038 556.059 130.369 559.81C113.975 557.447 81.9316 523.003 69 503.906C75.2743 504.694 90.1691 506.439 99.5545 507.115Z"
            fill="url(#paint6_linear_187_2367)"
            stroke="#071C36"
            strokeWidth="0.86741"
          />
          <circle
            r="7.85186"
            transform="matrix(-1 0 0 1 102.523 594.95)"
            fill="url(#paint7_radial_187_2367)"
            stroke="#7875AD"
            strokeWidth="0.882231"
          />
          <path
            d="M105.203 598.096C107.067 600.169 107.361 607.194 107.275 610.447C98.7307 612.52 100.543 620.811 100.543 625.475C88.115 616.769 92.4302 604.574 96.1412 599.565C98.3852 598.212 103.339 596.024 105.203 598.096Z"
            fill="white"
            stroke="#7875AD"
            strokeWidth="0.882231"
          />
          <path
            d="M100.214 616.012C101.615 609.311 108.84 609.831 112.277 610.929C116.673 612.348 116.162 616.47 115.021 617.811C115.865 618.564 115.631 620.747 114.089 621.556C115.555 622.952 114.622 624.481 113.469 625.899C114.247 628.78 111.528 630.608 109.486 630.474C107.443 630.341 104.18 629.875 101.927 627.891C99.506 624.3 98.4627 624.389 100.214 616.012Z"
            fill="url(#paint8_linear_187_2367)"
          />
          <path
            d="M113.469 625.899C114.247 628.78 111.528 630.608 109.486 630.474C107.443 630.341 104.18 629.875 101.927 627.891C99.506 624.3 98.4627 624.389 100.214 616.012C101.615 609.311 108.84 609.831 112.277 610.929C116.673 612.348 116.162 616.47 115.021 617.811M113.469 625.899C114.622 624.481 115.555 622.952 114.089 621.556M113.469 625.899C112.281 625.888 109.608 625.727 108.418 625.177M114.089 621.556C115.631 620.747 115.865 618.564 115.021 617.811M114.089 621.556C112.809 621.637 109.917 621.672 108.594 621.166M115.021 617.811C113.5 617.725 110.205 617.439 109.192 616.978"
            stroke="#7875AD"
            strokeWidth="0.882231"
          />
          <path
            d="M76.5201 546.515C76.5201 546.515 76.9206 545.784 78.0218 545.31C78.4633 545.119 93.588 548.678 106.039 548.664C111.373 553.283 119.917 560.114 130.218 562.326C136.333 560.873 145.094 553.435 149.691 547.927C153.729 547.86 157.249 547.363 159.059 547.233H160.23C160.23 547.233 161.108 547.367 161.401 547.558C161.965 547.927 162.073 548.577 162.073 548.577C162.138 549.206 162.089 549.705 162.073 550.204C161.844 557.155 155.828 567.509 153.594 569.157C151.469 570.725 146.612 573.284 144.443 573.928C141.906 574.681 135.899 577.007 123.061 577.007C106.928 577.007 90.6202 572.627 86.3703 568.983C83.7274 566.718 79.3369 558.222 77.8691 553.574C77.2328 551.981 76.4692 548.792 76.2656 548C76.062 547.208 76.3504 546.68 76.5201 546.515Z"
            fill="url(#paint9_linear_187_2367)"
            stroke="#071C36"
            strokeWidth="0.86741"
          />
        </g>
        <path
          opacity="0.3"
          d="M172.603 514.741H86.3964V615.811H172.603V514.741Z"
          fill="#F2F2F2"
        />
        <g opacity="0.3">
          <path
            d="M100 599.5L158.5 538"
            stroke="white"
            strokeWidth="20"
            strokeLinecap="round"
          />
          <path
            d="M100 562L123 538"
            stroke="white"
            strokeWidth="20"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_187_2367"
            x1="542.798"
            y1="22.606"
            x2="542.798"
            y2="172.676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_187_2367"
            x1="542.789"
            y1="172.676"
            x2="542.789"
            y2="204.62"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_187_2367"
            x1="542.818"
            y1="588.541"
            x2="542.818"
            y2="646.6"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <radialGradient
            id="paint3_radial_187_2367"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(145.357 480.257) scale(400.91 400.91)"
          >
            <stop stopColor="#8FACBD" />
            <stop offset="0.4" stopColor="#819DAD" />
            <stop offset="0.69" stopColor="#648598" />
            <stop offset="0.95" stopColor="#698595" />
            <stop offset="1" stopColor="#678393" />
          </radialGradient>
          <radialGradient
            id="paint4_radial_187_2367"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(136.02 594.44) rotate(90) scale(6.74957)"
          >
            <stop stopColor="#143763" />
            <stop offset="1" stopColor="#021E34" />
          </radialGradient>
          <linearGradient
            id="paint5_linear_187_2367"
            x1="162.268"
            y1="598.692"
            x2="150.385"
            y2="591.319"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#143763" />
            <stop offset="1" stopColor="#021E34" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_187_2367"
            x1="121.5"
            y1="503.906"
            x2="121.5"
            y2="559.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#071C36" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <radialGradient
            id="paint7_radial_187_2367"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(8.29298 8.29298) rotate(90) scale(8.29298)"
          >
            <stop stopColor="#143763" />
            <stop offset="1" stopColor="#021E34" />
          </radialGradient>
          <linearGradient
            id="paint8_linear_187_2367"
            x1="103.241"
            y1="610.272"
            x2="108.706"
            y2="620.139"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#021E34" />
            <stop offset="1" stopColor="#143763" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_187_2367"
            x1="119.136"
            y1="494.126"
            x2="119.136"
            y2="577.029"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B4B4B4" />
            <stop offset="1" stopColor="#EDEDED" />
          </linearGradient>
        </defs>
        <Joystick x="480" y="550" />
        <InsertCoin x="780" y="660" width="42" height="36" />
        <CoinBag x="290" y="520" />
        <GamingText coinsLength={coinsArray.length} />
        <DragCoinHint
          coinsLength={coinsArray.length}
          coinDragged={isDragged}
          x="730"
          y="590"
          width="123"
          height="126"
        />
        {coinsArray.length <= 0 && <SelectGameText />}
      </svg>
    </DragDropContext>
  );
}
