import "./Carousel.css";
import Slider from "react-slick";
import { CarouselItemType } from "../../types/types";

const { REACT_APP_BASE_URL } = process.env;

type CarouselType = {
  title: string;
  items?: Array<CarouselItemType>;
};

const Carousel = ({ title, items }: CarouselType) => {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
    dots: false,
    infinite: true,
    initialSlide: 0,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: 7,
    speed: 2500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <span>
        I NOSTRI <b>{title.toUpperCase()}</b>
      </span>
      <Slider {...settings}>
        {items?.map(({ id, link, logo }: CarouselItemType) => (
          <div key={id} className="carousel-item">
            <a rel="noreferrer" href={link} target="_blank">
              <img
                src={`${REACT_APP_BASE_URL}${logo?.url}`}
                alt="carousel item"
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
