import { useEffect } from "react";
import gsap from "gsap";
import "./ScrollingLine.css";
import { CustomEase, MotionPathPlugin, ScrollTrigger } from "gsap/all";
import useScrollColor from "../../hooks/useScrollColor";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger, CustomEase);

type ScrollingLineProps = {
  className?: string;
  page?: string;
};

export function ScrollingLine({ className, page }: ScrollingLineProps) {
  const { scrollColor } = useScrollColor();

  useEffect(() => {
    window.scrollTo(0, 0);
    //document.getElementById("content-wrapper").scrollTo(0, 0);

    gsap.to("#arrow", {
      duration: 10,
      ease: "power0",
      motionPath: {
        path: "M0 328H1370",
        autoRotate: true,
        align: "#line",
        alignOrigin: [0.5, 0.5],
      },
      scrollTrigger: {
        trigger: ".App",
        start: "top top",
        endTrigger: ".footer-container",
        end: "top bottom",
        scrub: 1,
      },
    });
  }, [page]);

  return (
    <div
      className={`line-wrapper ${className ? className : ""}`}
      style={{ background: scrollColor }}
    >
      <Arrow />
      <MainLine />
    </div>
  );
}

function Arrow() {
  return (
    <svg
      id="arrow"
      width="26"
      height="38"
      viewBox="0 0 26 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.45305 30.1601L1.74594 30.8672L2.45305 31.5743L6.08586 35.2071L6.79297 35.9142L7.50008 35.2071L23.0001 19.7071L23.7072 19L23.0001 18.2929L7.50007 2.79289L6.79297 2.08578L6.08586 2.79289L2.45305 6.4257L1.74594 7.13281L2.45305 7.83992L13.6131 19L2.45305 30.1601Z"
        fill="#FFF"
        stroke="white"
        strokeWidth="4"
      />
    </svg>
  );
}

function MainLine() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1382 656"
      preserveAspectRatio="none"
      stroke="null"
    >
      <path
        id="line"
        d="M0 328H1382"
        fill="none"
        stroke="#fff"
        strokeWidth="10"
      />
    </svg>
  );
}
