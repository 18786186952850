import React, { useEffect } from "react";
import axiosInstance from "../../api/interceptor";
import parse from "html-react-parser";
import { css } from "styled-components/macro";

type FooterItemsType = {
  serviceTerms: string;
  privacyPolicy: string;
  cookiesPolicy: string;
};

export function ServiceTerms() {
  const [footerItems, setFooterItems] = React.useState<FooterItemsType>();

  useEffect(() => {
    axiosInstance
      .get("footer-items")
      .then((res) => setFooterItems(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      css={css`
        position: relative;
        top: 140px;
        padding: 20px 280px 0px 280px;
        color: white;
        text-align: left;
        margin-bottom: 225px;
        @media (max-width: 768px) {
          padding: 5px 20px 0px 20px;
        }

        p {
          margin: 30px 0px;
        }
      `}
    >
      {parse((footerItems?.serviceTerms as string) ?? "")}
    </div>
  );
}

export function PrivacyPolicy() {
  const [footerItems, setFooterItems] = React.useState<FooterItemsType>();

  useEffect(() => {
    axiosInstance
      .get("footer-items")
      .then((res) => setFooterItems(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      css={css`
        position: relative;
        top: 140px;
        padding: 20px 280px 0px 280px;
        color: white;
        text-align: left;
        margin-bottom: 225px;
        @media (max-width: 768px) {
          padding: 5px 20px 0px 20px;
        }

        p {
          margin: 30px 0px;
        }
      `}
    >
      {parse((footerItems?.privacyPolicy as string) ?? "")}
    </div>
  );
}

export function CookiesPolicy() {
  const [footerItems, setFooterItems] = React.useState<FooterItemsType>();

  useEffect(() => {
    axiosInstance
      .get("footer-items")
      .then((res) => setFooterItems(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      css={css`
        position: relative;
        top: 140px;
        padding: 20px 280px 0px 280px;
        color: white;
        text-align: left;
        margin-bottom: 225px;
        @media (max-width: 768px) {
          padding: 5px 20px 0px 20px;
        }

        p {
          margin: 30px 0px;
        }
      `}
    >
      {parse((footerItems?.cookiesPolicy as string) ?? "")}
    </div>
  );
}
