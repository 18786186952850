import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomepageScreen } from "./screens/Homepage/Homepage";
import { AnimatedNetwork } from "./components/AnimatedNetwork/AnimatedNetwork";
import { Header } from "./components/Header/Header";
import { useEffect, useState } from "react";
import { Footer } from "./components/Footer/Footer";
import AboutUs from "./screens/AboutUs/AboutUs";
import { Byz } from "./screens/Byz/Byz";
import { KBArt } from "./screens/KBArt/KBArt";
import { ContactUs } from "./screens/ContactUs/ContactUs";
import {
  CookiesPolicy,
  PrivacyPolicy,
  ServiceTerms,
} from "./screens/FooterItems/FooterItems";
import { SubCyberSecurity } from "./screens/SubMenuPages/SubCyberSecurity/SubCyberSecurity";
import { SubEngineering } from "./screens/SubMenuPages/SubEngineering/SubEngineering";
import { SubDigital } from "./screens/SubMenuPages/SubDigital/SubDigital";
import { SubResearch } from "./screens/SubMenuPages/SubResearch/SubResearch";
import { CookiesModal } from "./components/CookiesModal/CookiesModal";
import { GamingPage } from "./screens/GamingPage/GamingPage";
import { ChristmasGame } from "./screens/ChristmasGame/ChristmasGame";

function withFooter(component: JSX.Element) {
  return (
    <>
      {component}
      <Footer />
    </>
  );
}

function App() {
  const [showCookies, setShowCookies] = useState<boolean>(true);
  const [acceptCookiesClicked, setAcceptCookiesClicked] =
    useState<boolean>(false);
  useEffect(() => {
    const scroll = () => {
      const height = window.innerHeight;
      const textToAnimate = [...document.getElementsByClassName("fade-in")];

      textToAnimate.forEach((el) => {
        const { y } = el.getBoundingClientRect();
        if (y < height - 100) {
          el.classList.add("animate");
        } else if (y > height) {
          el.classList.remove("animate");
        }
      });

      //setShow(document.documentElement.scrollTop > 350)
    };

    window.addEventListener("scroll", scroll);

    const cookiesAccepted: boolean =
      localStorage.getItem("cookiesAccepted") === "true";
    setShowCookies(!cookiesAccepted);

    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  function acceptCookies() {
    setAcceptCookiesClicked(true);
    setTimeout(() => {
      setShowCookies(false);
      localStorage.setItem("cookiesAccepted", "true");
    }, 2000);
  }

  return (
    <div className="App">
      <AnimatedNetwork>
        <BrowserRouter>
          <Header />
          {showCookies && (
            <CookiesModal
              acceptClick={acceptCookies}
              refuseClick={() => {
                setShowCookies(false);
                localStorage.setItem("cookiesAccepted", "false");
              }}
              acceptClicked={acceptCookiesClicked}
            />
          )}
          <Routes>
            <Route
              path="/"
              caseSensitive={false}
              element={withFooter(<HomepageScreen />)}
            ></Route>
            <Route
              path="/about-us"
              caseSensitive={false}
              element={withFooter(<AboutUs />)}
            />
            <Route
              path="/byz"
              caseSensitive={false}
              element={withFooter(<Byz />)}
            />
            <Route
              path="/keybiz-art"
              caseSensitive={false}
              element={<KBArt />}
            />
            <Route
              path="/contact-us"
              caseSensitive={false}
              element={withFooter(<ContactUs />)}
            />
            <Route
              path="/terms-of-service"
              caseSensitive={false}
              element={withFooter(<ServiceTerms />)}
            />
            <Route
              path="/privacy-policy"
              caseSensitive={false}
              element={withFooter(<PrivacyPolicy />)}
            />
            <Route
              path="/cookie-policy"
              caseSensitive={false}
              element={withFooter(<CookiesPolicy />)}
            />
            <Route
              path="/cosa-facciamo/cyber-security"
              caseSensitive={false}
              element={withFooter(<SubCyberSecurity />)}
            />
            <Route
              path="/cosa-facciamo/engineering"
              caseSensitive={false}
              element={withFooter(<SubEngineering />)}
            />
            <Route
              path="/cosa-facciamo/digital-factory"
              caseSensitive={false}
              element={withFooter(<SubDigital />)}
            />
            <Route
              path="/cosa-facciamo/r&d"
              caseSensitive={false}
              element={withFooter(<SubResearch />)}
            />
            <Route
              path="/game-room"
              caseSensitive={false}
              element={<GamingPage />}
            />
            <Route
              path="/christmas-game"
              caseSensitive={false}
              element={withFooter(<ChristmasGame />)}
            />
          </Routes>
        </BrowserRouter>
      </AnimatedNetwork>
    </div>
  );
}

export default App;
