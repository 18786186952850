import "./AnimatedCircle.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "../../components/ChangingProgressProvider/ChangingProgressProvider";

type AnimatedCirlceType = {
  id?: string;
  valore: number;
  inView?: boolean;
};

export function AnimatedCircle({ id, valore, inView }: AnimatedCirlceType) {
  return (
    <div id="animated-circle">
      <ChangingProgressProvider values={[0, valore]} inView={inView}>
        {(percentage: number) => {
          return (
            <CircularProgressbar
              value={percentage}
              //text={id}
              styles={buildStyles({
                pathTransition:
                  percentage === 0
                    ? "none"
                    : "stroke-dashoffset 0.5s ease 0.2s",
              })}
            />
          );
        }}
      </ChangingProgressProvider>
    </div>
  );
}
