import React from "react";
import { spaceshipAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { PageButton } from "../../PageButton/PageButton";
import "./InnovationFactory.css";
import parse from "html-react-parser";
import { ParagraphType } from "../../../types/types";

type InnovationFactoryType = {
  title: string;
  innovationParagraph: Array<ParagraphType>;
};

export function InnovationFactory(content: InnovationFactoryType) {
  React.useEffect(() => {
    const scroll = () => {
      const height = window.innerHeight;
      const animateWhenOnScreen = [
        ...document?.getElementsByClassName("spaceship"),
      ];

      animateWhenOnScreen.forEach((el) => {
        const { y } = el.getBoundingClientRect();
        if (y < height + 100) {
          el.classList.add("animate");
        } else if (y > height) {
          el.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", scroll);

    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <div className="innovation-container">
      <div className="innovation-image">
        {spaceshipAnimated()}
        <hr />
      </div>
      <div className="innovation-text__container">
        <div className="innovation-text fade-in">
          <div className="innovation-text__title">
            <h1>{content?.title?.toUpperCase()}</h1>
          </div>
          <div className="innovation-text__description">
            {content?.innovationParagraph?.map((paragraph) => {
              return (
                <React.Fragment key={paragraph.id}>
                  {parse(paragraph.description)}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="innovation-page-button fade-in">
          <PageButton
            text="scopri di più"
            linkToPage={"cosa-facciamo/digital-factory"}
          />
        </div>
      </div>
    </div>
  );
}
