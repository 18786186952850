import { useNavigate } from "react-router-dom";
import { Node } from "../../components/Node/Node";

const { REACT_APP_BASE_URL } = process.env;

type MobileTimelineSectionType = {
  lineMobile: { url: string };
};

export function MobileTimelineSection(content: MobileTimelineSectionType) {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <div className="timeline-content">
      <div className="line">
        <img src={`${REACT_APP_BASE_URL}${content?.lineMobile?.url}`} alt="" />
        <Node />
        <Node />
        <Node />
        <Node />
        <Node />
        <Node />
      </div>
      <div className="steps">
        <div id="first" className="step">
          <span>
            <b>GENNAIO 2007 </b>
          </span>
          <span> FONDAZIONE </span>
          <span> KEYBIZ </span>
        </div>
        <div
          id="second"
          className="step"
          onClick={() => redirectTo("/cosa-facciamo/innovation-factory")}
        >
          <span>
            <b>GIUGNO 2012</b>
          </span>
          <span> NASCITA INNOVATION</span>
          <span>FACTORY</span>
        </div>
        <div
          id="third"
          className="step"
          onClick={() => redirectTo("/metamorphosis")}
        >
          <span>
            <b> GENNAIO 2020</b>
          </span>
          <span> NASCITA </span>
          <span> METAMORPHOSIS </span>
        </div>
        <div
          id="fourth"
          className="step"
          onClick={() => redirectTo("/cosa-facciamo/cyber-security")}
        >
          <span>
            <b> FEBBRAIO 2020</b>
          </span>
          <span> NASCITA CYBER</span>
          <span> SECURITY </span>
        </div>
        <div id="fifth" className="step">
          <span>
            <b> MAGGIO 2021</b>
          </span>
          <span> 150 DIPENDENTI</span>
        </div>
        <div
          id="sixth"
          className="step"
          onClick={() => redirectTo("/cosa-facciamo/engineering")}
        >
          <span>
            <b> LUGLIO 2021</b>
          </span>
          <span> NASCITA KB </span>
          <span> ENGINEERING </span>
        </div>
      </div>
    </div>
  );
}
