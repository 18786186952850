import React, { useEffect, useState } from "react";

import { ScrollingLine } from "../../components/ScrollingLine/ScrollingLine";
import { PageButton } from "../../components/PageButton/PageButton";

import map from "../../assets/images/contact-us/map.svg";
import mail from "../../assets/images/contact-us/mail.svg";
import "./ContactUs.css";

export function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div id="contact-us-desktop">
        <ScrollingLine page="contact-us" />
        <div className="title-container">
          <img src={mail} alt="mail background" />
          <h1>Contattaci</h1>
        </div>
        <div className="content-container">
          <ContactUsForm />
          {/* <KBGoogleMap height={300} width="100%" /> */}
        </div>
      </div>
      <div id="contact-us-mobile">
        <ContactUsForm />
      </div>
    </>
  );
}

const directionLink =
  "https://www.google.it/maps/dir//Via+Mario+Bianchini,+47,+00142+Roma+RM/@41.8350399,12.4982749,17z/data=!4m17!1m7!3m6!1s0x13258a4f29f5dfa7:0x81f6702071499668!2sVia+Mario+Bianchini,+47,+00142+Roma+RM!3b1!8m2!3d41.8350399!4d12.5004636!4m8!1m0!1m5!1m1!1s0x13258a4f29f5dfa7:0x81f6702071499668!2m2!1d12.5004636!2d41.8350399!3e2;";

function ContactUsForm() {
  const [formOnFocus, setFormOnFocus] = useState({
    inputName: false,
    inputLastName: false,
    inputMailObject: false,
    inputTelefono: false,
    inputMessage: false,
  });

  const [formFilled, setFormFilled] = useState({
    name: false,
    lastName: false,
    mailObject: false,
    telefono: false,
    message: false,
  });

  const [formInput, setFormInput] = useState({
    name: "",
    lastName: "",
    mailObject: "",
    telefono: "",
    message: "",
  });

  const openEmailClient = () => {
    const message = {
      emailId: "info@keybiz.it",
      subject: `${formInput.mailObject}`,
      emailBody: `Nome: ${formInput.name}
      Cognome: ${formInput.lastName}
      Telefono: ${formInput.telefono}
      Messaggio: ${formInput.message}`,
    };

    const email = message.emailId;
    const subject = message.subject;
    const emailBody = message.emailBody;
    document.location =
      "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  };

  const onChangeHandler = (e: any) => {
    e.target.value !== ""
      ? setFormFilled({
          ...formFilled,
          [e.target.name]: true,
        })
      : setFormFilled({
          ...formFilled,
          [e.target.name]: false,
        });

    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = () => {
    if (
      formInput.name === "" ||
      formInput.lastName === "" ||
      formInput.mailObject === "" ||
      formInput.telefono === "" ||
      formInput.message === ""
    ) {
      alert("Riempi tutti i campi prima di procedere");
    } else {
      openEmailClient();
      //console.log(formInput);
      setFormInput({
        name: "",
        lastName: "",
        mailObject: "",
        telefono: "",
        message: "",
      });

      setFormFilled({
        name: false,
        lastName: false,
        mailObject: false,
        telefono: false,
        message: false,
      });
      //event.preventDefault();
      //alert("Messaggio inviato con successo");
    }
  };

  return (
    <React.Fragment>
      <div className="contactus-container ">
        <div className="intestazione-container">
          <img src={mail} alt="mail background" />
          <h1>Contattaci</h1>
        </div>
        <div className="map-container">
          <a href={directionLink} target="_blank" rel="noreferrer">
            <img className="map" src={map} alt="map" />
          </a>
        </div>
        <div className="form-container">
          <form onSubmit={submitHandler}>
            <div className="name-field">
              <div className="group">
                <label
                  className={`label ${
                    formOnFocus.inputName === true || formFilled.name
                      ? "focus"
                      : ""
                  }`}
                >
                  Nome
                </label>
                <input
                  onFocus={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputName: true,
                    })
                  }
                  onBlur={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputName: false,
                    })
                  }
                  name="name"
                  value={formInput.name}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="group">
                <label
                  className={`label ${
                    formOnFocus.inputLastName === true || formFilled.lastName
                      ? "focus"
                      : ""
                  }`}
                >
                  Cognome
                </label>
                <input
                  onFocus={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputLastName: true,
                    })
                  }
                  onBlur={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputLastName: false,
                    })
                  }
                  name="lastName"
                  value={formInput.lastName}
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="contacts-field">
              <div className="group">
                <label
                  className={`label ${
                    formOnFocus.inputMailObject === true ||
                    formFilled.mailObject
                      ? "focus"
                      : ""
                  }`}
                >
                  Oggetto dell'e-mail
                </label>
                <input
                  onFocus={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputMailObject: true,
                    })
                  }
                  onBlur={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputMailObject: false,
                    })
                  }
                  name="mailObject"
                  value={formInput.mailObject}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="group">
                <label
                  className={`label ${
                    formOnFocus.inputTelefono === true || formFilled.telefono
                      ? "focus"
                      : ""
                  }`}
                >
                  Telefono
                </label>
                <input
                  onFocus={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputTelefono: true,
                    })
                  }
                  onBlur={() =>
                    setFormOnFocus({
                      ...formOnFocus,
                      inputTelefono: false,
                    })
                  }
                  name="telefono"
                  value={formInput.telefono}
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="group">
              <label
                className={`label ${
                  formOnFocus.inputMessage === true || formFilled.message
                    ? "focus"
                    : ""
                }`}
              >
                Messaggio
              </label>
              <textarea
                rows={3}
                onFocus={() =>
                  setFormOnFocus({
                    ...formOnFocus,
                    inputMessage: true,
                  })
                }
                onBlur={() =>
                  setFormOnFocus({
                    ...formOnFocus,
                    inputMessage: false,
                  })
                }
                name="message"
                value={formInput.message}
                onChange={onChangeHandler}
              />
            </div>
            <PageButton text="INVIA" width="58%" onClick={submitHandler} />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
