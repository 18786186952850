import { motion } from "framer-motion";

const linesVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "linear",
      delay: 0.5,
    },
  },
};

const circlesVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeInOut",
      delay: 1.5,
    },
  },
};

const shieldVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const rotatingVariants = {
  still: {
    rotate: 0,
  },
  move: {
    rotate: 360,
    transition: {
      duration: 10,
      repeat: "Infinity",
      ease: "linear",
      delay: 0,
    },
  },
};

const svgVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  },
};

const pathVariants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      duration: 3,
      ease: "easeInOut",
    },
  },
};

const bubbleVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    x: 0,
  },
  visible: {
    opacity: [0, 0.2, 0.6, 0.8, 0.8],
    y: [20, -80],
    x: [0, -4, 2, -5, 0, -2, 3, 0],
    transition: {
      duration: 4,
      ease: "easeInOut",
      delay: 2,
    },
  },
};

const bubbleVariants1 = {
  hidden: {
    opacity: 0,
    y: 20,
    x: 0,
  },
  visible: {
    opacity: [0, 0.2, 0.6, 0.8, 0.8],
    y: [20, -75],
    x: [0, 1, -3, 4, 0, -2, 0, -2, 0],
    transition: {
      duration: 4,
      ease: "easeInOut",
      delay: 2,
    },
  },
};

export const spaceshipAnimated = () => (
  <svg
    className="spaceship"
    width="277"
    height="277"
    viewBox="0 0 277 277"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.715 235.439L88.4893 248.651L28.6553 188.744L41.8509 168.493L108.715 235.439Z"
      fill="#071C36"
      stroke="white"
      strokeWidth="8"
    />
    <path
      d="M13.4939 109.376L62.9897 59.82L103.442 57.8554L42.6849 118.686L13.4939 109.376Z"
      fill="#071C36"
      stroke="white"
      strokeWidth="8"
    />
    <path
      d="M219.211 173.775L217.249 214.277L167.761 263.825L158.463 234.598L219.211 173.775Z"
      fill="#071C36"
      stroke="white"
      strokeWidth="8"
    />
    <path
      d="M215.5 81C226.27 81 235 72.2696 235 61.5C235 50.7304 226.27 42 215.5 42C204.73 42 196 50.7304 196 61.5C196 72.2696 204.73 81 215.5 81Z"
      fill="#9C9C9C"
    />
    <path
      d="M193.064 84.0315L193.064 84.0316C205.384 96.3666 225.361 96.3666 237.682 84.0316L237.682 84.0315C250 71.699 249.999 51.7053 237.682 39.3724L234.852 42.199L237.682 39.3723C225.361 27.0365 205.384 27.0365 193.064 39.3723C180.746 51.7053 180.746 71.6984 193.064 84.0315ZM166.081 32.4641C193.466 5.0456 233.148 -2.56944 267.367 9.63877C279.562 43.9053 271.955 83.6413 244.569 111.061L133.168 222.598L54.6732 144.008L166.081 32.4641Z"
      fill="#071C36"
      stroke="white"
      strokeWidth="8"
    />
    <path
      className="spaceship-longline"
      d="M48 229L6 271"
      stroke="white"
      strokeWidth="8"
      pathLength="15"
    />
    <path
      className="spaceship-shortline"
      d="M30.5869 210.5L-3.91309 245"
      stroke="white"
      strokeWidth="8"
      pathLength="15"
    />
    <path
      className="spaceship-shortline"
      d="M68 246L32 282"
      stroke="white"
      strokeWidth="8"
      pathLength="15"
    />
    <defs>
      <clipPath id="clip0_1175_2454">
        <rect width="277" height="277" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const shieldAnimated = (controls: any, ref?: any) => (
  <motion.svg
    width="206"
    height="278"
    viewBox="0 0 206 278"
    fill="none"
    initial="hidden"
    variants={shieldVariants}
    animate={controls}
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4040_3774)">
      <path
        d="M103.854 9C123.92 46.0787 179.227 56.3371 204.185 57.9438V80.5618V114.674V140.629V169.551V189.573C202.232 195.773 198.39 202.14 193.253 208.483C171.601 235.217 126.951 261.512 103.854 273C75.277 258.787 14.4543 221.906 4.27148 189.573V57.9438C29.2295 56.3371 83.7877 46.0787 103.854 9Z"
        fill="#071C36"
        stroke="white"
        strokeWidth="7.65217"
      />
      <motion.circle
        cx="167"
        cy="193"
        r="7"
        fill="#9C9C9C"
        variants={circlesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.circle
        cx="128"
        cy="184"
        r="7"
        fill="#9C9C9C"
        variants={circlesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.circle
        cx="103"
        cy="134"
        r="7"
        fill="#9C9C9C"
        variants={circlesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.circle
        cx="128"
        cy="84"
        r="7"
        fill="#9C9C9C"
        variants={circlesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.circle
        cx="167"
        cy="75"
        r="7"
        fill="#9C9C9C"
        variants={circlesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.path
        d="M206 191.959H175.918C175.782 188.973 173.8 183 166.959 183C160.118 183 158.136 188.973 158 191.959C158 194.945 159.792 200.918 166.959 200.918C174.126 200.918 175.918 195.216 175.918 192.366"
        stroke="white"
        strokeWidth="6.23041"
        variants={linesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.path
        d="M204 161L127.959 161L127.959 175.082C130.945 175.218 136.918 177.2 136.918 184.041C136.918 190.882 130.945 192.864 127.959 193C124.973 193 119 191.208 119 184.041C119 176.874 124.701 175.082 127.552 175.082"
        stroke="white"
        strokeWidth="6.23041"
        variants={linesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.path
        d="M202 133.959H111.918C111.782 130.973 109.8 125 102.959 125C96.1175 125 94.1357 130.973 94 133.959C94 136.945 95.7918 142.918 102.959 142.918C110.126 142.918 111.918 137.216 111.918 134.366"
        stroke="white"
        strokeWidth="6.23041"
        variants={linesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.path
        d="M204 107L127.959 107L127.959 92.9175C130.945 92.7818 136.918 90.8 136.918 83.9588C136.918 77.1175 130.945 75.1357 127.959 75C124.973 75 119 76.7917 119 83.9588C119 91.1258 124.701 92.9175 127.552 92.9175"
        stroke="white"
        strokeWidth="6.23041"
        variants={linesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
      <motion.path
        d="M206 75.9588H175.918C175.782 72.9725 173.8 67 166.959 67C160.118 67 158.136 72.9725 158 75.9588C158 78.945 159.792 84.9175 166.959 84.9175C174.126 84.9175 175.918 79.2165 175.918 76.366"
        stroke="white"
        strokeWidth="6.23041"
        variants={linesVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      />
    </g>
    <defs>
      <clipPath id="clip0_4040_3774">
        <rect width="206" height="278" fill="white" />
      </clipPath>
    </defs>
  </motion.svg>
);

export const gearAnimated = () => (
  <svg
    className="gear2"
    height="277"
    viewBox="0 0 277 277"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1175_2456)">
      <motion.path
        d="M79.5188 44.4018C85.5085 39.9655 100.42 31.093 112.147 31.093C113.661 22.7274 115.3 9.54534 115.93 4H158.017L161.327 31.093C168.88 32.5165 187.651 38.5826 196.747 45.7915C203.682 40.9149 214.008 32.7718 218.321 29.2958L248.205 59.0833L231.576 80.6637C235.949 87.0312 244.79 102.653 246.176 114.209C254.448 115.989 267.505 118.043 273 118.848L271.698 161.131L244.654 163.619C243.021 171.092 237.946 188.132 230.614 197.114C235.358 204.16 243.289 214.657 246.677 219.044L216.577 248.61L195.371 231.559C188.968 235.854 173.284 244.748 161.766 245.96L158.81 272.848L116.724 273C116.077 267.484 114.404 254.411 112.867 246.045C101.373 244.813 85.865 236.248 79.485 232.074L58.4757 249.369L28.0388 220.153C31.393 215.705 39.3993 205.029 44.0529 197.929C36.9754 188.716 32.2392 171.353 30.9121 163.731L4 160.077L4.5088 117.777C10.0087 117.213 23.0511 115.735 31.3965 114.321C32.8483 102.769 41.992 85.2111 46.3817 78.8706L29.8327 57.228L59.8265 27.5528C64.146 31.0602 72.602 39.5068 79.5188 44.4018Z"
        fill="#071c36"
        variants={rotatingVariants as {}}
        initial="still"
        animate="move"
      />
      <motion.path
        d="M196.747 45.7915C187.651 38.5826 168.88 32.5165 161.327 31.093L158.017 4H115.93C115.3 9.54534 113.661 22.7274 112.147 31.093C100.42 31.093 85.5085 39.9655 79.5188 44.4018C72.602 39.5068 64.1459 31.0602 59.8265 27.5528L29.8327 57.228L46.3817 78.8706C41.992 85.2111 32.8483 102.769 31.3965 114.321C23.0511 115.735 10.0087 117.213 4.5088 117.777L4 160.077L30.9121 163.731C32.2392 171.353 36.9754 188.716 44.0529 197.929C39.3993 205.029 31.393 215.705 28.0388 220.153L58.4757 249.369L79.485 232.074C85.865 236.248 101.373 244.813 112.867 246.045C114.404 254.411 116.077 267.484 116.724 273L158.81 272.848L161.766 245.96C173.284 244.748 188.968 235.854 195.371 231.559L216.577 248.61L246.677 219.044C243.289 214.657 235.358 204.16 230.614 197.114C237.946 188.132 243.021 171.092 244.654 163.619L271.698 161.131L273 118.848C267.505 118.043 254.448 115.989 246.176 114.209C244.79 102.653 235.949 87.0312 231.576 80.6637L248.205 59.0833L218.321 29.2958C214.008 32.7718 203.682 40.9149 196.747 45.7915ZM196.747 45.7915C196.762 45.8036 196.793 45.8277 196.793 45.8277C196.823 45.8064 196.716 45.813 196.747 45.7915Z"
        stroke="white"
        strokeWidth="7.7971"
        variants={rotatingVariants as {}}
        initial="still"
        animate="move"
      />
      <path
        d="M204.405 138.523C204.405 175.052 174.946 204.653 138.621 204.653C102.296 204.653 72.8367 175.052 72.8367 138.523C72.8367 101.994 102.296 72.3928 138.621 72.3928C174.946 72.3928 204.405 101.994 204.405 138.523Z"
        fill="white"
        stroke="white"
        strokeWidth="2.72901"
      />
      <path
        d="M158 138.5C158 151.265 155.488 162.674 151.563 170.78C147.538 179.094 142.552 183 138 183C133.448 183 128.462 179.094 124.437 170.78C120.512 162.674 118 151.265 118 138.5C118 125.735 120.512 114.326 124.437 106.22C128.462 97.9059 133.448 94 138 94C142.552 94 147.538 97.9059 151.563 106.22C155.488 114.326 158 125.735 158 138.5Z"
        stroke="#9C9C9C"
        strokeWidth="6"
      />
      <circle cx="138.5" cy="138.5" r="44.5" stroke="#9C9C9C" strokeWidth="6" />
      <path
        d="M96.5 139H182"
        stroke="#9C9C9C"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1175_2456">
        <rect width="277" height="277" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const researchAnimated = (controls: any, ref?: any) => (
  <motion.svg
    height="277"
    viewBox="0 0 277 277"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    variants={svgVariants}
    animate={controls}
    initial="hidden"
    ref={ref}
  >
    <path
      d="M51.1683 59.4858C54.8435 59.3606 60.2707 57.5579 60.2707 50.8467C60.2707 41.3339 55.2643 40.8809 49.8029 40.8809L17.4893 40.8809C12.0278 40.8809 7.02148 41.3339 7.02148 50.8467C7.02148 58.4569 14 59.7555 17.4893 59.4535V251.069C17.4893 256.354 20.6751 267.014 33.4185 267.377C46.1619 267.739 50.5614 256.656 51.1683 251.069V59.4858ZM51.1683 59.4858L30.2327 59.4535"
      stroke="white"
      strokeWidth="7.68116"
      fill="#071c36"
    />
    <motion.path
      d="M34 264L34 111"
      stroke="#9C9C9C"
      strokeWidth="30"
      variants={pathVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
    />
    <path
      d="M51.1683 59.4858C54.8435 59.3606 60.2707 57.5579 60.2707 50.8467C60.2707 41.3339 55.2643 40.8809 49.8029 40.8809L17.4893 40.8809C12.0278 40.8809 7.02148 41.3339 7.02148 50.8467C7.02148 58.4569 14 59.7555 17.4893 59.4535V251.069C17.4893 256.354 20.6751 267.014 33.4185 267.377C46.1619 267.739 50.5614 256.656 51.1683 251.069V59.4858ZM51.1683 59.4858L30.2327 59.4535"
      stroke="white"
      strokeWidth="7.68116"
    />
    <motion.circle
      cx="32"
      cy="237"
      r="5.5"
      stroke="white"
      strokeWidth="3"
      variants={bubbleVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
    />
    <motion.circle
      cx="39"
      cy="204"
      r="7.85714"
      stroke="white"
      strokeWidth="4.28571"
      variants={bubbleVariants1}
      initial="hidden"
      animate={controls}
      ref={ref}
    />
    <path
      d="M183.608 240.413H224.825V267.159H73.6687V240.413H121.257H125.097V236.573V192.179C125.097 184.104 122.182 178.613 118.054 175.242C114.11 172.02 109.436 171.041 106.184 171.125H73.6687V149.362H198.627H198.911L199.191 149.32C203.418 148.694 209.521 146.457 214.675 141.757C219.94 136.956 224.109 129.669 224.313 119.324C224.518 108.899 220.396 101.663 214.979 97.0047C209.706 92.4698 203.373 90.502 199.016 90.0594L198.822 90.0398H198.627H125.353H121.512V93.8803V109.735V113.576H125.353H135.621V125.826H79.5853V113.576H90.3086H94.1492V109.735V25.9316V22.091H90.3086H79.5853V9.84058H135.621V22.091H125.353H121.512V25.9316V44.5043V48.3449H125.353H187.705C211.816 48.3449 228.384 54.4946 240.135 63.2667C251.93 72.071 259.206 83.766 264.285 95.4153C274.263 118.299 268.28 143.936 251.555 165.84C245.345 173.973 232.484 181.028 218.595 186.017C204.789 190.976 191.01 193.531 183.718 193.323L179.768 193.211V197.162V236.573V240.413H183.608Z"
      stroke="white"
      strokeWidth="7.68116"
      fill="#071c36"
    />
    <path
      d="M142.903 69.8711C142.903 80.4923 134.248 89.1331 123.532 89.1331C112.817 89.1331 104.162 80.4923 104.162 69.8711C104.162 59.25 112.817 50.6091 123.532 50.6091C134.248 50.6091 142.903 59.25 142.903 69.8711Z"
      stroke="white"
      strokeWidth="7.68116"
    />
  </motion.svg>
);
