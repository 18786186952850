import React, { useEffect } from "react";
import axiosInstance from "../../api/interceptor";

import { MobileByzDigitalization } from "../../animatedSVG/ByzDigitalization/MobileBizDigitalization";
import { ByzDigitalization } from "../../animatedSVG/ByzDigitalization/ByzDigitalization";
import { ByzSketchbook } from "../../animatedSVG/ByzSketchbook/ByzSketchbook";
import { ScrollingLine } from "../../components/ScrollingLine/ScrollingLine";
import { CustomSpinner } from "../../components/CustomSpinner/CustomSpinner";
import { ByzPageType } from "../../types/types";

import "./Byz.css";

const { REACT_APP_BASE_URL } = process.env;

type ByzContentType = {
  className: string;
  content?: ByzPageType;
};

export function Byz() {
  const [byzPage, setByzPage] = React.useState<ByzPageType>();
  const [showAnimatedLine, setShowAnimatedLine] =
    React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("byz")
      .then((res) => {
        setByzPage(res.data);
        setShowAnimatedLine(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="byz-page">
      {isLoading && <CustomSpinner />}
      {showAnimatedLine && <ScrollingLine page="byz" />}
      <DesktopContent className={"desktop"} content={byzPage} />
      <MobileContent className="mobile" content={byzPage} />
    </div>
  );
}

function DesktopContent({ className, content }: ByzContentType) {
  return (
    <div className={className}>
      <div className="content-wrapper">
        <div className="idea-section">
          <div className="idea-content">
            <div className="logo">
              <img
                src={`${REACT_APP_BASE_URL}${content?.byzMainSection.byzIcon.url}`}
                alt="byzOpaque"
              />
            </div>
            <h1 className="fade-once">
              {content?.byzMainSection?.title?.toUpperCase()}
            </h1>
            <p className="fade-once">
              {content?.byzMainSection.firstParagraph}
            </p>
            <p className="fade-once">
              {content?.byzMainSection.secondParagraph}
            </p>
          </div>
        </div>
        <div className="book-section fade-once">
          <h1>{content?.firstSectionTitle?.toUpperCase()}</h1>
          <figure>{<ByzSketchbook />}</figure>
        </div>
        <div className="third-section">
          <h1>{content?.secondSectionTitle?.toUpperCase()}</h1>
          <ByzDigitalization className="sketches-container" />
        </div>
      </div>
    </div>
  );
}

function MobileContent({ className, content }: ByzContentType) {
  return (
    <div className={className} id="mobile-content">
      <div className="title">
        {content?.byzMainSection?.title?.toUpperCase()}
      </div>
      <div className="byz-idea">
        <section>
          <h3>L’idea</h3>
          <p>{content?.byzMainSection.firstParagraph}</p>
          <br />
          <p>{content?.byzMainSection.secondParagraph}</p>
        </section>
        <section className="byz-book">
          <h3>{content?.firstSectionTitle}</h3>
          <figure>
            <ByzSketchbook />
          </figure>
        </section>
        <section className="byz-sketches">
          <h3>{content?.secondSectionTitle}</h3>
          <figure>
            <MobileByzDigitalization className="sketches" />
          </figure>
        </section>
      </div>
    </div>
  );
}
