import { Node } from "../../components/Node/Node";
import { LogosTimelineType } from "../../types/types";

const { REACT_APP_BASE_URL } = process.env;

type MobileLogoSectionType = {
  lineMobile: {
    url: string;
  };
  mobileLogos: LogosTimelineType;
};

export function MobileLogoSection(content: MobileLogoSectionType) {
  return (
    <div className="timeline-content">
      <div className="line">
        <img
          src={`${REACT_APP_BASE_URL}${content?.lineMobile?.url}`}
          alt=""
          id="line-logo"
        />
        <Node />
        <Node />
        <Node />
        <Node />
        <Node />
      </div>
      <div className="steps">
        <div id="first-logo" className="step logo-step">
          <img
            src={`${REACT_APP_BASE_URL}${content?.mobileLogos?.firstLogo?.url}`}
            alt=""
          />
          <span>2007-2009</span>
        </div>
        <div id="second-logo" className="step logo-step">
          <img
            src={`${REACT_APP_BASE_URL}${content?.mobileLogos?.secondLogo?.url}`}
            alt=""
          />
          <span>2009-2015</span>
        </div>
        <div id="third-logo" className="step logo-step">
          <img
            src={`${REACT_APP_BASE_URL}${content?.mobileLogos?.thirdLogo?.url}`}
            alt=""
          />
          <span>2015-2019</span>
        </div>
        <div id="fourth-logo" className="step logo-step">
          <img
            src={`${REACT_APP_BASE_URL}${content?.mobileLogos?.fourthLogo?.url}`}
            alt=""
          />
          <span>2019-2021</span>
        </div>
        <div id="fifth-logo" className="step logo-step">
          <img
            src={`${REACT_APP_BASE_URL}${content?.mobileLogos?.fifthLogo?.url}`}
            alt=""
          />
          <span>2021-present</span>
        </div>
      </div>
    </div>
  );
}
