import "./AboutUs.css";
import React, { useEffect, useLayoutEffect } from "react";
import gsap from "gsap";
import { MotionPathPlugin, ScrollTrigger, CustomEase } from "gsap/all";
import parse from "html-react-parser";

import { AboutUsLine } from "../../animatedSVG/AboutUsLine/AboutUsLine";
import { AboutUsArrow } from "../../animatedSVG/AboutUsArrow/AboutUsArrow";
import { MobileLogoSection } from "../../animatedSVG/MobileLogoSection/MobileLogoSection";
import { MobileTimelineSection } from "../../animatedSVG/MobileTimelineSection/MobileTimelineSection";

import axiosInstance from "../../api/interceptor";
import {
  BossContentType,
  LogosTimelineType,
  TitleDescriptionType,
} from "../../types/types";
import { CustomSpinner } from "../../components/CustomSpinner/CustomSpinner";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger, CustomEase);

const { REACT_APP_BASE_URL } = process.env;

type AboutUsType = {
  mainSectionImage: {
    url: string;
  };
  mainSectionText: TitleDescriptionType;
  foundersSection: {
    description: string;
    maurizioContent?: BossContentType;
    paoloContent?: BossContentType;
    marcoContent?: BossContentType;
  };
  linkedinIcon: { url: string };
  lineMobile: { url: string };
  mobileLogos: LogosTimelineType;
};

const AboutUs = () => {
  const [aboutUs, setAboutUs] = React.useState<AboutUsType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("about-us")
      .then((res) => {
        setAboutUs(res.data);
        window.scrollTo(0, 0);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const mobileTimeLineTitle = (title: string, subtitle: string) => {
    return (
      <div className="timeline-title">
        <div>{title}</div>
        <hr />
        <div>{subtitle}</div>
      </div>
    );
  };

  const MobileContent = () => {
    useLayoutEffect(() => {
      gsap.utils.toArray(".circle").forEach((element: any) => {
        gsap.to(element, {
          boxShadow: "0px 0px 0px 2px #091f3a, 0px 0px 0px 4px white",
          ease: "none",
          delay: 0.8,
          scrollTrigger: {
            trigger: element,
            start: "center 80%",
            end: "center 50%",
            toggleActions: "play none none reverse",
          },
        });
      });

      gsap.utils.toArray(".step").forEach((element: any) => {
        gsap.fromTo(
          element,
          {
            opacity: 0,
            y: 40,
          },
          {
            y: 0,
            opacity: 1,
            ease: "none",
            scrollTrigger: {
              trigger: element,
              start: "end 90%",
              end: "end 90%",
              toggleActions: "play none none reverse",
            },
          }
        );
      });
    }, []);

    return (
      <>
        <div className="content-container">
          <div className="title-content">
            <h1>{aboutUs?.mainSectionText?.title?.toUpperCase()}</h1>
          </div>
        </div>
        <div className="description-container">
          {parse((aboutUs?.mainSectionText.description as string) ?? "")}
          {parse((aboutUs?.foundersSection.description as string) ?? "")}
        </div>
        <div className="icon-founders-container">
          <div className="PP">
            <div className="founder-circle-container">
              <img
                src={`${REACT_APP_BASE_URL}${aboutUs?.foundersSection.paoloContent?.icon?.url}`}
                alt=""
                className="founder-icon"
              />
              <div className="founder-circle PP" />
            </div>
            <div className="bold"> PAOLO PENNA </div>
            <div> FONDATORE & CFO</div>
            <div>
              <a
                href={aboutUs?.foundersSection.paoloContent?.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${REACT_APP_BASE_URL}${aboutUs?.linkedinIcon?.url}`}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="MS">
            <div className="founder-circle-container">
              <img
                src={`${REACT_APP_BASE_URL}${aboutUs?.foundersSection.maurizioContent?.icon?.url}`}
                alt=""
                className="founder-icon"
              />
              <div className="founder-circle MS" />
            </div>
            <div className="bold"> MAURIZIO SACCO </div>
            <div> FONDATORE & CCO </div>
            <div>
              <a
                href={aboutUs?.foundersSection.maurizioContent?.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${REACT_APP_BASE_URL}${aboutUs?.linkedinIcon?.url}`}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="MP">
            <div className="founder-circle-container">
              <img
                src={`${REACT_APP_BASE_URL}${aboutUs?.foundersSection.marcoContent?.icon?.url}`}
                alt=""
                className="founder-icon"
              />
              <div className="founder-circle MP" />
            </div>
            <div className="bold"> MICHELE COLELLA </div>
            <div> CTO </div>
            <div>
              <a
                href={aboutUs?.foundersSection.marcoContent?.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${REACT_APP_BASE_URL}${aboutUs?.linkedinIcon?.url}`}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="timeline-container">
          {mobileTimeLineTitle(
            "Evoluzione del logo",
            "Il look di KeyBiz dalla fondazione ad oggi"
          )}
          <MobileLogoSection
            lineMobile={aboutUs?.lineMobile as { url: string }}
            mobileLogos={aboutUs?.mobileLogos as LogosTimelineType}
          />
          {mobileTimeLineTitle("Timeline", "I nostri traguardi")}
          <MobileTimelineSection
            lineMobile={aboutUs?.lineMobile as { url: string }}
          />
        </div>
      </>
    );
  };

  const DesktopContent = () => {
    useLayoutEffect(() => {
      const bossesCirclesTimeline = gsap
        .timeline({
          defaults: {
            scale: 10,
            opacity: 0,
            //autoAlpha: 0,
            duration: 0.5,
            transformOrigin: "center",
            ease: "elastic(1, 1)",
          },
        })
        .to("#circle-maurizio", {}, 1.63)
        .to("#circle-paolo", {}, 2.13)
        .to("#circle-michele", {}, 2.72);

      const bossesTimeline = gsap
        .timeline({
          defaults: {
            autoAlpha: 1,
            duration: 0.5,
            transformOrigin: "center",
            ease: "elastic(1, 1)",
          },
        })
        .to("#arrow", {}, 0)
        .to(
          "#maurizio-sacco",
          {
            scale: 3.5,
            transformOrigin: "center",
          },
          1.61
        )
        .to(".maurizio-text", {}, 1.63)
        .to(
          "#paolo-penna",
          {
            scale: 3.5,
            transformOrigin: "center",
          },
          2.13
        )
        .to(".paolo-text", {}, 2.15)
        .to(
          "#michele-colella",
          {
            scale: 3.5,
            transformOrigin: "center",
          },
          2.72
        )
        .to(".michele-text", {}, 2.74);

      const circlesTimeline = gsap
        .timeline({
          defaults: {
            autoAlpha: 1,
            duration: 0.5,
            transformOrigin: "center",
            ease: "elastic(1, 1)",
          },
        })
        .to("#logo2007", {}, 4.82)
        .to(".logo2007-text", {}, 4.82)
        .to("#logo2007-active", { duration: 1, scale: 2 }, 4.77)
        .to("#logo2009", {}, 5.07)
        .to(".logo2009-text", {}, 5.07)
        .to("#logo2009-active", { duration: 1, scale: 2 }, 5.02)
        .to("#logo2015", {}, 5.33)
        .to(".logo2015-text", {}, 5.33)
        .to("#logo2015-active", { duration: 1, scale: 2 }, 5.28)
        .to("#logo2019", {}, 6.0)
        .to(".logo2019-text", {}, 6.0)
        .to("#logo2019-active", { duration: 1, scale: 2 }, 5.95)
        .to("#logo2021", {}, 6.35)
        .to(".logo2021-text", {}, 6.35)
        .to("#logo2021-active", { duration: 1, scale: 2 }, 6.31)

        .to("#foundation-active", { duration: 1, scale: 2 }, 7.4)
        .to(".foundation-text", {}, 7.45)
        .to("#innovation-active", { duration: 1, scale: 2 }, 8.01)
        .to(".innovation-text", {}, 8.06)
        .to("#metamorphosis-active", { duration: 1, scale: 2 }, 8.29)
        .to(".metamorphosis-text", {}, 8.34)
        .to("#cyber-active", { duration: 1, scale: 2 }, 8.57)
        .to(".cyber-text", {}, 8.62)
        .to("#employers-active", { duration: 1, scale: 2 }, 9.09)
        .to(".employers-text", {}, 9.14)
        .to("#engineering-active", { duration: 1, scale: 2 }, 9.35)
        .to(".engineering-text", {}, 9.4)
        .to("#byz-active", { duration: 1.8, scale: 2 }, 9.64)
        .to(".byz-text", {}, 9.69);

      const tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".aboutus-line",
            start: "top top",
            end: "bottom 50%",
            scrub: 1,
          },
        })
        .to("#arrow", {
          duration: 10,
          lazy: false,
          ease: "power0",
          motionPath: {
            path: "#aboutPath",
            autoRotate: true,
            align: "#aboutPath",
            alignOrigin: [0.5, 0.5],
          },
        })
        .add(bossesCirclesTimeline, 0)
        .add(bossesTimeline, 0)
        .add(circlesTimeline, 0);

      return () => {
        bossesTimeline.kill();
        tl.kill();
      };
    }, []);
    return (
      <>
        <div className="about-us__section">
          <div className="section-content">
            <img
              src={`${REACT_APP_BASE_URL}${aboutUs?.mainSectionImage?.url}`}
              alt="backgroundimage"
            />
            <div className="section-text-container fade-once">
              <div className="title-container">
                {aboutUs?.mainSectionText?.title}
              </div>
              <div className="description-container">
                {parse((aboutUs?.mainSectionText?.description as string) ?? "")}
              </div>
            </div>
          </div>
          <AboutUsLine {...(aboutUs?.linkedinIcon as { url: string })} />
          <AboutUsArrow />
        </div>
        <div className="timeline-section">
          {/* <div className={`section-content`}>
            {parse((aboutUs?.foundersSection.description as string) ?? "")}
          </div> */}
        </div>
        <div className="third-section"></div>
        <div className="fourth-section"></div>
      </>
    );
  };

  return (
    <>
      {isLoading && <CustomSpinner />}
      <div className="about-us">
        <DesktopContent />
      </div>
      <div className="about-us-mobile">
        <MobileContent />
      </div>
    </>
  );
};

export default AboutUs;
