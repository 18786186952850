import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

type ByzDigitalizationProps = {
  className: string;
};

const arrowVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 1,
    },
  },
};

const pathVariants1 = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.8,
      ease: "easeInOut",
    },
  },
};

const pathVariants2 = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.5,
      delay: 1.8,
      ease: "easeInOut",
    },
  },
};

export function ByzDigitalization({ className }: ByzDigitalizationProps) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  return (
    <div className={className} ref={ref}>
      <Byz1 />
      <motion.svg
        variants={arrowVariants}
        initial="hidden"
        animate={controls}
        width="100"
        height="22"
        viewBox="0 0 100 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
      >
        <motion.path
          variants={pathVariants1}
          d="M86 1L98 7L94 20.5"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <motion.path
          variants={pathVariants1}
          d="M1 12.5C22 19.3333 66.4 20.2 98 7"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </motion.svg>
      <Byz2 />
      <motion.svg
        animate={controls}
        fill="none"
        height="22"
        initial="hidden"
        ref={ref}
        variants={arrowVariants}
        viewBox="0 0 100 22"
        width="100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M86 20.5L98 14.5L94 1"
          strokeLinecap="round"
          stroke="white"
          strokeWidth="4"
          variants={pathVariants2}
        />
        <motion.path
          d="M1 9C22 2.16667 66.4 1.3 98 14.5"
          strokeLinecap="round"
          stroke="white"
          strokeWidth="4"
          variants={pathVariants2}
        />
      </motion.svg>
      <Byz3 />
    </div>
  );
}

export function Byz1() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 0.2,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
      width="222"
      height="368"
      viewBox="0 0 222 368"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M211.702 71.7307C228.056 112.18 209.052 154.69 197.505 170.888C204.806 175.348 206.023 182.362 206.023 190.551C206.023 206.536 187.851 215.832 179.333 217.236C174.507 225.102 162.297 227.911 158.322 230.72C155.767 235.495 154.347 234.933 151.508 237.18C154.915 241.113 161.446 279.877 157.47 297.855C154.29 312.237 154.442 333.622 154.915 342.518C156.145 343.173 159.174 345.945 161.446 351.787C163.717 357.63 160.499 361.338 158.606 362.462C150.939 364.443 143.645 364.443 130.213 362.462C109.417 359.394 116.868 305.439 116.868 296.45C101.535 297.293 94.5474 332.954 94.5474 342.518C94.5474 362.462 86.2963 367.81 80.239 367.81C73.5192 367.997 58.6612 364.249 58.6612 351.787C58.6612 313.021 62.4921 290.566 65.0475 282.42C54.8259 279.611 46.1814 265.037 44.1939 261.105C37.6754 248.207 43.2435 221.977 47.4251 217.236C39.7679 209.568 57.3669 193.922 51.6882 190.551C34.6143 182.897 23.6686 166.95 20.33 159.933C-18.0579 104.427 6.60655 58.7154 23.7373 42.7977C23.3587 42.1423 22.1472 40.1011 20.33 37.1797C27.1445 25.9437 52.32 9.08974 64.056 2.06725C65.1917 3.19085 67.9743 5.9999 70.0186 8.24733C121.581 -11.7527 160.972 9.27729 174.223 22.2923C174.885 21.5432 177.459 18.8092 182.457 13.8653C193.587 23.9777 213.216 49.7269 221.64 61.3374C219.557 63.21 214.655 67.9104 211.702 71.7307Z"
        fill="white"
      />
      <path
        d="M179.22 202.673C177.039 216.357 162.423 224.026 155.388 226.149C157.526 219.922 154.994 213.6 152.202 208.851C156.505 209.73 168.302 200.808 173.662 196.237C174.532 196.711 176.863 198.663 179.22 202.673Z"
        fill="white"
        stroke="black"
        strokeWidth="2.0404"
      />
      <circle
        cx="142.179"
        cy="219.968"
        r="14.2828"
        transform="rotate(-173.655 142.179 219.968)"
        fill="#021E34"
        stroke="black"
        strokeWidth="2.0404"
      />
      <path
        d="M162.815 190.969C162.211 194.059 163.637 199.44 164.271 201.177C165.218 203.032 167.583 207.052 169.464 208.295C171.816 209.848 175.814 213.025 182.321 211.252C188.828 209.48 195.147 202.151 198.99 196.35C202.833 190.549 198.719 182.528 192.415 179.735C187.371 177.501 181.874 183.216 179.756 186.353C178.613 187.762 177.096 189.897 173.769 191.486C171.969 192.346 170.889 190.095 170.691 188.483C170.341 185.641 170.101 184.094 169.201 183.014C167.614 180.508 164.941 182.168 164.536 183.334C163.96 186.215 163.419 187.879 162.815 190.969Z"
        fill="#021E34"
        stroke="black"
        strokeWidth="2.04245"
        strokeLinecap="round"
      />
      <path
        d="M152.823 302.095C158.699 251.412 140.274 210.413 132.01 198.749C118.38 201.198 97.7655 198.341 89.1618 196.607C87.7675 207.353 83.4895 233.681 77.5316 253.024C70.0841 277.202 62.4326 339.842 63.5549 351.983C64.4526 361.695 74.0629 363.103 78.7558 362.593C81.2383 362.593 86.6317 361.756 88.3457 358.41C90.4881 354.227 89.1618 352.595 89.1618 342.291C89.978 318.214 105.077 296.79 108.852 293.423C111.871 290.73 120.38 290.669 124.257 290.975C124.189 298.15 124.093 317.072 124.257 335.354C124.42 353.635 132.01 358.002 135.785 357.9C140.308 358.58 150.558 359.165 155.373 356.064C160.188 352.962 153.979 347.358 150.272 344.943C149.932 339.162 149.966 322.499 152.823 302.095Z"
        fill="white"
        stroke="black"
        strokeWidth="2.04039"
      />
      <path
        d="M10.5617 104.152C10.5617 157.678 54.9982 201.111 109.864 201.111C164.731 201.111 209.167 157.678 209.167 104.152C209.167 50.6255 164.731 7.19293 109.864 7.19293C54.9982 7.19293 10.5617 50.6255 10.5617 104.152Z"
        fill="white"
        stroke="black"
        strokeWidth="2.04039"
      />
      <circle
        r="17.8021"
        transform="matrix(-1 0 0 1 72.0083 208.277)"
        fill="#021E34"
        stroke="black"
        strokeWidth="2.00024"
      />
      <path
        d="M78.0847 215.411C82.3112 220.111 82.9765 236.037 82.7809 243.413C63.4098 248.113 67.5181 266.911 67.5181 277.485C39.3415 257.747 49.125 230.098 57.5389 218.74C62.6265 215.672 73.8583 210.712 78.0847 215.411Z"
        fill="white"
        stroke="black"
        strokeWidth="2.00024"
      />
      <path
        d="M66.773 256.031C69.9497 240.838 86.3304 242.017 94.1237 244.506C104.089 247.724 102.932 257.068 100.344 260.108C102.257 261.817 101.727 266.765 98.2319 268.599C101.554 271.765 99.4401 275.231 96.8254 278.446C98.5894 284.979 92.4244 289.122 87.7941 288.82C83.1638 288.517 75.7651 287.461 70.6555 282.963C65.1675 274.822 62.8021 275.022 66.773 256.031Z"
        fill="#021E34"
      />
      <path
        d="M96.8254 278.446C98.5894 284.979 92.4244 289.122 87.7941 288.82C83.1638 288.517 75.7651 287.461 70.6555 282.963C65.1675 274.822 62.8021 275.022 66.773 256.031C69.9497 240.838 86.3304 242.017 94.1237 244.506C104.089 247.724 102.932 257.068 100.344 260.108M96.8254 278.446C99.4401 275.231 101.554 271.765 98.2319 268.599M96.8254 278.446C94.1319 278.421 88.0704 278.057 85.3724 276.811M98.2319 268.599C101.727 266.765 102.257 261.817 100.344 260.108M98.2319 268.599C95.3288 268.782 88.7726 268.863 85.7726 267.716M100.344 260.108C96.8955 259.915 89.4243 259.266 87.129 258.221"
        stroke="black"
        strokeWidth="2.00024"
      />
      <path
        d="M137.681 70.0011C163.664 52.7634 170.474 40.3128 181.804 26.5401C193.833 40.197 196.032 43.2805 208.035 58.0642C198.497 72.8735 175.165 104.389 140.236 127.691C89.7127 109.583 41.2966 55.2068 29.6812 38.3738C41.6617 26.6654 57.8519 13.6727 65.183 9.50195C84.1985 28.0695 109.016 57.6483 137.681 70.0011Z"
        fill="#021E34"
        stroke="black"
        strokeWidth="2.04039"
      />
      <path
        d="M17.6117 137.313C9.91522 118.273 10.2176 99.4124 12.8014 87.6771C54.2572 110.546 87.761 121.186 116.985 123.968C125.678 128.974 136.5 133.342 140.685 134.782L157.452 122.982C183.794 119.129 199.688 109.351 208.238 99.8914C209.267 108.563 207.111 127.523 199.277 143.929C156.469 182.19 55.0141 175.69 17.6117 137.313Z"
        fill="#C4C4C4"
      />
      <path
        d="M64.1904 335.557C71.2909 339.638 83.8796 338.958 89.3887 338.107V351.472C89.8308 353.887 89.0831 358.205 86.6347 360.756C82.758 364.021 77.2489 363.918 71.7393 362.286C60.8232 358.205 63.3742 347.391 64.1904 335.557Z"
        fill="#021E34"
        stroke="black"
        strokeWidth="2.04039"
      />
      <path
        d="M124.382 334.231C134.42 336.68 146.01 334.605 150.294 333.211V344.739C150.771 345.283 152.192 346.657 154.069 347.8C158.558 351.269 158.864 355.043 155.497 356.472C148.458 359.94 139.888 358.512 133.155 357.39C123.157 353.819 124.382 343.413 124.382 334.231Z"
        fill="#021E34"
        stroke="black"
        strokeWidth="2.04039"
      />
    </motion.svg>
  );
}

export function Byz2() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 1.4,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
      width="232"
      height="354"
      viewBox="0 0 232 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M150.692 227.487C151.26 228.396 152.317 228.402 152.774 228.292H155.661C158.58 227.44 164.636 225.584 165.507 224.978C166.377 224.372 166.374 223.369 166.264 222.943C166.106 221.791 165.734 219.09 165.507 217.5C165.279 215.909 163.96 215.196 163.329 215.038C161.578 214.881 157.593 214.764 155.661 215.559C153.247 216.553 148.609 219.346 148.041 220.245C147.587 220.964 147.852 221.775 148.041 222.091C148.688 223.511 150.124 226.578 150.692 227.487Z"
        fill="#C4C4C4"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M140.47 195.8C136.569 197.526 132.532 202.45 131.19 205.323L138.338 215.748L141.793 217.168C145.187 216.238 153.988 216.882 155.17 216.488C156.648 215.995 158.619 213.532 160.097 211.808C161.28 210.429 161.576 206.91 161.576 205.323C161.63 203.681 160.623 199.478 156.156 195.8C150.571 191.203 145.48 193.583 140.47 195.8Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M161.675 208.698L156.469 207.136C154.102 206.662 151.877 207.325 150.457 209.597C149.042 211.863 145.346 215.514 144.967 215.845C144.588 216.176 143.831 216.224 143.452 216.224C143.149 216.337 143.105 216.713 143.121 216.886L146.671 221.146C146.908 221.32 147.58 221.648 148.375 221.572C149.17 221.496 149.684 220.815 149.842 220.484C150 220.136 150.789 219.13 152.682 217.88C156.166 215.684 161.012 215.671 163 215.94C163.49 216.255 164.534 216.896 164.799 216.934C165.13 216.981 165.32 216.839 165.414 216.744C165.717 216.328 165.541 215.151 165.414 214.614L161.675 208.698Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M152.206 230.853L155.141 235.066C155.283 235.303 155.693 235.287 155.756 235.255C155.851 235.255 156.287 235.198 158.028 234.593C159.77 233.987 163.235 234.498 164.749 234.829C165.254 235.034 166.387 235.445 166.879 235.445C167.372 235.445 167.653 234.94 167.731 234.687C167.873 233.11 168.195 229.793 168.347 229.149C168.536 228.345 168.157 226.735 168.157 226.451C168.157 226.167 167.163 223.706 167.021 223.233C166.908 222.854 166.627 222.539 166.501 222.428C166.264 222.444 165.743 222.466 165.554 222.428C165.317 222.381 165.128 222.618 165.128 222.807C164.825 223.867 164.087 224.732 163.756 225.032C161.594 225.836 157.148 227.483 156.656 227.635C156.04 227.824 155.52 227.682 155.141 227.635C154.838 227.597 154.005 227.209 153.626 227.019C153.279 227.004 152.509 227.019 152.206 227.209C151.828 227.445 151.354 227.966 151.354 228.865C151.354 229.585 151.922 230.49 152.206 230.853Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M151.307 256.833C151.799 244.526 141.636 209.611 136.492 193.692C131.049 182.9 94.1299 183.657 88.3553 191.514C82.5808 199.371 74.061 245.047 73.067 250.064C72.2718 254.078 72.7357 257.227 73.067 258.3C78.4629 259.657 90.6083 263.147 96.0231 266.252C102.792 270.133 104.78 281.493 107.099 285.279C113.877 287.513 118.695 286.21 120.257 285.279C124.88 280.404 134.466 270.427 135.829 269.518C137.193 268.609 147.111 272.547 150.929 271.837C151.465 270.322 150.739 260.733 151.307 256.833Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M206.173 74.9474C180.008 2.39663 115.501 -2.92981 86.5176 3.47581C65.1993 6.84582 45.2282 20.1525 37.9075 26.3846C17.5073 46.1695 -12.8232 101.34 29.0563 163.743C85.8928 217.928 146.835 199.415 170.201 183.385C187.02 172.483 217.76 135.533 206.173 74.9474Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M35.8736 101.949C38.2773 100.914 169.671 102.104 193.432 100.4H195.988C195.988 100.4 197.904 100.692 198.544 101.11C199.775 101.915 200.011 103.334 200.011 103.334C200.153 104.707 200.047 105.796 200.011 106.884C199.511 122.057 186.38 144.655 181.504 148.253C176.866 151.675 170.571 153.379 165.837 154.785C160.3 156.429 143.591 159.802 119.831 161.175C84.6757 163.205 63.04 156.816 54.0957 153.622C46.9402 151.066 38.744 130.134 35.5403 119.988C34.1514 116.512 32.4848 109.55 32.0404 107.821C31.5959 106.093 32.2255 104.94 32.5959 104.58C32.5959 104.58 33.47 102.985 35.8736 101.949Z"
        fill="#C4C4C4"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M68.0966 14.8234C99.5251 45.9869 126.031 61.2878 135.356 65.0428C149.319 57.7537 175.13 34.4031 186.19 19.0833C194.3 18.2629 214.041 15.628 230.588 10.5162C206.354 74.5566 158.643 121.652 135.356 129.841C99.5725 124.681 29.6313 49.5021 1.40558 7.81824C15.1004 9.53797 47.6112 13.3466 68.0966 14.8234Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M151.069 269.944C150.955 268.656 149.728 266.946 149.128 266.252L133.414 269.944C129.17 274.551 120.587 284.058 120.208 285.232C119.83 286.406 120.366 293.042 120.682 296.213V299.1V306.484C120.682 307.052 120.612 307.762 120.492 308.661C120.208 310.791 120.051 319.611 120.161 324.044C120.177 324.344 120.218 325.019 120.256 325.322C120.294 325.625 120.555 325.859 120.682 325.938C122.528 327.105 126.456 329.516 127.403 329.819C136.538 331.097 142.691 329.251 143.306 329.204C143.799 329.166 144.3 328.904 144.49 328.778L147.756 325.417C147.693 324.991 147.604 323.902 147.756 322.956C148.04 321.63 148.324 313.868 148.466 312.069C148.608 310.271 150.312 290.723 150.312 290.297C151.968 277.375 151.211 271.553 151.069 269.944Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M120.61 334.691L122.125 343.684C122.267 344.527 122.362 344.804 122.551 345.057C123.545 346.382 126.906 347.802 128.657 348.323C135.189 349.979 158.003 348.323 158.429 348.323C158.855 348.323 159.091 347.944 159.091 347.565C159.205 343.4 158.161 340.813 157.624 340.04L147.211 332.75C138.849 333.129 121.964 333.792 121.32 333.413C120.904 333.83 120.673 334.439 120.61 334.691Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M157.624 339.992C155.012 336.471 149.815 333.697 147.543 332.75C143.472 335.78 136.254 334.644 134.881 334.833C134.995 335.287 134.502 337.436 134.881 338.004C135.165 338.209 135.686 339.992 140.656 341.744C143.458 343.561 151.739 341.475 155.116 341.744C157.151 340.939 157.435 340.56 157.624 339.992Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M120.374 323.758C119.616 325.499 120.689 331.078 121.32 333.65C122.788 335.212 123.64 335.354 127.805 335.969C131.97 336.584 138.076 336.158 141.626 335.638C145.176 335.117 147.022 333.035 147.732 332.561C148.641 330.024 148.11 325.13 147.732 323C146.548 324.01 143.974 326.124 143.141 326.503C142.308 326.881 134.305 327.134 130.408 327.213C130.061 327.244 129.055 327.288 127.805 327.213C126.555 327.137 123.088 324.72 121.51 323.521C120.752 323.284 120.705 323.331 120.374 323.758Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M99.0986 267.009C101.598 268.448 105.22 279.189 106.719 284.38C105.867 286.1 103.15 290.836 99.0986 296.024C94.318 301.325 88.8275 327.121 88.2595 330.813C87.5779 332.555 84.536 333.306 83.1003 333.463C79.0613 333.527 70.3679 333.293 67.9067 331.854C65.4454 330.415 63.347 327.626 62.6055 326.411C63.4417 318.885 65.4643 301.211 66.8654 290.722C68.2664 280.234 71.0464 264.642 72.2612 258.158C72.4821 257.906 73.1227 257.401 73.9179 257.401C86.6029 259.909 95.9746 265.21 99.0986 267.009Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M58.7924 339.566C58.8303 337.9 59.0583 337.673 59.171 337.389C59.5317 336.48 60.9148 335.259 61.4903 334.738C70.0259 334.549 87.5514 334.662 89.3689 336.631C91.1865 338.601 91.0098 345.53 90.6943 348.749C90.6627 348.954 90.5144 349.43 90.1736 349.695C71.5721 355.943 62.2476 350.026 61.1117 349.695C59.6728 349.317 58.9659 347.613 58.7924 346.808C58.7766 345.088 58.7545 341.232 58.7924 339.566Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M62.5584 338.904C62.2325 337.522 62.6265 336.474 63.1945 335.59H87.5706C89.1988 335.59 88.817 337.799 88.4226 338.904C87.2235 341.112 83.8503 345.719 79.9501 346.477C75.0783 347.423 70.6319 345.864 66.9887 344.586L66.9811 344.583C63.3366 343.305 63.1945 341.602 62.5584 338.904Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M60.8285 325.982C59.7682 329.996 60.7023 333.681 61.3018 335.022L61.7751 335.922C62.0276 336.095 61.8868 336.215 62.7956 336.442C64.2723 335.799 66.8397 336.458 67.5023 336.726C68.0861 337.594 69.7932 339.415 71.9516 339.756C74.1099 340.096 78.373 339.614 80.2347 339.33C80.5976 339.03 81.6641 338.25 83.0273 337.531C85.3371 336.471 88.2918 336.616 88.7809 337.058C89.046 336.944 89.1489 336.805 89.2751 336.726C89.3382 336.632 89.4929 336.367 89.6065 336.064C90.591 333.148 89.6696 328.822 89.0858 327.023C87.8078 327.875 84.7691 329.778 82.838 330.573C74.6968 331.567 68.733 329.485 67.7863 329.106C67.029 328.803 63.9682 324.909 62.5325 323C62.1222 323.442 61.2071 324.657 60.8285 325.982Z"
        fill="#344459"
      />
      <path
        d="M88.7809 337.058C89.046 336.944 89.1489 336.805 89.2751 336.726C89.3382 336.632 89.4929 336.367 89.6065 336.064C90.591 333.148 89.6696 328.822 89.0858 327.023C87.8078 327.875 84.7691 329.778 82.838 330.573C74.6968 331.567 68.733 329.485 67.7863 329.106C67.029 328.803 63.9682 324.909 62.5325 323C62.1222 323.442 61.2071 324.657 60.8285 325.982C59.7682 329.996 60.7023 333.681 61.3018 335.022L61.7751 335.922C62.0276 336.095 61.8868 336.215 62.7956 336.442C64.2723 335.799 66.8397 336.458 67.5023 336.726C68.0861 337.594 69.7932 339.415 71.9516 339.756C74.1099 340.096 78.373 339.614 80.2347 339.33C80.5976 339.03 81.6641 338.25 83.0273 337.531C85.3371 336.471 88.2918 336.616 88.7809 337.058ZM88.7809 337.058C88.5159 337.171 89.0649 336.963 88.7809 337.058Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M87.1503 215.691L82.2831 219.194C71.3998 225.062 60.9179 226.245 56.4629 226.103C55.0533 225.078 50.7024 221.56 49.1705 215.691C47.6385 209.823 49.8088 202.74 51.0854 199.932C53.253 196.462 59.0165 188.905 64.7294 186.444C70.4423 183.983 80.3283 187.47 84.5571 189.52C86.0598 192.723 89.0652 200.292 89.0652 204.949C89.0652 209.605 87.7886 214.051 87.1503 215.691Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M51.7676 237.8L52.7142 251.526L86.0834 238.747V232.499L73.5877 225.494L51.7676 237.8Z"
        fill="#C4C4C4"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M50.7266 235.859C50.7266 237.26 51.5785 238.337 52.0045 238.699C52.3453 238.889 52.6514 238.81 52.7618 238.747C54.4974 237.595 58.2618 235.793 61.1396 233.445C64.7369 230.511 71.5054 227.339 73.5407 227.339C78.7945 227.955 85.7997 232.641 86.131 232.783C86.3582 232.707 86.7463 232.499 86.7463 232.357L86.7937 230.842C86.7779 230.7 86.7653 230.378 86.841 230.227C87.0777 229.895 87.125 228.949 87.125 228.428C86.9357 224.907 85.2475 219.577 84.4271 217.352C83.8275 216.422 82.4202 214.37 81.5871 213.613C80.7541 212.856 79.3783 212.414 78.7945 212.288C78.1792 212.146 77.7532 212.228 76.9012 212.43C73.872 213.149 61.0923 218.441 55.0811 220.997C53.6296 225.368 50.7266 234.458 50.7266 235.859Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M63.5527 268.424C60.7917 264.826 54.8721 256.752 53.2817 253.23C52.9043 252.394 52.6279 251.331 52.4297 250.15L55.4116 247.456C55.6483 247.377 56.1973 247.266 56.5003 247.456C57.0682 247.929 57.9202 247.929 58.7722 247.929C60.3815 247.834 67.8126 244.574 68.6646 243.532C69.7059 241.881 70.1792 240.314 70.4159 238.794L85.2782 235.528C85.4833 235.607 86.1018 236.067 86.9348 237.279C87.3135 238.491 87.0926 239.551 86.9348 239.93C82.2963 240.876 81.7283 239.93 70.9839 246.272C66.7713 251.715 65.7774 267.524 65.7774 269.607C64.9064 269.607 63.9314 268.818 63.5527 268.424Z"
        fill="white"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M62.762 271.311L65.7912 275.145C66.1321 276.357 68.8047 278.9 70.0984 280.02C71.6604 281.409 74.86 284.252 75.163 284.517C75.447 284.99 76.0623 284.99 76.2989 284.99C76.6019 285.331 77.0563 285.574 77.2456 285.653C78.4762 286.016 81.2499 286.741 82.4995 286.741C83.749 286.741 84.8503 286.584 85.2447 286.505C87.6429 286.568 92.6475 286.703 93.4805 286.741C94.5218 286.789 95.1371 286.41 95.3265 286.221C98.1285 284.024 98.7344 279.468 98.6871 277.464C98.6492 277.048 98.3557 276.849 98.2137 276.802C98.1348 276.707 97.8161 276.518 97.1724 276.518C98.2137 276.376 98.7817 276.092 99.2077 275.902C99.8467 275.618 99.965 275.145 100.06 274.956C101.006 272.589 100.817 268.566 100.817 268.14C100.817 267.714 100.675 267.666 100.438 267.477C100.76 267.339 100.859 267.201 101.081 266.889L101.101 266.862C102.142 265.205 102.142 258.768 102.142 258.058C102.142 257.348 101.527 256.875 100.912 256.733C101.707 256.695 102.127 255.628 102.142 255.265L102.278 250.627L102.414 245.988C102.414 245.231 101.906 244.616 101.574 244.237C100.533 243.148 93.2754 240.719 89.6939 239.788C87.3747 239.709 82.6888 239.551 82.4995 239.551C82.3101 239.551 82.0735 239.709 81.9788 239.788L81.1268 240.64L80.7482 240.166C80.7008 240.135 80.5494 240.072 80.3222 240.072C75.2103 241.16 70.4771 243.953 69.7671 244.284C69.1991 244.549 68.9309 244.963 68.8678 245.136C68.426 245.247 67.3816 245.572 66.7379 245.988C66.0941 246.405 65.6177 247.203 65.4599 247.55C65.2864 247.929 64.5511 250.172 62.9987 256.117C61.058 263.549 61.5313 267.098 61.6734 268.424C61.7944 269.553 62.3991 270.822 62.762 271.311Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <g opacity="0.4">
        <path
          d="M89.716 242.012C90.7005 241.671 93.471 242.391 94.7332 242.816C94.8278 242.864 95.0171 242.911 95.2065 243.101C95.4431 243.337 95.5378 243.574 95.6325 243.763C95.7082 243.915 95.7587 244.173 95.7745 244.284C95.7902 244.442 95.8407 245.06 95.9165 246.272C96.0111 247.786 95.4431 252.993 95.4431 253.135C95.4431 253.249 95.3485 253.372 95.3011 253.419C95.1907 253.514 94.9035 253.722 94.6385 253.798C92.4139 254.46 88.8166 254.082 88.58 254.082C88.3433 254.082 88.012 253.94 87.8227 253.798C87.6333 253.656 87.4913 253.23 87.4913 252.898C87.3493 251.62 87.8227 244.899 87.9647 243.811C88.1067 242.722 88.4853 242.438 89.716 242.012Z"
          fill="#7BA1B7"
        />
        <path
          d="M92.435 265.4C93.4195 265.362 93.6183 264.926 93.7603 264.453L94.9436 258.679C94.9752 258.505 95.0194 258.101 94.9436 257.874C94.849 257.59 94.4703 256.643 93.713 256.359C93.1072 256.132 89.8318 255.76 88.2698 255.602C88.0331 255.578 87.5215 255.628 87.2758 255.839C87.1102 255.981 86.9648 256.17 86.8972 256.407C86.8025 256.738 86.6605 257.543 86.6605 257.874C86.5185 259.452 86.3008 262.825 86.5659 263.696C86.6132 264.264 86.9445 264.642 87.3705 264.784C88.3171 265.447 91.2044 265.447 92.435 265.4Z"
          fill="#7BA1B7"
        />
        <path
          d="M86.2818 274.488C86.3197 275.131 87.1496 275.466 87.4652 275.482C88.254 275.545 89.9927 275.652 90.6364 275.576C91.2801 275.5 91.662 275.261 91.7724 275.15C91.8513 275.071 92.028 274.885 92.1037 274.772C92.719 273.068 93.287 268.524 93.287 268.24C93.287 268.013 93.1608 267.798 93.0977 267.719C93.0188 267.624 92.7948 267.388 92.5297 267.198C90.5418 266.157 88.3171 266.583 87.8912 266.63C87.5504 266.668 87.2127 266.962 87.0865 267.104C86.9729 267.217 86.8498 267.561 86.8024 267.719C86.6131 269.707 86.2439 273.844 86.2818 274.488Z"
          fill="#7BA1B7"
        />
        <path
          d="M84.7199 283.859C84.6442 284.73 85.067 285.043 85.2879 285.09C85.7483 285.516 88.1277 285.8 88.8377 285.8C89.4057 285.8 89.7844 285.516 89.879 285.327C89.9737 285.137 91.441 279.789 91.441 279.789C91.6145 279.379 91.7818 278.407 91.0623 277.801C90.163 277.044 87.6544 276.854 86.8024 276.949C86.1208 277.025 85.7611 277.738 85.6665 278.085C85.3825 279.647 84.7956 282.988 84.7199 283.859Z"
          fill="#7BA1B7"
        />
        <path
          d="M89.716 242.012C90.7005 241.671 93.471 242.391 94.7332 242.816C94.8278 242.864 95.0171 242.911 95.2065 243.101C95.4431 243.337 95.5378 243.574 95.6325 243.763C95.7082 243.915 95.7587 244.173 95.7745 244.284C95.7902 244.442 95.8407 245.06 95.9165 246.272C96.0111 247.786 95.4431 252.993 95.4431 253.135C95.4431 253.249 95.3485 253.372 95.3011 253.419C95.1907 253.514 94.9035 253.722 94.6385 253.798C92.4139 254.46 88.8166 254.082 88.58 254.082C88.3433 254.082 88.012 253.94 87.8227 253.798C87.6333 253.656 87.4913 253.23 87.4913 252.898C87.3493 251.62 87.8227 244.899 87.9647 243.811C88.1067 242.722 88.4853 242.438 89.716 242.012Z"
          stroke="black"
          strokeWidth="0.959371"
        />
        <path
          d="M92.435 265.4C93.4195 265.362 93.6183 264.926 93.7603 264.453L94.9436 258.679C94.9752 258.505 95.0194 258.101 94.9436 257.874C94.849 257.59 94.4703 256.643 93.713 256.359C93.1072 256.132 89.8318 255.76 88.2698 255.602C88.0331 255.578 87.5215 255.628 87.2758 255.839C87.1102 255.981 86.9648 256.17 86.8972 256.407C86.8025 256.738 86.6605 257.543 86.6605 257.874C86.5185 259.452 86.3008 262.825 86.5659 263.696C86.6132 264.264 86.9445 264.642 87.3705 264.784C88.3171 265.447 91.2044 265.447 92.435 265.4Z"
          stroke="black"
          strokeWidth="0.959371"
        />
        <path
          d="M86.2818 274.488C86.3197 275.131 87.1496 275.466 87.4652 275.482C88.254 275.545 89.9927 275.652 90.6364 275.576C91.2801 275.5 91.662 275.261 91.7724 275.15C91.8513 275.071 92.028 274.885 92.1037 274.772C92.719 273.068 93.287 268.524 93.287 268.24C93.287 268.013 93.1608 267.798 93.0977 267.719C93.0188 267.624 92.7948 267.388 92.5297 267.198C90.5418 266.157 88.3171 266.583 87.8912 266.63C87.5504 266.668 87.2127 266.962 87.0865 267.104C86.9729 267.217 86.8498 267.561 86.8024 267.719C86.6131 269.707 86.2439 273.844 86.2818 274.488Z"
          stroke="black"
          strokeWidth="0.959371"
        />
        <path
          d="M84.7199 283.859C84.6442 284.73 85.067 285.043 85.2879 285.09C85.7483 285.516 88.1277 285.8 88.8377 285.8C89.4057 285.8 89.7844 285.516 89.879 285.327C89.9737 285.137 91.441 279.789 91.441 279.789C91.6145 279.379 91.7818 278.407 91.0623 277.801C90.163 277.044 87.6544 276.854 86.8024 276.949C86.1208 277.025 85.7611 277.738 85.6665 278.085C85.3825 279.647 84.7956 282.988 84.7199 283.859Z"
          stroke="black"
          strokeWidth="0.959371"
        />
      </g>
      <path
        d="M99.668 255.129C100.501 255.167 100.993 254.798 101.135 254.608C101.751 254.608 101.751 247.461 101.751 246.609C101.751 245.757 101.467 245.473 100.52 244.668C99.5733 243.864 97.254 243.58 97.0647 243.58C96.9132 243.58 96.7807 243.706 96.7334 243.769C96.6703 244.053 96.5819 244.829 96.7334 245.662C97.0647 247.272 96.1654 252.904 96.1654 253.52C96.1654 254.012 96.5441 254.45 96.7334 254.608C97.3645 254.766 98.8349 255.091 99.668 255.129Z"
        fill="#50697F"
      />
      <path
        d="M96.9227 257.07C96.4683 257.07 96.2285 257.701 96.1654 258.016C95.6921 260.051 94.7454 264.217 94.7454 264.595C94.7454 264.974 94.8401 265.29 94.8874 265.4C94.919 265.574 95.0673 265.968 95.4081 266.157C96.5819 266.915 98.9264 266.851 99.952 266.725C100.757 266.394 100.709 266.11 100.993 265.069C101.306 263.923 101.561 260.146 101.561 258.868C101.561 257.598 100.112 257.356 99.5364 257.261L99.526 257.259C98.958 257.164 97.4907 257.07 96.9227 257.07Z"
        fill="#50697F"
      />
      <path
        d="M93.4201 274.535C93.4201 274.952 93.8935 275.371 94.1301 275.529C94.8401 275.955 96.2601 275.908 96.9227 275.86C97.5854 275.813 98.6267 275.387 99.2893 274.724C99.8194 274.194 99.952 271.664 99.952 270.465C99.952 269.802 99.8952 268.382 99.668 268.003C99.384 267.53 95.7394 267.293 95.4081 267.293C95.143 267.293 94.6981 267.767 94.5088 268.003C94.1459 270.007 93.4201 274.119 93.4201 274.535Z"
        fill="#50697F"
      />
      <path
        d="M93.3255 277.612C93.2497 277.65 93.1677 277.848 93.1361 277.943C92.3315 283.102 90.7695 284.806 90.7695 285.185C90.7695 285.488 91.022 285.69 91.1482 285.753C91.6057 285.832 92.8048 285.971 93.9408 285.895C95.3608 285.8 96.4021 284.143 96.9227 283.15C97.7274 282.014 98.0587 279.221 98.1533 278.274C98.2291 277.517 97.254 277.012 96.9227 276.949C96.7965 276.917 96.4305 276.949 95.9761 277.138C95.4081 277.375 94.2721 277.47 93.9408 277.47C93.6095 277.47 93.4201 277.564 93.3255 277.612Z"
        fill="#50697F"
      />
      <path
        d="M99.668 255.129C100.501 255.167 100.993 254.798 101.135 254.608C101.751 254.608 101.751 247.461 101.751 246.609C101.751 245.757 101.467 245.473 100.52 244.668C99.5733 243.864 97.254 243.58 97.0647 243.58C96.9132 243.58 96.7807 243.706 96.7334 243.769C96.6703 244.053 96.5819 244.829 96.7334 245.662C97.0647 247.272 96.1654 252.904 96.1654 253.52C96.1654 254.012 96.5441 254.45 96.7334 254.608C97.3645 254.766 98.8349 255.091 99.668 255.129Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M96.9227 257.07C96.4683 257.07 96.2285 257.701 96.1654 258.016C95.6921 260.051 94.7454 264.217 94.7454 264.595C94.7454 264.974 94.8401 265.29 94.8874 265.4C94.919 265.574 95.0673 265.968 95.4081 266.157C96.5819 266.915 98.9264 266.851 99.952 266.725C100.757 266.394 100.709 266.11 100.993 265.069C101.306 263.923 101.561 260.146 101.561 258.868C101.561 257.598 100.112 257.356 99.5364 257.261L99.526 257.259C98.958 257.164 97.4907 257.07 96.9227 257.07Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M93.4201 274.535C93.4201 274.952 93.8935 275.371 94.1301 275.529C94.8401 275.955 96.2601 275.908 96.9227 275.86C97.5854 275.813 98.6267 275.387 99.2893 274.724C99.8194 274.194 99.952 271.664 99.952 270.465C99.952 269.802 99.8952 268.382 99.668 268.003C99.384 267.53 95.7394 267.293 95.4081 267.293C95.143 267.293 94.6981 267.767 94.5088 268.003C94.1459 270.007 93.4201 274.119 93.4201 274.535Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M93.3255 277.612C93.2497 277.65 93.1677 277.848 93.1361 277.943C92.3315 283.102 90.7695 284.806 90.7695 285.185C90.7695 285.488 91.022 285.69 91.1482 285.753C91.6057 285.832 92.8048 285.971 93.9408 285.895C95.3608 285.8 96.4021 284.143 96.9227 283.15C97.7274 282.014 98.0587 279.221 98.1533 278.274C98.2291 277.517 97.254 277.012 96.9227 276.949C96.7965 276.917 96.4305 276.949 95.9761 277.138C95.4081 277.375 94.2721 277.47 93.9408 277.47C93.6095 277.47 93.4201 277.564 93.3255 277.612Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M157.904 242.792L158.485 242.996C162.38 244.142 170.293 246.397 170.786 246.249C171.279 246.1 171.541 245.476 171.61 245.183C171.644 244.61 171.728 243.128 171.796 241.788C171.881 240.112 170.959 236.731 170.903 236.46C170.858 236.244 170.649 236.087 170.55 236.036C169.902 235.642 168.456 234.741 167.852 234.289C167.097 233.724 162.774 232.461 161.789 232.267C161.001 232.112 159.787 232.018 159.279 231.99C159.217 232.119 159.102 232.409 159.136 232.542C159.412 234.845 158.208 239.154 157.572 241.021L157.529 241.859L157.904 242.792Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M152.602 235.884C154.341 233.049 157.905 232.191 159.47 232.116C159.963 234.737 158.898 238.295 158.303 239.746L156.753 239.878C156.511 239.879 155.97 239.863 155.748 239.785C155.976 240.321 156.146 242.5 156.203 243.523C156.152 243.868 155.872 244.527 155.168 244.405C154.464 244.283 154.168 244.003 154.108 243.879C154.022 243.779 153.78 243.453 153.503 242.941C152.281 240.978 152.102 238.25 152.166 237.131C152.142 236.604 152.393 236.225 152.602 235.884Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M163.839 250.449C163.481 250.406 162.858 250.14 162.592 250.012C162.936 249.386 166.262 247.874 167.882 247.197C168.031 246.869 168.373 246.03 168.545 245.302C168.76 244.392 171.673 245.256 171.16 246.425C170.75 247.36 170.011 248.028 169.693 248.245C169.563 248.321 169.203 248.52 168.804 248.712C167.952 249.368 166.1 250.065 165.281 250.331C164.95 250.388 164.198 250.491 163.839 250.449Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M161.894 250.079C161.436 250.084 160.856 249.785 160.647 249.642C161.7 248.213 164.096 245.27 165.069 244.863C166.041 244.457 167.767 245.254 168.508 245.704C168.449 246.219 168.267 247.341 168.014 247.709C167.697 248.169 166.609 248.462 165.424 249.033C163.474 250.01 162.467 250.072 161.894 250.079Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M157.795 248.903C157.364 248.711 157.321 248.478 157.353 248.386C158.4 247.075 160.783 244.338 161.941 243.872C163.1 243.407 164.509 244.339 165.069 244.863C165.041 245.389 164.87 246.588 164.415 247.176C163.847 247.91 160.913 249.476 160.664 249.596C160.464 249.692 160.085 249.67 159.92 249.647C159.391 249.479 158.225 249.095 157.795 248.903Z"
        fill="#344459"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M161.664 239.626C161.185 239.334 157.565 239.384 155.815 239.446C155.641 240.298 155.973 242.305 156.16 243.202C155.459 243.155 154.405 243.377 154.221 243.555C153.985 243.784 153.805 244.447 153.817 244.71C153.807 246.159 154.507 247.182 154.858 247.512C155.464 247.759 156.801 248.275 157.307 248.37C157.813 248.464 158.106 248.407 158.189 248.367C158.911 248.084 160.54 247.251 161.287 246.185C162.22 244.852 162.262 239.991 161.664 239.626Z"
        fill="#344459"
      />
      <path
        d="M156.64 243.313C156.525 243.25 156.356 243.216 156.16 243.202M156.64 243.313C155.948 243.984 155.671 244.945 155.618 245.341M156.64 243.313C156.724 243.221 157.004 242.984 157.453 242.768M156.16 243.202C155.459 243.155 154.405 243.377 154.221 243.555C153.985 243.784 153.805 244.447 153.817 244.71C153.807 246.159 154.507 247.182 154.858 247.512C155.464 247.759 156.801 248.275 157.307 248.37C157.813 248.464 158.106 248.407 158.189 248.367C158.911 248.084 160.54 247.251 161.287 246.185C162.22 244.852 162.262 239.991 161.664 239.626C161.185 239.334 157.565 239.384 155.815 239.446C155.641 240.298 155.973 242.305 156.16 243.202Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M158.599 244.91L160.654 245.629C161 245.787 161.287 245.732 161.387 245.685C161.506 245.392 161.757 244.742 161.809 244.479C161.861 244.216 161.655 244.107 161.545 244.086C160.995 243.843 159.665 243.468 159.356 243.319C159.047 243.171 158.59 243.168 158.4 243.185C158.297 243.383 158.091 243.839 158.087 244.079C158.084 244.318 158.427 244.733 158.599 244.91Z"
        fill="#50697F"
      />
      <path
        d="M162.412 246.194L162.798 246.38C163.126 246.494 163.847 246.737 164.109 246.789C164.372 246.841 164.59 246.706 164.666 246.632C164.771 246.285 164.984 245.55 164.999 245.395C165.013 245.239 164.859 245.078 164.78 245.017L162.591 244.251C162.483 244.214 162.305 244.352 162.229 244.425L162.041 244.961C162.004 245.115 161.95 245.481 162.03 245.71C162.11 245.938 162.318 246.128 162.412 246.194Z"
        fill="#50697F"
      />
      <path
        d="M165.559 246.945L167.199 247.419C167.581 247.472 167.818 247.368 167.889 247.309L168.371 246.074C168.392 246.015 168.392 245.871 168.226 245.773C168.019 245.65 165.675 245.18 165.586 245.149C165.515 245.124 165.415 245.256 165.374 245.326L165.108 246.085C165.03 246.308 165.086 246.579 165.144 246.699C165.19 246.796 165.44 246.903 165.559 246.945Z"
        fill="#50697F"
      />
      <path
        d="M168.278 247.345C168.316 247.425 168.438 247.602 168.617 247.665C168.794 247.726 169.337 247.818 169.594 247.858L169.595 247.858C169.676 247.87 169.813 247.826 169.872 247.803C170.367 247.535 170.892 246.706 171.092 246.324C171.093 246.274 171.056 246.171 170.9 246.157C170.706 246.139 168.883 245.902 168.823 245.931C168.774 245.954 168.691 246.069 168.655 246.123L168.358 246.972C168.308 247.115 168.284 247.28 168.278 247.345Z"
        fill="#50697F"
      />
      <path
        d="M169.602 247.859C169.599 247.858 169.597 247.858 169.594 247.858M169.594 247.858C169.337 247.818 168.794 247.726 168.617 247.665C168.438 247.602 168.316 247.425 168.278 247.345C168.284 247.28 168.308 247.115 168.358 246.972C168.408 246.829 168.577 246.347 168.655 246.123C168.691 246.069 168.774 245.954 168.823 245.931C168.883 245.902 170.706 246.139 170.9 246.157C171.056 246.171 171.093 246.274 171.092 246.324C170.892 246.706 170.367 247.535 169.872 247.803C169.813 247.827 169.674 247.87 169.594 247.858ZM160.654 245.629L158.599 244.91C158.427 244.733 158.084 244.318 158.087 244.079C158.091 243.839 158.297 243.383 158.4 243.185C158.59 243.168 159.047 243.171 159.356 243.319C159.665 243.468 160.995 243.843 161.545 244.086C161.655 244.107 161.861 244.216 161.809 244.479C161.757 244.742 161.506 245.392 161.387 245.685C161.287 245.732 161 245.787 160.654 245.629ZM162.798 246.38L162.412 246.194C162.318 246.128 162.11 245.938 162.03 245.71C161.95 245.481 162.004 245.115 162.041 244.961L162.229 244.425C162.305 244.352 162.483 244.214 162.591 244.251C162.698 244.289 164.095 244.778 164.78 245.017C164.859 245.078 165.013 245.239 164.999 245.395C164.984 245.55 164.771 246.285 164.666 246.632C164.59 246.706 164.372 246.841 164.109 246.789C163.847 246.737 163.126 246.494 162.798 246.38ZM167.199 247.419L165.559 246.945C165.44 246.903 165.19 246.796 165.144 246.699C165.086 246.579 165.03 246.308 165.108 246.085C165.171 245.906 165.311 245.504 165.374 245.326C165.415 245.256 165.515 245.124 165.586 245.149C165.675 245.18 168.019 245.65 168.226 245.773C168.392 245.871 168.392 246.015 168.371 246.074L167.889 247.309C167.818 247.368 167.581 247.472 167.199 247.419Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M160.682 246.281L158.359 245.468C158.144 245.393 158.021 245.383 157.986 245.387C157.075 245.888 155.241 246.924 155.191 247.067C155.141 247.21 155.267 247.328 155.337 247.369L157.347 248.072C157.704 248.198 158.135 248.114 158.305 248.057C158.995 247.663 160.434 246.816 160.677 246.58C160.92 246.345 160.782 246.283 160.682 246.281Z"
        fill="#50697F"
      />
      <path
        d="M163.827 247.181L162.129 246.587L161.549 246.384C161.504 246.368 161.399 246.382 161.323 246.455C161.237 246.586 160.589 247.168 160.275 247.443L158.645 248.376C158.454 248.443 158.034 248.574 157.878 248.559C157.853 248.631 157.986 248.731 158.055 248.772L159.708 249.35C160.03 249.463 160.371 249.415 160.501 249.377C160.954 249.134 161.934 248.605 162.237 248.43C162.562 248.303 163.48 247.695 163.898 247.407L163.945 247.273C163.958 247.237 163.872 247.197 163.827 247.181Z"
        fill="#50697F"
      />
      <path
        d="M164.875 247.197C164.755 247.195 164.674 247.244 164.649 247.269C164.149 247.896 161.992 249.08 160.977 249.594C161.085 249.665 161.338 249.821 161.481 249.871C161.624 249.921 161.9 249.917 162.02 249.909L162.86 249.802C163.385 249.735 164.801 249.127 165.042 249.011C165.235 248.918 166.609 248.356 167.271 248.086C167.297 248.062 167.35 248.004 167.363 247.968C167.375 247.932 167.329 247.873 167.305 247.847C166.545 247.631 164.994 247.199 164.875 247.197Z"
        fill="#50697F"
      />
      <path
        d="M164.345 249.569C164.104 249.685 163.247 249.937 162.931 250.027L163.646 250.277C163.717 250.302 163.935 250.312 164.035 250.313C164.364 250.278 165.045 250.206 165.129 250.195C165.213 250.184 165.385 250.134 165.46 250.11C165.886 249.942 166.764 249.594 166.86 249.547C166.965 249.534 168.111 248.982 168.247 248.879C168.356 248.797 168.866 248.544 169.107 248.428C169.168 248.399 169.304 248.326 169.364 248.267C169.425 248.208 169.351 248.162 169.306 248.147L168.203 247.861C168.158 247.845 168.054 247.829 167.993 247.888C167.895 248.054 167.379 248.258 167.133 248.339C166.304 248.701 164.586 249.453 164.345 249.569Z"
        fill="#50697F"
      />
      <path
        d="M160.682 246.281L158.359 245.468C158.144 245.393 158.021 245.383 157.986 245.387C157.075 245.888 155.241 246.924 155.191 247.067C155.141 247.21 155.267 247.328 155.337 247.369L157.347 248.072C157.704 248.198 158.135 248.114 158.305 248.057C158.995 247.663 160.434 246.816 160.677 246.58C160.92 246.345 160.782 246.283 160.682 246.281Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M163.827 247.181L162.129 246.587L161.549 246.384C161.504 246.368 161.399 246.382 161.323 246.455C161.237 246.586 160.589 247.168 160.275 247.443L158.645 248.376C158.454 248.443 158.034 248.574 157.878 248.559C157.853 248.631 157.986 248.731 158.055 248.772L159.708 249.35C160.03 249.463 160.371 249.415 160.501 249.377C160.954 249.134 161.934 248.605 162.237 248.43C162.562 248.303 163.48 247.695 163.898 247.407L163.945 247.273C163.958 247.237 163.872 247.197 163.827 247.181Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M164.875 247.197C164.755 247.195 164.674 247.244 164.649 247.269C164.149 247.896 161.992 249.08 160.977 249.594C161.085 249.665 161.338 249.821 161.481 249.871C161.624 249.921 161.9 249.917 162.02 249.909L162.86 249.802C163.385 249.735 164.801 249.127 165.042 249.011C165.235 248.918 166.609 248.356 167.271 248.086C167.297 248.062 167.35 248.004 167.363 247.968C167.375 247.932 167.329 247.873 167.305 247.847C166.545 247.631 164.994 247.199 164.875 247.197Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M164.345 249.569C164.104 249.685 163.247 249.937 162.931 250.027L163.646 250.277C163.717 250.302 163.935 250.312 164.035 250.313C164.364 250.278 165.045 250.206 165.129 250.195C165.213 250.184 165.385 250.134 165.46 250.11C165.886 249.942 166.764 249.594 166.86 249.547C166.965 249.534 168.111 248.982 168.247 248.879C168.356 248.797 168.866 248.544 169.107 248.428C169.168 248.399 169.304 248.326 169.364 248.267C169.425 248.208 169.351 248.162 169.306 248.147L168.203 247.861C168.158 247.845 168.054 247.829 167.993 247.888C167.895 248.054 167.379 248.258 167.133 248.339C166.304 248.701 164.586 249.453 164.345 249.569Z"
        stroke="black"
        strokeWidth="0.959371"
      />
      <path
        d="M153.117 237.221L153.589 236.511C155.441 237.059 157.031 238.002 157.228 238.133C157.386 238.238 157.443 238.693 157.452 238.907C157.401 239.726 157.28 241.441 157.207 241.756C157.134 242.072 156.766 242.335 156.591 242.427C156.386 242.48 155.867 242.559 155.436 242.455C154.898 242.325 153.886 241.538 153.662 241.276C153.483 241.067 153.429 240.743 153.425 240.607L153.145 238.035L153.117 237.221Z"
        fill="#50697F"
        stroke="black"
        strokeWidth="0.959371"
      />
    </motion.svg>
  );
}

function Byz3() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 2.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
      width="245"
      height="368"
      viewBox="0 0 245 368"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M191.477 218.417C181.307 230.202 163.5 224.546 156.735 221.643C162.25 217.09 161.661 196.951 162.442 190.353C164.808 195.689 176.036 198.266 194.642 196.478C195.023 197.555 192.136 212.845 191.477 218.417Z"
        fill="white"
      />
      <path
        d="M139.243 197.633C144.997 191.591 154.572 191.345 160.63 197.085C166.687 202.824 166.934 212.374 161.18 218.417C155.426 224.459 145.851 224.704 139.793 218.965C133.735 213.226 133.489 203.675 139.243 197.633Z"
        fill="white"
      />
      <path
        d="M184.378 190.854C182.056 192.922 180.751 197.371 180.212 199.113C179.85 201.135 177.935 213.016 178.674 215.113C179.598 217.735 180.85 222.613 187.034 225.098C193.218 227.583 205.55 225.369 212.042 223.101C218.534 220.834 221.557 208.497 218.259 202.547C215.622 197.786 208.624 195.276 205.082 196.478C203.341 196.903 201.011 198.247 197.38 198.566C194.735 198.798 196.498 197.257 197.037 194.271C197.158 193.601 197.38 191.643 197.38 190.854C197.627 187.941 192.729 186.328 191.477 186.641C188.646 187.348 186.7 188.787 184.378 190.854Z"
        fill="white"
      />
      <path
        d="M137.837 200.337C118.94 189.245 97.821 195.715 89.6233 200.337C74.1191 228.697 66.9632 331.834 66.38 342.16C77.1698 348.365 99.1636 346.135 102.906 345.02C102.42 333.191 105.25 320.176 106.903 314.892C109.712 305.907 111.867 300.216 115.579 295.808C117.899 296.922 119.337 297.585 122.673 297.585C121.944 306.165 122.579 332.814 124.134 340.183C130.589 343.518 152.794 341.088 158.302 339.698C160.683 316.251 163.099 270.21 162.674 257.622C161.18 213.358 146.148 214.04 137.837 200.337Z"
        fill="white"
      />
      <path
        d="M213.16 69.7166C186.292 -4.59125 135.623 -3.32429 87.8674 2.35092C65.9768 5.80255 45.4696 19.4315 37.9524 25.8146C14.3442 54.96 -10.2007 115.65 28.8637 166.499C89.2123 241.529 152.236 209.414 176.229 192.996C193.499 181.83 234.789 130.777 213.16 69.7166Z"
        fill="white"
      />
      <path
        d="M66.8141 11.5233C99.0863 43.4415 127.971 60.3955 137.546 64.2415C151.883 56.7758 178.388 32.8597 189.745 17.1689C198.072 16.3286 227.947 7.5866 244.939 2.35092C223.92 83.5804 161.458 122.222 137.546 130.609C100.802 125.324 25.1945 52.1685 0 5.63097C14.0624 7.39235 45.7789 10.0108 66.8141 11.5233Z"
        fill="white"
      />
      <path
        d="M60.5823 204.796C61.3465 220.633 69.9593 225.098 75.1349 227.704C85.4001 227.704 93.7218 219.403 93.7218 209.164C93.7218 198.925 85.4001 190.625 75.1349 190.625C64.8697 190.625 62.5918 195.095 60.5823 204.796Z"
        fill="white"
      />
      <path
        d="M81.1416 216.199C85.3197 220.833 85.9774 236.537 85.7839 243.809C66.6348 248.443 92.1478 302.87 69.9593 274.093C47.8764 245.454 56.5479 226.311 64.8654 215.113C69.8947 212.088 76.9635 211.565 81.1416 216.199Z"
        fill="white"
      />
      <path
        d="M69.9593 256.251C73.0997 241.27 89.2927 242.433 96.9967 244.887C106.848 248.06 105.704 257.274 103.146 260.271C105.037 261.956 104.513 266.835 101.058 268.643C104.342 271.765 102.252 275.183 99.6675 278.353C101.411 284.794 95.317 288.88 90.7397 288.581C86.1624 288.283 80.186 281.175 75.1349 276.74C69.7097 268.713 66.0339 274.977 69.9593 256.251Z"
        fill="white"
      />
      <path
        d="M16.8547 100.885C16.8547 100.885 17.7523 99.2514 20.2205 98.1911C21.2098 97.766 55.1086 105.721 83.0142 105.691C94.9705 116.016 114.12 131.287 137.206 136.232C150.912 132.984 170.548 116.356 180.851 104.042C189.902 103.892 197.79 102.781 201.847 102.491H204.471C204.471 102.491 206.439 102.79 207.096 103.218C208.36 104.042 208.602 105.497 208.602 105.497C208.748 106.902 208.639 108.017 208.602 109.133C208.089 124.673 194.605 147.818 189.599 151.503C184.836 155.008 173.949 160.729 169.089 162.168C163.403 163.852 149.939 169.052 121.166 169.052C85.0078 169.052 48.4569 159.26 38.9316 151.115C33.0082 146.05 23.1679 127.059 19.8782 116.666C18.452 113.106 16.7407 105.976 16.2843 104.205C15.8279 102.435 16.4744 101.254 16.8547 100.885Z"
        fill="white"
      />
      <path
        d="M122.673 333.719C121.079 334.96 122.009 341.314 122.673 344.336C122.673 349.572 122.673 352.771 124.471 356.844C125.91 360.101 133.223 363.07 135.378 362.989H170.693C171.114 361.503 171.651 353.982 169.123 350.336C165.964 345.779 162.965 344.288 160.778 342.833C161.993 340.216 161.069 333.186 160 331.974C159.611 332.905 156.501 334.98 154.994 335.901C153.179 335.998 141.861 339.051 138.829 339.283C135.038 339.574 129.575 336.822 128.165 335.901C126.756 334.98 124.666 332.168 122.673 333.719Z"
        fill="white"
      />
      <path
        d="M67.5921 334.775C63.2373 339.118 64.5139 344.697 65.6966 346.943C61.4682 349.076 62.7318 356.299 62.8776 357.754C63.0235 359.208 62.7318 361.002 65.3564 363.086C80.9193 373.015 100.767 365.171 102.906 363.086C105.045 361.002 105.042 353.584 104.313 350.336C105.246 346.109 103.924 341.723 103.146 340.059C101.656 341.222 89.8619 341.92 87.7622 342.386C81.6383 343.743 73.716 341.271 72.3552 340.059C69.6334 335.793 69.2932 336.132 67.5921 334.775Z"
        fill="white"
      />
      <path
        d="M191.248 212.485C181.54 223.765 163.333 219.3 156.875 216.522C162.14 212.164 162.254 203.488 163 197.174C165.661 200.903 184.581 201.533 191.248 200.897C191.611 201.928 191.878 207.153 191.248 212.485Z"
        fill="white"
        stroke="black"
        strokeWidth="1.92555"
      />
      <circle
        cx="153.609"
        cy="204.454"
        r="13.4789"
        transform="rotate(-136.473 153.609 204.454)"
        fill="url(#paint0_radial_2551_2914)"
        stroke="black"
        strokeWidth="1.92555"
      />
      <path
        d="M185.663 194.419C183.446 196.398 181.449 201.257 180.935 202.925C180.59 204.86 180.075 209.231 180.781 211.238C181.663 213.748 182.858 218.417 188.761 220.795C194.665 223.173 203.596 221.267 209.793 219.097C215.991 216.927 217.473 208.55 214.325 202.854C211.807 198.298 204.414 199.46 201.033 200.611C199.37 201.018 197.012 201.758 193.604 201.055C191.761 200.675 192.232 198.367 193.002 197.042C194.361 194.705 195.062 193.405 195.001 192.08C195.237 189.291 192.281 189.014 191.311 189.66C189.235 191.498 187.88 192.44 185.663 194.419Z"
        fill="url(#paint1_linear_2551_2914)"
        stroke="black"
        strokeWidth="1.9275"
        strokeLinecap="round"
      />
      <path
        d="M141.795 197.096C123.755 186.48 103.593 192.673 95.7674 197.096C80.9662 224.239 70.5265 330.307 69.9697 340.19C80.2702 346.129 93.3547 342.324 96.9274 341.257C96.4634 329.936 102.774 310.123 104.351 305.066C107.033 296.467 113.26 290.574 114.466 288.393C118.642 290.141 122.539 291.224 127.318 290.172C126.623 298.385 127.643 332.441 129.128 339.494C135.29 342.686 147.347 340.824 152.606 339.494C154.879 317.052 158.812 269.412 158.174 258.25C156.735 233.086 149.729 210.212 141.795 197.096Z"
        fill="white"
        stroke="black"
        strokeWidth="1.85595"
      />
      <path
        d="M210.937 76.5492C185.288 5.42925 122.053 0.20786 93.6413 6.48715C72.7433 9.7907 53.1661 22.835 45.9898 28.9442C25.9919 48.3388 -6.82036 106 37.3132 163.593C99.7574 230.952 153.92 202.587 176.826 186.873C193.313 176.186 222.296 135.94 210.937 76.5492Z"
        fill="white"
        stroke="#071C36"
        strokeWidth="1.85595"
      />
      <path
        d="M75.5839 17.6109C106.393 48.1598 132.376 63.1589 141.516 66.8399C155.204 59.6945 180.507 36.8045 191.349 21.7868C199.298 20.9825 218.65 18.3997 234.871 13.3886C211.114 76.166 164.345 122.333 141.516 130.36C106.439 125.302 37.8773 51.6057 10.2082 10.7439C23.6329 12.4297 55.5026 16.1633 75.5839 17.6109Z"
        fill="url(#paint2_linear_2551_2914)"
        stroke="#071C36"
        strokeWidth="1.85595"
      />
      <circle
        r="16.8002"
        transform="matrix(-1 0 0 1 81.9352 205.545)"
        fill="url(#paint3_radial_2551_2914)"
        stroke="black"
        strokeWidth="1.88766"
      />
      <path
        d="M87.6696 212.278C91.6582 216.713 92.2861 231.743 92.1014 238.704C73.8206 243.139 77.6977 260.879 77.6977 270.858C51.1069 252.231 60.3398 226.138 68.2801 215.42C73.0814 212.524 83.681 207.843 87.6696 212.278Z"
        fill="white"
        stroke="black"
        strokeWidth="1.88766"
      />
      <path
        d="M76.9944 250.612C79.9923 236.274 95.4511 237.387 102.806 239.735C112.21 242.772 111.118 251.591 108.676 254.459C110.482 256.072 109.981 260.742 106.683 262.472C109.818 265.46 107.823 268.731 105.355 271.766C107.02 277.93 101.202 281.841 96.8324 281.555C92.4627 281.27 85.4805 280.272 80.6585 276.028C75.4793 268.345 73.247 268.534 76.9944 250.612Z"
        fill="url(#paint4_linear_2551_2914)"
      />
      <path
        d="M105.355 271.766C107.02 277.93 101.202 281.841 96.8324 281.555C92.4627 281.27 85.4805 280.272 80.6585 276.028C75.4793 268.345 73.247 268.534 76.9944 250.612C79.9923 236.274 95.4511 237.387 102.806 239.735C112.21 242.772 111.118 251.591 108.676 254.459M105.355 271.766C107.823 268.731 109.818 265.46 106.683 262.472M105.355 271.766C102.813 271.741 97.0932 271.398 94.5471 270.222M106.683 262.472C109.981 260.742 110.482 256.072 108.676 254.459M106.683 262.472C103.943 262.645 97.7558 262.721 94.9247 261.64M108.676 254.459C105.422 254.277 98.3708 253.665 96.2047 252.678"
        stroke="black"
        strokeWidth="1.88766"
      />
      <path
        d="M26.2984 101.912C26.2984 101.912 27.1553 100.348 29.5115 99.3329C30.456 98.9261 62.8175 106.54 89.4577 106.511C100.872 116.394 119.153 131.009 141.192 135.742C154.277 132.633 173.022 116.718 182.858 104.933C191.498 104.789 199.029 103.726 202.902 103.448H205.407C205.407 103.448 207.285 103.734 207.913 104.144C209.119 104.933 209.351 106.325 209.351 106.325C209.49 107.671 209.386 108.738 209.351 109.805C208.861 124.679 195.988 146.831 191.209 150.357C186.662 153.713 176.269 159.187 171.629 160.565C166.201 162.177 153.348 167.154 125.88 167.154C91.3608 167.154 56.4674 157.781 47.3741 149.986C41.7193 145.139 32.3252 126.962 29.1847 117.015C27.8232 113.608 26.1895 106.784 25.7538 105.089C25.3181 103.394 25.9353 102.265 26.2984 101.912Z"
        fill="url(#paint5_linear_2551_2914)"
        stroke="#071C36"
        strokeWidth="1.85595"
      />
      <path
        d="M127.733 333.308C126.211 334.495 127.099 340.577 127.733 343.469C127.733 348.48 127.733 351.542 129.449 355.44C130.823 358.558 135.28 359.183 137.337 359.105L166.244 358.502C166.646 357.079 166.847 353.361 164.434 349.872C161.418 345.51 157.057 343.886 154.969 342.495C156.129 339.989 155.247 333.261 154.226 332.101C153.855 332.992 150.886 334.978 149.447 335.859C147.715 335.952 143.527 336.194 140.632 336.416C137.012 336.695 134.321 336.277 132.976 335.396C131.63 334.514 129.635 331.823 127.733 333.308Z"
        fill="url(#paint6_linear_2551_2914)"
      />
      <path
        d="M71.1263 333.122C66.969 337.279 68.1877 342.618 69.3168 344.768C65.2801 346.81 66.4865 353.723 66.6257 355.115C66.7649 356.507 66.4865 358.224 68.992 360.219C83.2364 368.431 97.156 361.518 99.1975 359.523C101.239 357.528 98.9656 349.454 98.2696 346.346C99.1604 342.3 97.8984 338.102 97.156 336.509C95.7331 337.623 92.3862 339.961 90.3818 340.407C84.5356 341.706 76.9726 339.339 75.6734 338.179C73.0751 334.096 72.7503 334.421 71.1263 333.122Z"
        fill="url(#paint7_linear_2551_2914)"
      />
      <path
        d="M127.733 333.308C126.211 334.495 127.099 340.577 127.733 343.469C127.733 348.48 127.733 351.542 129.449 355.44C130.823 358.558 135.28 359.183 137.337 359.105L166.244 358.502C166.646 357.079 166.847 353.361 164.434 349.872C161.418 345.51 157.057 343.886 154.969 342.495C156.129 339.989 155.247 333.261 154.226 332.101C153.855 332.992 150.886 334.978 149.447 335.859C147.715 335.952 143.527 336.194 140.632 336.416C137.012 336.695 134.321 336.277 132.976 335.396C131.63 334.514 129.635 331.823 127.733 333.308Z"
        stroke="black"
        strokeWidth="1.85595"
      />
      <path
        d="M71.1263 333.122C66.969 337.279 68.1877 342.618 69.3168 344.768C65.2801 346.81 66.4865 353.723 66.6257 355.115C66.7649 356.507 66.4865 358.224 68.992 360.219C83.2364 368.431 97.156 361.518 99.1975 359.523C101.239 357.528 98.9656 349.454 98.2696 346.346C99.1604 342.3 97.8984 338.102 97.156 336.509C95.7331 337.623 92.3862 339.961 90.3818 340.407C84.5356 341.706 76.9726 339.339 75.6734 338.179C73.0751 334.096 72.7503 334.421 71.1263 333.122Z"
        stroke="black"
        strokeWidth="1.85595"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2551_2914"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(153.609 204.454) rotate(90) scale(14.4417)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_2551_2914"
          x1="209.769"
          y1="213.552"
          x2="184.343"
          y2="197.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2551_2914"
          x1="122.539"
          y1="10.7439"
          x2="122.539"
          y2="130.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_2551_2914"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.744 17.744) rotate(90) scale(17.744)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_2551_2914"
          x1="83.471"
          y1="238.329"
          x2="95.1634"
          y2="259.441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#021E34" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2551_2914"
          x1="117.482"
          y1="-10.182"
          x2="117.482"
          y2="167.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4B4B4" />
          <stop offset="1" stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2551_2914"
          x1="116.42"
          y1="332.101"
          x2="116.42"
          y2="364.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2551_2914"
          x1="116.42"
          y1="332.101"
          x2="116.42"
          y2="364.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
}
