import { motion } from "framer-motion";

export function AnimatedByz() {
  const leftArmVariants = {
    starting: {
      rotate: 0,
    },
    moving: {
      rotate: [0, 90, 0],
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  const rightArmVariants = {
    starting: {
      rotate: 0,
    },
    moving: {
      rotate: [0, 50, 0],
      transition: {
        duration: 1.5,
        ease: "easeInOut",
        delay: 0.1,
      },
    },
  };

  const headVariants = {
    starting: {
      rotate: 0,
    },
    moving: {
      rotate: [0, 1.5, 0],
      transition: {
        duration: 1.8,
        ease: "easeInOut",
        delay: 0.1,
      },
    },
  };

  const svgByz = () => (
    <svg viewBox="0 0 2641 3966" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1486.35 2158.86C1282.72 2039.34 1055.13 2109.06 966.793 2158.86C799.717 2464.47 722.604 3575.9 716.319 3687.17C832.592 3754.04 1069.6 3730.01 1109.93 3717.99C1104.69 3590.52 1135.19 3450.27 1153 3393.32C1183.28 3296.5 1206.5 3235.18 1246.5 3187.68C1271.5 3199.68 1287 3206.82 1322.94 3206.82C1315.09 3299.29 1321.93 3586.46 1338.69 3665.87C1408.24 3701.81 1647.53 3675.62 1706.88 3660.65C1732.55 3407.97 1758.58 2911.83 1754 2776.18C1737.9 2299.18 1575.91 2306.53 1486.35 2158.86Z"
        fill="white"
      />
      {/* INIZIO 1 PARTE TESTA BYZ */}
      <motion.g
        variants={headVariants}
        style={{
          originX: "1200px",
          originY: "1200px",
        }}
        initial="starting"
        animate="moving"
      >
        <path
          d="M2298.04 751.278C2008.51 -49.4761 1462.5 -35.8231 947.871 25.334C711.974 62.5293 490.985 209.397 409.978 278.182C155.572 592.258 -108.928 1246.26 312.037 1794.22C962.364 2602.76 1641.51 2256.68 1900.07 2079.76C2086.18 1959.43 2531.13 1409.28 2298.04 751.278Z"
          fill="white"
        />
        <path
          d="M720.997 124.177C1068.77 468.133 1380.03 650.832 1483.21 692.277C1637.72 611.826 1923.34 354.102 2045.72 185.014C2135.46 175.959 2457.39 81.7545 2640.5 25.334C2414 900.677 1740.9 1317.08 1483.21 1407.46C1087.26 1350.52 272.497 562.177 0.99707 60.6802C152.536 79.6612 494.318 107.878 720.997 124.177Z"
          fill="white"
        />
        <path
          d="M182.627 1087.16C182.627 1087.16 192.299 1069.55 218.896 1058.12C229.558 1053.54 594.856 1139.27 895.573 1138.94C1024.42 1250.21 1230.77 1414.77 1479.55 1468.06C1627.25 1433.06 1838.85 1253.87 1949.88 1121.18C2047.41 1119.56 2132.42 1107.59 2176.13 1104.46H2204.42C2204.42 1104.46 2225.62 1107.68 2232.7 1112.3C2246.31 1121.18 2248.93 1136.85 2248.93 1136.85C2250.5 1152 2249.33 1164.01 2248.93 1176.03C2243.4 1343.5 2098.09 1592.92 2044.15 1632.62C1992.82 1670.4 1875.5 1732.04 1823.13 1747.55C1761.86 1765.7 1616.77 1821.73 1306.71 1821.73C917.055 1821.73 523.176 1716.21 420.531 1628.44C356.699 1573.86 250.658 1369.21 215.208 1257.22C199.839 1218.85 181.397 1142.01 176.479 1122.93C171.561 1103.85 178.528 1091.13 182.627 1087.16Z"
          fill="white"
        />
      </motion.g>
      {/* FINE 1 PARTE TESTA BYZ */}
      <path
        d="M1322.94 3596.22C1305.76 3609.59 1315.79 3678.06 1322.94 3710.62C1322.94 3767.05 1322.94 3801.52 1342.32 3845.41C1357.83 3880.51 1436.63 3912.5 1459.85 3911.63H1840.41C1844.95 3895.61 1850.73 3814.57 1823.5 3775.28C1789.45 3726.18 1757.13 3710.1 1733.56 3694.43C1746.66 3666.22 1736.71 3590.47 1725.18 3577.41C1720.99 3587.44 1687.47 3609.8 1671.24 3619.72C1651.68 3620.77 1529.72 3653.67 1497.04 3656.17C1456.19 3659.31 1397.32 3629.65 1382.13 3619.72C1366.94 3609.8 1344.42 3579.5 1322.94 3596.22Z"
        fill="white"
      />
      <path
        d="M729.381 3607.59C682.453 3654.4 696.21 3714.51 708.955 3738.71C663.388 3761.7 677.006 3839.54 678.577 3855.21C680.148 3870.88 677.006 3890.21 705.289 3912.68C872.997 4019.68 1086.88 3935.14 1109.93 3912.68C1132.97 3890.21 1132.94 3810.28 1125.09 3775.28C1135.14 3729.73 1120.9 3682.47 1112.52 3664.53C1096.45 3677.07 969.364 3684.59 946.737 3689.61C880.745 3704.23 795.374 3677.59 780.709 3664.53C751.379 3618.56 747.712 3622.22 729.381 3607.59Z"
        fill="white"
      />
      <motion.g
        variants={leftArmVariants}
        style={{
          originX: "1646px",
          originY: "2338px",
        }}
        initial="starting"
        animate="moving"
      >
        {/*  INIZIO BRACCIO SINISTRO */}
        <path
          d="M2064.33 2475.23C1954.75 2602.23 1762.85 2541.29 1689.95 2510.01C1749.38 2460.94 1733.22 2266.5 1741.63 2195.4C1779.63 2243.9 1897.95 2258.09 2098.45 2238.82C2102.54 2250.43 2071.44 2415.2 2064.33 2475.23Z"
          fill="white"
        />
        <path
          d="M1530.13 2215.04C1592.14 2149.93 1695.32 2147.28 1760.6 2209.13C1825.88 2270.97 1799.85 2410.12 1737.85 2475.23C1675.84 2540.35 1578.91 2513.25 1513.63 2451.4C1448.35 2389.56 1468.13 2280.15 1530.13 2215.04Z"
          fill="white"
        />
        <path
          d="M1987.83 2178.22C1962.81 2200.5 1948.75 2248.44 1942.95 2267.22C1939.05 2289 1918.41 2417.03 1926.37 2439.63C1936.33 2467.88 1949.82 2520.46 2016.46 2547.23C2083.1 2574.01 2215.99 2550.15 2285.95 2525.72C2355.91 2501.29 2388.48 2368.34 2352.95 2304.22C2324.52 2252.92 2249.12 2225.86 2210.95 2238.82C2192.18 2243.4 2167.07 2257.89 2127.95 2261.32C2099.45 2263.82 2118.45 2247.22 2124.25 2215.04C2125.55 2207.82 2127.95 2186.72 2127.95 2178.22C2130.61 2146.82 2077.83 2129.45 2064.33 2132.82C2033.83 2140.44 2012.85 2155.94 1987.83 2178.22Z"
          fill="white"
        />
        <path
          d="M2052.54 2425.4C1947.93 2546.95 1751.73 2498.84 1682.13 2468.9C1738.87 2421.94 1740.1 2328.45 1748.13 2260.4C1776.82 2300.59 1980.69 2307.38 2052.54 2300.53C2056.45 2311.64 2059.33 2367.94 2052.54 2425.4Z"
          fill="white"
          stroke="black"
          strokeWidth="20.7501"
        />
        <circle
          cx="1646.93"
          cy="2338.86"
          r="145.251"
          transform="rotate(-136.473 1646.93 2338.86)"
          fill="url(#paint0_radial_315_230)"
          stroke="black"
          strokeWidth="20.7501"
        />
        <path
          d="M1992.36 2230.72C1968.47 2252.04 1946.95 2304.41 1941.41 2322.38C1937.69 2343.23 1932.14 2390.34 1939.74 2411.97C1949.25 2439.01 1962.13 2489.32 2025.74 2514.95C2089.36 2540.58 2185.6 2520.03 2252.39 2496.65C2319.18 2473.27 2335.15 2382.99 2301.23 2321.62C2274.09 2272.52 2194.43 2285.04 2157.99 2297.44C2140.07 2301.83 2114.65 2309.8 2077.93 2302.23C2058.07 2298.13 2063.15 2273.26 2071.45 2258.98C2086.09 2233.8 2093.65 2219.79 2092.99 2205.51C2095.53 2175.46 2063.68 2172.47 2053.22 2179.44C2030.85 2199.24 2016.25 2209.4 1992.36 2230.72Z"
          fill="url(#paint1_linear_315_230)"
          stroke="black"
          strokeWidth="20.771"
          strokeLinecap="round"
        />
        {/* FINE BRACCIO SINISTRO */}
      </motion.g>
      <path
        d="M1529 2123.94C1334.6 2009.54 1117.33 2076.28 1033 2123.94C873.498 2416.44 760.998 3559.44 754.998 3665.94C865.998 3729.94 1007 3688.94 1045.5 3677.44C1040.5 3555.44 1108.5 3341.94 1125.5 3287.44C1154.4 3194.78 1221.5 3131.28 1234.5 3107.78C1279.5 3126.61 1321.5 3138.28 1373 3126.94C1365.5 3215.44 1376.5 3582.44 1392.5 3658.44C1458.9 3692.84 1588.83 3672.78 1645.5 3658.44C1670 3416.61 1712.37 2903.23 1705.5 2782.94C1690 2511.78 1614.5 2265.28 1529 2123.94Z"
        fill="white"
        stroke="black"
        strokeWidth="20"
      />
      <path
        d="M1377.47 3591.78C1361.07 3604.58 1370.63 3670.11 1377.47 3701.28C1377.47 3755.28 1377.47 3788.28 1395.97 3830.28C1410.77 3863.88 1458.8 3870.61 1480.97 3869.78L1792.47 3863.28C1796.8 3847.94 1798.97 3807.88 1772.97 3770.28C1740.47 3723.28 1693.47 3705.78 1670.97 3690.78C1683.47 3663.78 1673.97 3591.28 1662.97 3578.78C1658.97 3588.38 1626.97 3609.78 1611.47 3619.28C1592.8 3620.28 1547.67 3622.88 1516.47 3625.28C1477.47 3628.28 1448.47 3623.78 1433.97 3614.28C1419.47 3604.78 1397.97 3575.78 1377.47 3591.78Z"
        fill="url(#paint2_linear_315_230)"
      />
      <path
        d="M767.467 3589.78C722.667 3634.58 735.8 3692.11 747.967 3715.28C704.467 3737.28 717.467 3811.78 718.967 3826.78C720.467 3841.78 717.467 3860.28 744.467 3881.78C897.967 3970.28 1047.97 3895.78 1069.97 3874.28C1091.97 3852.78 1067.47 3765.78 1059.97 3732.28C1069.57 3688.68 1055.97 3643.44 1047.97 3626.28C1032.63 3638.28 996.567 3663.48 974.967 3668.28C911.967 3682.28 830.467 3656.78 816.467 3644.28C788.467 3600.28 784.967 3603.78 767.467 3589.78Z"
        fill="url(#paint3_linear_315_230)"
      />
      <path
        d="M1377.47 3591.78C1361.07 3604.58 1370.63 3670.11 1377.47 3701.28C1377.47 3755.28 1377.47 3788.28 1395.97 3830.28C1410.77 3863.88 1458.8 3870.61 1480.97 3869.78L1792.47 3863.28C1796.8 3847.94 1798.97 3807.88 1772.97 3770.28C1740.47 3723.28 1693.47 3705.78 1670.97 3690.78C1683.47 3663.78 1673.97 3591.28 1662.97 3578.78C1658.97 3588.38 1626.97 3609.78 1611.47 3619.28C1592.8 3620.28 1547.67 3622.88 1516.47 3625.28C1477.47 3628.28 1448.47 3623.78 1433.97 3614.28C1419.47 3604.78 1397.97 3575.78 1377.47 3591.78Z"
        stroke="black"
        strokeWidth="20"
      />
      <path
        d="M767.467 3589.78C722.667 3634.58 735.8 3692.11 747.967 3715.28C704.467 3737.28 717.467 3811.78 718.967 3826.78C720.467 3841.78 717.467 3860.28 744.467 3881.78C897.967 3970.28 1047.97 3895.78 1069.97 3874.28C1091.97 3852.78 1067.47 3765.78 1059.97 3732.28C1069.57 3688.68 1055.97 3643.44 1047.97 3626.28C1032.63 3638.28 996.567 3663.48 974.967 3668.28C911.967 3682.28 830.467 3656.78 816.467 3644.28C788.467 3600.28 784.967 3603.78 767.467 3589.78Z"
        stroke="black"
        strokeWidth="20"
      />
      {/* INIZIO 2 PARTE TESTA BYZ */}
      <motion.g
        variants={headVariants}
        style={{
          originX: "1200px",
          originY: "1200px",
        }}
        initial="starting"
        animate="moving"
      >
        <path
          d="M2274.09 824.906C1997.69 58.5062 1316.26 2.23956 1010.09 69.9062C784.891 105.506 573.924 246.073 496.59 311.907C281.09 520.907 -72.501 1142.28 403.09 1762.91C1076 2488.78 1659.67 2183.11 1906.5 2013.78C2084.17 1898.61 2396.49 1464.91 2274.09 824.906Z"
          fill="white"
          stroke="#071C36"
          strokeWidth="20"
        />
        <path
          d="M815.5 189.778C1147.5 518.978 1427.5 680.611 1526 720.278C1673.5 643.278 1946.17 396.611 2063 234.778C2148.67 226.111 2357.2 198.278 2532 144.278C2276 820.778 1772 1318.28 1526 1404.78C1148 1350.28 409.167 556.111 111 115.778C255.667 133.944 599.1 174.178 815.5 189.778Z"
          fill="url(#paint4_linear_315_230)"
          stroke="#071C36"
          strokeWidth="20"
        />
        <path
          d="M284.393 1098.22C284.393 1098.22 293.627 1081.36 319.018 1070.43C329.196 1066.05 677.929 1148.09 965.009 1147.78C1088.01 1254.28 1285.01 1411.78 1522.51 1462.78C1663.51 1429.28 1865.51 1257.78 1971.51 1130.78C2064.61 1129.23 2145.77 1117.77 2187.5 1114.78H2214.5C2214.5 1114.78 2234.74 1117.86 2241.5 1122.28C2254.5 1130.78 2257 1145.78 2257 1145.78C2258.5 1160.28 2257.38 1171.78 2257 1183.28C2251.72 1343.56 2113 1582.28 2061.5 1620.28C2012.5 1656.43 1900.5 1715.43 1850.5 1730.28C1792.01 1747.65 1653.5 1801.28 1357.5 1801.28C985.517 1801.28 609.5 1700.28 511.509 1616.28C450.572 1564.04 349.339 1368.16 315.497 1260.98C300.825 1224.26 283.22 1150.72 278.525 1132.46C273.83 1114.2 280.481 1102.02 284.393 1098.22Z"
          fill="url(#paint5_linear_315_230)"
          stroke="#071C36"
          strokeWidth="20"
        />
      </motion.g>
      {/* FINE 2 PARTE TESTA BYZ */}
      <motion.g
        variants={rightArmVariants}
        style={{
          originX: "895px",
          originY: "2338px",
        }}
        initial="starting"
        animate="moving"
      >
        {/* INIZIO BRACCIO DESTRO */}
        <path
          d="M683.479 2283.89C663.386 2550.45 817.952 2520.13 892.173 2550.45C1039.38 2550.45 1120.42 2450.14 1120.42 2331C1120.42 2211.86 1039.38 2119 892.173 2119C773.886 2119 712.297 2171.02 683.479 2283.89Z"
          fill="white"
        />
        <path
          d="M978.313 2416.58C1038.23 2470.5 1033.46 2630.38 1030.69 2715C756.079 2768.92 1299.89 3415 817.952 3090.24C519.992 2889.45 609.886 2505.5 744.901 2403.94C817.025 2368.74 918.397 2362.66 978.313 2416.58Z"
          fill="white"
        />
        <path
          d="M817.952 2882.62C862.987 2708.31 956.386 2637 1120.42 2686.09C1261.69 2723.01 1245.29 2830.22 1208.61 2865.1C1224.89 2890 1230.39 2931.5 1184.89 2952.39C1216.89 2979.5 1205.95 3019.84 1168.89 3056.73C1193.89 3131.68 1096.33 3186.97 1030.69 3183.5C965.049 3180.03 960.886 3180.4 892.173 3121.04C814.373 3027.63 761.659 3100.52 817.952 2882.62Z"
          fill="white"
        />
        <circle
          r="181.041"
          transform="matrix(-1 0 0 1 895.337 2338.22)"
          fill="url(#paint6_radial_315_230)"
          stroke="black"
          strokeWidth="20.3417"
        />
        <path
          d="M957.132 2410.77C1000.11 2458.56 1006.88 2620.53 1004.89 2695.54C807.893 2743.33 849.673 2934.51 849.673 3042.04C563.126 2841.31 662.621 2560.12 748.187 2444.62C799.926 2413.42 914.15 2362.98 957.132 2410.77Z"
          fill="white"
          stroke="black"
          strokeWidth="20.3417"
        />
        <path
          d="M842.094 2823.86C874.4 2669.35 1040.99 2681.34 1120.24 2706.65C1221.58 2739.38 1209.82 2834.41 1183.5 2865.32C1202.96 2882.7 1197.56 2933.02 1162.02 2951.67C1195.8 2983.87 1174.31 3019.12 1147.72 3051.82C1165.66 3118.25 1102.96 3160.39 1055.87 3157.31C1008.78 3154.24 933.541 3143.49 881.578 3097.74C825.767 3014.96 801.711 3016.99 842.094 2823.86Z"
          fill="url(#paint7_linear_315_230)"
        />
        <path
          d="M1147.72 3051.82C1165.66 3118.25 1102.96 3160.39 1055.87 3157.31C1008.78 3154.24 933.541 3143.49 881.578 3097.74C825.767 3014.96 801.711 3016.99 842.094 2823.86C874.4 2669.35 1040.99 2681.34 1120.24 2706.65C1221.58 2739.38 1209.82 2834.41 1183.5 2865.32M1147.72 3051.82C1174.31 3019.12 1195.8 2983.87 1162.02 2951.67M1147.72 3051.82C1120.32 3051.55 1058.68 3047.86 1031.24 3035.18M1162.02 2951.67C1197.56 2933.02 1202.96 2882.7 1183.5 2865.32M1162.02 2951.67C1132.5 2953.54 1065.82 2954.35 1035.31 2942.7M1183.5 2865.32C1148.43 2863.36 1072.45 2856.76 1049.11 2846.13"
          stroke="black"
          strokeWidth="20.3417"
        />
        {/* FINE BRACCIO DESTRO */}
      </motion.g>
      <defs>
        <radialGradient
          id="paint0_radial_315_230"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1646.93 2338.86) rotate(90) scale(155.626)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_315_230"
          x1="2252.13"
          y1="2436.9"
          x2="1978.13"
          y2="2266.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_315_230"
          x1="1255.56"
          y1="3578.78"
          x2="1255.56"
          y2="3924.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_315_230"
          x1="1255.56"
          y1="3578.78"
          x2="1255.56"
          y2="3924.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_315_230"
          x1="1321.5"
          y1="115.778"
          x2="1321.5"
          y2="1404.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_315_230"
          x1="1267"
          y1="-109.722"
          x2="1267"
          y2="1801.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4B4B4" />
          <stop offset="1" stopColor="#EDEDED" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_315_230"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(191.212 191.212) rotate(90) scale(191.212)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="paint7_linear_315_230"
          x1="911.886"
          y1="2691.5"
          x2="1037.89"
          y2="2919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#021E34" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
      </defs>
    </svg>
  );

  return <>{svgByz()}</>;
}
