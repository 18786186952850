import { Link } from "react-router-dom";
import { css } from "styled-components/macro";

type PageButtonType = {
  text: string;
  width?: string;
  onClick?: () => void;
  linkToPage?: string;
};

export function PageButton({
  text,
  width,
  onClick,
  linkToPage,
}: PageButtonType) {
  return linkToPage ? (
    <div
      css={css`
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        color: #021e34;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 4px;
        text-align: center;
      `}
      style={{ width: width ? width : "inherit" }}
      onClick={onClick}
    >
      <Link
        className="page-link"
        to={linkToPage}
        css={css`
          width: 100%;
          color: #021e34;
          text-decoration: none;
        `}
      >
        <p>{text.toUpperCase()}</p>
      </Link>
    </div>
  ) : (
    <div
      css={css`
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        color: #021e34;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 4px;
        text-align: center;
      `}
      style={{ width: width ? width : "inherit" }}
      onClick={onClick}
    >
      <p>{text.toUpperCase()}</p>
    </div>
  );
}
