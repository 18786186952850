import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { useCallback } from 'react';
import './AnimatedNetwork.css';

export function  AnimatedNetwork ({ children }) {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Particles
          init={particlesInit}
          options={{
            fpsLimit: 60,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: [ 'remove', 'push' ],
                },
                resize: true,
              },
              modes: {
                // repulse: {
                //   distance: 50,
                //   duration: 0.5,
                //   maxSpeed: 20,
                //   factor: 0.4
                // },
                grab: {
                  distance: 400,
                  links: {
                    opacity: 1
                  }
                },
                bubble: {
                  distance: 100,
                  size: 10,
                  duration: 2,
                  opacity: 0.8
                },
                repulse: {
                  distance: 200
                },
                push: {
                  quantity: 1
                },
                remove: {
                  quantity: 1
                }
              },
            },
            particles: {
              color: {
                value: '#ffffff',
                opacity: 0.1,
              },
              links: {
                color: '#ffffff',
                distance: 350,
                enable: true,
                opacity: 0.1,
                width: 5,
              },
              collisions: {
                enable: true
              },
              move: {
                directions: 'none',
                enable: true,
                outModes: {
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 900,
                },
                value: 20,
                limit: 20
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: 'circle',
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
      </div>
      {children}
    </>
  );
};
