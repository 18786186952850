import { useNavigate } from "react-router-dom";

export function TimelineTitleText() {
  return (
    <>
      <svg
        className="timeline-title"
        x="-250"
        y="752"
        viewBox="0 0 240 80"
        stroke="white"
        strokeWidth="0"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text x="89" y="56">
          Timeline
        </text>
      </svg>
      <svg
        className="timeline-description"
        x="-250"
        y="752"
        viewBox="0 0 240 80"
        stroke="white"
        strokeWidth="0"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text x="89" y="63">
          I nostri traguardi
        </text>
      </svg>
    </>
  );
}

export function TimelineElements() {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <g>
      <circle
        id="foundation"
        cx="900"
        cy="2310"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="foundation-active"
        cx="900"
        cy="2310"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <FoundationText />

      <circle
        id="innovation-factory"
        cx="1250"
        cy="2560"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
        onClick={() => redirectTo("/cosa-facciamo/digital-factory")}
      />
      <circle
        id="innovation-active"
        cx="1250"
        cy="2560"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <InnovationText />

      <circle
        id="metamorphosis"
        cx="850"
        cy="2560"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
        //onClick={() => redirectTo("/metamorphosis")}
      />
      <circle
        id="metamorphosis-active"
        cx="850"
        cy="2560"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <MetamorphosisText />

      <circle
        id="cyber-security"
        cx="450"
        cy="2560"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
        onClick={() => redirectTo("/cosa-facciamo/cyber-security")}
      />
      <circle
        id="cyber-active"
        cx="450"
        cy="2560"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <CyberText />

      <circle
        id="employers-factory"
        cx="350"
        cy="2760"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="employers-active"
        cx="350"
        cy="2760"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <EmployersText />

      <circle
        id="engineering"
        cx="700"
        cy="2760"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
        onClick={() => redirectTo("/cosa-facciamo/engineering")}
      />
      <circle
        id="engineering-active"
        cx="700"
        cy="2760"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <EngineeringText />

      <circle
        id="byz"
        cx="1100"
        cy="2760"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
        onClick={() => redirectTo("/byz")}
      />
      <circle
        id="byz-active"
        cx="1100"
        cy="2760"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <ByzText />
    </g>
  );
}

function FoundationText() {
  return (
    <svg
      className="foundation-text"
      x="580"
      y="212"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="55" y="142">
        GENNAIO 2007
      </text>
      <text x="55" y="147">
        FONDAZIONE KEYBIZ
      </text>
    </svg>
  );
}

function InnovationText() {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <svg
      className="innovation-text"
      x="950"
      y="618"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => redirectTo("/cosa-facciamo/digital-factory")}
    >
      <text x="25.5" y="117.5">
        GIUGNO 2012
      </text>
      <text x="9" y="122.5">
        NASCITA DIGITAL FACTORY
      </text>
    </svg>
  );
}

function MetamorphosisText() {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <svg
      className="metamorphosis-text"
      x="120"
      y="618"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      //onClick={() => redirectTo("/metamorphosis")}
    >
      <text x="92" y="117.5">
        GENNAIO 2020
      </text>
      <text x="76" y="122.5">
        NASCITA METAMORPHOSIS
      </text>
    </svg>
  );
}

function CyberText() {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <svg
      className="cyber-text"
      x="-650"
      y="618"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => redirectTo("/cosa-facciamo/cyber-security")}
    >
      <text x="149" y="117.5">
        FEBBRAIO 2020
      </text>
      <text x="136" y="122.5">
        NASCITA CYBER SECURITY
      </text>
    </svg>
  );
}

function EmployersText() {
  return (
    <svg
      className="employers-text"
      x="-500"
      y="592.5"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="139" y="153">
        MAGGIO 2021
      </text>
      <text x="139" y="158">
        150 DIPENDENTI
      </text>
    </svg>
  );
}

function EngineeringText() {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <svg
      className="engineering-text"
      x="250"
      y="590"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => redirectTo("/cosa-facciamo/engineering")}
    >
      <text x="76" y="153">
        LUGLIO 2021
      </text>
      <text x="76" y="158">
        NASCITA KB ENGINEERING
      </text>
    </svg>
  );
}

function ByzText() {
  const navigate = useNavigate();
  function redirectTo(pageName: string) {
    navigate(pageName);
  }
  return (
    <svg
      className="byz-text"
      x="995"
      y="590"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => redirectTo("/byz")}
    >
      <text x="21" y="153">
        DICEMBRE 2021
      </text>
      <text x="21" y="158">
        NASCITA BYZ
      </text>
    </svg>
  );
}
