import { HighlightType } from "../../types/types";
import "./BulletList.css";

type BulletListType = {
  title: string;
  content: Array<HighlightType>;
};

export function BulletList({ title, content }: BulletListType) {
  return (
    <figure className="bullet-section ">
      <figcaption className="bullet-title">{title}</figcaption>
      <ul className="bullet-content">
        {content?.map((el) => {
          return (
            <li className="bullet-list fade-in" role="article" key={el.title}>
              <header>
                <b>{el.title}</b>
              </header>
              <p>{el.description}</p>
            </li>
          );
        })}
      </ul>
    </figure>
  );
}
