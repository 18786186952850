import { motion } from "framer-motion";
import "./ByzCookies.css";

type ByzCookiesType = {
  acceptClicked: boolean;
};

export function ByzCookies({ acceptClicked }: ByzCookiesType) {
  const armVariants = {
    starting: {
      rotate: 0,
    },
    moving: {
      rotate: [0, -50, 0],
      transition: {
        duration: 1.8,
        ease: "easeInOut",
        delay: 0.1,
      },
    },
  };

  return (
    <svg
      className="byz-cookies"
      width="150"
      height="225"
      viewBox="0 0 150 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.948 122.013C72.4391 115.257 59.5766 119.198 54.5839 122.013C45.1413 139.285 40.7831 202.099 40.4279 208.388C46.9993 212.167 60.3943 210.809 62.6736 210.13C62.3776 202.926 64.1013 194.999 65.1077 191.781C66.8189 186.309 68.1313 182.843 70.392 180.158C71.8049 180.836 72.6809 181.24 74.7125 181.24C74.2685 186.466 74.6551 202.696 75.6023 207.184C79.5333 209.215 93.0569 207.735 96.4117 206.889C97.8621 192.609 99.3331 164.568 99.0744 156.901C98.1645 129.943 89.0097 130.358 83.948 122.013Z"
        fill="white"
      />
      <path
        d="M129.822 42.46C113.459 -2.79624 82.5997 -2.02462 53.5145 1.4318C40.1823 3.53397 27.6927 11.8345 23.1144 15.722C8.73614 33.4727 -6.21261 70.4348 17.579 101.404C54.3336 147.1 92.7172 127.541 107.33 117.542C117.848 110.741 142.995 79.6482 129.822 42.46Z"
        fill="white"
      />
      <path
        d="M40.6923 7.01811C60.3472 26.4575 77.939 36.7831 83.7703 39.1254C92.5026 34.5786 108.645 20.0128 115.562 10.4565C120.633 9.9447 138.828 4.62052 149.177 1.4318C136.376 50.9036 98.334 74.4376 83.7703 79.5455C61.3921 76.3272 15.3444 31.7726 0 3.42947C8.56451 4.50222 27.881 6.09692 40.6923 7.01811Z"
        fill="white"
      />
      <path
        d="M36.8969 124.728C37.3623 134.374 42.6078 137.093 45.7599 138.68C52.0119 138.68 57.08 133.625 57.08 127.389C57.08 121.153 52.0119 116.098 45.7599 116.098C39.508 116.098 38.1207 118.82 36.8969 124.728Z"
        fill="white"
      />
      <path
        d="M49.4183 131.673C51.9629 134.495 52.3634 144.06 52.2456 148.489C40.5831 151.311 56.1214 184.459 42.6078 166.933C29.1585 149.49 34.4398 137.832 39.5054 131.012C42.5684 129.169 46.8737 128.851 49.4183 131.673Z"
        fill="white"
      />
      <path
        d="M42.6078 156.066C44.5204 146.942 54.3826 147.651 59.0746 149.145C65.0742 151.078 64.3777 156.689 62.8198 158.515C63.9715 159.541 63.6521 162.513 61.548 163.614C63.5481 165.515 62.2754 167.596 60.7012 169.527C61.7633 173.45 58.0516 175.938 55.2638 175.757C52.4761 175.575 48.8362 171.246 45.7599 168.545C42.4558 163.656 40.2171 167.471 42.6078 156.066Z"
        fill="white"
      />
      <path
        d="M10.2652 61.4429C10.2652 61.4429 10.8118 60.4478 12.315 59.802C12.9176 59.5432 33.5632 64.3879 50.5588 64.3695C57.8406 70.6583 69.5032 79.9588 83.5636 82.9704C91.9111 80.9922 103.87 70.865 110.145 63.3656C115.657 63.274 120.462 62.5975 122.932 62.4208H124.531C124.531 62.4208 125.729 62.6027 126.129 62.8637C126.899 63.3656 127.047 64.2514 127.047 64.2514C127.135 65.1076 127.069 65.7867 127.047 66.4658C126.734 75.9304 118.522 90.0269 115.473 92.2708C112.572 94.4059 105.941 97.8897 102.981 98.7664C99.5185 99.792 91.3186 102.959 73.7949 102.959C51.7729 102.959 29.512 96.995 23.7108 92.0346C20.1032 88.9499 14.1101 77.3834 12.1066 71.0541C11.238 68.8857 10.1957 64.5431 9.91774 63.4648C9.63979 62.3865 10.0335 61.6676 10.2652 61.4429Z"
        fill="white"
      />
      <path
        d="M74.7125 203.247C73.7416 204.003 74.308 207.873 74.7125 209.714C74.7125 212.902 74.7125 214.851 75.8077 217.331C76.684 219.315 81.1379 221.123 82.4502 221.074H103.958C104.215 220.168 104.542 215.588 103.002 213.368C101.078 210.592 99.2516 209.684 97.9195 208.798C98.6596 207.204 98.0971 202.923 97.4459 202.185C97.2091 202.751 95.3147 204.015 94.397 204.576C93.2919 204.635 86.3989 206.494 84.5518 206.636C82.243 206.813 78.9158 205.137 78.0574 204.576C77.199 204.015 75.9261 202.303 74.7125 203.247Z"
        fill="white"
      />
      <path
        d="M41.1661 203.89C38.5139 206.536 39.2914 209.933 40.0117 211.301C37.4364 212.6 38.206 216.999 38.2948 217.885C38.3836 218.771 38.206 219.863 39.8045 221.133C49.2829 227.18 61.3711 222.403 62.6736 221.133C63.976 219.863 63.9742 215.346 63.5302 213.368C64.0985 210.793 63.2934 208.122 62.8198 207.108C61.912 207.817 54.7292 208.242 53.4504 208.526C49.7207 209.352 44.8958 207.847 44.067 207.108C42.4093 204.51 42.2021 204.717 41.1661 203.89Z"
        fill="white"
      />

      {/* INIZIO BRACCIO SX */}
      <motion.g
        variants={armVariants}
        style={{
          originX: "83px",
          originY: "128px",
        }}
        initial="starting"
        animate={acceptClicked ? "moving" : "starting"}
      >
        <path
          d="M105.827 151.657C95.1847 152.631 89.0838 141.348 87.1257 136.688C92.021 137.068 94.8934 120.216 98.4246 117.329C102.372 118.264 105.827 124.111 104.528 129.339C106.7 134.154 111.409 138.749 117.85 142.329C117.524 143.042 108.809 149.22 105.827 151.657Z"
          fill="white"
        />
        <path
          d="M89.9315 116.488C95.6546 116.246 100.502 120.69 100.758 126.413C101.015 132.136 96.583 136.972 90.86 137.213C85.137 137.455 80.2897 133.012 80.0333 127.289C79.7769 121.565 84.2085 116.73 89.9315 116.488Z"
          fill="white"
        />
        <path
          d="M115.46 134.657C113.327 134.571 110.562 136.147 109.465 136.751C108.322 137.577 101.712 142.533 101.077 143.922C100.284 145.658 98.5765 148.664 100.447 152.84C102.317 157.016 109.465 161.801 113.753 163.776C118.041 165.751 125.415 161.098 126.624 156.586C127.59 152.977 125.33 148.401 123.007 147.306C121.944 146.686 120.152 146.239 118.207 144.665C116.79 143.519 118.395 143.599 120.085 142.38C120.464 142.107 121.507 141.245 121.883 140.856C123.394 139.534 121.744 136.403 120.976 135.96C119.24 134.96 117.593 134.743 115.46 134.657Z"
          fill="white"
        />
        <path
          d="M108.179 149.054C98.0061 149.998 91.1402 139.112 89.2743 134.661C93.9529 135.018 98.1453 130.787 101.524 128.023C101.061 131.134 110.107 140.465 113.704 143.329C113.392 144.012 111.033 146.72 108.179 149.054Z"
          fill="white"
          stroke="black"
          strokeWidth="1.32196"
        />
        <circle
          cx="93.4139"
          cy="127.142"
          r="9.25369"
          transform="rotate(-92.4919 93.4139 127.142)"
          fill="url(#paint0_radial_5571_1545)"
          stroke="black"
          strokeWidth="1.32196"
        />
        <path
          d="M114.033 137.467C111.995 137.387 108.692 138.836 107.643 139.415C106.55 140.206 104.211 142.12 103.603 143.448C102.842 145.108 101.207 147.984 102.989 151.974C104.772 155.963 110.093 159.279 114.189 161.162C118.285 163.044 123.011 159.612 124.171 155.298C125.099 151.847 120.893 148.897 118.674 147.853C117.659 147.261 116.141 146.503 114.792 144.531C114.063 143.464 115.396 142.549 116.409 142.261C118.194 141.755 119.16 141.447 119.762 140.763C121.208 139.498 119.879 137.952 119.092 137.808C117.191 137.726 116.072 137.546 114.033 137.467Z"
          fill="url(#paint1_linear_5571_1545)"
          stroke="black"
          strokeWidth="1.32329"
          strokeLinecap="round"
        />
      </motion.g>
      {/* FINE BRACCIO SX */}

      <path
        d="M86.358 120.039C75.371 113.573 63.0918 117.345 58.3255 120.039C49.311 136.57 42.9529 201.169 42.6138 207.188C48.8872 210.805 56.8561 208.488 59.032 207.838C58.7494 200.943 62.5925 188.877 63.5533 185.797C65.187 180.559 68.979 176.97 69.7137 175.642C72.257 176.707 74.6307 177.366 77.5413 176.726C77.1174 181.727 77.7391 202.469 78.6434 206.764C82.3961 208.709 89.7396 207.574 92.9422 206.764C94.3269 193.097 96.7218 164.082 96.3332 157.284C95.4572 141.958 91.1902 128.027 86.358 120.039Z"
        fill="white"
        stroke="black"
        strokeWidth="1.13034"
      />
      <path
        d="M128.469 46.6212C112.847 3.30659 74.3348 0.12657 57.0312 3.95089C44.3036 5.96287 32.3803 13.9073 28.0097 17.628C15.8302 29.4401 -4.15366 64.5582 22.7253 99.6343C60.7561 140.658 93.7432 123.383 107.694 113.813C117.735 107.304 135.386 82.7922 128.469 46.6212Z"
        fill="white"
        stroke="#071C36"
        strokeWidth="1.13034"
      />
      <path
        d="M46.0334 10.7256C64.7971 29.331 80.6219 38.4661 86.1888 40.7079C94.525 36.3561 109.935 22.4152 116.538 13.2689C121.38 12.7791 133.166 11.206 143.045 8.15412C128.577 46.3879 100.092 74.5051 86.1888 79.3938C64.8253 76.3137 23.0687 31.4297 6.21718 6.54338C14.3933 7.57011 33.8031 9.84398 46.0334 10.7256Z"
        fill="url(#paint2_linear_5571_1545)"
        stroke="#071C36"
        strokeWidth="1.13034"
      />
      <circle
        r="10.2319"
        transform="matrix(-1 0 0 1 49.901 125.184)"
        fill="url(#paint3_radial_5571_1545)"
        stroke="black"
        strokeWidth="1.14965"
      />
      <path
        d="M53.3934 129.285C55.8226 131.986 56.205 141.14 56.0925 145.379C44.9588 148.08 47.3201 158.885 47.3201 164.962C31.1254 153.617 36.7485 137.726 41.5845 131.198C44.5086 129.435 50.9642 126.584 53.3934 129.285Z"
        fill="white"
        stroke="black"
        strokeWidth="1.14965"
      />
      <path
        d="M68.3082 142.248C67.3813 142.7 66.17 142.889 65.6802 142.926C63.9281 142.926 62.8543 143.604 59.9437 146.232C57.6152 148.335 57.2403 149.915 57.3439 150.443C57.3439 150.754 57.2422 151.698 56.8352 152.986C56.4283 154.275 56.4961 156.594 56.5809 157.592C56.6092 158.242 56.7335 159.7 57.0048 160.333C57.3439 161.125 56.8635 160.814 57.3439 162.538C57.7282 163.917 59.1242 165.335 59.7741 165.872C60.019 166.108 60.7462 166.793 61.6957 167.652C62.6452 168.511 64.2766 169.235 64.9737 169.489C66.8388 169.97 70.9645 170.795 72.547 170.252C74.1294 169.71 75.5801 169.517 76.1075 169.489C76.6162 169.423 77.7861 169.229 78.3965 168.981C79.1595 168.67 79.7812 167.85 80.6007 166.833C81.2562 166.019 82.3621 164.911 82.8331 164.459C83.2946 163.856 84.2177 162.464 84.2177 161.718C84.2177 160.972 84.8959 158.939 85.235 158.016C85.3292 156.971 85.4611 154.761 85.235 154.286C84.9525 153.693 84.7264 152.393 84.2177 150.81C83.8108 149.544 83.1251 148.474 82.8331 148.098C82.5034 147.57 81.6688 146.351 80.968 145.696C80.092 144.876 79.5833 144.933 78.5095 144.085C77.4357 143.237 74.9207 142.728 74.412 142.587C73.9034 142.446 73.9034 142.248 72.1514 141.739C70.3993 141.231 69.4668 141.683 68.3082 142.248Z"
        fill="#BB844C"
      />
      <path
        d="M76.2793 150.745C76.1663 151.853 76.8539 152.431 77.2118 152.582C77.9466 152.921 78.5117 153.006 79.1899 152.695C79.7324 152.446 79.9811 151.612 80.0376 151.225C80.0847 150.839 79.9924 149.937 79.2464 149.417C78.5004 148.897 77.824 148.974 77.5791 149.078C77.193 149.172 76.3923 149.637 76.2793 150.745Z"
        fill="#55382F"
      />
      <path
        d="M68.706 155.436C68.6608 155.73 69.0074 155.973 69.1864 156.058C69.2334 156.086 69.3446 156.165 69.4124 156.255C69.5932 156.549 69.9775 156.736 70.1471 156.792C70.2884 156.83 70.6444 156.866 70.9383 156.708C71.2322 156.549 71.4752 156.566 71.56 156.595C71.7767 156.538 72.2156 156.318 72.2382 155.888C72.2608 155.459 72.436 155.069 72.5208 154.927C72.5208 154.777 72.4304 154.373 72.0687 153.966C71.707 153.56 71.0325 153.382 70.7405 153.345C70.505 153.335 69.9323 153.39 69.5254 153.684C69.0168 154.051 69.1015 154.362 68.9602 154.814C68.8189 155.266 68.7625 155.069 68.706 155.436Z"
        fill="#55382F"
      />
      <path
        d="M65.9366 147.326C66.0722 147.755 66.6713 148.258 66.9539 148.456C67.6321 148.739 67.8299 148.513 68.1125 148.371C68.3386 148.258 68.64 148.249 68.7624 148.258C68.9791 148.296 69.4519 148.213 69.6102 147.58C69.7684 146.947 69.6761 146.487 69.6102 146.337C69.5725 146.252 69.4463 146.003 69.2428 145.687C68.9885 145.291 68.6211 145.037 68.4233 145.037C68.2651 145.037 67.9618 144.961 67.8299 144.924C67.5379 144.877 66.8408 144.918 66.3887 145.461C65.8235 146.139 65.767 146.789 65.9366 147.326Z"
        fill="#55382F"
      />
      <path
        d="M59.4654 153.288C59.7593 152.927 60.1342 152.855 60.2849 152.864C60.6797 152.751 61.0711 152.817 61.2174 152.864C61.2645 152.912 61.4378 153.062 61.7543 153.288C62.0708 153.514 62.1876 153.985 62.2065 154.193V155.153C62.2065 155.634 61.7543 155.86 61.5565 155.973C61.3983 156.063 60.6428 156.011 60.2849 155.973C60.0494 156.011 59.5389 156.086 59.3806 156.086C59.1828 156.086 58.9002 155.747 59.0415 155.295C59.1546 154.933 59.1828 154.409 59.1828 154.193C59.1545 154.042 59.1715 153.65 59.4654 153.288Z"
        fill="#55382F"
      />
      <path
        d="M63.0825 160.946C63.4668 160.811 63.7889 160.701 63.902 160.664C64.2128 160.558 64.4671 160.664 64.6649 160.664C64.8232 160.664 65.0888 161.154 65.2019 161.398C65.2866 161.549 65.5523 161.924 65.9366 162.218C66.3209 162.512 66.1909 163.037 66.0779 163.264C66.0308 163.358 65.8857 163.625 65.6823 163.942C65.4279 164.337 64.8628 164.337 64.2411 164.337C63.7437 164.337 63.3368 164.074 63.1955 163.942C63.0919 163.819 62.8112 163.512 62.5173 163.264C62.2234 163.015 62.2818 162.331 62.3477 162.02C62.4325 161.719 62.6982 161.082 63.0825 160.946Z"
        fill="#55382F"
      />
      <path
        d="M71.7578 163.631C72.2326 163.382 73.086 163.527 73.4533 163.631C73.491 163.584 73.6794 163.631 74.1315 164.196C74.5837 164.761 74.4707 165.694 74.3576 166.089C74.1975 166.325 73.7755 166.83 73.3686 166.965C72.8599 167.135 72.8599 166.796 72.2382 166.965C71.7409 167.101 71.3716 166.909 71.2492 166.796C71.1832 166.749 71.0062 166.542 70.8253 166.089C70.6444 165.637 70.8253 164.733 70.9383 164.337C71.0137 164.205 71.2831 163.88 71.7578 163.631Z"
        fill="#55382F"
      />
      <path
        d="M74.412 142.587C73.9034 142.446 73.9034 142.248 72.1514 141.74C76.9768 147.588 83.8114 161.477 72.547 170.253C74.1295 169.71 75.5801 169.518 76.1076 169.49C76.6162 169.424 77.7861 169.23 78.3965 168.981C79.1595 168.67 79.7812 167.851 80.6007 166.833C81.2563 166.019 82.3621 164.912 82.8331 164.46C83.2946 163.857 84.2178 162.464 84.2178 161.718C84.2178 160.972 84.896 158.94 85.2351 158.017C85.3293 156.971 85.4611 154.761 85.2351 154.286C84.9525 153.693 84.7264 152.393 84.2178 150.811C83.8108 149.545 83.1251 148.475 82.8331 148.098C82.5034 147.57 81.6688 146.351 80.968 145.696C80.092 144.876 79.5834 144.933 78.5095 144.085C77.4357 143.237 74.9207 142.729 74.412 142.587Z"
        fill="#9F7243"
      />
      <path
        d="M79.3025 160.24C79.506 160.398 79.7453 160.645 79.8395 160.749C79.9337 160.946 80.2125 161.365 80.5742 161.455C80.9359 161.546 81.1205 161.493 81.1676 161.455C81.2524 161.389 81.5576 161.189 82.1001 160.918C82.7783 160.579 82.6936 159.986 82.7783 159.59C82.8631 159.194 82.7783 158.799 82.2132 158.036C81.648 157.273 80.5177 157.301 80.3199 157.584C80.122 157.866 79.4156 158.121 79.0482 158.799C78.6809 159.477 79.0482 160.042 79.3025 160.24Z"
        fill="#442C24"
      />
      <path
        d="M79.246 149.417C78.4999 148.897 77.8236 148.974 77.5787 149.078C77.3875 149.125 77.0665 149.282 76.8059 149.544C77.032 150.099 77.5689 151.53 77.908 152.85C78.3295 152.943 78.7499 152.896 79.1894 152.695C79.732 152.446 79.9807 151.612 80.0372 151.226C80.0843 150.839 79.992 149.937 79.246 149.417Z"
        fill="#442C24"
      />

      {/* INIZIO BISCOTTO INTERO */}
      <motion.g
        className={`whole-cookie ${acceptClicked && "animate"}`}
        variants={armVariants}
        style={{
          originX: "83px",
          originY: "128px",
        }}
        initial="starting"
        animate={acceptClicked ? "moving" : "starting"}
      >
        <path
          d="M126.97 135.363C125.95 135.512 124.738 135.322 124.26 135.209C122.591 134.676 121.362 134.995 117.79 136.612C114.932 137.906 114.094 139.298 114.032 139.832C113.937 140.128 113.553 140.996 112.773 142.1C111.993 143.203 111.352 145.433 111.129 146.41C110.958 147.037 110.632 148.464 110.698 149.15C110.78 150.007 110.417 149.564 110.35 151.353C110.297 152.783 111.195 154.559 111.65 155.269C111.812 155.567 112.296 156.442 112.939 157.549C113.582 158.657 114.916 159.842 115.502 160.297C117.132 161.322 120.811 163.364 122.484 163.329C124.156 163.294 125.597 163.552 126.108 163.686C126.612 163.778 127.786 163.949 128.443 163.898C129.264 163.834 130.106 163.243 131.196 162.523C132.068 161.947 133.459 161.229 134.045 160.941C134.668 160.508 135.971 159.463 136.198 158.752C136.425 158.041 137.69 156.311 138.294 155.535C138.702 154.568 139.5 152.503 139.43 151.982C139.341 151.331 139.521 150.024 139.519 148.362C139.516 147.032 139.189 145.804 139.025 145.356C138.872 144.753 138.448 143.338 137.98 142.5C137.395 141.453 136.893 141.352 136.128 140.218C135.363 139.083 133.123 137.833 132.681 137.544C132.24 137.255 132.3 137.066 130.786 136.048C129.272 135.031 128.246 135.177 126.97 135.363Z"
          fill="#BB844C"
        />
        <path
          d="M131.976 145.883C131.532 146.904 132.011 147.664 132.306 147.916C132.902 148.463 133.415 148.716 134.155 148.626C134.748 148.554 135.239 147.835 135.41 147.484C135.573 147.131 135.759 146.243 135.207 145.521C134.655 144.799 133.987 144.666 133.722 144.69C133.326 144.663 132.421 144.862 131.976 145.883Z"
          fill="#55382F"
        />
        <path
          d="M123.335 148.046C123.202 148.312 123.458 148.649 123.603 148.784C123.639 148.826 123.721 148.935 123.758 149.042C123.841 149.377 124.15 149.671 124.295 149.777C124.418 149.856 124.746 149.998 125.074 149.937C125.402 149.876 125.629 149.966 125.701 150.018C125.924 150.031 126.409 149.954 126.562 149.552C126.714 149.15 127 148.831 127.123 148.723C127.169 148.579 127.206 148.168 126.985 147.67C126.764 147.172 126.176 146.798 125.909 146.673C125.688 146.593 125.126 146.471 124.649 146.627C124.052 146.822 124.038 147.144 123.766 147.531C123.494 147.919 123.5 147.713 123.335 148.046Z"
          fill="#55382F"
        />
        <path
          d="M123.165 139.478C123.164 139.928 123.581 140.59 123.79 140.864C124.35 141.34 124.608 141.185 124.92 141.136C125.169 141.097 125.459 141.18 125.573 141.226C125.768 141.328 126.244 141.393 126.587 140.838C126.931 140.283 126.983 139.817 126.966 139.654C126.956 139.562 126.911 139.286 126.814 138.923C126.692 138.469 126.419 138.115 126.231 138.054C126.08 138.006 125.814 137.842 125.7 137.766C125.436 137.632 124.76 137.46 124.164 137.839C123.419 138.313 123.167 138.915 123.165 139.478Z"
          fill="#55382F"
        />
        <path
          d="M115.186 143.188C115.576 142.933 115.955 142.979 116.096 143.033C116.506 143.046 116.859 143.228 116.984 143.317C117.015 143.376 117.134 143.573 117.367 143.884C117.599 144.196 117.567 144.68 117.522 144.883L117.23 145.799C117.083 146.256 116.584 146.334 116.361 146.381C116.183 146.419 115.479 146.139 115.15 145.994C114.914 145.959 114.405 145.875 114.254 145.827C114.066 145.767 113.9 145.358 114.172 144.97C114.39 144.66 114.576 144.169 114.642 143.963C114.661 143.811 114.796 143.443 115.186 143.188Z"
          fill="#55382F"
        />
        <path
          d="M116.301 151.583C116.708 151.571 117.048 151.565 117.167 151.564C117.496 151.558 117.706 151.736 117.894 151.796C118.045 151.844 118.149 152.391 118.182 152.659C118.217 152.828 118.356 153.266 118.632 153.663C118.909 154.06 118.625 154.521 118.449 154.702C118.375 154.778 118.156 154.988 117.865 155.228C117.503 155.527 116.964 155.355 116.372 155.166C115.898 155.015 115.591 154.64 115.497 154.471C115.435 154.323 115.261 153.945 115.057 153.619C114.853 153.292 115.117 152.659 115.274 152.383C115.447 152.121 115.893 151.596 116.301 151.583Z"
          fill="#55382F"
        />
        <path
          d="M123.747 156.781C124.275 156.689 125.044 157.087 125.362 157.297C125.413 157.264 125.578 157.366 125.836 158.042C126.095 158.718 125.704 159.572 125.475 159.914C125.251 160.09 124.696 160.442 124.267 160.448C123.731 160.454 123.834 160.131 123.19 160.104C122.675 160.081 122.382 159.786 122.299 159.641C122.251 159.576 122.145 159.325 122.111 158.839C122.076 158.353 122.524 157.547 122.752 157.205C122.864 157.102 123.219 156.874 123.747 156.781Z"
          fill="#55382F"
        />
        <path
          d="M132.681 137.544C132.24 137.254 132.3 137.066 130.786 136.048C133.602 143.087 135.885 158.398 122.484 163.329C124.157 163.293 125.597 163.552 126.108 163.686C126.612 163.778 127.786 163.949 128.443 163.898C129.264 163.834 130.106 163.243 131.196 162.523C132.068 161.947 133.459 161.229 134.045 160.941C134.668 160.508 135.972 159.462 136.199 158.752C136.426 158.041 137.69 156.311 138.294 155.535C138.702 154.568 139.501 152.503 139.43 151.982C139.341 151.331 139.522 150.024 139.519 148.362C139.516 147.032 139.189 145.804 139.025 145.356C138.872 144.753 138.448 143.338 137.98 142.5C137.395 141.453 136.893 141.352 136.128 140.218C135.364 139.083 133.123 137.833 132.681 137.544Z"
          fill="#9F7243"
        />
        <path
          d="M131.967 155.848C132.112 156.06 132.265 156.368 132.323 156.496C132.353 156.713 132.491 157.196 132.808 157.392C133.125 157.588 133.317 157.594 133.373 157.573C133.474 157.536 133.826 157.438 134.425 157.345C135.174 157.229 135.274 156.638 135.475 156.287C135.676 155.935 135.716 155.533 135.41 154.634C135.104 153.735 134.019 153.418 133.744 153.627C133.47 153.836 132.719 153.863 132.163 154.397C131.607 154.932 131.785 155.582 131.967 155.848Z"
          fill="#442C24"
        />
        <path
          d="M135.207 145.521C134.655 144.799 133.987 144.666 133.722 144.691C133.526 144.677 133.172 144.729 132.844 144.899C132.891 145.497 132.966 147.024 132.888 148.384C133.261 148.6 133.676 148.684 134.156 148.626C134.748 148.555 135.239 147.835 135.41 147.485C135.573 147.131 135.76 146.243 135.207 145.521Z"
          fill="#442C24"
        />
      </motion.g>
      {/* FINE BISCOTTO INTERO */}

      {/* INIZIO BISCOTTO MORSO */}
      <motion.g
        className={`bitten-cookie ${acceptClicked && "animate"}`}
        variants={armVariants}
        style={{
          originX: "83px",
          originY: "128px",
        }}
        initial="starting"
        animate={acceptClicked ? "moving" : "starting"}
      >
        <svg
          width="41"
          height="34"
          viewBox="0 0 41 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          x="110"
          y="130"
        >
          <path
            d="M26.4442 10.3417C26.1078 9.81848 25.2578 8.61035 24.5486 7.96375C23.8959 7.44878 22.3787 6.89976 21.5324 8.8234C20.9082 9.69718 20.4713 10.6005 18.9405 10.1668C17.7158 9.81988 16.3716 9.82507 15.8526 9.87103C15.6453 10.047 15.0771 10.4492 14.463 10.6495C13.849 10.8498 12.6632 10.7876 12.1471 10.7315C11.6982 10.3066 10.4896 9.5127 9.2465 9.73601C8.00345 9.95933 6.84202 10.5578 6.41669 10.8291C6.21238 11.0341 5.48839 11.448 4.22696 11.4637C2.96552 11.4794 1.54143 12.5027 0.987057 13.0124C0.991025 13.3232 0.901352 14.2683 0.510914 15.5619C0.120475 16.8556 0.217897 19.1737 0.315412 20.171C0.351966 20.8205 0.494909 22.2769 0.77425 22.9064C1.12343 23.6932 0.639102 23.3886 1.14146 25.1061C1.54335 26.4801 2.95732 27.8807 3.61407 28.4093C3.86196 28.6416 4.59784 29.318 5.55822 30.1649C6.5186 31.0117 8.15916 31.7143 8.85939 31.9597C10.7304 32.4162 14.8664 33.1886 16.4418 32.6259C18.0172 32.0632 19.4652 31.8525 19.9923 31.8175C20.5001 31.7451 21.6674 31.5362 22.2746 31.2797C23.0335 30.9591 23.6447 30.1318 24.4512 29.1041C25.0963 28.2819 26.1879 27.1602 26.6531 26.7021C27.1069 26.0934 28.0122 24.6895 28.0027 23.9435C27.9931 23.1976 28.6453 21.1564 28.9726 20.229C29.0535 19.1823 29.1571 16.971 28.925 16.4992C28.6349 15.9094 28.3922 14.6125 27.8634 13.0366C27.4404 11.776 26.741 10.7147 26.4442 10.3417Z"
            fill="#BB844C"
          />
          <path
            d="M19.9249 13.0722C19.826 14.1813 20.5209 14.7508 20.8808 14.897C21.6198 15.2267 22.1859 15.3042 22.8601 14.9848C23.3995 14.7292 23.6375 13.8915 23.6891 13.5046C23.7312 13.1178 23.6274 12.2167 22.8748 11.7063C22.1222 11.1959 21.4469 11.2818 21.2033 11.3885C20.8184 11.4876 20.0238 11.9631 19.9249 13.0722Z"
            fill="#55382F"
          />
          <path
            d="M12.4121 17.8595C12.3706 18.1539 12.7203 18.3925 12.9004 18.475C12.9478 18.5026 13.0599 18.5803 13.1289 18.6699C13.3134 18.9614 13.7001 19.143 13.8704 19.1974C14.0122 19.2332 14.3686 19.2645 14.6605 19.1025C14.9523 18.9405 15.1956 18.9544 15.2807 18.9815C15.4966 18.9223 15.9327 18.6962 15.9498 18.2665C15.9669 17.8367 16.1371 17.4445 16.2201 17.3021C16.2182 17.1514 16.1226 16.7495 15.7557 16.3472C15.3889 15.9449 14.7122 15.7765 14.4198 15.7425C14.1842 15.7361 13.6122 15.7981 13.2091 16.0971C12.7052 16.4709 12.7939 16.7807 12.6584 17.2346C12.5229 17.6885 12.4639 17.4914 12.4121 17.8595Z"
            fill="#55382F"
          />
          <path
            d="M3.14482 15.83C3.43407 15.4646 3.80802 15.3882 3.95884 15.3957C4.35215 15.2776 4.74435 15.3385 4.89129 15.3838C4.93898 15.4303 5.11421 15.5788 5.43357 15.8008C5.75292 16.0228 5.87573 16.4922 5.89721 16.6992L5.90948 17.6599C5.91561 18.1403 5.4664 18.3721 5.27005 18.4876C5.11297 18.5801 4.35692 18.537 3.99853 18.5039C3.76355 18.5445 3.25404 18.6264 3.0958 18.6284C2.89801 18.631 2.61112 18.2955 2.74663 17.8416C2.85503 17.4785 2.87658 16.9544 2.87381 16.7378C2.84363 16.5875 2.85558 16.1954 3.14482 15.83Z"
            fill="#55382F"
          />
          <path
            d="M6.85938 23.4412C7.24193 23.3007 7.56266 23.1873 7.6752 23.1482C7.98467 23.0387 8.24032 23.141 8.43812 23.1385C8.59635 23.1365 8.86821 23.6228 8.98437 23.8663C9.07106 24.0159 9.34145 24.3874 9.72949 24.6763C10.1175 24.9653 9.99426 25.4925 9.88412 25.72C9.83823 25.8148 9.69659 26.0841 9.49719 26.4032C9.24793 26.802 8.68281 26.8093 8.06117 26.8172C7.56386 26.8235 7.15361 26.565 7.01064 26.435C6.90547 26.3138 6.62087 26.0104 6.32383 25.7655C6.0268 25.5206 6.07646 24.836 6.13842 24.5244C6.21934 24.2219 6.47682 23.5818 6.85938 23.4412Z"
            fill="#55382F"
          />
          <path
            d="M15.5683 26.0148C16.0398 25.7601 16.895 25.8943 17.2637 25.9932C17.3008 25.9456 17.4897 25.9903 17.949 26.5496C18.4084 27.109 18.3072 28.0429 18.1993 28.4399C18.0422 28.6774 17.6266 29.1877 17.2215 29.3285C16.715 29.5045 16.7107 29.1655 16.0912 29.3429C15.5956 29.4849 15.224 29.2975 15.1001 29.186C15.0336 29.1398 14.8539 28.9348 14.6672 28.485C14.4806 28.0352 14.6499 27.1287 14.7579 26.7317C14.8316 26.5989 15.0968 26.2695 15.5683 26.0148Z"
            fill="#55382F"
          />
          <path
            d="M26.4442 10.3413C26.1078 9.81805 25.2578 8.60992 24.5486 7.96333C23.8958 7.44836 22.3787 6.89933 21.5324 8.82298C20.9082 9.69675 20.4712 10.6001 18.9405 10.1664C19.957 11.5468 22.0811 15.279 22.4451 19.1642C22.9001 24.0206 19.2759 30.7989 16.4418 32.6255C18.0172 32.0628 19.4652 31.8521 19.9923 31.8171C20.5001 31.7447 21.6674 31.5357 22.2746 31.2793C23.0335 30.9587 23.6447 30.1314 24.4512 29.1037C25.0963 28.2815 26.1879 27.1598 26.6531 26.7016C27.1069 26.093 28.0122 24.6891 28.0027 23.9431C27.9931 23.1971 28.6453 21.1559 28.9726 20.2286C29.0535 19.1819 29.1571 16.9706 28.925 16.4987C28.6349 15.909 28.3922 14.6121 27.8634 13.0362C27.4403 11.7755 26.741 10.7143 26.4442 10.3413Z"
            fill="#9F7243"
          />
          <path
            d="M23.0695 22.5279C23.2749 22.6836 23.5173 22.9273 23.6128 23.0297C23.7095 23.2263 23.9937 23.6409 24.3565 23.7267C24.7193 23.8125 24.9033 23.7574 24.9499 23.7191C25.0338 23.6521 25.3364 23.4485 25.8755 23.1703C26.5493 22.8226 26.457 22.2303 26.5367 21.8336C26.6164 21.437 26.5266 21.0425 25.9517 20.2868C25.3769 19.5311 24.247 19.5738 24.0528 19.8588C23.8586 20.1439 23.1554 20.4073 22.7968 21.0901C22.4381 21.7729 22.8126 22.3334 23.0695 22.5279Z"
            fill="#442C24"
          />
          <path
            d="M22.8747 11.7063C22.1221 11.1959 21.4468 11.2818 21.2033 11.3885C21.0127 11.4376 20.6937 11.5995 20.4365 11.8643C20.3501 11.9531 20.2184 12.1213 20.1919 12.2065C20.461 12.8744 21.1051 14.0397 21.4632 15.0942C21.8858 15.1813 22.4231 15.1918 22.86 14.9847C23.3994 14.7292 23.6374 13.8915 23.689 13.5046C23.7311 13.1178 23.6273 12.2167 22.8747 11.7063Z"
            fill="#442C24"
          />
          <path
            d="M27.7568 2.24521C27.7154 2.53965 28.0651 2.77823 28.2451 2.86071C28.2925 2.88837 28.4047 2.96607 28.4736 3.05562C28.6582 3.34718 29.0449 3.52876 29.2151 3.58311C29.3569 3.61898 29.7134 3.65022 30.0052 3.48824C30.2971 3.32625 30.5403 3.3401 30.6254 3.36728C30.8413 3.308 31.2774 3.08199 31.2946 2.65221C31.3117 2.22243 31.4819 1.83026 31.5649 1.68789C31.5629 1.53719 31.4674 1.13523 31.1005 0.732953C30.7336 0.330682 30.057 0.16222 29.7645 0.128273C29.5289 0.12186 28.957 0.1838 28.5538 0.48286C28.0499 0.856685 28.1386 1.16642 28.0031 1.62032C27.8676 2.07423 27.8086 1.87715 27.7568 2.24521Z"
            fill="#BB844C"
          />
          <path
            d="M35.9756 4.64364C35.9341 4.93809 36.2838 5.17666 36.4639 5.25915C36.5113 5.2868 36.6234 5.3645 36.6924 5.45406C36.8769 5.74561 37.2636 5.9272 37.4339 5.98154C37.5757 6.01742 37.9321 6.04866 38.224 5.88667C38.5158 5.72469 38.759 5.73854 38.8442 5.76571C39.0601 5.70643 39.4962 5.48043 39.5133 5.05065C39.5304 4.62087 39.7006 4.22869 39.7836 4.08633C39.7817 3.93563 39.6861 3.53366 39.3192 3.13139C38.9524 2.72912 38.2757 2.56066 37.9833 2.52671C37.7477 2.5203 37.1757 2.58224 36.7726 2.8813C36.2686 3.25512 36.3574 3.56486 36.2219 4.01876C36.0864 4.47266 36.0274 4.27559 35.9756 4.64364Z"
            fill="#BB844C"
          />
          <path
            d="M32.8708 14.3204C32.468 14.525 31.9528 14.1399 31.7455 13.9218C31.6824 13.8672 31.5155 13.7443 31.3528 13.6893C30.8428 13.5677 30.3913 13.1013 30.2293 12.8833C30.1079 12.6972 29.8873 12.2002 29.9764 11.7009C30.0655 11.2016 29.9257 10.8588 29.8447 10.7499C29.8236 10.4103 29.9336 9.67213 30.5429 9.43578C31.1522 9.19944 31.6321 8.76157 31.7958 8.57218C32.0134 8.5007 32.6383 8.44004 33.3972 8.7692C34.1562 9.09836 34.7317 9.98796 34.9246 10.3916C35.0499 10.7271 35.2426 11.5797 35.0113 12.3065C34.7223 13.215 34.2334 13.2401 33.6477 13.6585C33.062 14.0769 33.3743 14.0646 32.8708 14.3204Z"
            fill="#BB844C"
          />
        </svg>
      </motion.g>

      {/* FINE BISCOTTO MORSO */}
      <path
        d="M46.8918 152.631C48.7176 143.899 58.1326 144.577 62.6118 146.007C68.3394 147.857 67.6745 153.228 66.1872 154.975C67.2867 155.957 66.9817 158.801 64.9731 159.855C66.8825 161.675 65.6675 163.667 64.1647 165.515C65.1786 169.269 61.6352 171.651 58.9739 171.477C56.3125 171.303 52.0601 170.696 49.1233 168.111C45.969 163.432 44.6095 163.547 46.8918 152.631Z"
        fill="url(#paint4_linear_5571_1545)"
      />
      <path
        d="M64.1647 165.515C65.1786 169.269 61.6352 171.651 58.9739 171.477C56.3125 171.303 52.0601 170.696 49.1233 168.111C45.969 163.432 44.6095 163.547 46.8918 152.631C48.7176 143.899 58.1326 144.577 62.6118 146.007C68.3394 147.857 67.6745 153.228 66.1872 154.975M64.1647 165.515C65.6675 163.667 66.8825 161.675 64.9731 159.855M64.1647 165.515C62.6166 165.5 59.1327 165.291 57.582 164.575M64.9731 159.855C66.9817 158.801 67.2867 155.957 66.1872 154.975M64.9731 159.855C63.3045 159.96 59.5363 160.007 57.812 159.348M66.1872 154.975C64.205 154.864 59.9108 154.491 58.5916 153.89"
        stroke="black"
        strokeWidth="1.14965"
      />
      <path
        d="M16.0167 62.0678C16.0167 62.0678 16.5386 61.1153 17.9736 60.4972C18.5488 60.2495 38.2582 64.8864 54.4831 64.8687C61.4347 70.8878 72.5685 79.7893 85.9913 82.6716C93.9602 80.7783 105.377 71.0856 111.367 63.908C116.629 63.8203 121.216 63.1728 123.575 63.0037H125.101C125.101 63.0037 126.245 63.1778 126.627 63.4275C127.361 63.9079 127.502 64.7557 127.502 64.7557C127.587 65.5752 127.524 66.2251 127.502 66.8751C127.204 75.9337 119.364 89.4254 116.454 91.5731C113.684 93.6165 107.354 96.9509 104.528 97.79C101.223 98.7715 93.3946 101.803 76.6655 101.803C55.6422 101.803 34.3908 96.0946 28.8526 91.347C25.4086 88.3947 19.6873 77.3244 17.7746 71.2666C16.9454 69.1913 15.9504 65.035 15.685 64.0029C15.4197 62.9708 15.7956 62.2828 16.0167 62.0678Z"
        fill="url(#paint5_linear_5571_1545)"
        stroke="#071C36"
        strokeWidth="1.13034"
      />
      <path
        d="M77.7939 202.997C76.8671 203.72 77.4077 207.424 77.7939 209.185C77.7939 212.237 77.7939 214.102 78.8395 216.476C79.676 218.375 82.3907 218.755 83.6435 218.708L101.249 218.341C101.493 217.474 101.616 215.21 100.146 213.085C98.3096 210.429 95.6533 209.44 94.3817 208.592C95.0882 207.066 94.5513 202.968 93.9296 202.262C93.7035 202.804 91.895 204.014 91.0189 204.551C89.964 204.607 87.4132 204.754 85.6498 204.89C83.4457 205.059 81.8067 204.805 80.9872 204.268C80.1677 203.731 78.9525 202.092 77.7939 202.997Z"
        fill="url(#paint6_linear_5571_1545)"
      />
      <path
        d="M43.3186 202.884C40.7866 205.415 41.5289 208.667 42.2165 209.976C39.758 211.22 40.4927 215.43 40.5775 216.278C40.6623 217.126 40.4927 218.171 42.0187 219.387C50.6941 224.388 59.1716 220.178 60.415 218.963C61.6584 217.748 60.2737 212.831 59.8498 210.937C60.3924 208.473 59.6237 205.917 59.1716 204.946C58.305 205.625 56.2666 207.049 55.0459 207.32C51.4853 208.111 46.8792 206.67 46.0879 205.964C44.5054 203.477 44.3076 203.675 43.3186 202.884Z"
        fill="url(#paint7_linear_5571_1545)"
      />
      <path
        d="M77.7939 202.997C76.8671 203.72 77.4077 207.424 77.7939 209.185C77.7939 212.237 77.7939 214.102 78.8395 216.476C79.676 218.375 82.3907 218.755 83.6435 218.708L101.249 218.341C101.493 217.474 101.616 215.21 100.146 213.085C98.3096 210.429 95.6533 209.44 94.3817 208.592C95.0882 207.066 94.5513 202.968 93.9296 202.262C93.7035 202.804 91.895 204.014 91.0189 204.551C89.964 204.607 87.4132 204.754 85.6498 204.89C83.4457 205.059 81.8067 204.805 80.9872 204.268C80.1677 203.731 78.9525 202.092 77.7939 202.997Z"
        stroke="black"
        strokeWidth="1.13034"
      />
      <path
        d="M43.3186 202.884C40.7866 205.415 41.5289 208.667 42.2165 209.976C39.758 211.22 40.4927 215.43 40.5775 216.278C40.6623 217.126 40.4927 218.171 42.0187 219.387C50.6941 224.388 59.1716 220.178 60.415 218.963C61.6584 217.748 60.2737 212.831 59.8498 210.937C60.3924 208.473 59.6237 205.917 59.1716 204.946C58.305 205.625 56.2666 207.049 55.0459 207.32C51.4853 208.111 46.8792 206.67 46.0879 205.964C44.5054 203.477 44.3076 203.675 43.3186 202.884Z"
        stroke="black"
        strokeWidth="1.13034"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5571_1545"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(93.4139 127.142) rotate(90) scale(9.91467)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_5571_1545"
          x1="116.82"
          y1="158.411"
          x2="111.78"
          y2="138.496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5571_1545"
          x1="74.6311"
          y1="6.54338"
          x2="74.6311"
          y2="79.3938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_5571_1545"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.8068 10.8068) rotate(90) scale(10.8068)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_5571_1545"
          x1="50.8362"
          y1="145.151"
          x2="57.9574"
          y2="158.009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#021E34" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5571_1545"
          x1="71.5507"
          y1="-6.2014"
          x2="71.5507"
          y2="101.831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4B4B4" />
          <stop offset="1" stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5571_1545"
          x1="70.9039"
          y1="202.262"
          x2="70.9039"
          y2="221.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5571_1545"
          x1="70.9039"
          y1="202.262"
          x2="70.9039"
          y2="221.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function BittenCookie() {
  return (
    <svg
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M27.4383 17.0388C26.8605 16.8086 25.4665 16.3197 24.5136 16.2057C23.6831 16.1661 22.1295 16.6013 22.559 18.6585C22.559 19.7323 22.7286 20.7214 21.2309 21.2583C20.0327 21.6878 18.9419 22.4734 18.5463 22.8125C18.4799 23.0762 18.2513 23.7337 17.8681 24.2537C17.4849 24.7736 16.4839 25.4123 16.0313 25.6666C15.419 25.5818 13.9741 25.6384 13.0924 26.5426C12.2107 27.4469 11.6135 28.609 11.4251 29.077C11.378 29.3625 11.0295 30.1202 10.0122 30.8662C8.99491 31.6122 8.43096 33.2727 8.27615 34.0097C8.46005 34.2603 8.93642 35.0814 9.37071 36.361C9.80499 37.6407 11.2317 39.4702 11.8907 40.225C12.2981 40.7323 13.261 41.8343 13.8542 42.1842C14.5957 42.6215 14.0245 42.6551 15.4316 43.7606C16.5573 44.645 18.522 44.9628 19.3636 45.0111C19.7004 45.0561 20.6924 45.1787 21.9661 45.3096C23.2398 45.4405 24.9831 45.0585 25.6956 44.8511C27.4834 44.135 31.2978 42.3594 32.2527 40.9858C33.2075 39.6121 34.2633 38.599 34.6719 38.2641C35.0429 37.91 35.8713 37.0615 36.2163 36.4999C36.6475 35.7979 36.6639 34.7694 36.7228 33.4644C36.7698 32.4204 37.006 30.873 37.1182 30.2299C37.1337 29.4708 37.0543 27.8022 36.6129 27.2008C36.1715 26.5993 35.5157 24.5593 35.243 23.6144C34.7003 22.7157 33.4993 20.8561 33.0362 20.6071C32.4572 20.2959 31.5059 19.3816 30.1596 18.4067C29.0826 17.6268 27.8967 17.1698 27.4383 17.0388Z"
          fill="#BB844C"
        />
        <path
          d="M23.7208 23.0504C24.285 24.0103 25.1816 24.0698 25.5593 23.9796C26.3523 23.8182 26.8581 23.5523 27.2209 22.9004C27.5112 22.379 27.218 21.559 27.0351 21.2142C26.8445 20.875 26.2362 20.2021 25.3272 20.2242C24.4181 20.2464 23.9185 20.7088 23.7824 20.9372C23.5268 21.2416 23.1566 22.0905 23.7208 23.0504Z"
          fill="#55382F"
        />
        <path
          d="M20.3903 31.3128C20.5277 31.5765 20.951 31.5674 21.1454 31.5298C21.2001 31.5248 21.3365 31.5228 21.4446 31.5556C21.7643 31.6856 22.1845 31.6085 22.3546 31.5538C22.4908 31.5006 22.7991 31.3188 22.9424 31.0173C23.0857 30.7159 23.2917 30.5858 23.3767 30.5584C23.518 30.3847 23.7415 29.9473 23.5056 29.5876C23.2697 29.2279 23.1802 28.8099 23.165 28.6458C23.0758 28.5243 22.7644 28.2528 22.232 28.1387C21.6997 28.0246 21.0512 28.2809 20.7934 28.4232C20.598 28.555 20.1686 28.9378 20.0144 29.4155C19.8217 30.0126 20.0739 30.2131 20.2275 30.6612C20.3811 31.1093 20.2185 30.9832 20.3903 31.3128Z"
          fill="#55382F"
        />
        <path
          d="M11.6698 35.0483C11.6928 34.5828 11.9527 34.3033 12.0797 34.2217C12.3311 33.897 12.6857 33.7186 12.8315 33.67C12.8974 33.6801 13.1263 33.6991 13.5152 33.6941C13.9041 33.6891 14.2769 33.9997 14.4147 34.1557L14.9831 34.9303C15.2673 35.3176 15.0366 35.7673 14.9439 35.9755C14.8699 36.142 14.2296 36.5464 13.9187 36.7278C13.7512 36.8975 13.3842 37.2603 13.2566 37.3539C13.0971 37.4709 12.6687 37.3647 12.5151 36.9166C12.3922 36.5581 12.1052 36.1192 11.977 35.9445C11.865 35.8397 11.6469 35.5138 11.6698 35.0483Z"
          fill="#55382F"
        />
        <path
          d="M19.1166 39.0824C19.3462 38.7457 19.5413 38.467 19.6101 38.3698C19.7983 38.1008 20.0658 38.0354 20.2253 37.9184C20.3528 37.8247 20.8568 38.0625 21.0928 38.1931C21.2503 38.2644 21.6863 38.4095 22.17 38.4191C22.6537 38.4286 22.8599 38.9293 22.9025 39.1784C22.9202 39.2822 22.9615 39.5837 22.9848 39.9593C23.0138 40.4287 22.5581 40.7631 22.0569 41.1309C21.6559 41.4251 21.1718 41.4532 20.9799 41.4305C20.8239 41.3931 20.4159 41.3116 20.0319 41.2849C19.6478 41.2583 19.2903 40.6724 19.1596 40.3828C19.0496 40.0897 18.887 39.4191 19.1166 39.0824Z"
          fill="#55382F"
        />
        <path
          d="M27.6991 36.1142C27.9347 35.6329 28.7086 35.2449 29.066 35.1111C29.0686 35.0509 29.2483 34.9774 29.9472 35.1655C30.6461 35.3537 31.1066 36.1724 31.2496 36.5582C31.2598 36.8428 31.2183 37.4995 30.9705 37.8496C30.6607 38.2872 30.4601 38.0139 30.0592 38.5184C29.7384 38.922 29.3271 38.9855 29.1615 38.9668C29.0804 38.9678 28.8151 38.9055 28.4018 38.648C27.9885 38.3905 27.5993 37.5545 27.4564 37.1686C27.4391 37.0177 27.4635 36.5956 27.6991 36.1142Z"
          fill="#55382F"
        />
        <path
          d="M27.4382 17.0388C26.8603 16.8086 25.4664 16.3197 24.5135 16.2057C23.683 16.1661 22.1293 16.6013 22.5589 18.6585C22.5589 19.7323 22.7284 20.7214 21.2307 21.2583C22.8603 21.7906 26.7581 23.5928 29.3126 26.5426C32.5059 30.2299 33.4969 37.8521 32.2525 40.9858C33.2074 39.6121 34.2632 38.599 34.6717 38.2641C35.0428 37.91 35.8712 37.0615 36.2162 36.4999C36.6474 35.7979 36.6638 34.7694 36.7226 33.4644C36.7697 32.4204 37.0059 30.873 37.1181 30.2299C37.1336 29.4708 37.0541 27.8022 36.6128 27.2008C36.1714 26.5993 35.5156 24.5593 35.2428 23.6144C34.7002 22.7157 33.4992 20.8561 33.036 20.6071C32.4571 20.2959 31.5058 19.3816 30.1595 18.4067C29.0825 17.6268 27.8965 17.1698 27.4382 17.0388Z"
          fill="#9F7243"
        />
        <path
          d="M31.7759 28.9167C32.0335 28.924 32.3724 28.9814 32.5097 29.0092C32.7026 29.1129 33.1749 29.2852 33.52 29.1441C33.8651 29.003 33.9827 28.8512 33.9984 28.793C34.0277 28.6897 34.1557 28.3481 34.4326 27.8084C34.7787 27.1338 34.3593 26.7055 34.1936 26.3364C34.0279 25.9673 33.7255 25.6985 32.8184 25.4177C31.9114 25.137 31.0168 25.8285 31.0245 26.1733C31.0322 26.5182 30.6131 27.1412 30.7182 27.9053C30.8233 28.6694 31.4538 28.9077 31.7759 28.9167Z"
          fill="#442C24"
        />
        <path
          d="M25.3272 20.2243C24.4181 20.2464 23.9185 20.7088 23.7824 20.9373C23.6558 21.088 23.4904 21.4052 23.435 21.7701C23.4164 21.8926 23.4069 22.106 23.4349 22.1908C24.0421 22.5778 25.2436 23.1516 26.1479 23.8015C26.5425 23.6267 26.9857 23.323 27.2209 22.9005C27.5112 22.379 27.218 21.559 27.0351 21.2143C26.8445 20.875 26.2362 20.2021 25.3272 20.2243Z"
          fill="#442C24"
        />
        <path
          d="M23.8001 9.68773C23.9375 9.95142 24.3607 9.94227 24.5552 9.90473C24.6098 9.89966 24.7462 9.89772 24.8544 9.9305C25.1741 10.0605 25.5943 9.98344 25.7644 9.92869C25.9006 9.87547 26.2088 9.69368 26.3522 9.39223C26.4955 9.09077 26.7014 8.96067 26.7865 8.93329C26.9277 8.75956 27.1512 8.32216 26.9153 7.96249C26.6794 7.60282 26.59 7.18477 26.5747 7.0207C26.4856 6.8992 26.1741 6.62767 25.6418 6.51359C25.1094 6.39952 24.4609 6.65576 24.2032 6.79814C24.0078 6.92986 23.5784 7.31273 23.4242 7.79041C23.2314 8.38752 23.4837 8.58797 23.6373 9.03608C23.7908 9.48419 23.6283 9.35812 23.8001 9.68773Z"
          fill="#BB844C"
        />
        <path
          d="M31.8818 6.86205C32.0193 7.12573 32.4425 7.11658 32.637 7.07905C32.6916 7.07398 32.828 7.07203 32.9362 7.10481C33.2558 7.23478 33.676 7.15776 33.8462 7.103C33.9824 7.04979 34.2906 6.868 34.4339 6.56654C34.5773 6.26509 34.7832 6.13498 34.8683 6.10761C35.0095 5.93387 35.233 5.49648 34.9971 5.13681C34.7612 4.77714 34.6718 4.35909 34.6565 4.19502C34.5673 4.07351 34.2559 3.80198 33.7236 3.68791C33.1912 3.57384 32.5427 3.83007 32.285 3.97245C32.0896 4.10418 31.6602 4.48705 31.506 4.96473C31.3132 5.56183 31.5655 5.76229 31.719 6.2104C31.8726 6.65851 31.7101 6.53244 31.8818 6.86205Z"
          fill="#BB844C"
        />
        <path
          d="M34.9803 16.5407C34.7715 16.9414 34.1284 16.9275 33.833 16.8704C33.7499 16.8627 33.5426 16.8598 33.3783 16.9096C32.8926 17.1071 32.2541 16.99 31.9956 16.9068C31.7886 16.826 31.3202 16.5498 31.1025 16.0917C30.8847 15.6337 30.5718 15.436 30.4425 15.3944C30.2279 15.1304 29.8883 14.4658 30.2468 13.9193C30.6052 13.3728 30.7411 12.7375 30.7643 12.4882C30.8998 12.3036 31.373 11.891 32.1819 11.7177C32.9908 11.5444 33.9762 11.9337 34.3678 12.1501C34.6647 12.3502 35.3172 12.932 35.5515 13.6578C35.8444 14.5651 35.4611 14.8697 35.2277 15.5505C34.9943 16.2314 35.2413 16.0399 34.9803 16.5407Z"
          fill="#BB844C"
        />
      </g>
    </svg>
  );
}
