import React from "react";
import { useEffect, useRef, useState } from "react";
import axiosInstance from "../../api/interceptor";

import { ScrollingLine } from "../../components/ScrollingLine/ScrollingLine";
import { ArtLogoCardType } from "../../types/types";
import { ArtLogoCard } from "../../components/ArtLogoCard/ArtLogoCard";
import { MiniLogos } from "../../components/MiniLogos/MiniLogos";

import magnifierDesktop from "../../assets/images/kbart/magnifier.svg";
import magnifierMobile from "../../assets/images/kbart/search.png";
import "./KBArt.css";
import { Footer } from "../../components/Footer/Footer";
import { CustomSpinner } from "../../components/CustomSpinner/CustomSpinner";

const { REACT_APP_BASE_URL } = process.env;

type KBArtType = {
  pageSection: {
    image: {
      url: string;
    };
    title: string;
    subtitle: string;
  };
  art_logos: ArtLogoCardType[];
  linkedinIcon: {
    url: string;
  };
};

type DeskLogosType = {
  className: string;
  linkedinIcon: string;
  logo: ArtLogoCardType;
};

type MobileLogosType = {
  className?: string;
  logos: Array<ArtLogoCardType>;
  linkedinIcon: string;
};

export function KBArt() {
  const [kbArtPage, setKbArtPage] = React.useState<KBArtType>();
  const [searchActive, setSearchActive] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const myRef = useRef<any>();

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("keybiz-art")
      .then((res) => setKbArtPage(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const sortedLogos = kbArtPage?.art_logos
    .sort(
      (logoA, logoB) =>
        new Date(logoB.artLogo.date).getTime() -
        new Date(logoA.artLogo.date).getTime()
    )
    .filter((logo: ArtLogoCardType) => {
      return (
        logo.artLogo.tags?.toLowerCase().indexOf(searchedValue.toLowerCase()) >
        -1
      );
    });

  const handleClickInside = (e: any) => {
    e.target.classList.add("input-clicked");
    document.getElementById("input-icon")?.classList.add("input-icon-clicked");
  };

  const handleClickOutside = (e: any) => {
    if (
      !myRef.current?.contains(e.target) &&
      (document.getElementById("input-search") as HTMLInputElement)?.value ===
        ""
    ) {
      document
        .getElementById("input-search")
        ?.classList.remove("input-clicked");
      document
        .getElementById("input-icon")
        ?.classList.remove("input-icon-clicked");
    }
  };

  useEffect(() => {
    searchActive && myRef?.current?.focus();
  }, [searchActive]);

  return (
    <div className="kbart-container">
      {isLoading && <CustomSpinner />}
      <ScrollingLine className="scrolling-line" page="kb-art" />
      <div className="kbart-content">
        <div className="kbart-text-wrapper">
          <img
            src={`${REACT_APP_BASE_URL}${kbArtPage?.pageSection.image.url}`}
            alt=""
          />
          <p>
            <b>{kbArtPage?.pageSection?.title?.toUpperCase()}</b>
            <br />
          </p>
          <p className="kbart-subtitle">
            {kbArtPage?.pageSection?.subtitle?.toUpperCase()}
          </p>
          <div className="input-wrapper">
            <input
              type="text"
              id="input-search"
              ref={myRef}
              placeholder="Cerca logo"
              onChange={(e) => setSearchedValue(e.target.value)}
              onClick={(e) => handleClickInside(e)}
              value={searchedValue}
            />
            <img src={magnifierDesktop} id="input-icon" alt="" />
          </div>
        </div>
        <div className="kbart-header-mini">
          <h1>{kbArtPage?.pageSection?.title?.toUpperCase()}</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              myRef.current.blur();
            }}
            onClick={() => setSearchActive(true)}
            onBlur={() => setSearchActive(false)}
            style={{
              borderBottom: searchActive
                ? "1px solid gray"
                : "1px solid transparent",
            }}
            className="input-wrapper-mobile"
          >
            <input
              type="text"
              id="input-search-mobile"
              ref={myRef}
              placeholder="Cerca logo"
              value={searchedValue}
              onChange={(e) => setSearchedValue(e.target.value)}
            />
            <img className="search-icon" src={magnifierMobile} alt="search" />
          </form>
        </div>
        <div className="kbart-logos-container">
          {(sortedLogos?.length ?? 0) > 0 ? (
            sortedLogos?.map((logo) => {
              return (
                <React.Fragment key={logo.artLogo.id}>
                  <DesktopLogo
                    className="kbart-full"
                    logo={logo}
                    linkedinIcon={kbArtPage?.linkedinIcon.url ?? ""}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <div className="empty-record">
              {" "}
              La ricerca non ha prodotto risultati
            </div>
          )}
          {(sortedLogos?.length ?? 0) > 0 && (
            <MobileLogos
              linkedinIcon={kbArtPage?.linkedinIcon.url as string}
              logos={sortedLogos as ArtLogoCardType[]}
              className="kbart-mini"
            />
          )}

          {/* 
            DECOMMENTA PER VISUALIZZARE IL CAROUSEL VERTICALE (DA 13 ELEMENTI IN SU)
          {(sortedLogos?.length ?? 0) < 13 || searchedValue ? (
            <MobileLogos
              linkedinIcon={kbArtPage?.linkedinIcon.url as string}
              logos={sortedLogos as ArtLogoCardType[]}
              className="kbart-mini"
            />
          ) : (
            <MiniLogos
              className="kbart-mini"
              logos={sortedLogos as ArtLogoCardType[]}
              linkedinIcon={kbArtPage?.linkedinIcon.url as string}
            />
          )} */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

function DesktopLogo({ logo, className, linkedinIcon }: DeskLogosType) {
  return (
    <div className={`logo-details ${className || ""}`} key={logo.artLogo.id}>
      <div className="image-container">
        <p className="logo-name">{logo?.artLogo.name}</p>
        <img
          src={`${REACT_APP_BASE_URL}${logo.artLogo.image.url}`}
          alt="Logo"
        />
      </div>
      <p>{new Date(logo.artLogo.date).toLocaleDateString()}</p>
      {!!logo?.artLogo.link && (
        <a href={logo?.artLogo.link} target="_blank" rel="noreferrer">
          <img
            className="linkedin-icon"
            src={`${REACT_APP_BASE_URL}${linkedinIcon}`}
            alt="logo linkedin"
          />
          <span>Vai al post!</span>
        </a>
      )}
    </div>
  );
}

function MobileLogos({ className, logos, linkedinIcon }: MobileLogosType) {
  return (
    <div className="mobile-search">
      <div className={`mobile-search-cards ${className || ""}`}>
        <ul className="cards">
          {logos?.map((logo) => (
            <li key={logo.artLogo.id}>
              <ArtLogoCard artLogo={logo.artLogo} linkedinIcon={linkedinIcon} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
