import React from "react";
import { gearAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { ParagraphType } from "../../../types/types";
import { PageButton } from "../../PageButton/PageButton";
import parse from "html-react-parser";
import "./Enginerring.css";

type EngineeringType = {
  title: string;
  engineeringParagraph: Array<ParagraphType>;
};

const Engineering = (content: EngineeringType) => {
  return (
    <div className="engineering-container">
      <div className="engineering-image">
        {gearAnimated()}
        <hr />
      </div>
      <div className="engineering-text__container">
        <div className="engineering-text fade-in">
          <div className="engineering-text__title">
            <h1>{content?.title?.toUpperCase()}</h1>
          </div>
          <div className="engineering-text__description">
            {content?.engineeringParagraph?.map((paragraph) => {
              return (
                <React.Fragment key={paragraph.id}>
                  {parse(paragraph.description)}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="engineering-page-button fade-in">
          <PageButton
            text="scopri di più"
            linkToPage={"cosa-facciamo/engineering"}
          />
        </div>
      </div>
    </div>
  );
};

export default Engineering;
