import React, { useEffect } from "react";
import parse from "html-react-parser";
import axiosInstance from "../../api/interceptor";
import closeButton from "../../assets/images/close-button.svg";

import "./CookiesModal.css";
import { ByzCookies } from "../../animatedSVG/ByzCookies/ByzCookies";

type CookiesModalType = {
  acceptClick: () => void;
  refuseClick?: () => void;
  acceptClicked: boolean;
};

export function CookiesModal({
  acceptClick,
  refuseClick,
  acceptClicked,
}: CookiesModalType) {
  const [cookiesPolicy, setCookiesPolicy] = React.useState<string>();
  const [showCookiesPolicy, setShowCookiesPolicy] =
    React.useState<boolean>(false);

  useEffect(() => {
    axiosInstance
      .get("footer-items")
      .then((res) => setCookiesPolicy(res.data.cookiesPolicy))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="cookies-modal-background">
      <div className="cookies-modal-content__wrapper">
        <img
          className="close-cookies-modal__button"
          src={closeButton}
          alt="close"
          onClick={refuseClick}
        />
        {!showCookiesPolicy && <ByzCookies acceptClicked={acceptClicked} />}
        {!showCookiesPolicy && (
          <div className="cookies-modal-content">
            <div className="cookies-modal-text">
              <p className="cookies-modal-title">
                Ciao! Questo sito utilizza cookie
              </p>
              <p className="cookies-modal-description">
                Utilizziamo i{" "}
                <span
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => setShowCookiesPolicy(true)}
                >
                  cookies
                </span>{" "}
                per personalizzare contenuti ed annunci, per fornire
                funzionalità dei social media e per analizzare il nostro
                traffico. Condividiamo inoltre informazioni sul modo in cui
                utilizza il nostro sito con i nostri partner che si occupano di
                analisi dei dati web, pubblicità e social media, i quali
                potrebbero combinarle con altre informazioni che ha fornito loro
                o che hanno raccolto dal suo utilizzo dei loro servizi.
              </p>
            </div>
            <div className="cookies-modal-buttons">
              <button className="refuse-cookies" onClick={refuseClick}>
                RIFIUTA
              </button>
              <button className="accept-cookies" onClick={acceptClick}>
                ACCETTA
              </button>
            </div>
          </div>
        )}
        {showCookiesPolicy && (
          <div className="cookies-policy-text">
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setShowCookiesPolicy(false)}
            >
              &#x2190; Torna indietro
            </span>
            {parse((cookiesPolicy as string) ?? "")}
          </div>
        )}
      </div>
    </div>
  );
}
