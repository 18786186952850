import React from 'react';

class ChangingProgressProvider extends React.Component {
  static defaultProps = {
    interval: 500,
  };

  state = {
    valuesIndex: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.inView !== this.props.inView) {
      setTimeout(() => {
        this.setState({
          valuesIndex: (this.state.valuesIndex + 1) % this.props.values.length,
        });
      }, this.props.interval);
    }
  }

  render() {
    return this.props.children(this.props.values[this.state.valuesIndex]);
  }
}

export default ChangingProgressProvider;
