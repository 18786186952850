import { useState } from "react";
import { HighlightType } from "../../types/types";
import "./Highlight.css";

const { REACT_APP_BASE_URL } = process.env;

export function Highlight({ title, description, image }: HighlightType) {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className="highlight-container fade-in"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <img
        alt="highlight"
        src={`${REACT_APP_BASE_URL}${image?.url}`}
        style={{ height: hovering ? 58 : 68, width: hovering ? 76 : 86 }}
      />
      <p className="title">{title}</p>
      <p className="description" style={{ opacity: hovering ? 1 : 0 }}>
        {description}
      </p>
    </div>
  );
}
