import { useEffect } from "react";
import { gsap } from "gsap";
import { MotionPathPlugin, ScrollTrigger } from "gsap/all";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

export function ByzSketchbook() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  const speedDrawingVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const writingVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const pathAnimationLong = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  const pathAnimationShort = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 0.4,
        ease: "easeInOut",
      },
    },
  };

  const pathAnimationWriting = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const keybizSketch = () => (
    <motion.svg
      variants={writingVariants}
      x="150"
      y="40"
      width="102"
      height="31"
      viewBox="0 0 102 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationWriting}
        d="M2 2V19.5C6.27778 13.6667 16 2 20.6667 2"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M13.6665 10.1667C13.6665 13.6667 14.5998 21.3667 18.3332 24.1667"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M28.8335 5.5C28.0557 8.61111 26.5002 15.0667 26.5002 16C26.5002 17.1667 25.3335 21.8333 27.6668 23C29.5335 23.9333 30.7779 24.1667 31.1668 24.1667C33.1113 24.1667 37.4668 23.4667 39.3335 20.6667"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M28.8335 17.1666L38.1668 14.8333"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M23 10.1666C23.7778 9.38887 25.5667 7.83331 26.5 7.83331C27.4333 7.83331 33.8889 7.83331 37 7.83331"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M40.5 7.83335C40.5 9.38891 41.2 12.7334 44 13.6667C46.8 14.6 48.2778 13.2778 48.6667 12.5C49.8333 10.9445 52.1667 6.90002 52.1667 3.16669"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M45.1665 14.8333C45.1665 16.3889 45.3998 19.7333 46.3332 20.6666C47.2665 21.6 47.4998 25.7222 47.4998 27.6666"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M61.4999 11.3333C60.7221 14.8333 59.3999 22.5333 60.3332 25.3333"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M59.1665 11.3333C59.9443 10.1666 62.1998 7.83331 64.9998 7.83331C67.7998 7.83331 69.2776 8.61109 69.6665 8.99998C70.0554 9.77776 70.3665 11.8 68.4998 13.6666C66.6332 15.5333 63.8332 16.7778 62.6665 17.1666C64.9998 17.1666 69.6665 17.4 69.6665 18.3333C69.6665 19.2666 69.6665 22.6111 69.6665 24.1666C68.1109 25.7222 64.9998 29.0666 64.9998 30"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M80.167 11.3333C79.0003 15.2222 76.9003 24.4 77.8337 30"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M83.6665 14.8332C85.9998 12.4999 91.3665 8.76656 94.1665 12.4999C96.9665 16.2332 96.1109 21.8333 95.3332 24.1667C94.1665 26.5 90.8998 30.7 87.1665 28.8334"
        stroke="black"
        strokeWidth="2.33333"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M86 18.3333C87.1667 18.7222 90.4333 19.5 94.1667 19.5C97.9 19.5 100.389 18.7222 101.167 18.3333"
        stroke="black"
        strokeWidth="2.33333"
      />
    </motion.svg>
  );

  const arrowSketch = () => (
    <motion.svg
      variants={writingVariants}
      x="210"
      y="65"
      width="60"
      height="19"
      viewBox="0 0 60 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M1 12.1673C1.95177 11.85 4.61672 11.2155 7.66237 11.2155C10.708 11.2155 16.5455 10.581 19.0836 10.2637C21.6216 9.94648 26.8881 9.50232 27.6495 10.2637"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M19.0835 14.0707C20.0353 15.657 23.0809 18.6392 27.6494 17.8778C32.2179 17.1164 35.2635 16.926 36.2153 16.926C37.8016 16.2915 40.7838 14.2611 40.0224 11.2154C39.261 8.16974 37.8016 7.40833 37.1671 7.40833C35.8981 7.40833 33.5504 7.97939 34.3118 10.2636C35.0732 12.5479 37.1671 13.1189 38.1188 13.1189C41.2914 13.4362 48.5883 13.1189 52.3954 9.31186C54.6796 7.78903 56.5197 4.87028 57.1542 3.60126"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M52.395 1.69775L57.1539 2.64952C57.4711 2.64952 58.1056 3.03023 58.1056 4.55306C58.1056 6.45659 58.1056 5.50482 57.1539 7.40836C56.3924 8.93119 56.8366 11.2154 57.1539 12.1672"
        stroke="black"
        strokeWidth="1.90354"
      />
    </motion.svg>
  );

  const byzWordSketch = () => (
    <motion.svg
      variants={writingVariants}
      x="275"
      y="55"
      width="48"
      height="19"
      viewBox="0 0 48 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M3.85498 3.85522C4.48949 6.71053 5.56816 12.8018 4.80675 14.3247"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M1 5.75873C1.31726 4.48971 2.52283 1.95166 4.80707 1.95166C7.09131 1.95166 8.29689 2.58617 8.61414 2.90343C9.24865 3.22068 10.3273 4.2359 9.56591 5.75873C8.61414 7.66227 4.80707 6.7105 6.71061 7.66227C8.61414 8.61403 9.56591 8.61403 10.5177 8.61403C11.2791 8.61403 12.7385 9.88306 13.373 10.5176C14.0075 11.4693 14.3247 13.5632 10.5177 14.3246C6.71061 15.0861 3.8553 15.9109 2.90354 16.2282"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M16.2285 5.75884C16.863 6.71061 18.5454 8.07574 19.0838 8.61414C20.0356 9.56591 21.9391 9.56591 22.8909 8.61414C23.8427 7.34512 25.9365 4.04566 26.698 1"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M21.9385 8.61414C21.9385 9.5659 22.1288 11.8501 22.8902 13.373C23.6517 14.8958 25.111 17.18 25.7455 18.1318"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M29.5527 5.75887C29.87 5.12436 30.6949 3.85534 31.4563 3.85534C32.2177 3.85534 34.3116 3.22083 35.2633 2.90357C36.2151 2.58631 38.1186 2.14216 38.1186 2.90357C38.1186 3.66498 38.1186 5.12436 38.1186 5.75887C37.8014 7.66239 37.1669 11.6598 37.1669 12.4212C37.1669 13.1826 37.1669 14.642 37.1669 15.2765"
        stroke="black"
        strokeWidth="1.90354"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M31.4565 9.56589C31.7738 9.24863 32.5987 8.61412 33.3601 8.61412C34.1215 8.61412 35.5809 8.61412 36.2154 8.61412C37.1671 8.93137 39.4514 9.37553 40.9742 8.61412C42.497 7.8527 45.4158 8.93137 46.6848 9.56589"
        stroke="black"
        strokeWidth="1.90354"
      />
    </motion.svg>
  );

  const sketch1 = () => (
    <motion.svg
      variants={speedDrawingVariants}
      x="150"
      y="130"
      width="81"
      height="89"
      viewBox="0 0 81 89"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M62.4335 67.5361C62.6686 67.2378 63.0682 66.3754 62.7861 65.3122C62.4335 63.9833 62.2166 57.4741 62.7861 56.0638C63.3557 54.6535 65.1457 51.9956 65.9322 51.4261C66.5614 50.9704 67.2973 51.2362 67.5866 51.4261C70.2083 52.6194 76.0213 56.373 78.2995 61.8407C80.5777 67.3083 79.6465 72.8339 78.8962 74.9132C78.5888 76.3867 77.2743 79.5563 74.4754 80.4459C71.6765 81.3355 68.8251 80.8166 67.7493 80.4459C66.0768 79.9487 62.5529 78.2382 61.8369 75.3742C61.1209 72.5102 61.647 68.847 61.9996 67.3734"
        stroke="black"
        strokeWidth="1.99"
      />
      <motion.path
        d="M75.8047 79.7679C76.1844 80.7804 76.9763 83.0387 77.1065 83.9717C77.2367 84.9047 78.5891 85.5176 79.2491 85.7075C79.9181 85.4634 81.1693 84.5955 80.8221 83.0767C80.475 81.5579 78.2004 79.3701 77.1065 78.4661L75.8047 80.1476L77.215 79.0085L75.9132 80.5544L77.5676 79.2255L76.4014 80.5544L77.7845 79.5238L76.4014 80.9883L78.11 79.7679L76.5641 81.2324L78.3541 79.9577L76.5641 81.5308L79.005 79.9577L76.2657 81.9376L78.7609 80.3917L76.8624 81.9376L79.2491 80.2832L76.9709 82.1817L79.005 80.7714L76.9709 82.5071L79.5203 80.5544L76.6454 83.1581L79.2491 81.2324L77.5676 82.8326L79.4118 81.5308L76.6454 83.7818L79.7373 81.6393L77.4591 83.6734L79.9271 81.9376L77.5133 84.0531L80.1712 82.0461L77.5676 84.3785L80.1712 82.5071L77.893 84.6226L80.4967 82.7241L78.3541 84.7311L80.4967 83.2665L78.3541 85.0837L80.6594 83.5649L78.7609 85.2193L80.4153 84.1073L79.005 85.4362L80.2797 84.6226"
        stroke="black"
        strokeWidth="0.1742"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M67.3089 80.275C66.5259 81.0208 64.7579 82.6337 63.9504 83.1187C63.1428 83.6038 63.1083 85.0882 63.192 85.7698C63.6785 86.2899 64.967 87.1014 66.2286 86.1873C67.4903 85.2733 68.6129 82.3237 69.0165 80.9632L66.9595 80.4236L68.5599 81.2754L66.6277 80.6827L68.4982 81.6848L66.8188 81.132L68.3087 82.0012L66.4195 81.3018L68.2115 82.3963L66.2586 81.5472L68.1323 82.6952L65.9841 81.6639L68.3872 83.2942L65.4929 81.5487L67.8923 83.2395L65.7265 82.0977L68.1833 83.6462L65.5444 82.2931L67.6385 83.6127L65.2449 82.4205L68.0399 84.0019L64.5185 82.3759L67.3098 84.0178L65.179 83.097L67.099 84.2844L63.9445 82.6201L67.1266 84.6263L64.3629 83.3263L66.9264 84.9178L64.0348 83.5249L66.9221 85.1849L63.7565 83.7022L66.4979 85.3654L63.6593 84.0973L66.4256 85.7498L63.74 84.564L65.9265 85.9622L63.4156 84.702L65.7157 86.2287L63.4501 85.1295L65.121 86.2165L63.346 85.439L64.5937 86.2934"
        stroke="black"
        strokeWidth="0.1742"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M71.7858 80.8494C71.5487 81.9044 70.974 84.2275 70.5717 85.0794C70.1695 85.9312 70.9651 87.1849 71.4132 87.7053C72.1066 87.8676 73.6288 87.8271 74.1704 86.3663C74.7121 84.9055 74.0079 81.8291 73.5881 80.4735L71.5778 81.167L73.3817 80.9868L71.4456 81.5668L73.5577 81.3614L71.854 81.8342L73.5758 81.7299L71.6163 82.1973L73.7143 82.1124L71.6187 82.4906L73.8145 82.4049L71.4553 82.7402L74.3591 82.7615L70.9828 82.9171L73.9171 82.9908L71.482 83.244L74.385 83.1675L71.439 83.5076L73.9133 83.4422L71.2607 83.7799L74.4633 83.543L70.6318 84.1461L73.8649 83.9616L71.5815 84.379L73.8376 84.3004L70.29 84.6679L74.0504 84.5694L71.0301 85.023L74.0458 84.923L70.8675 85.3703L74.1906 85.1475L70.7346 85.6723L73.938 85.5332L70.8731 86.0548L74.0913 85.893L71.1994 86.3982L73.7942 86.3468L71.0062 86.6931L73.7669 86.6855L71.2723 87.0294L73.2655 87.0056L71.3576 87.3447L72.8697 87.3624"
        stroke="black"
        strokeWidth="0.1742"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M19.4483 49.2021C19.2132 48.9038 18.8136 48.0413 19.0957 46.9782C19.4483 45.6492 19.6653 39.1401 19.0957 37.7298C18.5262 36.3195 16.7362 33.6616 15.9496 33.092C15.3204 32.6364 14.5845 32.9022 14.2952 33.092C11.6735 34.2854 5.8605 38.039 3.58231 43.5066C1.30412 48.9743 2.23529 54.4998 2.98564 56.5791C3.29302 58.0527 4.6075 61.2223 7.40642 62.1119C10.2053 63.0014 13.0567 62.4825 14.1325 62.1119C15.805 61.6146 19.329 59.9042 20.045 57.0402C20.761 54.1762 20.2348 50.513 19.8822 49.0394"
        stroke="black"
        strokeWidth="1.99"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M6.07715 61.4338C5.69745 62.4464 4.90551 64.7047 4.77532 65.6376C4.64514 66.5706 3.29269 67.1836 2.63274 67.3734C1.96375 67.1293 0.712551 66.2614 1.0597 64.7426C1.40686 63.2238 3.68143 61.0361 4.77532 60.132L6.07715 61.8135L4.66684 60.6744L5.96866 62.2204L4.31426 60.8914L5.48048 62.2204L4.09729 61.1898L5.48048 62.6543L3.77184 61.4338L5.31775 62.8984L3.52774 61.6237L5.31775 63.1967L2.87683 61.6237L5.61609 63.6035L3.12092 62.0576L5.01942 63.6035L2.63274 61.9491L4.91093 63.8476L2.87683 62.4373L4.91093 64.1731L2.36153 62.2204L5.23639 64.824L2.63274 62.8984L4.31426 64.4986L2.47001 63.1967L5.23639 65.4478L2.14456 63.3052L4.42275 65.3393L1.95471 63.6035L4.36851 65.719L1.71062 63.712L4.31426 66.0445L1.71062 64.1731L3.98881 66.2886L1.38516 64.3901L3.52774 66.397L1.38516 64.9325L3.52774 66.7496L1.22243 65.2308L3.12092 66.8852L1.46652 65.7733L2.87683 67.1022L1.60213 66.2886"
        stroke="black"
        strokeWidth="0.1742"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M14.5729 61.9409C15.356 62.6867 17.1239 64.2996 17.9315 64.7847C18.739 65.2697 18.7735 66.7542 18.6898 67.4358C18.2033 67.9558 16.9149 68.7673 15.6532 67.8533C14.3916 66.9392 13.2689 63.9897 12.8653 62.6292L14.9223 62.0896L13.322 62.9414L15.2542 62.3487L13.3836 63.3507L15.063 62.7979L13.5732 63.6672L15.4623 62.9678L13.6704 64.0622L15.6232 63.2131L13.7495 64.3612L15.8978 63.3299L13.4947 64.9601L16.3889 63.2147L13.9895 64.9054L16.1553 63.7637L13.6986 65.3122L16.3374 63.9591L14.2434 65.2787L16.6369 64.0865L13.842 65.6679L17.3633 64.0419L14.572 65.6838L16.7028 64.763L14.7829 65.9503L17.9373 64.2861L14.7553 66.2923L17.5189 64.9923L14.9555 66.5838L17.8471 65.1909L14.9597 66.8508L18.1253 65.3682L15.384 67.0314L18.2225 65.7632L15.4562 67.4158L18.1418 66.23L15.9553 67.6281L18.4663 66.368L16.1661 67.8947L18.4318 66.7954L16.7608 67.8824L18.5358 67.105L17.2881 67.9594"
        stroke="black"
        strokeWidth="0.1742"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M10.0951 62.5154C10.3321 63.5705 10.9069 65.8936 11.3091 66.7454C11.7114 67.5972 10.9158 68.8509 10.4677 69.3713C9.77428 69.5336 8.25209 69.4931 7.71041 68.0323C7.16873 66.5715 7.87295 63.4952 8.29277 62.1396L10.3031 62.833L8.4992 62.6528L10.4352 63.2328L8.32311 63.0274L10.0268 63.5003L8.30505 63.3959L10.2646 63.8633L8.16651 63.7784L10.2622 64.1566L8.06633 64.0709L10.4256 64.4062L7.5218 64.4276L10.8981 64.5831L7.96374 64.6568L10.3989 64.91L7.4959 64.8336L10.4419 65.1736L7.96756 65.1082L10.6202 65.4459L7.4176 65.209L11.2491 65.8121L8.01596 65.6277L10.2993 66.045L8.04328 65.9664L11.5908 66.334L7.83045 66.2355L10.8507 66.689L7.83507 66.589L11.0134 67.0363L7.69031 66.8135L11.1463 67.3383L7.94291 67.1992L11.0078 67.7208L7.78951 67.559L10.6815 68.0642L8.08669 68.0128L10.8746 68.3591L8.114 68.3516L10.6086 68.6955L8.61538 68.6716L10.5233 69.0107L9.01114 69.0284"
        stroke="black"
        strokeWidth="0.1742"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M56.4024 30.3075C55.4996 29.8758 53.4506 29.5068 52.4772 31.4851C51.5038 33.4634 52.9351 34.8477 53.7725 35.2925C54.5052 35.6065 56.1983 35.897 57.1089 34.5467C58.0196 33.1965 57.279 31.2888 56.7949 30.5038L54.4398 30.1113L57.1089 30.7393L53.9688 30.3075L56.7949 30.9356L53.537 30.5038L56.9127 31.2496L53.1445 30.7393L57.0697 31.5636L52.9875 30.9356L57.1089 31.8776L52.8305 31.2496L57.5799 32.1916L52.3987 31.4066L57.3052 32.3486L52.5165 31.6421L57.3052 32.6234L52.4772 31.8776L57.6977 32.8981L52.3987 32.0739L57.4229 33.0552L52.2809 32.3486L57.4229 33.3299L52.2809 32.6234L57.3052 33.6439L52.2809 32.8981L57.3052 33.8794L52.3202 33.1337L57.1089 34.0757L52.3595 33.4084L56.9127 34.3112L52.595 33.7224L56.5201 34.5467L52.6342 33.9579L56.6771 34.8215L52.752 34.2327L56.4024 34.9785L52.9875 34.5467L56.1276 35.1355L53.3015 34.8215L55.8136 35.2925L53.1445 34.9785L55.8136 35.6065L53.537 35.2925L54.7146 35.7243"
        stroke="black"
        strokeWidth="0.23551"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M38.1231 25.6427C37.2203 25.211 35.1713 24.842 34.1979 26.8203C33.2245 28.7986 34.6558 30.1829 35.4932 30.6277C36.2259 30.9417 37.919 31.2322 38.8296 29.8819C39.7403 28.5317 38.9997 26.624 38.5156 25.839L36.1605 25.4465L38.8296 26.0745L35.6895 25.6427L38.5156 26.2708L35.2577 25.839L38.6334 26.5848L34.8652 26.0745L38.7904 26.8988L34.7082 26.2708L38.8296 27.2128L34.5512 26.5848L39.3006 27.5268L34.1194 26.7418L39.0259 27.6838L34.2372 26.9773L39.0259 27.9586L34.1979 27.2128L39.4184 28.2334L34.1194 27.4091L39.1436 28.3904L34.0017 27.6838L39.1436 28.6651L34.0017 27.9586L39.0259 28.9791L34.0017 28.2334L39.0259 29.2146L34.0409 28.4689L38.8296 29.4109L34.0802 28.7436L38.6334 29.6464L34.3157 29.0576L38.2408 29.8819L34.3549 29.2932L38.3978 30.1567L34.4727 29.5679L38.1231 30.3137L34.7082 29.8819L37.8483 30.4707L35.0222 30.1567L37.5343 30.6277L34.8652 30.3137L37.5343 30.9417L35.2577 30.6277L36.4353 31.0595"
        stroke="black"
        strokeWidth="0.23551"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M52.1054 19.109L36.3479 15.095C34.142 14.4441 29.4482 13.9451 28.32 17.1562C26.9097 21.1702 23.9806 31.4763 25.1739 35.0292C26.1286 37.8715 31.1406 41.9813 33.5273 43.6809C36.8903 45.6426 45.7265 49.2082 54.1666 47.7762C57.8117 47.7762 61.1458 45.6788 62.3573 44.6301C63.65 42.9215 66.3115 38.7177 66.6153 35.5716C66.9191 32.4255 67.1577 28.2759 67.2391 26.5944C66.9769 25.6813 65.3189 23.4917 60.7842 22.038C56.2495 20.5843 52.6388 19.6062 51.4002 19.2988"
        stroke="black"
        strokeWidth="1.99"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M60.9475 2.80905C56.6804 1.44394 46.5623 -0.467205 40.2268 2.80905C33.8913 6.08531 27.9499 13.1242 25.7711 16.2341C23.9088 21.7397 20.7428 34.1288 22.9776 39.6398C25.7711 46.5286 25.6355 50.3799 30.2461 53.9599C34.8568 57.5399 41.1218 61.1199 44.1865 61.1199C47.2512 61.1199 57.259 60.252 64.6631 51.4105C70.5864 44.3372 73.2606 34.0528 73.8572 29.7948C75.2862 19.5972 67.6193 3.48709 59.9169 2.29375"
        stroke="black"
        strokeWidth="1.99"
      />
    </motion.svg>
  );

  const sketch2 = () => (
    <motion.svg
      variants={speedDrawingVariants}
      x="250"
      y="130"
      width="73"
      height="57"
      viewBox="0 0 73 57"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationLong}
        d="M46.7075 2H4.41C3.62671 13.7493 3.23506 37.496 3.23506 39.5978C3.23506 43.1226 2.8436 46.6474 2.06041 48.9973C1.66876 51.3471 3.00035 55.8119 11.4599 54.8719C19.9194 53.932 27.5175 53.697 30.2592 53.697C34.1756 53.3054 42.7135 52.5221 45.5333 52.5221C48.3531 52.5221 56.1077 52.5221 59.6325 52.5221C62.374 52.5221 67.857 51.3471 67.857 46.6474C67.857 41.9477 67.857 33.7232 67.857 30.1984C67.0737 23.9321 65.7421 10.6945 66.6821 7.87466"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M60.8074 39.5978H44.3584V47.8224H60.8074C62.374 47.4307 65.2722 45.7075 64.3322 41.9477"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M11.4604 39.5978L29.0844 40.7728C29.4761 42.731 30.0244 47.1174 29.0844 48.9973C27.9095 51.3472 20.8599 48.9973 12.6354 50.1722C11.6954 49.2323 11.4604 45.0809 11.4604 43.1226"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M4.41064 19.624L37.3087 38.4229C40.4419 36.4647 47.6481 32.0783 51.4079 30.1984C55.1677 28.3185 57.6742 27.0652 58.4575 26.6736C61.5907 23.9321 68.092 17.9791 69.0319 16.0992"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M16.1602 2C23.2097 7.09137 37.5439 17.2741 38.4839 17.2741C40.3638 16.3342 50.2332 6.69973 54.9329 2"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M4.41077 5.5248L7.93557 2L4.41077 9.04959L11.4604 2L3.23584 14.9243L14.9852 5.5248L7.93557 14.9243L18.51 7.87466L6.76064 23.1488L24.3846 7.87466L16.1601 18.449L26.7345 11.3995L14.9852 26.6736L31.4342 11.3995L24.3846 25.4986L31.4342 18.449L25.5595 32.5482L40.8337 14.9243L33.7841 32.5482L46.7083 12.5744L40.8337 31.3733L56.1078 2L44.3585 36.073L63.1574 2L56.1078 25.4986L66.6822 6.69973L64.3323 14.9243L71.3819 10.2245"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M47.8833 46.6474V37.248L51.4081 47.8224L52.583 41.9477L56.1078 47.8224V41.9477"
        stroke="black"
        strokeWidth="2.34986"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M16.1602 40.7728V47.8224L18.51 39.5978L19.685 48.9973L22.0348 41.9477L23.2097 48.9973L24.3847 41.9477"
        stroke="black"
        strokeWidth="2.34986"
      />
    </motion.svg>
  );

  const sketch3 = () => (
    <motion.svg
      variants={writingVariants}
      x="140"
      y="240"
      width="71"
      height="64"
      viewBox="0 0 71 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationLong}
        d="M46.8108 11.3459C43.1127 10.7296 32.7579 11.5309 20.9239 19.6667C9.08994 27.8026 4.28237 39.0819 3.35784 43.7045C2.12513 45.2454 0.0295299 48.8819 1.50878 51.1008C2.98803 53.3196 10.138 56.3398 13.5281 57.5725L36.6414 62.1951C40.3395 61.887 48.4754 59.7913 51.4339 53.8743C54.3924 47.9573 55.7484 40.3146 56.0566 37.2328C56.3645 30.4529 54.9463 16.1535 46.8108 13.195"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M21.8488 3.94977C25.8552 11.6542 33.8678 27.4329 33.8678 28.9121C33.8678 30.3914 35.7168 32.6102 36.6413 33.5348C38.4904 34.1511 43.4829 34.6442 48.6603 31.6857C53.8376 28.7272 64.3773 18.7423 68.9999 14.1196C68.3836 12.8869 66.4113 10.4215 63.4528 10.4215C60.4943 10.4215 57.9056 12.8869 56.981 14.1196C52.9747 18.1259 44.4074 26.1385 42.1885 26.1385C39.9697 26.1385 38.1822 21.824 37.5659 19.6668C36.025 14.1196 32.5734 2.84033 31.0942 2.10071C29.6149 1.36108 23.6979 4.8743 20.9243 6.72336"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M24.6226 6.72327L31.0943 3.02515L28.3207 8.57233H32.0188L28.3207 15.9686L33.8679 13.195L31.0943 20.5912L34.7924 17.8176L32.9433 23.3648L35.7169 22.4403L32.0188 28.912L37.566 23.3648L35.7169 28.912L38.4905 27.063V31.6856L44.0377 26.1384L43.1132 31.6856L49.5849 18.7422V28.912L54.2076 16.8931V25.2139L60.6793 10.4214L59.7547 20.5912L65.3019 10.4214L64.3774 15.0441L67.151 13.195"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M18.1508 27.9876C17.5345 29.5284 16.8565 33.5347 19.0754 37.2329C21.2942 40.931 26.1635 41.2392 28.3208 40.931C30.478 40.0065 34.6076 37.2329 33.868 33.5347C32.9435 28.9121 31.0944 25.214 28.3208 25.214C25.5472 25.214 22.7736 24.2894 19.0755 27.063"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M26.4715 28.912C24.6224 28.912 20.9243 29.4667 20.9243 31.6856C20.9243 33.9045 22.7734 35.6919 23.6979 36.3082H29.2451C29.5533 35.3837 29.9847 33.1648 29.2451 31.6856C28.5055 30.2063 25.2388 30.4529 23.6979 30.761C23.3897 31.6856 22.9583 33.7195 23.6979 34.4592C24.4375 35.1988 26.4715 35.3837 27.396 35.3837C27.7042 34.4592 28.1357 32.6101 27.396 32.6101C26.4715 32.6101 24.6224 31.6856 24.6224 32.6101C24.6224 33.5346 24.6224 34.4592 25.547 34.4592"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M44.9622 37.2328C43.1132 37.2328 39.415 37.7875 39.415 40.0064C39.415 42.2253 40.0314 44.6291 40.3396 45.5536C41.8805 46.4781 45.5169 48.1423 47.7358 47.4027C49.9547 46.663 51.7421 44.6291 52.3585 43.7045C52.9748 41.8555 53.6528 37.7875 51.4339 36.3083C48.6604 34.4592 44.9622 34.4592 41.2641 39.0819"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M48.6604 38.1574C47.7358 37.5411 45.517 36.8631 44.0377 39.082C42.5585 41.3008 42.805 43.0882 43.1132 43.7045C44.0377 44.3209 46.4414 45.1838 48.6604 43.7045C50.8793 42.2253 50.8177 40.0065 50.5095 39.082C49.2768 38.7738 46.6265 38.3424 45.8868 39.082C44.9623 40.0064 44.9623 41.8555 45.8868 42.78C46.6265 43.5196 48.044 43.0882 48.6604 42.78L48.6604 40.9309C48.9686 40.6227 49.0302 40.1913 46.8114 40.9309"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M33.868 47.4027L23.6982 42.78C23.0818 42.1636 21.1095 41.1158 18.151 41.8555C15.1925 42.5951 13.8365 44.0127 13.5283 44.629V50.1762C15.0692 51.1008 18.8906 53.1347 21.8491 53.8744C24.8076 54.614 32.9435 56.648 36.6416 57.5725C38.7988 57.5725 43.4831 57.3876 44.9624 56.648C46.4416 55.9083 44.9624 53.8744 44.0379 52.9498L32.9435 47.4026"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M21.8487 41.8555L15.377 51.1008"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M26.4717 43.7045L20 51.1008"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M29.245 45.5536L25.5469 52.9498"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M33.8678 48.3271L31.0942 54.7989"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M37.5659 51.1008L35.7168 54.7989"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M42.1884 52.0253L40.3394 55.7234"
        stroke="black"
        strokeWidth="1.84906"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M14.4526 43.7045C19.6916 46.7863 30.9093 53.1347 33.8678 53.8744C36.8263 54.614 40.0313 55.4153 41.264 55.7234"
        stroke="black"
        strokeWidth="1.84906"
      />
    </motion.svg>
  );

  const sketch4 = () => (
    <motion.svg
      variants={writingVariants}
      x="270"
      y="250"
      width="88"
      height="62"
      viewBox="0 0 88 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M58.8048 17.0977C57.8291 15.3903 55.4389 11.8294 53.6828 11.244C51.9267 10.6586 50.0243 9.04887 49.2926 8.31716C47.8291 7.09765 43.7316 5.39034 39.0487 8.31716C34.3657 11.244 31.2438 14.9025 30.2682 16.3659C28.8048 20.7561 25.8779 29.8293 25.8779 31C25.8779 32.1707 26.3657 35.3903 26.6096 36.8537C27.0974 39.0488 29.8291 43.439 36.8535 43.439C43.8779 43.439 50.5121 43.439 52.9511 43.439C54.9023 43.1951 59.2438 41.9756 60.9999 39.0488C62.756 36.122 63.6828 31 63.9267 28.8049C63.8284 26.6098 63.1055 21.6342 60.9999 19.2927"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M29.5366 5.39024C35.1464 10.2683 46.8049 20.1707 48.561 20.7561C50.3171 21.3415 60.0244 10.7561 64.6586 5.39024C65.1464 3.92683 65.683 1 63.9269 1C62.1708 1 55.3903 10.2683 52.2195 14.9024C51.7317 15.6341 50.3171 16.9512 48.561 16.3659C46.3659 15.6341 36.8537 6.85366 32.4635 5.39024"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M63.9268 2.46344L60.9999 8.3171V4.65856L56.6097 14.1708V9.78051L52.2194 17.8293L51.4877 16.3659L48.5609 20.0244C44.9024 16.122 36.8536 8.02442 33.9268 6.85368"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M35.3902 19.2927C34.4146 20.0244 32.4634 21.9268 32.4634 23.6829C32.4634 25.439 34.4146 27.3414 35.3902 28.0732C37.3414 28.3171 41.5365 28.5122 42.7073 27.3414C43.878 26.1707 44.1707 23.439 44.1707 22.2195C42.9512 21.2439 39.9268 19.2927 37.5853 19.2927"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M40.5123 21.4879C39.7806 21.244 38.0245 20.9025 36.8538 21.4879C35.6831 22.0732 34.9026 23.1952 34.6587 23.683C34.9026 24.6586 35.6831 26.6098 36.8538 26.6098C38.0245 26.6098 39.7806 26.122 40.5123 25.8781C41.0002 25.1464 41.8294 23.5366 41.2441 22.9512C40.6587 22.3659 38.5611 22.2195 37.5855 22.2195C36.8538 22.9512 35.5367 24.561 36.1221 25.1464C36.7075 25.7317 37.8294 25.8781 38.3172 25.8781C39.2928 25.6342 41.0977 25 40.5123 24.4147C39.927 23.8293 38.805 23.1951 38.3172 22.9512C38.0733 23.4391 37.7319 24.4147 38.3172 24.4147"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M58.805 21.4877C57.8294 21.2438 55.5855 21.1951 54.4148 22.9512C53.244 24.7073 53.4392 26.6097 53.6831 27.3415C54.4148 28.3171 56.4635 30.122 58.805 29.5366C61.1465 28.9512 62.2197 27.8293 62.4636 27.3415C62.9514 25.6342 63.049 21.9268 59.5368 20.7561"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M58.8052 22.2195C58.0735 22.2195 56.4638 22.6585 55.8784 24.4146C55.293 26.1707 55.6345 27.5853 55.8784 28.0731C57.3417 28.0731 60.2684 27.9268 60.2684 27.3414C60.2684 26.7561 60.7562 24.6585 61.0001 23.6829H57.3416V25.878L58.8052 26.6097V25.1463"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M60.9999 32.4634L44.9023 31.7317C43.9267 31.7317 41.3901 31.5853 39.0487 31C36.7072 30.4146 31.7316 31.2439 29.5365 31.7317C28.8048 32.7073 27.634 35.0975 28.8048 36.8536C29.9755 38.6097 32.2194 40.0244 33.195 40.5122H44.1706H50.0242L56.6096 39.7805C57.8291 39.0488 60.2681 37.439 60.2681 36.8536C60.2681 36.2683 60.7559 34.1707 60.9998 33.1951"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M34.6587 32.4634C34.6587 34.6585 34.805 39.1951 35.3904 39.7805"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M41.2441 33.1951C41.0002 34.9024 40.6587 38.6097 41.2441 39.7804"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M48.561 33.1951C48.3171 34.4146 48.122 37.2926 49.2927 39.0487"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M55.1465 33.9269V39.0488"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M30.2681 35.3903C31.7315 35.8781 35.0973 36.8537 36.8534 36.8537C38.6095 36.8537 41.4876 36.8537 42.7071 36.8537C43.9266 36.6098 46.951 36.2683 49.2925 36.8537C51.6339 37.439 56.6095 36.6098 58.8047 36.122"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M64.6587 28.0732C70.0245 25.878 81.6343 21.4878 85.1465 21.4878"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M84.4147 16.3658C82.2196 18.0732 77.683 21.9268 77.0977 23.6829C81.1952 24.2683 85.6342 25.3902 87.3416 25.878"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M25.8783 27.3415C22.4636 26.6098 14.7563 24.8537 11.2441 23.6829C6.85388 22.2195 5.39047 20.7561 1.73193 20.7561"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M9.04878 16.3658C9.78049 18.561 11.0976 23.0976 10.5122 23.6829C8.7561 24.2683 3.43902 25.878 1 26.6097"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M55.1465 43.439C56.1221 46.8536 58.0733 53.9755 58.0733 55.1463C58.0733 56.317 59.5367 59.0487 60.2684 60.2682"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M64.6587 57.3414C62.9514 55.878 59.2441 52.9512 58.0733 52.9512C56.9026 54.7073 54.6587 59.0487 53.6831 61"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M32.4633 43.439C31.2438 47.0975 28.8048 54.7073 28.8048 55.878C28.8048 57.0487 26.8535 59.7804 25.8779 60.9999"
        stroke="black"
        strokeWidth="1.46341"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M19.2925 58.0732C22.2193 57.3415 28.2193 55.8781 28.8047 55.8781C29.5364 55.8781 31.7315 60.2683 33.9266 61"
        stroke="black"
        strokeWidth="1.46341"
      />
    </motion.svg>
  );

  const sketchLogo = () => (
    <motion.svg
      variants={speedDrawingVariants}
      x="450"
      y="70"
      width="35"
      height="29"
      viewBox="0 0 35 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M23.0009 4.4431C22.3766 5.51533 15.4075 10.9094 12.001 13.4725C12.4183 14.1941 13.5212 15.7933 14.5934 16.4176C15.9337 17.1979 21.0303 24.4724 22.3265 25.945C23.3635 27.123 25.0071 27.5056 25.6993 27.5497C26.3915 27.5938 28.2 27.57 29.8965 27.122C31.593 26.6741 31.1824 25.1189 30.765 24.3974L21.6916 14.0896C22.4718 12.7494 29.6128 9.72981 32.4703 8.52155L32.4711 8.52121C34.7566 7.55478 33.5703 5.81122 32.6915 5.06024L26.5059 3.97133C25.5977 3.68182 23.6252 3.37087 23.0009 4.4431Z"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M1.63121 1.69218L9.98151 1.52898C10.6737 1.57306 12.0404 1.93809 11.9699 3.04559C11.8994 4.15308 11.5879 9.04452 11.4409 11.3518C11.4115 11.8133 11.0759 12.7185 9.96839 12.648C8.8609 12.5775 8.0344 13.9148 7.75959 14.5923C8.68251 14.6511 10.5195 14.9071 10.4842 15.4608C11.0027 16.0498 11.0442 17.5815 11.0001 18.2736L9.82303 25.8436C9.80834 26.0743 9.35482 26.6478 7.65831 27.0957C5.96181 27.5437 5.07622 27.6263 4.84549 27.6116C4.15331 27.5675 2.77776 27.3409 2.81302 26.7872C2.8571 26.095 3.29792 19.1731 3.43016 17.0966C3.53596 15.4353 3.65931 6.22296 3.70777 1.82443"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M31.131 7.7408L30.527 6.31235L30.3947 8.3889L28.4945 5.48792L28.2741 8.94884L26.4179 5.35568L26.1975 8.8166L24.2091 7.29999L24.6809 10.805L21.3522 8.50803L21.8681 11.3209L19.8797 9.80424L22.5031 23.1762L17.7591 10.3642L19.3066 18.8026L16.2865 11.6604L16.8024 14.4732L15.5062 13.0007"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M22.811 18.3308L24.4468 25.3849L24.7113 21.2318L26.4792 26.2093L26.8319 20.6719L29.2039 27.0778"
        stroke="black"
        strokeWidth="1.38717"
      />
    </motion.svg>
  );

  const arrowSketch2 = () => (
    <motion.svg
      variants={writingVariants}
      x="490"
      y="80"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M6.94174 1.3276L9.01857 1.1998C7.46006 2.22224 4.07464 4.42261 3.00108 5.04459C1.65913 5.82207 -0.417705 5.94987 1.65913 5.82207C3.73596 5.69427 8.06027 8.20776 9.44483 8.12256C10.5525 8.05439 12.2707 8.87519 12.9914 9.29411"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M14.7699 4.3204L4.38574 4.95941"
        stroke="black"
        strokeWidth="1.38717"
      />
    </motion.svg>
  );

  const phraseSketch = () => (
    <motion.svg
      variants={writingVariants}
      x="510"
      y="45"
      width="161"
      height="54"
      viewBox="0 0 161 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationWriting}
        d="M8.32189 27.2911C7.59442 27.2182 6.00416 27.1032 5.46291 27.2253C4.92166 27.3475 4.88815 27.8291 4.93905 28.0546L7.42689 29.6262C7.70331 29.8008 8.15139 30.3159 7.73231 30.9793C7.31323 31.6427 6.85922 32.3614 6.6846 32.6379C6.50998 32.9143 5.85958 33.3929 4.6549 33.096C3.45023 32.7991 3.04725 32.2738 2.99635 32.0483"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M11.487 28.7097C12.3891 28.5061 14.298 27.933 14.7171 27.2696C15.1362 26.6062 13.335 26.3965 12.382 26.3746C11.3562 27.0802 9.47046 28.5961 10.1339 29.0152C10.9632 29.539 12.0975 31.4161 14.1272 30.9579C15.751 30.5914 18.8632 29.889 20.2163 29.5835"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M26.8709 25.9843L25.6973 22.6481C26.5481 22.0605 28.7005 21.1015 30.5044 21.9666"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M33.1482 25.7949C33.0508 25.1082 32.6798 23.4795 31.9746 22.4587"
        stroke="black"
        strokeWidth="1.38717"
      />
      <circle
        cx="32.2729"
        cy="19.6144"
        r="0.693587"
        transform="rotate(-8.06974 32.2729 19.6144)"
        fill="black"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M35.9985 21.1877L35.5065 22.6585C35.6039 23.3452 35.4522 25.072 35.8957 25.4054C36.6798 25.9947 38.2175 25.3097 38.7402 25.7026L40.2162 21.2902C40.4776 21.4866 41.0392 22.1542 41.195 23.253C41.3508 24.3517 42.3054 24.4966 42.7632 24.4317C42.9921 24.3992 43.4304 24.197 43.3525 23.6476"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M45.7096 20.5112C44.9904 20.3797 43.5716 20.254 43.6495 20.8033C43.7273 21.3527 44.2695 21.8829 44.5309 22.0794L46.7858 23.1607C47.0471 23.3572 47.452 23.9069 46.9805 24.5341C46.509 25.1614 45.4106 24.9902 44.9204 24.8262"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M52.5765 19.5378C51.8573 19.4062 50.3011 19.3 49.8296 19.9272C49.2403 20.7113 48.7483 22.1821 49.5323 22.7714C50.1596 23.2429 51.1671 22.7732 51.5924 22.4794L55.4206 19.835C55.7144 20.2604 56.4589 21.229 57.0861 21.7004C57.7134 22.1719 58.328 22.2249 58.5569 22.1924"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M54.9341 16.4013C55.2279 16.8267 56.0902 17.6384 57.189 17.4827"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M59.3469 17.8772C60.295 17.9763 62.4853 18.2729 63.6619 18.6665C64.2113 18.5886 64.4135 19.0269 64.446 19.2558L58.8549 19.348C58.9198 19.8059 59.3438 20.8199 60.5204 21.2135C61.6971 21.6071 63.8225 21.4458 64.7381 21.316C65.5564 20.4995 67.2913 18.5722 67.6849 17.3956C68.39 18.4164 70.5274 19.3276 71.5079 19.6556C72.3586 19.068 74.0406 17.7554 73.9627 17.206C74.1574 18.5795 75.0389 19.8555 76.4123 19.6608C77.5111 19.505 79.0294 18.3557 79.6512 17.8005"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M84.5551 17.8057C83.3781 17.7391 80.9063 17.7627 80.4348 18.3899C79.9633 19.0172 79.9104 19.6318 79.9428 19.8607C80.2366 20.286 81.2363 21.0783 82.8844 20.8447C84.5325 20.611 85.4024 20.4877 85.6313 20.4552C86.0891 20.3903 86.9853 20.1231 86.9074 19.5738C86.8295 19.0244 85.7646 18.1013 85.2418 17.7084"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M96.1082 16.9859C96.3294 16.7452 96.9105 16.2578 97.4648 16.2344C98.0192 16.2109 99.1013 16.628 99.573 16.8394L101.018 18.1668L94.1465 19.8457C94.397 20.2979 95.1752 21.1906 96.284 21.1437C97.3927 21.0969 101.346 20.4669 103.184 20.1578"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M116.767 15.2914C115.834 15.0995 113.695 14.866 112.609 15.4672C111.524 16.0685 110.81 16.7003 110.589 16.941C110.83 17.1622 111.45 17.5988 112.004 17.5754C112.559 17.5519 113.602 17.045 114.054 16.7945L114.893 20.2301"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M108.657 20.4938L114.864 19.5372"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M114.834 18.8442C115.749 18.5742 117.71 17.8896 118.241 17.3118C118.992 18.6685 119.715 19.3321 120.408 19.3028"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M119.451 13.0952C119.942 13.7687 121.063 15.1097 121.617 15.0862"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M125.569 16.5838C124.636 16.4149 122.665 16.2065 122.243 16.7245C121.82 17.2425 121.732 17.788 121.741 17.996C121.944 18.4042 122.681 19.2066 124.011 19.1503C125.342 19.0941 126.044 19.0644 126.229 19.0566C126.599 19.041 127.332 18.8849 127.311 18.3856C127.29 17.8864 126.511 16.9608 126.123 16.5604"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M134.15 15.9449C133.447 15.7432 131.909 15.4843 131.378 16.0621C130.847 16.6399 130.734 17.2463 130.744 17.4773C130.994 17.9295 131.773 18.8222 132.881 18.7753C133.99 18.7284 136.558 18.1571 137.703 17.8772"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M136.893 15.1348C137.586 15.1055 139.11 15.0411 139.664 15.0176C140.219 14.9942 141.34 16.3352 141.831 17.0086C140.888 16.5857 138.729 15.8902 137.644 16.4915C136.559 17.0927 137.27 18.5899 137.761 19.2633C139.147 19.2047 142.317 18.6542 143.91 16.9207"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M146.077 18.9117C145.576 18.0073 144.706 16.054 145.237 15.4761C145.768 14.8983 147.769 15.1377 148.702 15.3296"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M153.206 16.5277C152.503 16.326 150.965 16.067 150.434 16.6449C149.77 17.3671 149.136 18.7823 149.858 19.446C150.436 19.9769 151.485 19.6086 151.937 19.3581L156.007 17.1034C156.257 17.5556 156.903 18.5928 157.481 19.1237C158.058 19.6546 158.665 19.7678 158.896 19.7581"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M155.86 13.6385C156.111 14.0907 156.889 14.9834 157.998 14.9365"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M14.342 40.8126C14.1013 40.5914 13.3426 40.1607 12.2338 40.2075C11.1251 40.2544 10.3859 40.2857 10.1549 40.2954C9.48151 40.7867 8.27909 41.902 8.85691 42.4329C9.43472 42.9639 11.8499 42.075 12.9853 41.5642L15.2986 47.02"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M17.7769 39.9732C17.7964 40.4352 17.9799 41.4918 18.5577 42.0228C19.1355 42.5537 20.2235 43.1094 20.6952 43.3208C21.1474 43.0703 22.1846 42.4248 22.7155 41.847C23.2465 41.2692 23.3206 39.7388 23.2913 39.0458C23.5907 40.653 24.732 43.5667 26.9026 42.3642"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M28.9528 41.5834C29.6555 41.7851 31.1937 42.044 31.7246 41.4662C32.2556 40.8884 32.3688 40.282 32.359 40.051C32.3492 39.82 32.0525 39.3697 30.9438 39.4166C29.835 39.4635 28.211 40.4577 27.5375 40.949C27.3261 41.4208 27.1862 42.4911 28.3184 42.9986C29.4506 43.5061 31.1196 43.5744 31.8126 43.5451C32.5055 43.5158 34.0184 43.1742 34.5259 42.042"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M36.5168 39.8752C36.0451 39.6637 35.1074 39.3794 35.1309 39.9338C35.1543 40.4882 35.6417 41.0692 35.8824 41.2904L39.4645 43.9158C39.715 44.368 40.0774 45.2783 39.5231 45.3017C38.9687 45.3251 36.9431 44.4852 35.9996 44.0623C35.7589 43.841 35.4101 43.2541 35.941 42.6763C36.472 42.0985 39.2984 39.9889 40.6453 39.0064C41.1073 38.9869 42.0371 39.0864 42.0605 39.6408C42.084 40.1951 41.6669 41.2772 41.4555 41.749C41.9467 42.4224 43.2065 43.7576 44.3152 43.7107C45.424 43.6638 46.5861 42.6891 47.0285 42.2075C47.7508 42.8712 49.4666 44.0482 50.5519 43.447C51.6373 42.8457 51.85 41.3095 51.8207 40.6165C51.5604 39.9333 50.4854 38.5904 48.2679 38.6841C46.0505 38.7779 45.5351 39.7253 45.5547 40.1872"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M34.3496 37.884C34.7823 37.1715 35.9248 35.7348 37.0336 35.6879C38.1423 35.641 42.6164 36.3775 44.7148 36.7516"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M61.081 38.6446C61.5623 38.8333 62.6631 39.1983 63.2157 39.1485C63.7683 39.0987 64.8275 39.0031 65.288 38.9616C65.2465 38.5011 65.0128 37.4543 64.4104 36.9516C63.8079 36.4488 62.2757 36.4477 61.5849 36.51C60.8941 36.5723 59.5375 36.9732 59.6372 38.0784C59.7369 39.1837 60.2638 39.879 60.5149 40.0885C61.7285 40.6754 64.2814 41.6987 64.7842 41.0963C65.2869 40.4938 67.5907 38.7539 68.6797 37.9593C69.2666 36.7457 70.4281 34.1804 70.3782 33.6277C70.0949 34.7145 68.8043 39.3409 70.9389 39.8448C72.6466 40.2479 73.8285 38.4235 74.2059 37.4609C74.6872 37.6497 75.9261 38.0022 77.0314 37.9025C78.1366 37.8028 78.8319 37.2759 79.0414 37.0248C78.9583 36.1038 78.5159 34.2866 77.4106 34.3863C76.3054 34.486 75.1496 35.0545 74.7098 35.3263C74.3739 36.7494 74.531 39.5208 77.8467 39.2218C81.1625 38.9227 82.3274 37.4249 82.4953 36.7133C82.8087 37.6136 83.6992 39.2511 84.7546 38.5988C85.81 37.9465 86.0323 37.3229 86.0115 37.0926C86.3041 37.7626 87.5675 38.9022 90.2808 38.1004"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M91.7124 36.5785C92.4239 36.7465 93.9727 36.9318 94.4755 36.3293C94.9783 35.7269 95.0625 35.1157 95.0417 34.8855C95.0209 34.6552 94.7031 34.2196 93.5978 34.3193C92.4926 34.419 90.9178 35.4895 90.2685 36.0124C90.0798 36.4936 89.9911 37.5695 91.1462 38.0224C92.3013 38.4754 93.9717 38.464 94.6625 38.4017C95.3532 38.3394 96.848 37.926 97.301 36.7709"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M97.9413 38.106C98.5697 37.3529 99.8143 35.7086 99.7644 35.1559C99.9513 37.2283 100.891 39.9291 102.211 39.1137C103.266 38.4614 105.247 36.7507 106.106 35.9768L105.732 31.8321C105.71 34.1555 106.217 38.7524 108.428 38.5531C110.638 38.3537 111.108 37.3828 111.066 36.9223C111.128 37.6131 111.806 38.9448 114.016 38.7454C116.227 38.5461 116.655 37.1147 116.592 36.4239C116.551 35.9634 116.041 34.9416 114.333 34.5385C112.625 34.1353 111.319 34.5782 110.879 34.8499C112.993 35.1236 117.46 35.2314 118.416 33.4739"
        stroke="black"
        strokeWidth="1.38717"
      />
      <motion.path
        variants={pathAnimationWriting}
        d="M100.206 32.3306C101.107 32.0173 103.045 31.3782 103.598 31.3283C104.151 31.2785 106.633 31.5189 107.805 31.6453C108.286 31.8341 109.375 32.0609 109.877 31.4584"
        stroke="black"
        strokeWidth="1.38717"
      />
    </motion.svg>
  );

  const sketch5 = () => (
    <motion.svg
      variants={speedDrawingVariants}
      x="460"
      y="145"
      width="51"
      height="50"
      preserveAspectRatio="none"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M19.7748 5.77315C14.0469 6.7278 2.40016 10.3555 1.63643 17.229C0.681783 25.8208 -0.272869 38.2313 7.36434 43.0046C15.0016 47.7778 20.7298 48.7325 27.4123 48.7325C34.0949 48.7325 45.5507 44.9139 49.3693 33.458C52.4242 24.2934 43.6414 13.7286 38.8682 9.59176C31.5489 5.45493 15.0013 0.236174 7.36434 12.4557C6.60087 13.9832 5.77357 16.2744 5.45535 17.229"
        stroke="black"
        strokeWidth="1.9093"
      />
      <motion.path
        variants={pathAnimationLong}
        d="M1.50049 1C5.95553 5.13682 16.0112 14.1742 20.5935 17.2291C25.1758 20.284 27.5943 23.5934 28.2307 24.8663C31.7311 20.4112 39.8881 12.2542 41.5958 10.5465C43.5051 8.63721 45.7327 6.40969 46.3691 4.81861"
        stroke="black"
        strokeWidth="1.9093"
      />
    </motion.svg>
  );

  const sketch6 = () => (
    <motion.svg
      variants={speedDrawingVariants}
      x="425"
      y="240"
      width="100"
      height="99"
      viewBox="0 0 100 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M48.1319 12.4344C40.6431 11.2152 23.7764 10.4889 16.2204 17.3373C8.66449 24.1857 6.74846 40.3125 6.73494 47.5199C8.39606 56.4556 15.0622 75.5812 28.4376 80.5971C41.813 85.6131 57.6429 82.2587 63.8858 79.9545C71.6963 73.4704 84.7773 53.9754 74.6174 27.8674C68.0583 17.1926 53 11.5 46.5 11.5"
        stroke="black"
        strokeWidth="2.47622"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M73.9345 14.0417C68.6962 22.4683 57.6087 39.9726 55.1651 42.5769C52.7215 45.1813 50.2081 52.2093 49.2569 55.3977C50.1009 59.0149 52.7373 67.0451 56.5313 70.2282C61.2738 74.2071 87.2771 82.125 88.4828 81.8437C89.6885 81.5624 94.8732 76.5386 95.5163 73.8458C96.1594 71.153 96.2398 66.0488 94.4714 63.9187C92.7031 61.7885 86.755 58.0909 84.0621 57.4478C81.3692 56.8048 73.8535 57.287 73.2909 54.8755C72.8408 52.9464 72.7819 49.0613 72.8087 47.3599L82.6154 34.9008C83.5398 33.4138 85.1074 29.2339 83.9821 24.411C82.8568 19.5881 77.3775 17.9001 74.7785 17.6589"
        stroke="black"
        strokeWidth="2.47622"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M42.585 59.4968C43.7506 55.4108 46.0416 45.9767 45.8809 40.9287C45.7201 35.8808 45.546 30.4121 45.4791 28.3088L46.8457 17.8191C46.6581 17.0152 45.2622 15.3915 41.1788 15.3272C37.0954 15.2628 30.0192 18.3547 26.9914 19.9088C25.5714 21.0878 23.0527 25.9135 24.3387 35.7844C25.6247 45.6552 27.5805 58.7601 28.3977 64.0787C28.5584 66.5839 29.8284 72.3902 33.6224 75.5733C37.4163 78.7565 41.7676 79.6059 43.469 79.6327C47.18 79.1906 54.192 77.6393 52.5522 74.9707C50.5025 71.6348 46.3227 70.0673 44.8356 69.1429C43.646 68.4034 42.2233 63.3956 41.6607 60.9842"
        stroke="black"
        strokeWidth="2.47622"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M27.554 22.3205L42.9471 17.4576L23.8564 28.2687L40.7365 24.3302L27.3931 32.529L39.4504 29.7157L26.107 37.9145L24.9012 38.1958L47.81 32.8507L29.0006 44.8675L44.3937 40.0046L30.4072 50.8962L41.2588 48.3643L27.3527 54.1516L37.5611 54.3125L24.7804 64.9227L44.9964 58.9345L31.9343 68.339L46.403 64.9631L32.1352 74.6489L46.6039 71.2731L41.4192 76.2968L47.4478 74.8902"
        stroke="black"
        strokeWidth="2.47622"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M73.854 19.146L88.3628 32.2885L72.5679 24.5315L79.36 31.8463L64.4091 27.7065L71.2013 35.0213L61.9172 33.3734L74.3762 43.1801L59.4253 39.0402L70.3973 47.9225L54.522 45.2698L72.0852 55.1569L53.7985 53.0667L91.4169 67.1741L52.5123 58.4523L91.8991 74.6898L55.6873 66.6111L78.0734 75.3729"
        stroke="black"
        strokeWidth="2.47622"
      />
    </motion.svg>
  );

  const sketch7 = () => (
    <motion.svg
      variants={writingVariants}
      x="555"
      y="130"
      width="110"
      height="231"
      viewBox="0 0 110 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathAnimationShort}
        d="M32.4706 35.168C26.7759 34.5685 14.3076 34.9881 9.9916 41.4621C5.67563 47.936 2.19888 64.5405 1 72.0335L17.1849 101.706C21.9804 107.4 33.7294 119.329 42.3613 121.487C50.9933 123.645 68.7367 122.386 76.5294 121.487C84.9216 114.893 101.706 99.9075 101.706 92.7142C101.706 85.5209 101.706 70.5349 101.706 63.9411L89.1176 37.8655C84.3221 34.5685 72.7529 27.7949 64.8403 27.0755C56.9277 26.3562 42.9608 30.3725 36.9664 32.4705"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M13.5884 13.5882C25.2775 29.773 52.2522 63.7613 66.6388 70.2352C73.1127 70.2352 77.7284 68.4369 79.227 67.5378L95.4119 43.2604L108 5.49574L99.9077 0.999939C96.6108 2.19882 90.017 5.67557 90.017 9.99154C90.017 14.3075 87.6192 20.7815 86.4203 23.4789C84.0226 27.9747 79.227 37.326 79.227 38.7646C79.227 40.2033 75.6304 44.1596 73.8321 45.9579L57.6472 34.2688C51.9525 28.5742 40.0237 16.4655 37.8657 13.5882C35.7077 10.7109 30.3727 5.19602 27.9749 2.79826C25.2986 2.79826 19.5736 4.23691 18.0842 9.99154"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M16.2856 21.6806L24.3781 10.8907L22.5798 28.8739L33.3697 15.3865L29.773 34.2689L45.0588 15.3865L37.8655 36.9663L45.0588 27.9747L40.563 45.0588L52.252 30.6722L46.8571 49.5546L54.9495 36.9663L52.252 51.3529L60.3445 38.7647L54.9495 62.1428L63.9411 47.7563V61.2436L70.2352 51.3529V62.1428L81.9243 40.563L79.2268 61.2436L91.8151 17.1848L90.9159 47.7563L102.605 7.29407"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M44.1599 63.9411C37.8657 64.2408 24.0187 64.3007 18.9834 62.1427C13.9481 59.9848 9.69207 62.4425 8.19347 63.9411C7.59403 71.4341 8.3733 87.4991 16.2859 91.8151C24.1985 96.131 36.367 97.21 41.4621 97.21C49.8543 95.4117 67.7176 91.9949 72.0335 92.7142C76.3495 93.4335 86.4201 92.4145 90.9159 91.8151C92.1148 90.3165 94.5125 86.4201 94.5125 82.8235C94.5125 79.2268 93.9131 69.3361 93.6134 64.8403C92.1148 64.2408 88.5781 63.4016 86.4201 64.8403C84.2621 66.2789 78.9271 72.0335 76.5293 74.731C73.8319 76.8291 67.8974 81.0251 65.7394 81.0251C63.5814 81.0251 54.0504 73.8319 49.5546 70.2352C45.9579 66.9383 37.8655 60.1646 34.2688 59.4453"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M28.8741 115.193C26.7761 114.894 22.2204 114.474 20.7817 115.193C19.343 115.913 17.7845 119.09 17.1851 120.588V132.277C19.2831 134.076 24.5582 137.672 28.8741 137.672C33.1901 137.672 39.0646 135.874 41.4624 134.975C41.7621 131.378 40.743 122.746 34.2691 116.992"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M18.0842 131.378C17.7845 129.28 17.3649 125.084 18.0842 125.084C18.8035 125.084 24.9776 131.678 27.9747 134.975L20.7814 119.689L35.1679 134.975L27.9747 115.193L36.9663 128.681"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M86.4198 114.294C85.8204 118.49 84.9812 127.062 86.4198 127.781C87.8585 128.501 93.6133 128.681 96.3109 128.681L104.403 125.983C104.104 123.585 102.785 118.071 99.9076 115.193C97.0302 112.316 90.916 110.997 88.2185 110.697"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M90.0166 126.882L86.4199 115.193L97.2098 127.781L91.8149 116.092L100.806 124.185L96.3107 113.395"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M22.5796 138.571C21.6805 143.067 20.7813 152.958 24.3779 156.555C27.9746 160.151 33.6693 162.849 36.0671 163.748H45.9579"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M37.8657 136.773L46.8573 148.462"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M50.4536 149.361C50.1538 148.462 49.1947 147.563 47.7561 151.16C46.3174 154.756 45.3583 156.255 45.0586 156.555C45.0586 159.552 45.7779 165.906 48.6552 167.345C51.5325 168.783 57.6468 169.143 60.3443 169.143L67.5376 161.95L66.6384 153.857C64.5404 151.759 59.0855 147.563 54.0502 147.563"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M52.2521 165.546L45.958 157.454L53.1513 163.748L49.5546 153.857L63.042 165.546L54.9496 152.958L62.1429 154.756L59.4454 149.361L66.6387 156.555"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M90.0166 128.681C91.5152 132.577 94.3326 140.909 93.6132 143.067"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M100.807 126.882C101.706 130.179 102.785 138.392 99.9077 144.866"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M101.706 149.361C102.005 147.563 101.706 143.966 98.109 143.966C94.5124 143.966 91.2155 143.966 90.0166 143.966L88.2183 151.16L92.7141 157.454L100.806 155.655L102.605 149.361"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M91.8149 154.756L90.0166 144.866L93.6132 152.059V147.563L98.109 156.555L97.2099 149.361L96.3107 143.067L101.706 152.059"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M37.8659 164.647C37.5662 177.235 37.1466 202.951 37.8659 205.109C41.4623 205.829 50.7536 207.207 54.9497 207.807L65.7396 185.328C68.7368 184.429 74.7312 183.17 74.7312 185.328C74.7312 187.486 74.7312 201.213 74.7312 207.807H89.1177C91.2158 194.319 95.232 165.726 94.5127 159.252"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M36.9663 205.109C37.266 209.006 37.6856 217.338 36.9663 219.496C36.9663 223.092 44.1596 226.389 47.7562 227.588C51.6526 227.888 59.6251 227.768 60.3445 224.891C61.0638 222.013 58.2464 216.499 56.7478 214.101L54.9495 207.807L38.7646 205.109"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M39.6638 224.891L36.9663 217.697L42.3613 222.193L37.8655 210.504L55.8487 228.487L43.2604 208.706L54.9495 220.395L49.5545 207.807L54.0503 211.403"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M75.63 209.605C75.0305 212.902 74.0115 219.855 74.7308 221.294C75.4501 222.733 78.0279 223.692 79.2268 223.992L89.1176 226.689L96.3109 223.092C97.21 222.493 98.6487 220.755 97.21 218.597C95.7714 216.439 92.4145 214.7 90.9159 214.101L90.0167 209.605"
        stroke="black"
        strokeWidth="1.79832"
      />
      <motion.path
        variants={pathAnimationShort}
        d="M76.5293 222.193L74.731 215L87.3192 230.286L78.3276 214.101L85.5209 221.294L80.1259 212.302L92.7141 222.193L87.3192 214.101L95.4116 217.697"
        stroke="black"
        strokeWidth="1.79832"
      />
    </motion.svg>
  );

  return (
    <svg
      ref={ref}
      width="80%"
      height="90%"
      preserveAspectRatio="none"
      viewBox="0 0 804 449"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M615.891 272.939V274.009C613.588 271.311 611.239 268.636 608.912 265.961C607.936 264.844 606.959 263.728 605.982 262.611C601.841 257.912 597.677 253.229 593.491 248.562C589.28 243.909 585.07 239.257 580.813 234.628C579.79 233.512 578.766 232.395 577.72 231.279C576.882 230.341 575.972 229.47 574.998 228.674C574.015 227.856 572.887 227.232 571.672 226.836C570.274 226.448 568.798 226.432 567.392 226.79C565.787 227.162 564.252 227.743 562.693 228.232C561.088 228.767 559.506 229.278 557.901 229.79C554.691 230.837 551.505 231.884 548.294 232.931C548.256 232.946 548.214 232.954 548.173 232.954C548.131 232.954 548.09 232.945 548.051 232.929C548.013 232.913 547.978 232.889 547.948 232.859C547.919 232.829 547.896 232.794 547.88 232.756L547.876 232.744C547.858 232.7 547.849 232.653 547.851 232.605C547.854 232.557 547.866 232.511 547.888 232.469C547.911 232.426 547.942 232.39 547.98 232.361C548.018 232.332 548.062 232.312 548.109 232.302C548.9 232.047 549.714 231.791 550.528 231.535C553.761 230.465 556.971 229.418 560.204 228.372C561.809 227.836 563.414 227.325 565.019 226.813C566.508 226.247 568.08 225.933 569.672 225.882C572.27 226.069 574.706 227.213 576.51 229.092C577.51 230.023 578.418 231 579.325 232C580.395 233.163 581.465 234.326 582.535 235.489C586.838 240.188 591.11 244.902 595.351 249.632C599.608 254.377 603.826 259.13 608.005 263.891C608.308 264.24 608.61 264.589 608.912 264.938C611.262 267.589 613.565 270.264 615.891 272.939Z"
        fill="white"
      />
      <path
        d="M574.637 232.96C573.758 231.828 572.587 230.957 571.25 230.442L569.927 230.489C580.309 246.644 593.91 260.484 609.881 271.147C598.133 258.418 586.385 245.689 574.637 232.96Z"
        fill="white"
      />
      <path
        d="M802.79 398.312L750.094 116.16C749.193 111.509 746.737 107.302 743.131 104.229C739.525 101.156 734.982 99.4001 730.247 99.2482C729.82 99.2387 729.394 99.2387 728.968 99.2482L693.071 100.536L689.207 100.678L671.292 101.322L667.428 101.454L655.904 101.871L423.532 110.213C423.181 110.232 422.822 110.232 422.471 110.223L370.4 109.134L351.131 108.736L350.515 108.727L336.292 108.424L212.36 105.848L179.503 105.157L163.301 104.825L159.419 104.74L140.944 104.361L137.071 104.276L118.171 103.888L114.298 103.803L93.5321 103.367L90.2748 103.301L71.7531 102.913L68.8272 102.856H68.4011C63.484 102.876 58.7323 104.634 54.986 107.819C51.2397 111.003 48.74 115.41 47.9288 120.26L0.270611 413.046C-0.213355 416.012 -0.046731 419.047 0.758902 421.943C1.56453 424.838 2.98989 427.523 4.93631 429.813C6.88273 432.102 9.30364 433.941 12.0313 435.202C14.759 436.464 17.7283 437.117 20.7334 437.116H294.429C298.458 437.128 302.378 438.421 305.622 440.809L310.811 444.654C314.387 447.286 318.709 448.712 323.149 448.726C376.01 434.774 431.586 434.774 484.447 448.726C487.172 448.733 489.872 448.199 492.39 447.155C494.907 446.112 497.193 444.579 499.114 442.646C502.4 439.362 506.785 437.409 511.424 437.164L783.492 422.827C786.442 422.673 789.325 421.891 791.948 420.531C794.571 419.172 796.874 417.268 798.701 414.947C800.529 412.625 801.839 409.94 802.544 407.071C803.249 404.202 803.333 401.216 802.79 398.312V398.312Z"
        fill="#CCCCCC"
      />
      <path
        d="M420.886 429.725C415.384 435.067 409.788 438.465 402.348 439.184V111.377C409.253 110.302 415.579 106.691 420.217 101.179C427.663 92.5499 436.597 85.4994 446.54 80.4046C453.82 76.5487 461.387 73.33 469.165 70.7811C491.934 63.1009 588.434 60.3529 623.117 58.6943L705.442 58.1852H705.581C711.284 58.191 716.771 60.491 720.927 64.6181C725.083 68.7451 727.597 74.3896 727.957 80.4046L731.531 140.133L733.567 174.275L747.148 362.409C747.434 367.139 746.835 371.882 745.385 376.368C743.935 380.853 741.663 384.992 738.699 388.548C735.735 392.105 732.137 395.01 728.11 397.096C724.083 399.182 719.707 400.409 715.231 400.706L715.23 400.706C714.671 400.739 714.112 400.772 713.537 400.772C575.35 402.94 447.208 404.27 420.886 429.725Z"
        fill="#E4E4D0"
      />
      <path
        d="M421.94 430.973C416.126 436.315 410.211 439.713 402.348 440.432V72.9653C409.646 71.8899 416.332 68.2794 421.234 62.767C429.103 54.138 438.545 47.0875 449.053 41.9927C456.747 38.1368 464.744 34.9182 472.964 32.3692C497.029 24.689 562.048 17.6548 598.703 15.9961L685.708 15.4871H685.856C691.883 15.4928 697.682 17.7929 702.075 21.92C706.467 26.047 709.124 31.6915 709.504 37.7065L713.282 97.4346L715.433 131.577L729.786 359.371C730.088 364.101 729.455 368.844 727.923 373.329C726.391 377.814 723.99 381.954 720.857 385.51C717.724 389.067 713.921 391.971 709.666 394.057C705.41 396.144 700.785 397.371 696.055 397.668C695.463 397.701 694.872 397.734 694.265 397.734C548.22 399.902 449.76 405.518 421.94 430.973Z"
        fill="#F7F8ED"
      />
      <path
        d="M705.252 381.956C712.258 375.44 716.389 366.408 716.738 356.847C716.785 355.489 716.757 354.129 716.656 352.774L712.518 298.301L697.639 102.497L695.061 68.6179L692.466 34.64C691.807 25.7163 687.85 17.3575 681.366 11.1917C674.881 5.02594 666.333 1.49483 657.388 1.2862C593.423 -0.356038 477.596 2.53429 420.758 40.6177C415.194 44.415 408.554 46.3156 401.823 46.0371V440.765C409.408 441.109 416.645 439.052 422.992 434.886C473.49 401.811 557.441 392.615 624.264 391.186C644.726 390.759 663.579 391.038 679.377 391.596C688.939 391.94 698.246 388.472 705.252 381.956Z"
        fill="#FFFFF7"
      />
      <path
        d="M383.286 429.725C388.787 435.067 394.384 438.465 401.824 439.184V111.377C394.919 110.302 388.592 106.691 383.954 101.179C376.508 92.5499 367.574 85.4994 357.631 80.4046C350.351 76.5487 342.784 73.33 335.007 70.7811C312.237 63.1009 215.737 60.3529 181.055 58.6943L98.7298 58.1852H98.5899C92.8871 58.191 87.4002 60.491 83.2441 64.6181C79.0879 68.7451 76.5744 74.3896 76.214 80.4046L72.64 140.133L70.6044 174.275L57.0235 362.409C56.7377 367.139 57.3368 371.882 58.7865 376.368C60.2361 380.853 62.508 384.992 65.4721 388.548C68.4363 392.105 72.0346 395.01 76.0614 397.096C80.0882 399.182 84.4645 400.409 88.9402 400.706L88.9416 400.706C89.5006 400.739 90.0596 400.772 90.634 400.772C228.821 402.94 356.963 404.27 383.286 429.725Z"
        fill="#E4E4D0"
      />
      <path
        d="M382.231 430.973C388.046 436.315 393.96 439.713 401.823 440.432V72.9653C394.525 71.8899 387.839 68.2794 382.937 62.767C375.068 54.138 365.627 47.0875 355.118 41.9927C347.424 38.1368 339.427 34.9182 331.207 32.3692C307.143 24.689 242.123 17.6548 205.469 15.9961L118.463 15.4871H118.315C112.288 15.4928 106.489 17.7929 102.097 21.92C97.7043 26.047 95.0478 31.6915 94.6669 37.7065L90.8898 97.4346L88.7384 131.577L74.3853 359.371C74.0833 364.101 74.7165 368.844 76.2486 373.329C77.7807 377.814 80.1817 381.954 83.3144 385.51C86.447 389.067 90.2499 391.971 94.5057 394.057C98.7614 396.144 103.387 397.371 108.117 397.668C108.708 397.701 109.299 397.734 109.907 397.734C255.951 399.902 354.412 405.518 382.231 430.973Z"
        fill="#F7F8ED"
      />
      <path
        d="M98.9192 381.956C91.9134 375.44 87.7818 366.408 87.4326 356.847C87.386 355.489 87.4135 354.129 87.5147 352.774L91.653 298.301L106.532 102.497L109.11 68.6179L111.705 34.64C112.364 25.7163 116.321 17.3575 122.805 11.1917C129.29 5.02594 137.837 1.49483 146.783 1.2862C210.748 -0.356038 326.575 2.53429 383.413 40.6177C388.977 44.415 395.617 46.3156 402.348 46.0371V440.765C394.763 441.109 387.526 439.052 381.179 434.886C330.681 401.811 246.729 392.615 179.907 391.186C159.445 390.759 140.592 391.038 124.793 391.596C115.232 391.94 105.925 388.472 98.9192 381.956Z"
        fill="#FFFFF7"
      />
      <path d="M401.821 46H398.404V438.433H401.821V46Z" fill="#E6E6E6" />
      <motion.g
        variants={speedDrawingVariants}
        initial="hidden"
        animate={controls}
      >
        {keybizSketch()}
        {arrowSketch()}
        {byzWordSketch()}
        {sketch1()}
        {sketch2()}
        {sketch3()}
        {sketch4()}
        {sketchLogo()}
        {arrowSketch2()}
        {phraseSketch()}
        {sketch5()}
        {sketch6()}
        {sketch7()}
      </motion.g>
    </svg>
  );
}
