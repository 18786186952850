import React, { Children, useEffect } from "react";
import parse from "html-react-parser";
import axiosInstance from "../../../api/interceptor";

import { ScrollingLine } from "../../../components/ScrollingLine/ScrollingLine";
import { gearAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { Highlight } from "../../../components/Highlight/Highlight";
import { SwiperImageDescription } from "../SubCyberSecurity/SubCyberSecurity";
import { BulletList } from "../../../components/BulletList/BulletList";
import { HighlightType } from "../../../types/types";

import "./SubEngineering.css";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";

const { REACT_APP_BASE_URL } = process.env;

type SubEngineeringType = {
  pageTitle: string;
  pageDescription: string;
  engineeringMobileIcon: { url: string };
  activities: {
    activity: Array<HighlightType>;
    sectionTitle: string;
  };
  engineeringServices: Array<HighlightType>;
  section2Title: string;
  engineeringHighlights: Array<HighlightType>;
  section3Title: string;
};

type GroupType = {
  title: string;
  content: any;
};

const Group = ({ title, content }: GroupType) => (
  <div className="group">
    <div className="title-group">{title}</div>
    <div className="group-content-mobile">
      <SwiperImageDescription content={content} slidesPerView={1} />
    </div>
    <div className="group-content-desktop">
      {Children.toArray(content?.map((el: any) => <Highlight {...el} />))}
    </div>
  </div>
);

export function SubEngineering() {
  const [engineering, setEngineering] = React.useState<SubEngineeringType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showAnimatedLine, setShowAnimatedLine] =
    React.useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("engineering")
      .then((res) => {
        setEngineering(res.data);
        setShowAnimatedLine(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="engineering-page">
      {isLoading && <CustomSpinner />}
      {showAnimatedLine && <ScrollingLine page="engineering" />}
      <div className="content">
        <div className="background">{gearAnimated()}</div>
        <div className="background-mobile">
          <img
            src={`${REACT_APP_BASE_URL}${engineering?.engineeringMobileIcon.url}`}
            alt=""
          />
        </div>
        <div className="content-description fade-once">
          <div className="title-content">{engineering?.pageTitle}</div>
          <div className="engineering-content">
            {parse((engineering?.pageDescription as string) ?? "")}
          </div>
          <BulletList
            title={engineering?.activities.sectionTitle as string}
            content={
              engineering?.activities.activity ?? [
                { title: "", description: "" },
              ]
            }
          />
          <div className="services-section">
            <Group
              title={engineering?.section2Title as string}
              content={engineering?.engineeringServices}
            />
          </div>

          <div className="highlights-section">
            <Group
              title={engineering?.section3Title as string}
              content={engineering?.engineeringHighlights}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
