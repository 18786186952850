import { motion } from "framer-motion";

const pathAnimation = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      duration: 8,
      ease: "easeInOut",
    },
  },
};

export function AnimatedLine() {
  return (
    <svg
      className="linea"
      width="100%"
      height="500vh"
      viewBox="0 0 1300 4570"
      preserveAspectRatio="none"
      stroke="null"
    >
      <motion.path
        variants={pathAnimation}
        initial="hidden"
        animate="visible"
        id="motionPath"
        d="M 0 281 H 457.5 V 613.5 H 1063.5 V 798.5 V 853 H 667 V 918 M 931 1385 V 1420.5 H 250.029 V 1610 V 1647 H 727 V 1730 M 479.5 2132.5 V 2160 H 1042 V 2300.5 V 2409 H 258 V 2930.5 H 985.909 V 3050.5 V 3204 H 578 V 3250.5"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
}
