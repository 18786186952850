import axios, {AxiosResponse} from 'axios';
//import i18n from "i18next";

const {REACT_APP_BASE_URL} = process.env;
const TIMEOUT = 60 * 1000;

//?_local=${i18n.language}

// interface lngMapI {
//     [key: string]: string | null
// }

// const getLanguage = () => {
//     const lngMap: lngMapI = {
//         it: null,
//         en: 'en',
//         default: null
//     }

//     return lngMap[i18n.language || "default"] ?
//       `?_locale=${lngMap[i18n.language]}` : '';
// }

const axiosInstance = axios.create({
    timeout: TIMEOUT,
    baseURL: REACT_APP_BASE_URL
});

axiosInstance.interceptors.request.use(
    config => {
        const {url} = config;
        config.url = `${url}`;
        /*
                const token = localStorage.getItem('token');
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
        */

        return config;
    },
    error => {
        Promise.reject(error)
    }
);

const errorHandler = (error: any) => {
    const {status, data: {message}} = error.response;
    let messaggio;

    switch (status) {
        case 401: {
            messaggio = 'Non sei autorizzato. Controlla le tue credenziali';
            break;
        }
        default:
            messaggio = message ? message : 'Errore generico!'

    }

    return Promise.reject({messaggio})
};

const successHandler = (response: AxiosResponse) => {
    return response
};

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

export default axiosInstance;
