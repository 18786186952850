import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { Byz1, Byz2 } from "./ByzDigitalization";

const arrowVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 2,
    },
  },
};

const pathVariants1 = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      duration: 1,
      delay: 1,
      ease: "easeInOut",
    },
  },
};

const pathVariants2 = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      delay: 2,
      ease: "easeInOut",
    },
  },
};

type ByzContentType = {
  className: string;
};

export function MobileByzDigitalization({ className }: ByzContentType) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  return (
    <div className={className} ref={ref}>
      <Byz1 />
      <motion.svg
        animate={controls}
        fill="none"
        initial="hidden"
        ref={ref}
        variants={arrowVariants}
        viewBox="0 -19 14 102"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M13.062 53.5773L9.35067 61L1.00016 58.5258"
          variants={pathVariants1}
          stroke="white"
          strokeWidth="1.23711"
          strokeLinecap="round"
        />
        <motion.path
          d="M5.94852 1C1.72172 13.9897 1.18564 41.4536 9.35058 61"
          variants={pathVariants1}
          stroke="white"
          strokeWidth="1.23711"
          strokeLinecap="round"
        />
      </motion.svg>
      <Byz2 />
      <motion.svg
        animate={controls}
        fill="none"
        height="62"
        initial="hidden"
        ref={ref}
        variants={arrowVariants}
        viewBox="0 -19 14 102"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "scaleX(-1)" }}
      >
        <motion.path
          d="M13.062 53.5773L9.35067 61L1.00016 58.5258"
          strokeLinecap="round"
          stroke="white"
          strokeWidth="1.23711"
          variants={pathVariants2}
        />
        <motion.path
          d="M5.94852 1C1.72172 13.9897 1.18564 41.4536 9.35058 61"
          strokeLinecap="round"
          stroke="white"
          strokeWidth="1.23711"
          variants={pathVariants2}
        />
      </motion.svg>
      <Byz3Mobile />
    </div>
  );
}

function Byz3Mobile() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!inView) {
      controls.start("hidden");
    } else controls.start("visible");
  }, [controls, inView]);

  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 1.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      ref={ref}
      width="245"
      height="368"
      viewBox="0 0 245 368"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M191.477 218.417C181.307 230.202 163.5 224.546 156.735 221.643C162.25 217.09 161.661 196.951 162.442 190.353C164.808 195.689 176.036 198.266 194.642 196.478C195.023 197.555 192.136 212.845 191.477 218.417Z"
        fill="white"
      />
      <path
        d="M139.243 197.633C144.997 191.591 154.572 191.345 160.63 197.085C166.687 202.824 166.934 212.374 161.18 218.417C155.426 224.459 145.851 224.704 139.793 218.965C133.735 213.226 133.489 203.675 139.243 197.633Z"
        fill="white"
      />
      <path
        d="M184.378 190.854C182.056 192.922 180.751 197.371 180.212 199.113C179.85 201.135 177.935 213.016 178.674 215.113C179.598 217.735 180.85 222.613 187.034 225.098C193.218 227.583 205.55 225.369 212.042 223.101C218.534 220.834 221.557 208.497 218.259 202.547C215.622 197.786 208.624 195.276 205.082 196.478C203.341 196.903 201.011 198.247 197.38 198.566C194.735 198.798 196.498 197.257 197.037 194.271C197.158 193.601 197.38 191.643 197.38 190.854C197.627 187.941 192.729 186.328 191.477 186.641C188.646 187.348 186.7 188.787 184.378 190.854Z"
        fill="white"
      />
      <path
        d="M137.837 200.337C118.94 189.245 97.821 195.715 89.6233 200.337C74.1191 228.697 66.9632 331.834 66.38 342.16C77.1698 348.365 99.1636 346.135 102.906 345.02C102.42 333.191 105.25 320.176 106.903 314.892C109.712 305.907 111.867 300.216 115.579 295.808C117.899 296.922 119.337 297.585 122.673 297.585C121.944 306.165 122.579 332.814 124.134 340.183C130.589 343.518 152.794 341.088 158.302 339.698C160.683 316.251 163.099 270.21 162.674 257.622C161.18 213.358 146.148 214.04 137.837 200.337Z"
        fill="white"
      />
      <path
        d="M213.16 69.7166C186.292 -4.59125 135.623 -3.32429 87.8674 2.35092C65.9768 5.80255 45.4696 19.4315 37.9524 25.8146C14.3442 54.96 -10.2007 115.65 28.8637 166.499C89.2123 241.529 152.236 209.414 176.229 192.996C193.499 181.83 234.789 130.777 213.16 69.7166Z"
        fill="white"
      />
      <path
        d="M66.8141 11.5233C99.0863 43.4415 127.971 60.3955 137.546 64.2415C151.883 56.7758 178.388 32.8597 189.745 17.1689C198.072 16.3286 227.947 7.5866 244.939 2.35092C223.92 83.5804 161.458 122.222 137.546 130.609C100.802 125.324 25.1945 52.1685 0 5.63097C14.0624 7.39235 45.7789 10.0108 66.8141 11.5233Z"
        fill="white"
      />
      <path
        d="M60.5823 204.796C61.3465 220.633 69.9593 225.098 75.1349 227.704C85.4001 227.704 93.7218 219.403 93.7218 209.164C93.7218 198.925 85.4001 190.625 75.1349 190.625C64.8697 190.625 62.5918 195.095 60.5823 204.796Z"
        fill="white"
      />
      <path
        d="M81.1416 216.199C85.3197 220.833 85.9774 236.537 85.7839 243.809C66.6348 248.443 92.1478 302.87 69.9593 274.093C47.8764 245.454 56.5479 226.311 64.8654 215.113C69.8947 212.088 76.9635 211.565 81.1416 216.199Z"
        fill="white"
      />
      <path
        d="M69.9593 256.251C73.0997 241.27 89.2927 242.433 96.9967 244.887C106.848 248.06 105.704 257.274 103.146 260.271C105.037 261.956 104.513 266.835 101.058 268.643C104.342 271.765 102.252 275.183 99.6675 278.353C101.411 284.794 95.317 288.88 90.7397 288.581C86.1624 288.283 80.186 281.175 75.1349 276.74C69.7097 268.713 66.0339 274.977 69.9593 256.251Z"
        fill="white"
      />
      <path
        d="M16.8547 100.885C16.8547 100.885 17.7523 99.2514 20.2205 98.1911C21.2098 97.766 55.1086 105.721 83.0142 105.691C94.9705 116.016 114.12 131.287 137.206 136.232C150.912 132.984 170.548 116.356 180.851 104.042C189.902 103.892 197.79 102.781 201.847 102.491H204.471C204.471 102.491 206.439 102.79 207.096 103.218C208.36 104.042 208.602 105.497 208.602 105.497C208.748 106.902 208.639 108.017 208.602 109.133C208.089 124.673 194.605 147.818 189.599 151.503C184.836 155.008 173.949 160.729 169.089 162.168C163.403 163.852 149.939 169.052 121.166 169.052C85.0078 169.052 48.4569 159.26 38.9316 151.115C33.0082 146.05 23.1679 127.059 19.8782 116.666C18.452 113.106 16.7407 105.976 16.2843 104.205C15.8279 102.435 16.4744 101.254 16.8547 100.885Z"
        fill="white"
      />
      <path
        d="M122.673 333.719C121.079 334.96 122.009 341.314 122.673 344.336C122.673 349.572 122.673 352.771 124.471 356.844C125.91 360.101 133.223 363.07 135.378 362.989H170.693C171.114 361.503 171.651 353.982 169.123 350.336C165.964 345.779 162.965 344.288 160.778 342.833C161.993 340.216 161.069 333.186 160 331.974C159.611 332.905 156.501 334.98 154.994 335.901C153.179 335.998 141.861 339.051 138.829 339.283C135.038 339.574 129.575 336.822 128.165 335.901C126.756 334.98 124.666 332.168 122.673 333.719Z"
        fill="white"
      />
      <path
        d="M67.5921 334.775C63.2373 339.118 64.5139 344.697 65.6966 346.943C61.4682 349.076 62.7318 356.299 62.8776 357.754C63.0235 359.208 62.7318 361.002 65.3564 363.086C80.9193 373.015 100.767 365.171 102.906 363.086C105.045 361.002 105.042 353.584 104.313 350.336C105.246 346.109 103.924 341.723 103.146 340.059C101.656 341.222 89.8619 341.92 87.7622 342.386C81.6383 343.743 73.716 341.271 72.3552 340.059C69.6334 335.793 69.2932 336.132 67.5921 334.775Z"
        fill="white"
      />
      <path
        d="M191.248 212.485C181.54 223.765 163.333 219.3 156.875 216.522C162.14 212.164 162.254 203.488 163 197.174C165.661 200.903 184.581 201.533 191.248 200.897C191.611 201.928 191.878 207.153 191.248 212.485Z"
        fill="white"
        stroke="black"
        strokeWidth="1.92555"
      />
      <circle
        cx="153.609"
        cy="204.454"
        r="13.4789"
        transform="rotate(-136.473 153.609 204.454)"
        fill="url(#mobile_paint0_radial_2551_2914)"
        stroke="black"
        strokeWidth="1.92555"
      />
      <path
        d="M185.663 194.419C183.446 196.398 181.449 201.257 180.935 202.925C180.59 204.86 180.075 209.231 180.781 211.238C181.663 213.748 182.858 218.417 188.761 220.795C194.665 223.173 203.596 221.267 209.793 219.097C215.991 216.927 217.473 208.55 214.325 202.854C211.807 198.298 204.414 199.46 201.033 200.611C199.37 201.018 197.012 201.758 193.604 201.055C191.761 200.675 192.232 198.367 193.002 197.042C194.361 194.705 195.062 193.405 195.001 192.08C195.237 189.291 192.281 189.014 191.311 189.66C189.235 191.498 187.88 192.44 185.663 194.419Z"
        fill="url(#mobile_paint1_linear_2551_2914)"
        stroke="black"
        strokeWidth="1.9275"
        strokeLinecap="round"
      />
      <path
        d="M141.795 197.096C123.755 186.48 103.593 192.673 95.7674 197.096C80.9662 224.239 70.5265 330.307 69.9697 340.19C80.2702 346.129 93.3547 342.324 96.9274 341.257C96.4634 329.936 102.774 310.123 104.351 305.066C107.033 296.467 113.26 290.574 114.466 288.393C118.642 290.141 122.539 291.224 127.318 290.172C126.623 298.385 127.643 332.441 129.128 339.494C135.29 342.686 147.347 340.824 152.606 339.494C154.879 317.052 158.812 269.412 158.174 258.25C156.735 233.086 149.729 210.212 141.795 197.096Z"
        fill="white"
        stroke="black"
        strokeWidth="1.85595"
      />
      <path
        d="M210.937 76.5492C185.288 5.42925 122.053 0.20786 93.6413 6.48715C72.7433 9.7907 53.1661 22.835 45.9898 28.9442C25.9919 48.3388 -6.82036 106 37.3132 163.593C99.7574 230.952 153.92 202.587 176.826 186.873C193.313 176.186 222.296 135.94 210.937 76.5492Z"
        fill="white"
        stroke="#071C36"
        strokeWidth="1.85595"
      />
      <path
        d="M75.5839 17.6109C106.393 48.1598 132.376 63.1589 141.516 66.8399C155.204 59.6945 180.507 36.8045 191.349 21.7868C199.298 20.9825 218.65 18.3997 234.871 13.3886C211.114 76.166 164.345 122.333 141.516 130.36C106.439 125.302 37.8773 51.6057 10.2082 10.7439C23.6329 12.4297 55.5026 16.1633 75.5839 17.6109Z"
        fill="url(#mobile_paint2_linear_2551_2914)"
        stroke="#071C36"
        strokeWidth="1.85595"
      />
      <circle
        r="16.8002"
        transform="matrix(-1 0 0 1 81.9352 205.545)"
        fill="url(#mobile_paint3_radial_2551_2914)"
        stroke="black"
        strokeWidth="1.88766"
      />
      <path
        d="M87.6696 212.278C91.6582 216.713 92.2861 231.743 92.1014 238.704C73.8206 243.139 77.6977 260.879 77.6977 270.858C51.1069 252.231 60.3398 226.138 68.2801 215.42C73.0814 212.524 83.681 207.843 87.6696 212.278Z"
        fill="white"
        stroke="black"
        strokeWidth="1.88766"
      />
      <path
        d="M76.9944 250.612C79.9923 236.274 95.4511 237.387 102.806 239.735C112.21 242.772 111.118 251.591 108.676 254.459C110.482 256.072 109.981 260.742 106.683 262.472C109.818 265.46 107.823 268.731 105.355 271.766C107.02 277.93 101.202 281.841 96.8324 281.555C92.4627 281.27 85.4805 280.272 80.6585 276.028C75.4793 268.345 73.247 268.534 76.9944 250.612Z"
        fill="url(#mobile_paint4_linear_2551_2914)"
      />
      <path
        d="M105.355 271.766C107.02 277.93 101.202 281.841 96.8324 281.555C92.4627 281.27 85.4805 280.272 80.6585 276.028C75.4793 268.345 73.247 268.534 76.9944 250.612C79.9923 236.274 95.4511 237.387 102.806 239.735C112.21 242.772 111.118 251.591 108.676 254.459M105.355 271.766C107.823 268.731 109.818 265.46 106.683 262.472M105.355 271.766C102.813 271.741 97.0932 271.398 94.5471 270.222M106.683 262.472C109.981 260.742 110.482 256.072 108.676 254.459M106.683 262.472C103.943 262.645 97.7558 262.721 94.9247 261.64M108.676 254.459C105.422 254.277 98.3708 253.665 96.2047 252.678"
        stroke="black"
        strokeWidth="1.88766"
      />
      <path
        d="M26.2984 101.912C26.2984 101.912 27.1553 100.348 29.5115 99.3329C30.456 98.9261 62.8175 106.54 89.4577 106.511C100.872 116.394 119.153 131.009 141.192 135.742C154.277 132.633 173.022 116.718 182.858 104.933C191.498 104.789 199.029 103.726 202.902 103.448H205.407C205.407 103.448 207.285 103.734 207.913 104.144C209.119 104.933 209.351 106.325 209.351 106.325C209.49 107.671 209.386 108.738 209.351 109.805C208.861 124.679 195.988 146.831 191.209 150.357C186.662 153.713 176.269 159.187 171.629 160.565C166.201 162.177 153.348 167.154 125.88 167.154C91.3608 167.154 56.4674 157.781 47.3741 149.986C41.7193 145.139 32.3252 126.962 29.1847 117.015C27.8232 113.608 26.1895 106.784 25.7538 105.089C25.3181 103.394 25.9353 102.265 26.2984 101.912Z"
        fill="url(#mobile_paint5_linear_2551_2914)"
        stroke="#071C36"
        strokeWidth="1.85595"
      />
      <path
        d="M127.733 333.308C126.211 334.495 127.099 340.577 127.733 343.469C127.733 348.48 127.733 351.542 129.449 355.44C130.823 358.558 135.28 359.183 137.337 359.105L166.244 358.502C166.646 357.079 166.847 353.361 164.434 349.872C161.418 345.51 157.057 343.886 154.969 342.495C156.129 339.989 155.247 333.261 154.226 332.101C153.855 332.992 150.886 334.978 149.447 335.859C147.715 335.952 143.527 336.194 140.632 336.416C137.012 336.695 134.321 336.277 132.976 335.396C131.63 334.514 129.635 331.823 127.733 333.308Z"
        fill="url(#mobile_paint6_linear_2551_2914)"
      />
      <path
        d="M71.1263 333.122C66.969 337.279 68.1877 342.618 69.3168 344.768C65.2801 346.81 66.4865 353.723 66.6257 355.115C66.7649 356.507 66.4865 358.224 68.992 360.219C83.2364 368.431 97.156 361.518 99.1975 359.523C101.239 357.528 98.9656 349.454 98.2696 346.346C99.1604 342.3 97.8984 338.102 97.156 336.509C95.7331 337.623 92.3862 339.961 90.3818 340.407C84.5356 341.706 76.9726 339.339 75.6734 338.179C73.0751 334.096 72.7503 334.421 71.1263 333.122Z"
        fill="url(#mobile_paint7_linear_2551_2914)"
      />
      <path
        d="M127.733 333.308C126.211 334.495 127.099 340.577 127.733 343.469C127.733 348.48 127.733 351.542 129.449 355.44C130.823 358.558 135.28 359.183 137.337 359.105L166.244 358.502C166.646 357.079 166.847 353.361 164.434 349.872C161.418 345.51 157.057 343.886 154.969 342.495C156.129 339.989 155.247 333.261 154.226 332.101C153.855 332.992 150.886 334.978 149.447 335.859C147.715 335.952 143.527 336.194 140.632 336.416C137.012 336.695 134.321 336.277 132.976 335.396C131.63 334.514 129.635 331.823 127.733 333.308Z"
        stroke="black"
        strokeWidth="1.85595"
      />
      <path
        d="M71.1263 333.122C66.969 337.279 68.1877 342.618 69.3168 344.768C65.2801 346.81 66.4865 353.723 66.6257 355.115C66.7649 356.507 66.4865 358.224 68.992 360.219C83.2364 368.431 97.156 361.518 99.1975 359.523C101.239 357.528 98.9656 349.454 98.2696 346.346C99.1604 342.3 97.8984 338.102 97.156 336.509C95.7331 337.623 92.3862 339.961 90.3818 340.407C84.5356 341.706 76.9726 339.339 75.6734 338.179C73.0751 334.096 72.7503 334.421 71.1263 333.122Z"
        stroke="black"
        strokeWidth="1.85595"
      />
      <defs>
        <radialGradient
          id="mobile_paint0_radial_2551_2914"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(153.609 204.454) rotate(90) scale(14.4417)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="mobile_paint1_linear_2551_2914"
          x1="209.769"
          y1="213.552"
          x2="184.343"
          y2="197.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </linearGradient>
        <linearGradient
          id="mobile_paint2_linear_2551_2914"
          x1="122.539"
          y1="10.7439"
          x2="122.539"
          y2="130.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <radialGradient
          id="mobile_paint3_radial_2551_2914"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.744 17.744) rotate(90) scale(17.744)"
        >
          <stop stopColor="#143763" />
          <stop offset="1" stopColor="#021E34" />
        </radialGradient>
        <linearGradient
          id="mobile_paint4_linear_2551_2914"
          x1="83.471"
          y1="238.329"
          x2="95.1634"
          y2="259.441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#021E34" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="mobile_paint5_linear_2551_2914"
          x1="117.482"
          y1="-10.182"
          x2="117.482"
          y2="167.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4B4B4" />
          <stop offset="1" stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="mobile_paint6_linear_2551_2914"
          x1="116.42"
          y1="332.101"
          x2="116.42"
          y2="364.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
        <linearGradient
          id="mobile_paint7_linear_2551_2914"
          x1="116.42"
          y1="332.101"
          x2="116.42"
          y2="364.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#071C36" />
          <stop offset="1" stopColor="#143763" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
}
