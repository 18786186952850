import React, { useEffect } from "react";
import axiosInstance from "../../../api/interceptor";
import { ScrollingLine } from "../../../components/ScrollingLine/ScrollingLine";
import { HighlightType } from "../../../types/types";
import parse from "html-react-parser";
import { Highlight } from "../../../components/Highlight/Highlight";

import downloadIcon from "../../../assets/images/mtm-button-icon.svg";
import { BulletList } from "../../../components/BulletList/BulletList";
import { DigitalCarousel } from "../../../components/DigitalCarousel/DigitalCarousel";
import { spaceshipAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { SwiperImageDescription } from "../SubCyberSecurity/SubCyberSecurity";

import "./SubDigital.css";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";

const { REACT_APP_BASE_URL } = process.env;

type SubDigitalType = {
  DigitalMain: {
    title: string;
    description: string;
    highlight: HighlightType[];
    icon: { url: string };
  };
  dssSection: {
    title: string;
    subtitle: string;
    description: string;
    advantages: { id: number; title: string }[];
    metamorphosis: {
      image: { url: string };
      description: string;
      file: { name: string; url: string };
    };
    dssCarousel: CarouselType[];
  };
  digitalEnablersSection: {
    title: string;
    description: string;
    subtitle: string;
    enablersTechnology: { id: number; image: { url: string }; link: string }[];
    enablersHighlight: HighlightType[];
    enablersCarousel: CarouselType[];
  };
};

type CarouselType = {
  id: number;
  background: { url: string };
  title: string;
  logo: { url: string };
  coloredLogo: { url: string };
};

export function SubDigital() {
  const [digital, setDigital] = React.useState<SubDigitalType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showAnimatedLine, setShowAnimatedLine] =
    React.useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("digital-factory")
      .then((res) => {
        setDigital(res.data);
        setShowAnimatedLine(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="digital-factory">
      {isLoading && <CustomSpinner />}
      {showAnimatedLine && (
        <ScrollingLine className="line-wrapper" page="digital" />
      )}
      <div className="content">
        <div className="digital-main">
          <div className="digital-icon fade-once">{spaceshipAnimated()}</div>
          <div className="digital-icon-mobile">
            <img
              src={`${REACT_APP_BASE_URL}${digital?.DigitalMain.icon.url}`}
              alt=""
            />
          </div>
          <h1 className="fade-once">{digital?.DigitalMain.title}</h1>
          <div className="fade-once">
            {parse((digital?.DigitalMain.description as string) ?? "")}
          </div>
          <div className="highlights-content-desktop">
            {digital?.DigitalMain.highlight.map((highlight: HighlightType) => (
              <React.Fragment key={highlight.id}>
                <Highlight {...highlight} />
              </React.Fragment>
            ))}
          </div>
          <div className="group-content-mobile fade-in">
            <SwiperImageDescription
              content={digital?.DigitalMain.highlight}
              slidesPerView={1}
            />
          </div>
        </div>
        <div className="dss-section">
          <h2 className="fade-in">{digital?.dssSection.title}</h2>
          <div className="description fade-in">
            {parse((digital?.dssSection.description as string) ?? "")}
          </div>
          <div className="metamorphosis-content">
            <img
              className="fade-in"
              src={`${REACT_APP_BASE_URL}${digital?.dssSection.metamorphosis.image.url}`}
              alt=""
            />
            <div className="metamorphosis-text-button fade-in">
              {parse(
                (digital?.dssSection.metamorphosis.description as string) ?? ""
              )}
              <button className="metamorphosis-button">
                <a
                  href={`${REACT_APP_BASE_URL}${digital?.dssSection.metamorphosis.file.url}`}
                  download={`${digital?.dssSection.metamorphosis.file.name}`}
                  target="_blank"
                >
                  <img src={downloadIcon} alt="download" />
                  VISUALIZZA PDF
                </a>
              </button>
            </div>
          </div>
          <div className="dss-advantages-section">
            <BulletList
              title={digital?.dssSection.subtitle as string}
              content={digital?.dssSection.advantages ?? [{ title: "" }]}
            />
          </div>
          <div className="carousel-section">
            <DigitalCarousel
              title="Le nostre storie di successo"
              items={digital?.dssSection.dssCarousel}
            />
          </div>
        </div>
        <div className="enablers-section">
          <h1 className="fade-in">{digital?.digitalEnablersSection.title}</h1>
          <div className="description fade-in">
            {parse(
              (digital?.digitalEnablersSection.description as string) ?? ""
            )}
          </div>
          <div className="enablers-highlights">
            {digital?.digitalEnablersSection.enablersHighlight.map(
              (highlight: HighlightType) => {
                return (
                  <div
                    className="enablers-highlight fade-in"
                    key={highlight.id}
                  >
                    <div className="highlight-image-container">
                      <img
                        src={`${REACT_APP_BASE_URL}${highlight.image?.url}`}
                      />
                    </div>
                    <div className="highlight-text">
                      <p style={{ fontWeight: "bold" }}>
                        {highlight.title.toUpperCase()}
                      </p>
                      {parse((highlight.description as string) ?? "")}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="enablers-technologies">
            <h2 className="fade-in">
              {digital?.digitalEnablersSection.subtitle}
            </h2>
            <div className="technologies-container">
              {digital?.digitalEnablersSection.enablersTechnology.map(
                (technology: {
                  id: number;
                  image: { url: string };
                  link: string;
                }) => {
                  return (
                    <a
                      className="fade-in"
                      key={technology.id}
                      href={technology.link}
                      target="_blank"
                    >
                      <img
                        src={`${REACT_APP_BASE_URL}${technology.image.url}`}
                        alt="technology"
                      />
                    </a>
                  );
                }
              )}
            </div>
            <div className="group-content-mobile fade-in">
              <SwiperImageDescription
                content={digital?.digitalEnablersSection.enablersTechnology}
                slidesPerView={1}
              />
            </div>
          </div>
        </div>
        {/* DECOMMENTARE PER VISUALIZZARE IL SECONDO CAROUSEL */}
        {/* <div className="carousel-section">
          <DigitalCarousel
            title="Le nostre storie di successo"
            items={digital?.digitalEnablersSection.enablersCarousel}
          />
        </div> */}
      </div>
    </div>
  );
}
