import "./CustomSpinner.css";

export function CustomSpinner() {
  return (
    <div className="spinner-box">
      {/* <span>Loading...</span> */}
      <div className="solar-system">
        <div className="earth-orbit orbit">
          <div className="planet earth"></div>
          <div className="venus-orbit orbit">
            <div className="planet venus"></div>
            <div className="mercury-orbit orbit">
              <div className="planet mercury"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
