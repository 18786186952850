import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Twirl as Hamburger } from "hamburger-react";
import { useEffect, useRef, useState } from "react";

import "./Header.css";
import useScrollColor from "../../hooks/useScrollColor";
import axiosInstance from "../../api/interceptor";
import logo8bit from "../../assets/images/gaming-page/logo-8bit.svg";
import logo8bitWhite from "../../assets/images/gaming-page/logo-8bit-white.svg";

const { REACT_APP_BASE_URL } = process.env;

type StrapiImageType = {
  url: string;
};

type HeaderType = {
  Header: {
    logoBianco?: StrapiImageType;
    logoBlu?: StrapiImageType;
    logoColori?: StrapiImageType;
  };
  menu: {
    iconeMenu: {
      iconEmail: StrapiImageType;
      iconLinkedin: StrapiImageType;
      iconYoutube: StrapiImageType;
    };
    menuArrowDown: StrapiImageType;
    menuArrowUp: StrapiImageType;
    newPage: StrapiImageType;
    submenuArrow: StrapiImageType;
  };
};

type SubMenuItemType = {
  id?: number;
  link?: string;
  name?: string;
};

type SubMenuType = {
  item: SubMenuItemType;
  onClick: () => void;
};

export function Header() {
  const ref = useRef<any>();
  const location = useLocation();

  const [header, setHeader] = React.useState<HeaderType>();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [menuClicked, setMenuClicked] = useState<boolean | undefined>(
    undefined
  );
  const [submenuClicked, setSubmenuClicked] = useState<boolean>(false);

  const { scrollColor } = useScrollColor();

  useEffect(() => {
    axiosInstance
      .get("header")
      .then((res) => setHeader(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (menuClicked && ref.current && !ref.current.contains(e.target)) {
        setMenuClicked(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    // Disable scrolling.
    var x = window.matchMedia("(max-width: 700px)");
    if (x.matches) {
      document.body.style.overflow = menuClicked ? "hidden" : "auto";
      document.body.style.position = menuClicked ? "fixed" : "static";
      document.ontouchmove = function (e) {
        //e?.preventDefault();
      };
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);

      // Enable scrolling.
      document.ontouchmove = function (e) {
        return true;
      };

      document.body.style.overflow = "auto";
      document.body.style.position = "static";
    };
  }, [menuClicked]);

  function openEmailClient() {
    const message = {
      emailId: "info@keybiz.it",
      subject: "",
      from: "",
    };

    const email = message.emailId;
    const subject = message.subject;
    const emailBody = message.from;
    document.location =
      "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  }

  const subMenuList: Array<{}> = [
    {
      id: 1,
      name: "Digital Factory",
      link: "digital-factory",
    },
    {
      id: 2,
      name: "Cyber Security",
      link: "cyber-security",
    },
    {
      id: 3,
      name: "Engineering",
      link: "engineering",
    },
    {
      id: 4,
      name: "R&D",
      link: "r&d",
    },
  ];

  function getHeader() {
    return (
      <>
        <div
          className={isHover ? "header hover" : "header"}
          style={{ background: isHover ? "white" : scrollColor }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Link to="">
            <img
              src={
                !isHover && location.pathname !== "/game-room"
                  ? `${REACT_APP_BASE_URL}${header?.Header?.logoBianco?.url}`
                  : isHover && location.pathname !== "/game-room"
                  ? `${REACT_APP_BASE_URL}${header?.Header?.logoColori?.url}`
                  : !isHover && location.pathname === "/game-room"
                  ? logo8bitWhite
                  : logo8bit
              }
              alt="logo kb"
              className={`logo-header`}
            />
          </Link>
        </div>
        <div className="header-mobile">
          <Link to="">
            <img
              src={
                location.pathname !== "/game-room"
                  ? `${REACT_APP_BASE_URL}${header?.Header?.logoBlu?.url}`
                  : logo8bit
              }
              alt="logo kb"
              className={`logo-header`}
            />
          </Link>
        </div>
      </>
    );
  }

  function getHamburger() {
    return (
      <>
        <div
          className="desktop-hamburger-container"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Hamburger
            //className="hamburger"
            color={isHover || menuClicked ? "#021E32" : "#fff"}
            size={25}
            toggled={menuClicked}
            toggle={() => setMenuClicked(!menuClicked)}
          />
        </div>
        <div className="mobile-hamburger-container">
          <Hamburger
            //className="hamburger"
            color="#021E32"
            size={25}
            toggled={menuClicked}
            toggle={() => setMenuClicked(!menuClicked)}
          />
        </div>
      </>
    );
  }

  function hamburgerClickHandler() {
    if (menuClicked === null) return "";
    else if (!!menuClicked) return "opened";
    else return "closed";
  }

  function menuItem(pageName: string, path: string, isNew?: boolean) {
    const pathname = `/${path}`;
    return (
      <div
        className="list-item"
        style={{
          fontWeight: window.location.pathname === pathname ? "bold" : "normal",
        }}
      >
        <Link to={pathname} onClick={() => setMenuClicked(false)}>
          <li>{pageName}</li>
        </Link>
        {!!isNew && (
          <div className="menu-button">
            <img
              src={`${REACT_APP_BASE_URL}${header?.menu?.newPage?.url}`}
              alt="new button"
            />
          </div>
        )}
      </div>
    );
  }

  function SubMenuItem({ item, onClick }: SubMenuType) {
    const pathname = `/cosa-facciamo/${item.link}`;
    return (
      <li
        key={item.id}
        style={{
          fontWeight: window.location.pathname === pathname ? "bold" : "normal",
        }}
      >
        <Link to={pathname} onClick={onClick}>
          <img
            src={`${REACT_APP_BASE_URL}${header?.menu?.submenuArrow?.url}`}
            alt="freccia submenu"
          />
          {item.name}
        </Link>
      </li>
    );
  }

  function getSubMenu(menuVoice: string, subMenuList: Array<{}>) {
    return (
      <div className="submenu-container">
        <div className="label-container">
          <label htmlFor="touch">
            {!submenuClicked ? (
              <span>{menuVoice}</span>
            ) : (
              <span>
                <b>{menuVoice}</b>
              </span>
            )}
            {!submenuClicked ? (
              <img
                src={`${REACT_APP_BASE_URL}${header?.menu?.menuArrowDown?.url}`}
                alt="menu arrow"
              />
            ) : (
              <img
                src={`${REACT_APP_BASE_URL}${header?.menu?.menuArrowUp?.url}`}
                alt="menu arrow"
              />
            )}
          </label>
        </div>
        <input
          type="checkbox"
          id="touch"
          onChange={() => setSubmenuClicked(!submenuClicked)}
        />
        <ul className="slide">
          {subMenuList.map((subMenuItem, index) => (
            <SubMenuItem
              key={index}
              item={subMenuItem}
              onClick={() => setMenuClicked(false)}
            />
          ))}
        </ul>
      </div>
    );
  }

  function getMenu() {
    return (
      <div ref={ref} className={`side-menu ${hamburgerClickHandler()}`}>
        {getHamburger()}
        <ul className="menu-list">
          {menuItem("HOME", "", false)}
          {menuItem("CHI SIAMO", "about-us")}
          
          {getSubMenu("COSA FACCIAMO", subMenuList)}

          {menuItem("LA NOSTRA MASCOTTE", "byz", false)}
          {menuItem("KEYBIZ ART", "keybiz-art", true)}
          {menuItem("GAME ROOM", "game-room", true)}
          {menuItem("CONTATTACI", "contact-us", false)}
        </ul>
        <div className="icons-menu">
          <a
            href="https://www.linkedin.com/company/keybiz/mycompany/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={`${REACT_APP_BASE_URL}${header?.menu?.iconeMenu?.iconLinkedin?.url}`}
              alt="icona linkedin"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCXLbpzDzra6e86MEMNKGwVg"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={`${REACT_APP_BASE_URL}${header?.menu?.iconeMenu?.iconYoutube?.url}`}
              alt="icona youtube"
            />
          </a>
          <a>
            <img
              src={`${REACT_APP_BASE_URL}${header?.menu?.iconeMenu?.iconEmail?.url}`}
              alt="icona email"
              onClick={() => openEmailClient()}
            />
          </a>
        </div>
        <div className="menu-copyright">
          <p>© 2022 KeyBiz srl</p>
        </div>
      </div>
    );
  }

  return (
    <>
      {getHeader()}
      {getMenu()}
    </>
  );
}
