import { Link } from "react-scroll";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./AnimatedMouse.css";

export function AnimatedMouse(props: any) {
  const { reference } = props;
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const svgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: 0,
      },
    },
  };

  const childVariants1 = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        repeat: Infinity,
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const childVariants2 = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        repeat: Infinity,
        duration: 0.5,
        ease: "easeInOut",
        delay: 0.3,
      },
    },
  };

  const childVariants3 = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        repeat: Infinity,
        duration: 0.5,
        ease: "easeInOut",
        delay: 0.6,
      },
    },
  };

  return (
    <div className="mouse-container">
      <Link to={reference} spy={true} smooth={true}>
        <motion.svg
          width="23"
          height="51"
          viewBox="0 0 23 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          cursor="pointer"
          ref={ref}
          animate={controls}
          variants={svgVariants}
          initial="hidden"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.58333 1.68675H13.4167C17.7685 1.68675 21.2963 5.17946 21.2963 9.48795V25.512C21.2963 29.8205 17.7685 33.3133 13.4167 33.3133H9.58333C5.23153 33.3133 1.7037 29.8205 1.7037 25.512V9.48795C1.7037 5.17947 5.23153 1.68675 9.58333 1.68675ZM13.4167 0C18.7094 0 23 4.2479 23 9.48795V25.512C23 30.7521 18.7094 35 13.4167 35H9.58333C4.2906 35 0 30.7521 0 25.512V9.48795C0 4.2479 4.2906 0 9.58333 0H13.4167Z"
            fill="white"
          />
          <motion.path
            d="M16.7063 39.1892C16.4007 38.9369 15.9551 38.9369 15.6494 39.1892L12.6681 41.6497C12.2817 41.9686 11.7183 41.9686 11.3319 41.6497L8.35057 39.1892C8.04494 38.9369 7.59929 38.9369 7.29365 39.1892C6.90211 39.5123 6.90211 40.1048 7.29365 40.428L11.3319 43.7608C11.7183 44.0797 12.2817 44.0797 12.6681 43.7608L16.7063 40.428C17.0979 40.1048 17.0979 39.5123 16.7063 39.1892Z"
            fill="white"
            variants={childVariants2}
            initial="hidden"
            animate="visible"
          />
          <motion.path
            d="M16.7063 46.1892C16.4007 45.9369 15.9551 45.9369 15.6494 46.1892L12.6681 48.6497C12.2817 48.9686 11.7183 48.9686 11.3319 48.6497L8.35057 46.1892C8.04494 45.9369 7.59929 45.9369 7.29365 46.1892C6.90211 46.5123 6.90211 47.1048 7.29365 47.428L11.3319 50.7608C11.7183 51.0797 12.2817 51.0797 12.6681 50.7608L16.7063 47.428C17.0979 47.1048 17.0979 46.5123 16.7063 46.1892Z"
            fill="white"
            variants={childVariants3}
            initial="hidden"
            animate="visible"
          />
          <motion.path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 7C10.6716 7 10 7.55964 10 8.25L10 10.75C10 11.4404 10.6716 12 11.5 12C12.3284 12 13 11.4404 13 10.75L13 8.25C13 7.55964 12.3284 7 11.5 7Z"
            fill="white"
            variants={childVariants1}
            initial="hidden"
            animate="visible"
          />
        </motion.svg>
      </Link>
    </div>
  );
}
