import {
  MaurizioSacco,
  MicheleColella,
  PaoloPenna,
} from "../BossesSvg/BossesSvg";
import {
  LogoEvolutionElements,
  LogoEvolutionText,
  LogoEvolutionTitle,
} from "../LogoEvolutionSection/LogoEvolution";
import {
  TimelineElements,
  TimelineTitleText,
} from "../TimelineSection/Timeline";

const { REACT_APP_BASE_URL } = process.env;

type IconType = {
  url: string;
};

export function AboutUsLine(icon: IconType) {
  return (
    <svg
      width="100%"
      height="400vh"
      className="aboutus-line"
      preserveAspectRatio="none"
      viewBox="0 0 1520 2887"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="aboutPath"
        d="M0 210H1387.5V850.5H200.5V1045H1387.5V1400H127.5V1710H1387.5V1950H127.5V2310H1387.5V2560H127.5V2760H1530"
        stroke="white"
        strokeWidth="2"
      />
      <PaoloPenna />
      <TextPaoloPenna url={icon.url as string} />
      <MaurizioSacco />
      <TextMaurizioSacco url={icon.url as string} />
      <MicheleColella />
      <TextMicheleColella url={icon.url as string} />
      <circle
        preserveAspectRatio="none"
        id="circle-paolo"
        cx="375"
        cy="850"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        //preserveAspectRatio="none"
        id="circle-maurizio"
        cx="1125"
        cy="850"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        //preserveAspectRatio="none"
        id="circle-michele"
        cx="650"
        cy="1045"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <LogoEvolutionTitle />
      <LogoEvolutionText />
      <LogoEvolutionElements />
      <TimelineTitleText />
      <TimelineElements />
    </svg>
  );
}

function TextPaoloPenna(icon: IconType) {
  return (
    <svg
      className="paolo-text"
      x="180"
      y="-668"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="50" y="48">
        PAOLO PENNA
      </text>
      <a
        href="https://www.linkedin.com/in/paolo-penna-58a32251/"
        target="_blank"
        rel="noreferrer"
      >
        <image
          className="img-circle"
          xlinkHref={`${REACT_APP_BASE_URL}${icon.url}`}
          x="73.5"
          y="44.8"
          height="4"
          width="4"
        />
      </a>
      <text x="50" y="58">
        Founder & CFO
      </text>
    </svg>
  );
}

function TextMaurizioSacco(icon: IconType) {
  return (
    <svg
      className="maurizio-text"
      x="200"
      y="-668"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="102" y="48">
        MAURIZIO SACCO
      </text>
      <a
        href="https://www.linkedin.com/in/maurizio-sacco-61a8084/"
        target="_blank"
        rel="noreferrer"
      >
        <image
          className="img-circle"
          xlinkHref={`${REACT_APP_BASE_URL}${icon.url}`}
          x="96.5"
          y="44.8"
          height="4"
          width="4"
        />
      </a>
      <text x="105.7" y="58">
        Founder & CCO
      </text>
    </svg>
  );
}

function TextMicheleColella(icon: IconType) {
  return (
    <svg
      className="michele-text"
      x="140"
      y="-475"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="100.5" y="48">
        MICHELE COLELLA
      </text>
      <a
        href="https://www.linkedin.com/in/colellamichele/"
        target="_blank"
        rel="noreferrer"
      >
        <image
          className="img-circle"
          xlinkHref={`${REACT_APP_BASE_URL}${icon.url}`}
          x="130"
          y="44.8"
          height="4"
          width="4"
        />
      </a>
      <text x="100.5" y="58">
        CTO
      </text>
    </svg>
  );
}
