import "./CyberSecurity.css";
import React from "react";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { PageButton } from "../../PageButton/PageButton";
import { shieldAnimated } from "../../../animatedSVG/AnimatedIcons/AnimatedIcons";
import { ParagraphType } from "../../../types/types";
import parse from "html-react-parser";

type CyberSecurityType = {
  title: string;
  cyberParagraph: Array<ParagraphType>;
};

export function CyberSecurity(content: CyberSecurityType) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else controls.start("hidden");
  }, [controls, inView]);

  return (
    <div className="cyberSecurity-container">
      <div className="cyberSecurity-text__container">
        <div className="cyberSecurity-text fade-in">
          <div className="cyberSecurity-text__title">
            <h1>{content?.title?.toUpperCase()}</h1>
          </div>
          <div className="cyberSecurity-text__description">
            {content?.cyberParagraph?.map((paragraph) => {
              return (
                <React.Fragment key={paragraph.id}>
                  {parse(paragraph.description)}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="cyberSecurity-page-button fade-in">
          <PageButton
            text="scopri di più"
            linkToPage={"cosa-facciamo/cyber-security"}
          />
        </div>
      </div>
      <div className="cyberSecurity-image">
        {shieldAnimated(controls, ref)}
        <hr />
      </div>
    </div>
  );
}
