import React, { useEffect } from "react";
import axiosInstance from "../../api/interceptor";
import parse from "html-react-parser";
import gsap from "gsap";
import { MotionPathPlugin, ScrollTrigger, CustomEase } from "gsap/all";
import { AnimatedByz } from "../../animatedSVG/AnimatedByz";
import { AnimatedMouse } from "../../animatedSVG/AnimatedMouse/AnimatedMouse";

import "./Homepage.css";
import { AnimatedLine } from "../../animatedSVG/AnimatedLine/AnimatedLine";
import { AnimatedArrow } from "../../animatedSVG/AnimatedArrow/AnimatedArrow";
import { CyberSecurity } from "../../components/HomepageSections/CyberSecurity/CyberSecurity";
import { ResearchDevelopment } from "../../components/HomepageSections/ResearchDevelopment/ResearchDevelopment";
import Engineering from "../../components/HomepageSections/Engineering/Engineering";
import Carousel from "../../components/Carousel/Carousel";
import { CarouselItemType, ParagraphType } from "../../types/types";
import { InnovationFactory } from "../../components/HomepageSections/InnovationFactory/InnovationFactory";
import { CustomSpinner } from "../../components/CustomSpinner/CustomSpinner";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger, CustomEase);

type HomepageSectionType = {
  title: string;
  innovationParagraph?: Array<ParagraphType>;
  cyberParagraph?: Array<ParagraphType>;
  engineeringParagraph?: Array<ParagraphType>;
  researchParagraph?: Array<ParagraphType>;
};

type MainSectionType = {
  title: string;
  description: string;
};

type HomepageType = {
  MainSection: MainSectionType;
  InnovationFactory?: HomepageSectionType;
  CyberSecurity?: HomepageSectionType;
  Engineering?: HomepageSectionType;
  ResearchAndDevelopment?: HomepageSectionType;
};

export function HomepageScreen() {
  const [homepage, setHomepage] = React.useState<HomepageType>();
  const [partners, setPartners] = React.useState<Array<CarouselItemType>>();
  const [clients, setClients] = React.useState<Array<CarouselItemType>>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("home-page")
      .then((res) => setHomepage(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));

    axiosInstance
      .get("partners")
      .then((res) => setPartners(res.data))
      .catch((err) => console.log(err));

    axiosInstance
      .get("clients")
      .then((res) => setClients(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    gsap.to("#arrow", {
      duration: 10,
      lazy: false,
      ease: "sine.out",
      motionPath: {
        path: "#motionPath",
        autoRotate: true,
        align: "#motionPath",
      },
      scrollTrigger: {
        trigger: "#motionPath",
        start: "top 35%",
        end: "bottom top-=600px",
        //endTrigger: '#',
        scrub: 1,
        toggleActions: "restart none none none",
      },
    });

    gsap.set("#arrow", {
      xPercent: -50,
      yPercent: -50,
    });
  }, []);

  function MainSection() {
    return (
      <div className="content">
        <div className="byz-container">
          <div className="image-desktop">
            <AnimatedByz />
          </div>
        </div>
        <div className="text-container">
          <div className="title-wrapper">
            {parse((homepage?.MainSection.title as string) ?? "")}
          </div>
          <div className="description-wrapper-desktop">
            {parse((homepage?.MainSection.description as string) ?? "")}
          </div>
          <div className="description-wrapper-mobile">
            La nostra storia è guidata dalla nostra curiosità, professionalità
            ed efficienza.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="full-background">
      {isLoading && <CustomSpinner />}
      <div className="home-container">
        <AnimatedArrow />
        <AnimatedLine />
        <MainSection />
        <AnimatedMouse reference="innovation-section" />
      </div>
      <div className="innovation-section">
        <InnovationFactory
          title={homepage?.InnovationFactory?.title as string}
          innovationParagraph={
            homepage?.InnovationFactory
              ?.innovationParagraph as Array<ParagraphType>
          }
        />
        <AnimatedMouse reference="cyber-section" />
      </div>
      <div className="cyber-section">
        <CyberSecurity
          title={homepage?.CyberSecurity?.title as string}
          cyberParagraph={
            homepage?.CyberSecurity?.cyberParagraph as Array<ParagraphType>
          }
        />
        <AnimatedMouse reference="engineering-section" />
      </div>
      <div className="engineering-section">
        <Engineering
          title={homepage?.Engineering?.title as string}
          engineeringParagraph={
            homepage?.Engineering?.engineeringParagraph as Array<ParagraphType>
          }
        />
        <AnimatedMouse reference="red-section" />
      </div>
      <div className="red-section">
        <ResearchDevelopment
          title={homepage?.ResearchAndDevelopment?.title as string}
          researchParagraph={
            homepage?.ResearchAndDevelopment
              ?.researchParagraph as Array<ParagraphType>
          }
        />
        <AnimatedMouse reference="partners-section" />
      </div>
      <div className="partners-section">
        <Carousel title="clienti" items={clients} />
        <Carousel title="partner" items={partners} />
      </div>
    </div>
  );
}
