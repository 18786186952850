import React from "react";
import { css } from "styled-components/macro";

import background from "../../assets/images/gaming-page/background-gaming.png";
import { Arcade } from "./Arcade";
import arcadeBottomMobile from "../../assets/images/gaming-page/arcade-bottom.png";

import "./GamingPage.css";
import { ArcadeMobile } from "./ArcadeMobile";

export function GamingPage() {
  return (
    <>
      <div
        id="gaming-page"
        css={css`
          width: 100%;
          height: 100vh;
          position: relative;
        `}
      >
        <img
          src={background}
          alt=""
          css={css`
            width: 100%;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          `}
        />
        <div
          css={css`
            width: 100%;
            height: calc(100vh - 45px);
            position: relative;
            top: 45px;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: flex-end;
          `}
        >
          <Arcade />
        </div>
      </div>
      <div
        id="gaming-page__mobile"
        css={css`
          width: 100%;
          height: 100vh;
          position: relative;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 70%;
            position: relative;
            top: 45px;
            z-index: 0;
            display: flex;
            justify-content: center;
            align-items: flex-start;
          `}
        >
          <ArcadeMobile />
        </div>
        <img
          src={arcadeBottomMobile}
          alt=""
          css={css`
            width: 100%;
            height: 30%;
          `}
        />
      </div>
    </>
  );
}
