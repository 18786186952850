import forwardIcon from "../../assets/images/kbart/forward-icon.png";
import { ArtLogoCardType } from "../../types/types";
import "./ArtLogoCard.css";

const { REACT_APP_BASE_URL } = process.env;

export function ArtLogoCard(artLogo: ArtLogoCardType) {
  return (
    <div className="logo-card">
      <figure>
        <img
          src={`${REACT_APP_BASE_URL}${artLogo.artLogo.image.url}`}
          alt="Logo"
        />
      </figure>
      <div className="card-bottom">
        <p>{artLogo.artLogo.name}</p>
        <p>{new Date(artLogo.artLogo.date).toLocaleDateString()}</p>
        {artLogo?.artLogo.link && <hr />}
        <div className="link-container">
          {artLogo?.artLogo.link && (
            <a href={artLogo?.artLogo.link} target="_blank" rel="noreferrer">
              <img
                src={`${REACT_APP_BASE_URL}${artLogo.linkedinIcon}`}
                alt="logo linkedin"
              />
              <p>
                vai al post <img src={forwardIcon} alt="forward" />
              </p>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
