import parse from "html-react-parser";
import closeModalButton from "../../assets/images/close-circled-button.svg";
import closeButton from "../../assets/images/close-button.svg";

import "./SuccessCasesModal.css";

const { REACT_APP_BASE_URL } = process.env;

type SuccessCaseModalType = {
  caseDescription: string;
  background: string;
  title: string;
  logo: string;
  coloredLogo: string;
  closeModal: () => void;
};

export function SuccessCasesModal({
  caseDescription,
  background,
  title,
  logo,
  coloredLogo,
  closeModal,
}: SuccessCaseModalType) {
  return (
    <div className="success-case-modal__wrapper">
      <div className="success-case-info">
        <h2>{title.toUpperCase()}</h2>
        <img
          className="success-case-logo"
          src={`${REACT_APP_BASE_URL}${coloredLogo}`}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
        <img
          className="success-case-background"
          src={`${REACT_APP_BASE_URL}${background}`}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
      </div>
      <div
        className="success-case-text-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="close-modal-button__wrapper">
          <img
            className="close-desktop-modal__button"
            src={closeButton}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          />
        </div>
        <div className="success-case-mobile-info">
          <span
            style={{
              textAlign: "center",
              width: "100%",
              fontWeight: "bold",
              letterSpacing: "0.1rem",
              marginBottom: "5px",
            }}
          >
            {title}
          </span>
          <img src={`${REACT_APP_BASE_URL}${coloredLogo}`} alt="" />
          <img src={`${REACT_APP_BASE_URL}${background}`} alt="" />
        </div>
        {parse((caseDescription as string) ?? "")}
        <img
          className="close-mobile-modal__button"
          src={closeModalButton}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        />
      </div>
    </div>
  );
}
