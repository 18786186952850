import "./DigitalCarousel.css";
import Slider from "react-slick";
import { CarouselItemType } from "../../types/types";
import React from "react";
import { SuccessCasesModal } from "../SuccessCasesModal/SuccessCasesModal";

const { REACT_APP_BASE_URL } = process.env;

type CarouselType = {
  title: string;
  items?: Array<CarouselItemType>;
};

export function DigitalCarousel({ title, items }: CarouselType) {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [caseModalContent, setCaseModalContent] = React.useState<{
    background: string;
    caseDescription: string;
    title: string;
    logo: string;
    coloredLogo: string;
  }>();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  function onCarouselItemClick(
    background: string,
    caseDescription: string,
    title: string,
    logo: string,
    coloredLogo: string
  ) {
    setCaseModalContent({
      background,
      caseDescription,
      title,
      logo,
      coloredLogo,
    });
    setOpenModal(true);
  }

  function disableScroll() {
    document.body.classList.add("stop-scrolling");
  }

  function enableScroll() {
    document.body.classList.remove("stop-scrolling");
  }

  openModal ? disableScroll() : enableScroll();

  return (
    <div className="digital-carousel-container">
      {openModal && (
        <div
          className="success-case-modal__background"
          onClick={() => setOpenModal(false)}
        >
          <SuccessCasesModal
            background={caseModalContent?.background as string}
            caseDescription={caseModalContent?.caseDescription as string}
            title={caseModalContent?.title as string}
            logo={caseModalContent?.logo as string}
            coloredLogo={caseModalContent?.coloredLogo as string}
            closeModal={() => setOpenModal(false)}
          />
        </div>
      )}
      <h2>{title}</h2>
      <Slider {...settings}>
        {items?.map(
          ({
            id,
            logo,
            coloredLogo,
            background,
            title,
            caseDescription,
          }: CarouselItemType) => (
            <div
              key={id}
              className="digital-carousel-item fade-in"
              onClick={() =>
                onCarouselItemClick(
                  background?.url as string,
                  caseDescription as string,
                  title as string,
                  logo.url as string,
                  coloredLogo?.url as string
                )
              }
            >
              <img
                className="item-background"
                src={`${REACT_APP_BASE_URL}${background?.url}`}
                alt="item-background"
              />
              <p className="item-title">{title.toUpperCase()}</p>
              <img
                className="item-logo"
                src={`${REACT_APP_BASE_URL}${logo?.url}`}
                alt="carousel item"
              />
            </div>
          )
        )}
      </Slider>
    </div>
  );
}
