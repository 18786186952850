import {
  Logo2007,
  Logo2009,
  Logo2015,
  Logo2019,
  Logo2021,
} from "../LogosSvg/LogosSvg";

export function LogoEvolutionTitle() {
  return (
    <>
      <svg
        className="logo-evolution-title"
        x="180"
        y="-30"
        viewBox="0 0 240 80"
        stroke="white"
        strokeWidth="0"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text x="89" y="35">
          Evoluzione del Logo
        </text>
      </svg>
      <svg
        className="logo-evolution-description"
        x="180"
        y="-30"
        viewBox="0 0 240 80"
        stroke="white"
        strokeWidth="0"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text x="86" y="42">
          Il look di KeyBiz dalla fondazione ad oggi
        </text>
      </svg>
    </>
  );
}

export function LogoEvolutionText() {
  return (
    <svg
      className="logos-text"
      x="200"
      y="-300"
      viewBox="0 0 240 80"
      stroke="white"
      strokeWidth="0"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text className="logo2007-text" x="16.5" y="125">
        2007-2009
      </text>
      <text className="logo2009-text" x="74.8" y="125">
        2009-2015
      </text>
      <text className="logo2015-text" x="134.8" y="125">
        2015-2019
      </text>
      <text className="logo2019-text" x="119" y="161.5">
        2019-2021
      </text>
      <text className="logo2021-text" x="40" y="161.5">
        2021-Present
      </text>
    </svg>
  );
}

export function LogoEvolutionElements() {
  return (
    <g>
      <circle
        id="log2007-circle"
        cx="350"
        cy="1710"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="logo2007-active"
        cx="350"
        cy="1710"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <Logo2007 />
      <circle
        id="logo2009-circle"
        cx="720"
        cy="1710"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="logo2009-active"
        cx="720"
        cy="1710"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <Logo2009 />
      <circle
        id="logo2015-circle"
        cx="1100"
        cy="1710"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="logo2015-active"
        cx="1100"
        cy="1710"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <Logo2015 />
      <circle
        id="logo2019-circle"
        cx="1000"
        cy="1950"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="logo2019-active"
        cx="1000"
        cy="1950"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <Logo2019 />
      <circle
        id="logo2021-circle"
        cx="500"
        cy="1950"
        r="8"
        stroke="white"
        fill="white"
        strokeWidth="1.5"
      />
      <circle
        id="logo2021-active"
        cx="500"
        cy="1950"
        r="8"
        stroke="white"
        fill="none"
        strokeWidth="0.75"
      />
      <Logo2021 />
    </g>
  );
}
